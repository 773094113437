import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useRef
} from 'react';
import { BsFilterLeft } from 'react-icons/all';
import { IoIosArrowDown } from 'react-icons/all';
import {
  DynamicSort,
  FilterModal,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import Fetcher from '../Fetcher';
import deviceService from '../../services/deviceService';
import thingService from '../../services/thingService';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import Device from './Device';
import { getRandomString } from '../../utils/stringUtils';
import StatusContext from '../../context/StatusContext';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';
import { hasValue } from 'utils/helper_functions';

import style from './Devices.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';

function Devices({ refresh }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [devices, setDevices] = useState();
  const [allThings, setAllThings] = useState();
  const [filteredListLength, setFilteredListLength] = useState();
  const [lastRefresh, setLastRefresh] = useState(getRandomString());
  const [firstUpdate] = useState(refresh);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const [filteredList, setFilteredList] = useState();
  const [sortOptions] = useState([
    { key: 'status', value: 'Status' },
    { key: 'id', value: 'ID' },
    { key: 'createdAt', value: 'Date Created' },
    { key: 'lastModifiedAt', value: 'Date Modified' }
  ]);
  const [filterOptions] = useState([{ key: 'status', value: 'Status' }]);
  const sortRef = useRef();
  const filtertRef = useRef();

  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const handleOnFilter = data => {
    if (data) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    }
  };

  useEffect(() => {
    refresh !== firstUpdate && handleManualRefresh(); //children function of interest
  }, [refresh, firstUpdate]);

  const handleManualRefresh = () => {
    setFilteredListLength();
    setLastRefresh(getRandomString());
  };

  const fetchAll = useCallback(async () => {
    return Promise.all([
      deviceService.getDevicesByUserId({
        userId,
        syncUpAppName: product
      }),
      thingService.getThingsByUserId({
        userId,
        syncUpAppName: product
      })
    ]);
  }, [userId, product]);

  const handleLoad = useCallback(([devices, things]) => {
    let temp = [];
    devices &&
      devices.forEach(device => {
        if (things) {
          let associatedDevice = things.filter(
            x => x.currentDevice && device.id === x.currentDevice.id
          );
          if (associatedDevice && associatedDevice.lenght > 0) {
            let temp_obj = {
              ...device,
              ratePlan: associatedDevice[0].currentDevice
                ? associatedDevice[0].currentDevice.ratePlan
                : device.ratePlan
            };
            temp = [...temp, temp_obj];
          } else {
            temp = [...temp, device];
          }
        }
      });
    setDevices(temp);
    setAllThings(things);
    setFilteredList([...temp]);
    setFilteredListLength(temp.length);
  }, []);

  const handleRemoveDevice = async ({ id }) => {
    await deviceService.unregisterDevice({
      deviceId: id,
      userId,
      syncUpAppName: product
    });
    setDevices(devices =>
      devices.map(d => (d.id === id ? { ...d, status: 'DELETED' } : d))
    );
    setAllThings(
      allThings.map(p =>
        p.currentDevice && p.currentDevice.id === id
          ? {
              ...p,
              currentDevice: undefined
            }
          : p
      )
    );
  };

  const onRemoveRealDevice = async ({ id }) => {
    await deviceService.removeRealDriveDevice({
      deviceId: id,
      userId,
      syncUpAppName: product
    });
    setDevices(devices =>
      devices.map(d => (d.id === id ? { ...d, status: 'DELETED' } : d))
    );
    setAllThings(
      allThings.map(p =>
        p.currentDevice && p.currentDevice.id === id
          ? {
              ...p,
              currentDevice: undefined
            }
          : p
      )
    );
  };

  const handleAddDevice = async ({ thingId, hardwareId, product, model }) => {
    try {
      await thingService.linkDevice({
        thingId,
        hardwareId,
        product,
        model,
        userId,
        syncUpAppName: product
      });
      addSuccessMessage({ message: `Onboarding started for ${hardwareId}` });
    } catch (error) {
      addErrorMessage({ error });
    }

    setDevices(
      devices.map(d =>
        d.hardwareId === hardwareId ? { ...d, status: 'ACTIVE' } : d
      )
    );
    setAllThings(
      allThings.map(p =>
        p.id === thingId
          ? {
              ...p,
              currentDevice: devices.find(d => d.hardwareId === hardwareId)
            }
          : p
      )
    );
  };

  const availableThings = useMemo(
    () => (allThings || []).filter(p => !p.currentDevice),
    [allThings]
  );

  return (
    <>
      <PageHeader
        title={'Devices'}
        tabHeader
        subtitle={
          filteredListLength > 1 &&
          `${filteredListLength} Devices are available`
        }
      >
        {filteredListLength > 1 && (
          <DynamicSort
            ref={sortRef}
            listItems={filteredList}
            optionsList={sortOptions}
            onSort={e => {
              handleOnSort(e);
            }}
            renderIcon={<IoIosArrowDown />}
          />
        )}
        <FilterModal
          ref={filtertRef}
          options={filterOptions}
          originalList={devices}
          listItems={filteredList}
          onFilter={handleOnFilter}
          renderIcon={<BsFilterLeft />}
        />
        <TmoButton
          onClick={handleManualRefresh}
          type="magenta_secondary"
          tooltip="Refresh"
          icon={<ReactSVG src={refreshIcon} />}
        />
      </PageHeader>

      <div className={style.items}>
        <Fetcher
          key={lastRefresh}
          action={fetchAll}
          onLoad={handleLoad}
          render={() => {
            return devices && hasValue(devices) ? (
              <>
                {filteredList &&
                  filteredList.map(device => {
                    return (
                      <Device
                        key={device.id}
                        device={device}
                        pUserId={userId}
                        availableThings={availableThings}
                        onAddClicked={handleAddDevice}
                        onRemoveDevice={handleRemoveDevice}
                        onRemoveRealDevice={onRemoveRealDevice}
                        onPlugInDevice={handleManualRefresh}
                      />
                    );
                  })}
              </>
            ) : (
              <div className={style.message}>User does not have any Device</div>
            );
          }}
        />
      </div>
    </>
  );
}

export default Devices;
