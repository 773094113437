import React, { useEffect, useMemo, useCallback } from 'react';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import { storageWrite } from '../../utils/storageUtils';
import { STORAGE_KEYS, STORAGE_TYPES } from '../../utils/app_constants';
import usePersistableState from '../../hooks/usePersistableState';
import { getHomePageUrl } from '../../utils/helper_functions';
import logoutService from '../../services/logoutService';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

function LoggedInUserMsal({ children }) {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [idToken, setIdToken] = usePersistableState({
    storageKey: STORAGE_KEYS.OKTA_ID_TOKEN,
    storageType: STORAGE_TYPES.LOCAL
  });
  const [userInfo, setUserInfo] = usePersistableState({
    storageKey: STORAGE_KEYS.OKTA_USER_INFO,
    storageType: STORAGE_TYPES.LOCAL
  });

  const logout = useCallback(async () => {
    instance.logoutRedirect();
    setUserInfo(undefined);
    setIdToken(undefined);
    await logoutService.invalidateToken();
  }, [instance, setUserInfo, setIdToken]);

  const contextValue = useMemo(() => {
    const parsedUserInfo = userInfo && JSON.parse(userInfo);
    return {
      isAuthenticated,
      userInfo: parsedUserInfo,
      idToken,
      homePageUrl:
        parsedUserInfo &&
        getHomePageUrl(
          parsedUserInfo[STORAGE_KEYS.OKTA_USER_PLATFRM_GROUPS]
            ? parsedUserInfo[STORAGE_KEYS.OKTA_USER_PLATFRM_GROUPS]
            : parsedUserInfo[STORAGE_KEYS.OKTA_USER_GROUPS]
        ),
      logout
    };
  }, [isAuthenticated, userInfo, idToken, logout]);

  useEffect(() => {
    const authCheck = async () => {
      if (isAuthenticated) {
        try {
          let user = instance.getActiveAccount();
          let thingsPlatformGroups = user?.idTokenClaims?.ThingsPlatformGroups;
          user.ThingsPlatformGroups = Array.isArray(thingsPlatformGroups)
            ? thingsPlatformGroups
            : [thingsPlatformGroups];
          user.family_name =
            user.name && user.name.split(',').length > 1
              ? user.name.split(',')[1]
              : '';
          user.given_name = user.name ? user.name.split(',')[0] : '';
          user.email = user.username ? user.username : '';
          setUserInfo(JSON.stringify(user));
          if (user) {
            const response = await instance.acquireTokenSilent({
              account: user
            });
            setIdToken(response.idToken);
          }
        } catch {
          await logout();
        }
      } else if (isAuthenticated === false) {
        storageWrite({
          key: STORAGE_KEYS.INTENDED_URL,
          value: window.location.pathname,
          storageType: STORAGE_TYPES.SESSION
        });
        if (
          inProgress === InteractionStatus.None &&
          !instance.getActiveAccount()
        ) {
          instance.loginRedirect();
        }
      }
    };

    instance && authCheck();
  }, [isAuthenticated, setIdToken, inProgress, instance, setUserInfo, logout]);

  return (
    <LoggedInUserContext.Provider value={contextValue}>
      {children}
    </LoggedInUserContext.Provider>
  );
}

export default LoggedInUserMsal;
