import React from 'react';

import TmoCheckbox from '../../forms/TmoCheckbox';

import style from './DeviceZonesFilter.module.css';

function DeviceZonesFilter() {
  const dataSet = [
    { id: 121, value: '1st Floor', selection: true },
    { id: 70, value: '2nd Floor', selection: false },
    { id: 119, value: '3rd Floor', selection: false },
    { id: 96, value: 'Restaurant', selection: false },
    { id: 91, value: 'Spa', selection: false }
  ];

  const onCheckboxClicked = e => {};

  const listItems = dataSet.map((data, index) => (
    <div key={index} className={style.row}>
      <TmoCheckbox
        isChecked={data.selection}
        id={data.id}
        value={data.value}
        onChange={onCheckboxClicked}
      />
    </div>
  ));

  return <div className={style.container}>{listItems}</div>;
}

export default DeviceZonesFilter;
