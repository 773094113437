import React, { useState } from 'react';
import ThemeContext from '../context/ThemeContext';

function withTheme(BaseComponent) {
  return props => {
    const [theme, setTheme] = useState('theme-default');
    const value = { theme, setTheme };

    return (
      <ThemeContext.Provider value={value}>
        <div className={theme}>
          <BaseComponent {...props} />
        </div>
      </ThemeContext.Provider>
    );
  };
}

export default withTheme;
