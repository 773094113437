import React from 'react';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';

import style from './Tab.module.css';

function Tab({
  to,
  value,
  className,
  isActiveByDefault,
  match,
  location,
  isSelected,
  onSelect,
  text,
  disabled,
  theme
}) {
  return (
    <div className={classNames(style.container, className)}>
      {to ? (
        <NavLink
          to={to}
          aria-disabled={disabled}
          className={classNames(
            style.link,
            theme && style[theme],
            disabled && style.disabled
          )}
          exact
          activeClassName={style.link_active}
          isActive={
            isActiveByDefault
              ? onOwnPath => !!(onOwnPath || match.url === location.pathname)
              : null
          }
        >
          {text}
        </NavLink>
      ) : (
        <div
          className={classNames(
            style.link,
            isSelected && style.link_active,
            disabled && style.disabled,
            theme && style[theme]
          )}
          onClick={() => onSelect(value)}
        >
          {text}
        </div>
      )}
    </div>
  );
}

export default withRouter(Tab);
