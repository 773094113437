import React from 'react';
import { ExportDataAsFile, TmoH3 } from 'tmo-bps-syncup-web-component-lib';

import DeleteDevice from './DeleteDevice';
import AddDevices from './AddDevices';

import style from './ManageDevices.module.css';

function ManageDevices({ groupId, group, groupDevices, onRemove, onAdd }) {
  return (
    <>
      <AddDevices
        className={style.add_devices}
        onAdd={onAdd}
        group={{ name: group, id: groupId }}
        devices={groupDevices}
      />
      <div className={style.devices_list}>
        <div className={style.header}>
          <TmoH3 className={style.heading}>Devices</TmoH3>
          {groupDevices?.length > 0 && (
            <ExportDataAsFile
              buttonText="Export CSV"
              className={style.export_btn}
              data={groupDevices?.map(k => ({
                imei: k
              }))}
              fileName={group}
              fileExtension="csv"
            />
          )}
        </div>
        <div className={style.results}>
          <div className={style.item}>
            {groupDevices?.length > 0 ? (
              groupDevices.map(d => (
                <DeleteDevice
                  key={d}
                  group={{ name: group, id: groupId }}
                  onRemove={onRemove}
                  device={d}
                />
              ))
            ) : (
              <span className={style.no_device}>No devices in {group}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageDevices;
