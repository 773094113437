import mediaRepository from '../repositories/mediaRepository';

const mediaService = {
  addMediaMeta: async ({ assetId, assetName, syncUpAppName, body }) => {
    return await mediaRepository.addMediaMeta({
      assetId,
      assetName,
      syncUpAppName,
      body
    });
  },
  getMediaMeta: async ({ assetId, assetName, syncUpAppName }) => {
    return await mediaRepository.getMediaMeta({
      assetId,
      assetName,
      syncUpAppName
    });
  },
  updateMediaMeta: async ({ assetId, assetName, syncUpAppName, body }) => {
    return await mediaRepository.updateMediaMeta({
      assetId,
      assetName,
      syncUpAppName,
      body
    });
  },
  deleteMediaMeta: async ({ assetId, assetName, syncUpAppName }) => {
    return await mediaRepository.deleteMediaMeta({
      assetId,
      assetName,
      syncUpAppName
    });
  },
  getAllMedia: async ({ syncUpAppName }) => {
    return await mediaRepository.getAllMedia({ syncUpAppName });
  },
  uploadMediaAsset: async ({ assetId, assetName, syncUpAppName, body }) => {
    return await mediaRepository.uploadMediaAsset({
      assetId,
      assetName,
      syncUpAppName,
      body
    });
  },
  getMediaAsset: async ({ assetId, assetName, syncUpAppName }) => {
    return await mediaRepository.getMediaAsset({
      assetId,
      assetName,
      syncUpAppName
    });
  }
};

export default mediaService;
