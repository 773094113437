export const deepMerge = (first, second = {}) => {
  return Object.entries(first).reduce((acc, [key, value]) => {
    if (!Array.isArray(value) && typeof value === 'object' && value !== null) {
      acc[key] = deepMerge(first[key], second[key]);
    } else {
      acc[key] = second[key] || first[key];
    }
    return acc;
  }, {});
};

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );
