import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  TmoButtons,
  withModal,
  ConfirmationModalV2,
  TmoSvg,
  useVisible
} from 'tmo-bps-syncup-web-component-lib';

import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import iotFirmwareService from '../../../services/iotFirmwareService';
import RoleFeature from '../../featureWrappers/RoleFeature';
import StatusContext from '../../../context/StatusContext';
import SyncUpProductContext from '../../../context/SyncUpProductContext';
import { DATE_IN_TIME_ZONE } from '../../../utils/app_constants';
import { getTimestampList } from '../../../utils/dateUtils';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';
import ViewDevices from './devices/ViewDevices';
import AssignConfigWrapper from './AssignConfigWrapper';
import AssignConfigObject from './AssignConfigObject';

import style from './Groups.module.css';
import deleteIcon from '../../../static/assets/icons/action/delete.svg';

function Group({ details, onAdd, onAssignConfig, onRemove, openModal }) {
  const { visible, setVisible } = useVisible(false);
  const { groupName, id, firmwareId, updatedAt } = details;
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const handleRemoveGroup = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Group`, {
        groupId: id,
        product: syncUpProductContext.syncUpAppName
      });
      try {
        setVisible(true);
        await iotFirmwareService.deleteIotFirmwareGroup({
          groupId: id,
          product: syncUpProductContext.syncUpAppName
        });
        onRemove();
        setVisible(false);
        addSuccessMessage({
          message: `Successfully deleted group: ${groupName}`
        });
      } catch (error) {
        setVisible(false);
        addErrorMessage({ error });
      }
    }
  };

  return (
    <>
      <div className={style.items}>
        <div className={style.card_header}>
          <div className={style.card_title}>
            <label className={style.display_name} title={groupName}>
              {groupName}
            </label>
          </div>
          <div className={style.action_controls}>
            <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
              <div className={classNames(style.row, style.delete)}>
                <TmoButtons
                  onClick={handleRemoveGroup}
                  type="magenta_secondary"
                  icon={<ReactSVG src={deleteIcon} />}
                  className={style.delete_btn}
                  tooltip="Delete"
                />
                <ConfirmationModalV2
                  message={`Are you sure you want to remove ${groupName}?`}
                  title="Confirm Group Removal"
                  acceptButtonText="Delete"
                  modalType="delete"
                  type="danger"
                  confirmInput
                  usePortal={false}
                />
              </div>
            </RoleFeature>
          </div>
        </div>
        <div className={style.content}>
          <ItemDetailList
            rowSet={[
              { 'Group Id': id },
              {
                'Updated at': getTimestampList(
                  updatedAt,
                  null,
                  DATE_IN_TIME_ZONE.SYSTEM
                )
              },
              {
                'Assigned Configuration/Firmware Id': firmwareId
                  ? firmwareId
                  : 'Not Available'
              }
            ]}
          />
          {visible && (
            <TmoSvg
              className={classNames(style.groups_spinner)}
              svgId="loading-spinner"
            />
          )}
          <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
            <div className={classNames(style.row, style.edit)}>
              <ViewDevices
                details={details}
                onRemove={onRemove}
                onAdd={onAdd}
              />
              <AssignConfigWrapper
                details={details}
                onAssignConfig={onAssignConfig}
              />
            </div>
            {firmwareId && (
              <div className={classNames(style.row, style.edit)}>
                <AssignConfigObject details={details} />
              </div>
            )}
          </RoleFeature>
        </div>
      </div>
    </>
  );
}

export default withModal(Group);
