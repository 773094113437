import React, { useEffect, useState } from 'react';
import { TmoCard, ContentContainer } from 'tmo-bps-syncup-web-component-lib';

import classNames from 'classnames';
import config from '../../configs/config';
import Clipboard from 'components/Clipboard';
import Authorized from '../../auth/Authorized';
import { authClient, getMSALToken } from '../../auth/authClient';
import { SUA_ROLES, IS_AZURE_AUTH } from '../../utils/app_constants';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './DPISupport.module.css';

const {
  schema,
  hostName,
  resources,
  httpMethods,
  headers,
  basePath
} = config.apiSettings.syncUpPlatform;

const fullUrl = `${schema}://${hostName}${basePath.concent}${resources.locationPrivacyConsent}`;

function DPISupport({ match: { url } }) {
  const [token, setToken] = useState();
  const curlRequest = `curl --location --request GET '${fullUrl}' \
  --header '${headers.dpiSubscriptionKey.key}: ${headers.dpiSubscriptionKey.value}' \
  --header 'Authorization: ${token}'`;
  useEffect(() => {
    const fetchToken = async () => {
      const token = IS_AZURE_AUTH
        ? await getMSALToken()
        : authClient.getIdToken();
      setToken(token);
    };
    fetchToken();
  }, []);

  return (
    <Authorized
      roles={[SUA_ROLES.ADMIN, SUA_ROLES.SYNCUP_DPI]}
      willShowErrorModal
    >
      <ContentContainer className={style.main_container}>
        <PageHeader title={'DPI Support'} />
        <div className={style.content}>
          <ItemDetailList
            rowSet={[
              { Endpoint: fullUrl, 'HTTP Method': httpMethods.GET },
              { '': ' Headers: ' }
            ]}
          />
        </div>
        <TmoCard className={style.container}>
          <div className={style.row}>
            <label className={style.label}>
              {headers.dpiSubscriptionKey.key}
            </label>
            <span className={style.value}>
              {headers.dpiSubscriptionKey.value}
            </span>
          </div>
          <div className={style.row}>
            <label
              className={classNames(style.label, style.clipboard_container)}
            >
              Authorization
            </label>
            <Clipboard
              value={token}
              clipboardSty={style.clipboard}
              buttonPositon="bottom"
            />
          </div>
        </TmoCard>
        <TmoCard className={style.container}>
          <div className={style.row}>
            <label
              className={classNames(style.label, style.clipboard_container)}
            >
              Curl Request
              <Clipboard
                value={curlRequest}
                clipboardSty={style.clipboard}
                buttonPositon="bottom"
              />
            </label>
          </div>
        </TmoCard>
      </ContentContainer>
    </Authorized>
  );
}

export default DPISupport;
