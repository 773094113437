import React, { useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import {
  TmoH1,
  DynamicList,
  TmoSelect,
  TmoCard,
  RawData,
  ContentContainer
} from 'tmo-bps-syncup-web-component-lib';

import userService from '../../services/userService';
import Fetcher from '../../components/Fetcher';
import config from '../../configs/config';
import { HashLink as Link } from 'react-router-hash-link';
import { SORT_DIRECTION } from '../../utils/app_constants';
import { sortList } from '../../utils/helper_functions';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import GoBack from '../../components/uiHelpers/goBack/GoBack';

import style from './ObservedUsersPage.module.css';

const headerNameListItemMap = [
  {
    listItemKey: 'user',
    headerDisplayName: 'User'
  },
  {
    listItemKey: 'hardwareId',
    headerDisplayName: 'Device',
    render: ({ hardwareId }) => (
      <Link to={`search?q=${hardwareId}`}>{hardwareId}</Link>
    )
  },
  {
    listItemKey: 'accountActivated',
    headerDisplayName: 'Account activated'
  },
  {
    listItemKey: 'lastUpdate',
    headerDisplayName: 'Last update from any sensor'
  },
  {
    listItemKey: 'lastLocationUpdate',
    headerDisplayName: 'Last location update'
  },
  {
    listItemKey: 'batteryLevel',
    headerDisplayName: 'Battery level'
  },
  {
    listItemKey: 'serviceStatus',
    headerDisplayName: 'Service status'
  },
  {
    listItemKey: 'onboardingStatus',
    headerDisplayName: 'Onboarding status'
  },
  {
    listItemKey: 'onlineStatus',
    headerDisplayName: 'Online status'
  }
];

const driveHeaderNameListItemMap = [
  {
    listItemKey: 'user',
    headerDisplayName: 'User'
  },
  {
    listItemKey: 'hardwareId',
    headerDisplayName: 'Device',
    render: ({ hardwareId }) => (
      <Link
        to={`search?q=${hardwareId}&device-status=ALL`}
        style={{ color: '#ba0060' }}
      >
        {hardwareId}
      </Link>
    )
  },
  {
    listItemKey: 'Progress',
    headerDisplayName: 'Progress'
  },
  {
    listItemKey: 'lastUpdate',
    headerDisplayName: 'Last update from any sensor'
  },
  {
    listItemKey: 'lastLocationUpdate',
    headerDisplayName: 'Last location update'
  },
  {
    listItemKey: 'deviceStatus',
    headerDisplayName: 'Device status'
  },
  {
    listItemKey: 'onboardingStatus',
    headerDisplayName: 'Onboarding status'
  },
  {
    listItemKey: 'wifiStatus',
    headerDisplayName: 'wifiStatus'
  }
];

// This was super quick and dirty. Please don't build on top of this.
// Apologies to the coding gods, both old and new.
function ObservedUsersPage({ className }) {
  const [users, setUsers] = useState();
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const ObservedUserList =
    syncUpProductContext.syncUpAppName === 'DRIVE'
      ? config.observedUsersDrive
      : config.observedUsers;

  const selectSpecs = {
    options: Object.keys(ObservedUserList).map(o => ({ value: o, text: o }))
  };

  const [userGroup, setUserGroup] = useState(Object.keys(ObservedUserList)[0]);

  const getUsers = useCallback(
    () =>
      userService.getObservedUsers({
        observedUsers: ObservedUserList[userGroup],
        syncUpAppName: syncUpProductContext.syncUpAppName
      }),
    [ObservedUserList, userGroup, syncUpProductContext.syncUpAppName]
  );

  const handleOnListSort = ({ listItemKey }) => {
    const copyUsers = [...users];
    sortList(copyUsers, { listItemKey }, sortDirection);
    sortDirection === SORT_DIRECTION.ASC
      ? setSortDirection(SORT_DIRECTION.DESC)
      : setSortDirection(SORT_DIRECTION.ASC);
    setUsers(copyUsers);
  };

  return (
    <ContentContainer className={classNames(style.container, className)}>
      <GoBack />
      <TmoH1>Observed Users</TmoH1>
      <div className={style.header_container}>
        <TmoSelect
          selectSpecs={selectSpecs}
          value={userGroup}
          onChange={setUserGroup}
        />
        <div className={style.action_button}>
          <RawData
            rawObject={users}
            title="Full Device Details"
            type="Users"
            usePortal={false}
          />
        </div>
      </div>
      <TmoCard className={style.content}>
        <Fetcher
          action={getUsers}
          onLoad={setUsers}
          data={users}
          render={() => (
            <>
              <DynamicList
                headerNameListItemMap={
                  syncUpProductContext.syncUpAppName === 'DRIVE'
                    ? driveHeaderNameListItemMap
                    : headerNameListItemMap
                }
                listItems={users}
                onSort={handleOnListSort}
                sortDirection={sortDirection}
              />
            </>
          )}
        />
      </TmoCard>
    </ContentContainer>
  );
}

export default ObservedUsersPage;
