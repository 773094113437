import React from 'react';
import classNames from 'classnames';
import SyntaxHighlighter from 'react-syntax-highlighter';
import xcode from 'react-syntax-highlighter/dist/cjs/styles/hljs/xcode';
import {
  ContentContainer,
  TmoH1,
  TmoCard,
  FilterableList,
  StatusPill
} from 'tmo-bps-syncup-web-component-lib';

import { ERROR_CODES } from '../../utils/glossary_constants';
import style from './ErrorCodes.module.css';

function ErrorCodes() {
  return (
    <ContentContainer className={style.container}>
      <TmoH1>Error Codes</TmoH1>
      <p>
        Error codes are a work in progress and subject to change. Here is the
        enumerations that existed when they were created.
      </p>
      <FilterableList
        listClass={style.list}
        label="Filter By:"
        list={ERROR_CODES}
        filterByArr={['name', 'code', 'type', 'description']}
        placeholder={'Filter error codes'}
        renderItem={item => (
          <TmoCard
            key={item.name}
            className={classNames(
              style.entry,
              item.description.includes('{') && style.entry_syntax
            )}
          >
            <ul className={style.entry_list}>
              <li className={style.entry_name_container}>
                <label className={style.entry_label}>Name: </label>
                <span className={style.entry_name}> {item.name}</span>
                {item.code && (
                  <StatusPill className={style.code} status="disabled">
                    <span className={style.code_span}>{item.code}</span>
                  </StatusPill>
                )}
              </li>
              <li className={style.entry_name_container}>
                <label className={style.entry_label}>Type: </label>
                <span>{item.type}</span>
              </li>
              <li>
                <label className={style.entry_label}>Description: </label>
                <span>
                  {item.description.includes('{') ? (
                    <SyntaxHighlighter language={'java'} style={xcode}>
                      {item.description}
                    </SyntaxHighlighter>
                  ) : (
                    item.description
                  )}
                </span>
              </li>
            </ul>
          </TmoCard>
        )}
      />
    </ContentContainer>
  );
}

export default ErrorCodes;
