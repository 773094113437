import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const iotFirmwareRepository = {
  getAllIotFirmware: async ({ product, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.iotFirmware}?`,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  createIotFirmware: async ({ body, product, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.iotFirmware,
      body: body,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  getIotFirmwareById: async ({ firmwareId, product }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: `${resources.iotFirmware}/${firmwareId}`,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  uploadIotFirmwareAsset: async ({
    firmwareId,
    body,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.iotFirmware}/${firmwareId}/upload`,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: product
      },
      body: body,
      formData: true
    });
  },
  updateIotFirmware: async ({
    firmwareId,
    body,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.iotFirmware}/${firmwareId}`,
      httpMethod: httpMethods.PUT,
      headers: {
        [headers.SyncUpProduct.key]: product
      },
      body: body
    });
  },
  deleteIotFirmware: async ({
    firmwareId,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.iotFirmware}/${firmwareId}`,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  getAllIotFirmwareGroups: async ({ product, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.iotFirmwareGroups,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  getIotFirmwareDevicesInGroup: async ({
    groupId,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.iotFirmwareDevicesInGroup.replace(
        ':groupId',
        groupId
      ),
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  createIotFirmwareGroup: async ({ body, product, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.iotFirmwareGroups,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: product
      },
      body: body
    });
  },
  updateIotFirmwareGroup: async ({
    groupId,
    body,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.iotFirmwareGroups}/${groupId}`,
      httpMethod: httpMethods.PUT,
      headers: {
        [headers.SyncUpProduct.key]: product
      },
      body: body
    });
  },
  deleteIotFirmwareGroup: async ({
    groupId,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.iotFirmwareGroups}/${groupId}`,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  addDevicesToFirmwareGroup: async ({
    groupId,
    body,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.iotFirmwareDevicesInGroup.replace(
        ':groupId',
        groupId
      ),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  deleteDeviceFromFirmwareGroup: async ({
    groupId,
    body,
    product,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.iotFirmwareDevicesInGroup.replace(
        ':groupId',
        groupId
      ),
      httpMethod: httpMethods.DELETE,
      body: body,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  }
};

export default iotFirmwareRepository;
