import React, { useCallback, useState, useContext } from 'react';
import classNames from 'classnames';
import {
  withModal,
  ModalV2,
  TmoButtons
} from 'tmo-bps-syncup-web-component-lib';

import iotFirmwareService from '../../../../services/iotFirmwareService';
import SyncUpProductContext from '../../../../context/SyncUpProductContext';
import ManageDevices from './ManageDevices';
import Fetcher from '../../../Fetcher';

import style from './ViewDevices.module.css';

function ViewDevices({ openModal, className, details, onRemove, onAdd }) {
  const [devices, setDevices] = useState([]);
  const { groupName, id } = details;
  const syncUpProductContext = useContext(SyncUpProductContext);
  const actionCallback = useCallback(() => {
    return iotFirmwareService.getIotFirmwareDevicesInGroup({
      groupId: id,
      product: syncUpProductContext.syncUpAppName
    });
  }, [id, syncUpProductContext]);

  const handleDeviceChange = async callbackFn => {
    setTimeout(async () => {
      await callbackFn();
      setDevices(await actionCallback());
    }, 1000);
  };
  return (
    <>
      <TmoButtons
        type="magenta_secondary"
        onClick={openModal}
        className={classNames(style.action_button, className)}
      >
        View Devices
      </TmoButtons>
      <ModalV2 title={`Device Details`} modalType="slide" portal={true}>
        <div className={style.device_list} id={style['scrollable']}>
          <Fetcher
            action={actionCallback}
            onLoad={setDevices}
            spinner={true}
            render={() => (
              <div className={style.scrollable_content}>
                <ManageDevices
                  group={groupName}
                  onRemove={() => handleDeviceChange(onRemove)}
                  onAdd={() => handleDeviceChange(onAdd)}
                  groupDevices={devices}
                  groupId={id}
                />
              </div>
            )}
          />
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(ViewDevices);
