import React, { useContext, useEffect, useState } from 'react';
import {
  TmoButtons as TmoButton,
  TmoInput,
  LabelWithInput,
  ConfirmationModal,
  useManualFetcher,
  ModalContext
} from 'tmo-bps-syncup-web-component-lib';

import { FETCH_STATE } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import { prettifyCamelCase } from 'utils/stringUtils';
import simulatorService from 'services/simulatorService';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import StatusContext from 'context/StatusContext';
import { DRIVE_BEHAVIOR } from '../../utils/deviceConsts';

import style from './CreateDeviceEvent.module.css';

function DriveSimulatedEvents({
  event,
  label,
  userId,
  deviceId,
  onEventCreated
}) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const { params: requestParameters, eventName } = event;
  const [inputFields, setInputFields] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { closeModal } = useContext(ModalContext);
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);

  const handleFormChange = (index, v) => {
    let data = [...inputFields];
    data[index].value = v;
    setInputFields(data);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleCancel = event => {
    event.stopPropagation();
    setIsModalOpen(false);
  };

  useEffect(() => {
    let inputs = [
      { name: 'latitude', isVisible: true },
      { name: 'longitude', isVisible: true }
    ];
    requestParameters &&
      requestParameters.forEach(e => {
        inputs = [
          ...inputs,
          {
            name: e.name,
            value: e.value,
            isVisible: e.isVisible
          }
        ];
      });
    setInputFields(inputs);
  }, [requestParameters]);

  const [fetchState, actionTrigger] = useManualFetcher({
    action: () => {
      let param = {};
      inputFields &&
        inputFields.forEach(e => {
          param = { ...param, [e.name]: e.value };
        });

      if (eventName === DRIVE_BEHAVIOR) {
        param = {
          ...param,
          drivebehavior: {
            pattern: event.displayName.replaceAll(' ', ''),
            elapsedmilliseconds: 1,
            location: {
              Longitude: param.longitude,
              Latitude: param.latitude
            }
          }
        };
      }

      return simulatorService.plugInDriveSimulator({
        deviceId: deviceId,
        userId: userId,
        syncUpAppName: product,
        simulator: {
          eventType: eventName,
          ...param
        }
      });
    },
    onLoad: () => {
      addSuccessMessage({
        message: 'event request sent successfully'
      });
      onEventCreated && onEventCreated();
    },
    onError: error => {
      addErrorMessage({ error });
    },
    onComplete: () => {
      setIsModalOpen(false);
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit} className={style.form_content}>
        {inputFields &&
          inputFields.map(
            (input, index) =>
              input.isVisible === true && (
                <LabelWithInput
                  inputType="primary"
                  labelText={prettifyCamelCase(input.name)}
                  name={input.name}
                  key={input.name}
                  formComponent={TmoInput}
                  value={input.value}
                  title={input.value}
                  required
                  onChange={v => handleFormChange(index, v)}
                />
              )
          )}
        <div className={style.button_wrapper}>
          <TmoButton onClick={closeModal} type="magenta_secondary">
            {' '}
            Cancel{' '}
          </TmoButton>
          <TmoButton
            buttonType="submit"
            type="magenta_primary"
            className={style.execute}
          >
            Execute
          </TmoButton>
        </div>
      </form>
      {
        <ConfirmationModal
          isOpen={isModalOpen}
          modalType="primary"
          onAcceptBtnClicked={() => {
            trackCustomEvents(`Simulated - ${label}`, {
              deviceId: deviceId,
              userId: userId
            });
            actionTrigger();
          }}
          onDenyBtnClicked={handleCancel}
          buttonsDisabled={fetchState === FETCH_STATE.LOADING}
          title={''}
          message={`Are you sure you want to trigger ${label} on this device? `}
          acceptBtnTitle="Execute"
          denyBtnTitle="Cancel"
        />
      }
    </>
  );
}

export default DriveSimulatedEvents;
