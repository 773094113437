import { APP_MOBILE } from './app_constants';
import Search from '../static/assets/icons/search.svg';
import AppVersion from '../static/assets/icons/leftpanel/Mobile.svg';
import DeviceSimulator from '../static/assets/icons/leftpanel/Vector.svg';
import Firmware from '../static/assets/icons/leftpanel/ac_plug.svg';
import Analytics from '../static/assets/icons/leftpanel/Bar_Chart.svg';
import Media from '../static/assets/icons/leftpanel/media.svg';
import AuthUser from '../static/assets/icons/leftpanel/auth_user.svg';
import ProductFaq from '../static/assets/icons/leftpanel/help.svg';

export const PORTAL_FAQ_DATA = [
  {
    categoryOrder: 1,
    id: 1,
    name: 'Search',
    icon: Search,
    categoryDesc: 'a place for user specific details and sub features.',
    faqList: [
      {
        description: `<p>User Details displays information on product specific users. It also acts as a home page for user specific feature details like - Device information, Notifications, etc. This feature is available for all the products.</p><p>This information can be reached by -</p><ul><li>Go to '<em>Search</em>' from the left menu (<a href="/search" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Enter desired input string in the '<em>Search User</em>' Page (here a user can be searched based on <em>name</em>, <em>Id</em>, <em>e-mail</em> or <em>MSISDN</em>).</li></ul><p>If input search matches, a list of matched results will be displayed below.</p><ul><li>Select '<em>View</em>' button of the desired user.</li></ul><p>It will trigger navigation to '<em>User Details'</em> page for selected user.</p><p><strong><em>For All Products &gt; Search &gt; enter keyword to search the user &gt; View &gt; User Details</em></strong></p>`,
        faqOrder: 1,
        id: 101,
        title: 'User Details',
        shortDesc: 'displays information on product specific users.'
      },
      {
        description: `<p>Device Details displays information on product specific devices. This feature is available for all the products and is accessible from user details page.</p><p>This information can be reached by -</p><ul><li>Go to '<em>Search</em>' from the left menu (<a href="/search" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Enter desired input string in the '<em>Search User</em>' Page (here a user can be searched based on <em>name</em>, <em>Id</em>, <em>e-mail</em> or <em>MSISDN</em>).</li></ul><p>If input search matches, a list of matched results will be displayed below.</p><ul><li>Select '<em>View</em>' button of the desired user.</li></ul><p>It will trigger navigation to '<em>User Details'</em> page for selected user.</p><ul><li>Select '<em>Devices</em>' tab below user details to view device specific information.</li></ul><p><strong><em>For All Products &gt; Search &gt; enter keyword to search the user &gt; View &gt; User Details &gt; Devices tab</em></strong></p>`,
        faqOrder: 2,
        id: 102,
        title: 'Device Details',
        shortDesc:
          'displays device details and features specific to devices for a user.'
      },
      {
        description: `<p>School Mode feature is only available for '<em>Kidswatch</em>' product. This feature is available from user details page.</p><p>This information can be reached by -</p><ul><li>Select <em>'Kidswatch'</em> product from the top menu.</li><li>Go to '<em>Search</em>' from the left menu (<strong><em>select product Kidswatch</em></strong> and <a href="/search" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Enter desired input string in the '<em>Search User</em>' Page (here a user can be searched based on <em>name</em>, <em>Id</em>, <em>e-mail</em> or <em>MSISDN</em>).</li></ul><p>If input search matches, a list of matched results will be displayed below.</p><ul><li>Select '<em>View</em>' button of the desired user.</li></ul><p>It will trigger navigation to '<em>User Details'</em> page for selected user.</p><ul><li>Select '<em>School Mode</em>' tab below user details to view device specific information.</li></ul><p><strong><em>Kidswatch Product &gt; Search &gt; enter keyword to search the user &gt; View &gt; User Details &gt; School Mode tab</em></strong></p>`,
        faqOrder: 3,
        id: 103,
        title: 'School Mode',
        shortDesc: 'view, schedule or modify school mode details.',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>Notifications displays information on product specific generated events. This feature is available for all the products and is accessible from user details page.</p><p>This information can be reached by -</p><ul><li>Go to '<em>Search</em>' from the left menu (<a href="/search" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Enter desired input string in the '<em>Search User</em>' Page (here a user can be searched based on <em>name</em>, <em>Id</em>, <em>e-mail</em> or <em>MSISDN</em>).</li></ul><p>If input search matches, a list of matched results will be displayed below.</p><ul><li>Select '<em>View</em>' button of the desired user.</li></ul><p>It will trigger navigation to '<em>User Details'</em> page for selected user.</p><ul><li>Select '<em>Notifications</em>' tab below user details to view device specific information.</li></ul><p><strong><em>For All Products &gt; Search &gt; enter keyword to search the user &gt; View &gt; User Details &gt; Notifications tab</em></strong></p>`,
        faqOrder: 4,
        id: 104,
        title: 'Notifications',
        shortDesc: 'covers device notifications and history.'
      },
      {
        description: `<p>Virtual Boundary displays all the virtual areas created by the user. This feature is available for all the products and is accessible from user details page.</p><p>This information can be reached by -</p><ul><li>Go to '<em>Search</em>' from the left menu (<a href="/search" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Enter desired input string in the '<em>Search User</em>' Page (here a user can be searched based on <em>name</em>, <em>Id</em>, <em>e-mail</em> or<em>MSISDN</em>).</li></ul><p>If input search matches, a list of matched results will be displayed below.</p><ul><li>Select '<em>View</em>' button of the desired user.</li></ul><p>It will trigger navigation to '<em>User Details'</em> page for selected user.</p><ul><li>Select '<em>Virtual Boundary</em>' tab below user details to view virtual boundary related information.</li></ul><p><strong><em>For All Products &gt; Search &gt; enter keyword to search the user &gt; View &gt; User Details &gt; Virtual Boundary tab</em></strong></p>`,
        faqOrder: 5,
        id: 105,
        title: 'Virtual Boundary',
        shortDesc: 'user created and managed virtual areas.'
      },
      {
        description: `<p>Contacts displays user's contact details. This feature is only available for '<em>Kidswatch</em>' product and is accessible from user details page.</p><p>This information can be reached by -</p><ul><li>Select <em>'Kidswatch'</em> product from the top menu.</li><li>Go to '<em>Search</em>' from the left menu (<strong><em>select product Kidswatch</em></strong> and <a href="/search" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Enter desired input string in the '<em>Search User</em>' Page (here a user can be searched based on <em>name</em>, <em>Id</em>, <em>e-mail</em> or <em>MSISDN</em>).</li></ul><p>If input search matches, a list of matched results will be displayed below.</p><ul><li>Select '<em>View</em>' button of the desired user.</li></ul><p>It will trigger navigation to '<em>User Details'</em> page for selected user.</p><ul><li>Select '<em>Contacts</em>' tab below user details to view contact information.</li></ul><p><strong><em>Kidswatch Product &gt; Search &gt; enter keyword to search the user &gt; View &gt; User Details &gt; Contacts tab</em></strong></p>`,
        faqOrder: 6,
        id: 106,
        title: 'Contacts',
        shortDesc: `view user's contact details.`,
        products: [APP_MOBILE.WATCH]
      }
    ]
  },
  {
    categoryOrder: 2,
    id: 2,
    name: 'App Version',
    icon: AppVersion,
    categoryDesc:
      'a place to get details of application version, billing details, and update related information.',
    faqList: [
      {
        description: `<p>This feature is available for all products.</p><ul><li>Go to <em>'App Version</em>' from the left menu (<a href="/app-versions" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Android/IOS Tab,</em> Click on<em>'Add New App Version</em>' button at top right.</li></ul><p>A new popup form will appear.</p><ul><li>Fill out the input fields with specific information and select '<em>Submit</em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em> For All Products > App Version &gt; Android/IOS Tab &gt; Add New App Version &gt; Fill out the form &gt; Submit</em></strong></p>`,
        faqOrder: 1,
        id: 201,
        title: 'How to add a new app version?',
        shortDesc: ''
      },
      {
        description: `<p>This feature is available for all products.</p><ul><li>Go to <em>'App Version</em>' from the left menu (<a href="/app-versions" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Android/IOS Tab,</em></li></ul><p>This will enlist all the details of the App Versions.</p><ul><li>It has a filter button which filter according to Distribution Channel, Build Number, Version, Required, Platform.</li></ul><p>Each Specific Version card has details like Id, Platform, Distribution Channel, Version, Build Number, any update required and a button to view version details object.</p><p><strong><em>For All Products &gt; App Version &gt; Android/IOS Tab &gt; App Versions</em></strong></p>`,
        faqOrder: 2,
        id: 202,
        title: 'How to get a list of all App Versions details?',
        shortDesc: ''
      },
      {
        description: `<p>This feature is available for all products.</p><ul><li>Go to <em>'App Version</em>' from the left menu (<a href="/app-versions" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Android/IOS Tab,</em></li></ul><p>This will enlist all the details of the App Versions.</p><ul><li>Each Specific Version card has a button to view version details object.</li><li>Click on <em>'Version Details Object</em>' button.</li><li>Version Details Object popup will display.</li></ul><p>It has a export button as well to export its details.</p><p><strong><em>For All Products &gt; App Version &gt; Android/IOS Tab &gt; App Versions</em></strong></p>`,
        faqOrder: 3,
        id: 203,
        title: 'How to get details of the App Version?',
        shortDesc: ''
      }
    ]
  },
  {
    categoryOrder: 3,
    id: 3,
    name: 'Device Simulators',
    icon: DeviceSimulator,
    categoryDesc: 'a place for creating and managing simulated devices.',
    faqList: [
      {
        description: `<p>A simulated device mocks an actual device and is useful in replicating and testing different scenarios that might arise from actual devices. This feature is available for all the products and is accessible from simulated devices page.</p><p>This feature can be reached by -</p><ul><li>Go to '<em>Device Simulators</em>' from the left menu (<a href="/simulators" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the existing simulators for the specific products.</p><ul><li>Select '<em>Add Simulator</em>' button at the bottom of the page.</li></ul><p>A new card is added at the end of existing listed simulators.</p><ul><li>Fill out the input fields with specific information and select '<em>Save</em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>For All Products &gt; Device Simulators &gt; Add Simulator &gt; Fill out the form &gt; Save button</em></strong></p>`,
        faqOrder: 1,
        id: 301,
        title: 'How to create a simulator?',
        shortDesc: ''
      },
      {
        description: `<p>A simulated device mocks an actual device and is useful in replicating and testing different scenarios that might arise from actual devices. This feature is available for all the products and is accessible from simulated devices page.</p><p>This feature can be reached by -</p><ul><li>Go to '<em>Device Simulators</em>' from the left menu (<a href="/simulators" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the existing simulators for the specific products.</p><ul><li>Select '<em>Delete</em>' button form the enlisted simulators.</li></ul><p>It will trigger delete process and soon a delete confirmation message will be displayed in toast message.</p><p><strong><em>For All Products &gt; Device Simulators &gt; Form the enlisted Simulators &gt; Delete button</em></strong></p>`,
        faqOrder: 2,
        id: 302,
        title: 'How to delete a simulator?',
        shortDesc: ''
      }
    ]
  },
  {
    categoryOrder: 4,
    id: 4,
    name: 'Firmware',
    icon: Firmware,
    categoryDesc: 'a place for creating and managing firmware.',
    faqList: [
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b> <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Group Tab,</em> Click on add new firmware group button at top right. </li></ul><p>Add new firmware group popup will appear. </p><ul><li>Fill out the input fields with specific information and select '<em>Submit </em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>Available Products &gt; Firmware &gt; Groups &gt; Add new firmware group &gt; Fill out the form &gt; Submit</em></strong></p>`,
        faqOrder: 1,
        id: 401,
        title: 'How to add new firmware group?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b> <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Group Tab,</em> this will enlist all the firmware groups.</li></ul><p>It has filter button, which filter firmware groups. </p><strong><em>Available Products &gt; Firmware &gt; Groups &gt; Firmware Groups</em></strong></p>`,
        faqOrder: 2,
        id: 402,
        title: 'How to get list of firmware groups?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b> <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Group Tab,</em> this will enlist all the firmware groups.</li><li>On the required firmware group card click on the view devices button.</li><li>Panel will expand below with its details. </li></ul><p>In the panel it has various actions buttons related to device details like: </p><ul><caption><b>Export CSV -</b></caption><li>Click on export CSV. </li><li>It will download CSV file with its details. </li></ul><ul><caption><b>Remove Device - </b></caption><li>Click on remove device button.</li><li>It will trigger save process and soon a confirmation message will be displayed in toast message. </li></ul><ul><caption><b>Add Devices - </b></caption><li>Click on add devices button.</li><li>Add devices to respective assigned firmware group will appear. </li><li>Add hardware Id's separated with commas. </li><li>Click on submit button.</li><li>It will trigger save process and soon a confirmation message will be displayed in toast message.</li></ul><strong><em>Available Products &gt; Firmware &gt; Groups &gt; Firmware Groups &gt; View devices </em></strong></p>`,
        faqOrder: 3,
        id: 403,
        title: 'How to view firmware devices?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b>  <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Group Tab,</em> this will enlist all the firmware groups.</li></ul><p>On the required firmware group card click on assign config button. </p><ul><li>Choose firmware ID to assign.</li><li>Click on submit button. </li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.</p><strong><em>Available Products &gt; Firmware &gt; Groups &gt; Firmware Groups &gt; Assign Config </em></strong></p>`,
        faqOrder: 4,
        id: 404,
        title: 'How to assign firmware configuration to firmware group?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b>  <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Group Tab,</em> this will enlist all the firmware groups.</li><li>On the required firmware group card click on view assigned fw config details.<li>Panel will expand below containing all assigned firmware config details </li></ul><p><strong><em>Available Products &gt; Firmware &gt; Groups &gt; Firmware Groups &gt; View assigned firmware config details </em></strong></p>`,
        faqOrder: 5,
        id: 405,
        title:
          'How to view assigned firmware configuration details of firmware group?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b>  <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Configuration Tab,</em> Click on add new Configuration button at top right.</li></ul><p>Add new firmware Configuration popup will appear. </p><ul> <li>Fill out the input fields with specific information and select '<em>Submit </em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.</p><p> <strong><em>Available Products &gt; Firmware &gt; Configuration &gt; Add new firmware configuration &gt; Fill out the form &gt; Submit </em></strong></p></p>`,
        faqOrder: 6,
        id: 406,
        title: 'How to add a new firmware configuration?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b>  <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Configuration Tab,</em> this will enlist all the firmware configurations.</li></ul><p> <strong><em>Available Products &gt; Firmware &gt; Configuration &gt; Firmware Configuration </em></strong></p></p>`,
        faqOrder: 7,
        id: 407,
        title: 'How to get list of firmware configuration?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b>  <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Configuration Tab,</em> this will enlist all the firmware configurations.</li> <li>On the required firmware configuration card click on the ellipse button on top right of card. </li><li>Click on edit config button.</li></ul><p>Edit configuration popup will appear.</p><ul> <li>Edit the required fields and click on '<em>Submit </em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.</p><p> <strong><em>Available Products &gt; Firmware &gt; Configuration &gt; configuration card &gt; ellipse button &gt; edit config </em></strong></p></p>`,
        faqOrder: 8,
        id: 408,
        title: 'How to edit firmware configuration?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for product <em>'Pets', 'Tracker'</em> and <em>'Kidswatch'</em>. </p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Firmware</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b>  <a href="/firmware" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Select <em>Configuration Tab,</em> this will enlist all the firmware configurations.</li><li>On the required firmware configuration card click on the ellipse button on top right of card. </li><li>Click on upload asset button. </li></ul><p>Upload Asset popup will appear, it has two options button:</p><ul> <caption> <b>File upload -</b> </caption> <li>Select asset file you want to upload.</li><li>Click on '<em>Upload Asset </em>' button.</li><li>It will trigger save process and soon a confirmation message will be displayed in toast message.</li></ul><ul> <caption> <b>Use Blob URL -</b> </caption> <li>Add media blob URL & hash code URL.<li>Click on '<em>Add </em>' button.</li><li>It will trigger save process and soon a confirmation message will be displayed in toast message.</li></ul><p> <strong><em>Available Products &gt; Firmware &gt; Configuration &gt; configuration card &gt; ellipse button &gt; upload asset </em></strong></p></p>`,
        faqOrder: 9,
        id: 409,
        title: 'How to upload firmware configuration assets?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      }
    ]
  },
  {
    categoryOrder: 5,
    id: 5,
    name: 'Analytics',
    hasSubCategory: true,
    icon: Analytics,
    categoryDesc: 'a place for business reports and metrics.',
    faqList: [
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Business Metric'</em> from sub-menu. (<b>select any of product from Pets, Tracker or Kidswatch</b> and <a href="/dashboard/business-metrics" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Here you will get monthly business metric for SyncUp products.</li><li>At the bottom of the report there are tabs for each product, click on the tab and get the respective monthly business metric of the selected product.</li></ul><p><strong><em>Available Products &gt; Analytics &gt; Business Metric &gt; Business Metric for SyncUp Products</em></strong></p>`,
        faqOrder: 4,
        id: 4,
        title: 'Business Metric',
        subCategory: 'Business Metric',
        subCategoryId: 1,
        subOrder: 1,
        shortDesc: 'Monthly Business metric for SyncUp products',
        products: [APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Available Product'</em> from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard'</em> from sub-menu. (<a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Click on the <em>'Analytics'</em> Tab.</li><li>Here all the product analytics will appear, like total devices sold, total paying lines, Net revenue etc.</li><li>For product Tracker - Analytics tab has assorted options in sub-tabs like:</li><ul><li><b>Dashboard</b>- All the product analytics will appear, like total devices sold, total paying lines, Net revenue etc.</li><li><b>Devices</b>- All the devices detailed report will be available here, like onboarding status, Onboarding Triage, Trackers by user, active devices etc.</li><li><b>App Engagement</b>- App detailed engagement related report will be available here like App Analytics, App User Journey, App Session etc.</li><li><b>Sales/subscribers</b>- All the sales related report will be available here like subscribes, revenue, rate plan, network active.</li><li><b>Rate Plan</b>- All the rate plan related reports will be available here like Initial rate plan, current rate plan, onboarding status etc.</li></ul></ul><p><strong><em>For All Products &gt; Analytics &gt; Dashboard &gt; Analytics</em></strong></p>`,
        faqOrder: 1,
        id: 1,
        title: 'Analytics',
        subCategory: 'Analytics',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: ''
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>select product <em>'Pets'</em> from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard'</em> from sub-menu. (<b>select product Pets </b> and <a href="/dashboard/diagnostics" rel="noopener noreferrer" target="_blank">click here </a> to reach till this step).</li><li>Click on <em>'Diagnostics</em>' Tab.</li><li>Here all system operational details will be available. </li><li>Details are taken out as incidents and a list is available on the table. </li></ul><p><strong><em>Pets Product &gt; Analytics &gt; Dashboard &gt; Diagnostics  &gt; App center </em></strong></p>`,
        faqOrder: 2,
        id: 2,
        title: 'Diagnostics',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: 'All Systems Operational diagnostic data',
        products: [APP_MOBILE.PETS]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard'</em> from sub-menu. (<b>select any of product from Pets, Tracker or Kidswatch</b> and <a href="/dashboard/devices" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Click on the <em>'Device Map'</em> Tab.</li><li>Here all active device related reports will appear like Total active device within a day, within 24hr etc.</li><li>On the right side of the report, it has a filter, with different options. By selecting the options respective device map report will appear.</li></ul><p><strong><em>Available Products &gt; Analytics &gt; Dashboard &gt; Device Map</em></strong></p>`,
        faqOrder: 3,
        id: 3,
        title: 'Device Map',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard'</em> from sub-menu. (<b>select any of product from Pets, Tracker or Kidswatch</b> and <a href="/dashboard/custom-appcenter-query" rel="noopener noreferrer" target="_blank"> click here</a> to reach till this step).</li><li>Click on the <em>'Custom Query'</em> Tab.</li><li>Here there are suggested query parameters are available, to run a query add the app center query parameter in the box and run a query.</li><li>Query parameters can be filtered by last one hour, last 6 hours, last 24 hours.</li></ul><p><strong><em>Available Products &gt; Analytics &gt; Dashboard &gt; Query Parameters</em></strong></p>`,
        faqOrder: 4,
        id: 4,
        title: 'Custom Query',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard'</em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab of '<em>Dashboard</em>' select '<em>Devices</em>' sub tab.</li><li>Select '<em>Active Devices</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Devices Sub Tab &gt; Active Devices Report</em></strong></p>`,
        faqOrder: 5,
        id: 5,
        title: 'Active Devices',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: 'report and metric.',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard'</em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab of '<em>Dashboard</em>' select '<em>Devices</em>' sub tab.</li><li>Select '<em>Device List</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Devices Sub Tab &gt; Device List Report</em></strong></p>`,
        faqOrder: 6,
        id: 6,
        title: 'Device List',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: 'report and metric.',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard'</em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab of '<em>Dashboard</em>' select '<em>Devices</em>' sub tab.</li><li>Select '<em>Tracker Things Profile</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Devices Sub Tab &gt; Tracker Things Profile Report</em></strong></p>`,
        faqOrder: 7,
        id: 7,
        title: 'Tracker Things Profile',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: 'report and metric.',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard' </em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank"> click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab select '<em>Devices</em>' sub tab.</li><li>Select <em>'Device Cont'</em> tab at the bottom of the page.</li><li>Select '<em>Sales vs Attempted to Onboard</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Devices Sub Tab &gt; Devices Cont Tab &gt; Sales vs Attempted to Onboard</em></strong></p>`,
        faqOrder: 8,
        id: 8,
        title: 'Sales vs Attempted to Onboard',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard' </em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank"> click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab select '<em>Sales/Subscribers</em>' sub tab.</li><li>Select '<em>Weekly Device Return Rate</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Subscribers/Sales Sub Tab &gt; Weekly Device Return Rate</em></strong></p>`,
        faqOrder: 9,
        id: 9,
        title: 'Weekly Device Return Rate',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard' </em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank"> click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab select '<em>Sales/Subscribers</em>' sub tab.</li><li>Select '<em>Weekly Warranty Exchange</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Subscribers/Sales Sub Tab &gt; Weekly Warranty Exchange</em></strong></p>`,
        faqOrder: 10,
        id: 10,
        title: 'Weekly Warranty Exchange',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard' </em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank"> click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab select '<em>Sales/Subscribers</em>' sub tab.</li><li>Select '<em>Disconnect Rate</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Subscribers/Sales Sub Tab &gt; Disconnect Rate</em></strong></p>`,
        faqOrder: 11,
        id: 11,
        title: 'Disconnect Rate',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard' </em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank"> click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab select '<em>Sales/Subscribers</em>' sub tab.</li><li>Select '<em>Subscribers</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Subscribers/Sales Sub Tab &gt; Subscribers</em></strong></p>`,
        faqOrder: 12,
        id: 12,
        title: 'Subscribers',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select <em>'Tracker'</em> product from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Dashboard' </em> from sub-menu. (<strong><b>select product Tracker</b></strong> and <a href="/dashboard/analytics" rel="noopener noreferrer" target="_blank"> click here</a> to reach till this step)</li><li>Under <em>'Analytics'</em> tab select '<em>Sales/Subscribers</em>' sub tab.</li><li>Select '<em>Sales and Shipping</em>' to view the Report.</li></ul><p><strong><em>Tracker Product &gt; Analytics &gt; Dashboard &gt; Analytics Tab &gt; Subscribers/Sales Sub Tab &gt; Sales and Shipping</em></strong></p>`,
        faqOrder: 13,
        id: 13,
        title: 'Sales and Shipping',
        subCategory: 'Dashboard',
        subCategoryId: 2,
        subOrder: 2,
        shortDesc: '',
        products: [APP_MOBILE.TRACKER]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select either <em>'Pets', 'Tracker'</em> or <em>'Kidswatch'</em> product from the top menu. </li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Error Metric'</em> from sub-menu. (<b>select any of product from Pets, Tracker or Kidswatch</b> </strong> and <a href="/dashboard/error-metrics" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Here you will get Negative response error details.</li><li>On the right side of the report, it has a filter, with different options like Count, Hardware ID, Latest feature, and product. By selecting the options respective error metrics will appear.</li></ul><p><strong><em>Available Products &gt; Analytics &gt; Error Metric &gt; Error Metric Tab &gt; Negative Response Error - AppInsight log</em></strong></p>`,
        faqOrder: 5,
        id: 5,
        title: 'Error Metric',
        subCategory: 'Error Metric',
        subCategoryId: 3,
        subOrder: 3,
        shortDesc: 'Negative Response Errors Report.',
        products: [APP_MOBILE.TRACKER, APP_MOBILE.PETS, APP_MOBILE.WATCH]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select either <em>'Tracker'</em>, <em>'Kidswatch'</em> or <em>'Drive'</em> product from the top menu. </li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Test Dashboard'</em> from sub-menu. (<b>select any of product from Tracker, Kidswatch or Drive</b>  and <a href="/dashboard/test-dashboard" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li><li>Here you will get App Health Dashboard report.</li><li>On the right side of the report, it has a filter, with different options. By selecting the options respective app health dashboard will appear.</li></ul><p><strong><em>Available Products &gt; Analytics &gt; Test Dashboard &gt; Test Dashboard Tab &gt; App Health Dashboard</em></strong></p>`,
        faqOrder: 6,
        id: 6,
        title: 'Test Dashboard',
        subCategory: 'Test Dashboard',
        subCategoryId: 4,
        subOrder: 4,
        shortDesc: 'App Health Dashboard',
        products: [APP_MOBILE.TRACKER, APP_MOBILE.DRIVE, APP_MOBILE.WATCH]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>Select either <em>'Tracker'</em>, <em>'Kidswatch'</em> or <em>'Drive'</em> product from the top menu. </li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Pier Ticketing'</em> from sub-menu. (<b>select any of product from Tracker, Kidswatch or Drive</b> and <a href="/dashboard/pier-ticketing-metrics" rel="noopener noreferrer" target="_blank">click here </a> to reach till this step).</li><li>Here you will get Pier Ticket Metrics report.</li><li>On the right side of the report, it has a filter, with different options. By selecting the options respective pier ticket report will appear.</li><li>At the bottom of the page, it has two tabs I.e., Pier Ticket Created and Pier Ticket Resolution, selecting the respective tab will display the metric of created tickets and resolved tickets, respectively.</li></ul><p><strong><em>Available Products &gt; Analytics &gt; Pier Ticketing &gt; Pier Ticket Metrics report</em></strong></p>`,
        faqOrder: 7,
        id: 7,
        title: 'Pier Ticketing',
        subCategory: 'Pier Ticketing',
        subCategoryId: 5,
        subOrder: 5,
        shortDesc: 'SyncUp Pier Ticket Created/Resolved',
        products: [APP_MOBILE.TRACKER, APP_MOBILE.DRIVE, APP_MOBILE.WATCH]
      },
      {
        description: `<p>This Report can be reached by -</p><ul><li>select product <em>'Tracker'</em> from the top menu.</li><li>Go to <em>'Analytics'</em> from the left menu.</li><li>Select <em>'Instabug Metric'</em> from sub-menu. (<b>Select product Tracker </b> and <a href="/dashboard/instabug-metric" rel="noopener noreferrer" target="_blank">click here </a> to reach till this step).</li><li>Here you will get Instabug Metric Report.</li><li>On the right side of the report, it has a filter, with different options. By selecting the options respective Instabug Metric report will appear. </li><li>At the bottom of the page, it has three tabs I.e., Instabug Report, Instabug Monthly Report, Instabug  Details, selecting the respective tab will display respective reports. </li></ul><p><strong><em>Tracker &gt; Analytics &gt; Instabug Metric &gt; Instabug Metrics &gt; Instabug Metric Reports</em></strong></p>`,
        faqOrder: 8,
        id: 8,
        title: 'Instabug Metric',
        subCategory: 'Instabug Metric',
        subCategoryId: 6,
        subOrder: 6,
        shortDesc: 'Instabug report ',
        products: [APP_MOBILE.TRACKER]
      }
    ]
  },
  {
    categoryOrder: 6,
    id: 6,
    name: 'Media',
    icon: Media,
    categoryDesc: 'a place for creating and managing media.',
    faqList: [
      {
        description: `<p>This feature can be reached by -</p><ul><li>Select Kidswatch product from the top menu. </li><li>Go to '<em>Media </em>' from the left menu (<b>select product Kidswatch</b> <a href="/media" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the current media configurations.  </p><ul><li>Select '<em>Add new media config </em>' button at the top right of the page.</li></ul><p>A new popup form will display.</p><ul><li>Fill out the input fields with specific information and select '<em>Submit </em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>Kidswatch  &gt; Media  &gt; Add New Media Config &gt; Fill out the form &gt; Submit button</em></strong></p>`,
        faqOrder: 1,
        id: 601,
        title: 'How to add new media configuration?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>This feature can be reached by -</p><ul><li>Select Kidswatch product from the top menu. </li><li>Go to '<em>Media </em>' from the left menu (<b>select product Kidswatch</b> <a href="/media" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the current media configurations.  </p><p>It has a filter button where user can filter by 'Config Name', 'Device Group', 'Type' and 'File Type'.</p><p><strong><em>Kidswatch  &gt; Media  &gt; Current Configurations</em></strong></p>`,
        faqOrder: 2,
        id: 602,
        title: 'How to get list of current media configurations?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>This feature can be reached by -</p><ul><li>Select Kidswatch product from the top menu. </li><li>Go to '<em>Media </em>' from the left menu (<b>select product Kidswatch</b> <a href="/media" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul> <p>This will enlist all the current media configurations.</p><ul><li>On the required media configuration card click on the ellipse button on top right of card. </li><li>Click on edit config button.</li></ul><p>Edit configuration popup will appear.</p><ul><li>Edit the required fields and click on '<em>Submit </em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>Kidswatch  &gt; Media  &gt; Media Configuration Card &gt; Ellipse Button &gt; Edit Config</em></strong></p>`,
        faqOrder: 3,
        id: 603,
        title: 'How to edit media configurations?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>This feature can be reached by -</p><ul><li>Select Kidswatch product from the top menu.</li><li>Go to '<em>Media </em>' from the left menu (<b>select product Kidswatch</b> <a href="/media" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the current media configurations.</p><ul><li>On the required media configuration card click on the ellipse button on top right of card.</li><li>Click on upload asset button.</li></ul><p>Upload Asset popup will appear, it has two options button:</p><ul><caption><b>File upload -</b></caption><li>Select asset file you want to upload.</li><li>Click on '<em>Upload Asset </em>' button.</li><li>It will trigger save process and soon a confirmation message will be displayed in toast message.</li></ul><ul><caption><b>Use Blob URL -</b></caption><li>Add media blob URL & hash code URL.</li><li>Click '<em>Add </em>' button.</li><li>It will trigger save process and soon a confirmation message will be displayed in toast message.</li></ul><p><strong><em>Kidswatch &gt; Media &gt; Media Configuration Card &gt; Ellipse Button &gt; Upload Asset</em></strong></p>`,
        faqOrder: 4,
        id: 604,
        title: 'How to upload media asset?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>This feature can be reached by -</p><ul><li>Select Kidswatch product from the top menu. </li><li>Go to '<em>Media </em>' from the left menu (<b>select product Kidswatch</b> <a href="/media" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the current media configurations.  </p><p>On the required media configuration card click on the ellipse button on top right of card. </p><ul><li>Click on config object button. </li></ul><p>Full object details of configuration will appear on a popup.</p><ul><li> It has export button as well to export object JSON file.</li></ul><strong><em>Kidswatch  &gt; Media  &gt; Media Configuration Card &gt; Ellipse Button &gt; Config Object</em></strong></p>`,
        faqOrder: 5,
        id: 605,
        title: 'How to get full configuration details of media?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>This feature can be reached by -</p><ul><li>Select Kidswatch product from the top menu. </li><li>Go to '<em>Media </em>' from the left menu (<b>select product Kidswatch</b> <a href="/media" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the current media configurations.  </p><p>On the required media configuration card click on the ellipse button on top right of card. </p><ul><li>Click on delete config button. </li></ul><p>Delete confirmation popup will appear.</p><ul><li> Type delete to confirm and click on '<em>Delete </em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>Kidswatch  &gt; Media  &gt; Media Configuration Card &gt; Ellipse Button &gt; Delete Config</em></strong></p>`,
        faqOrder: 6,
        id: 606,
        title: 'How to delete media configuration?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      }
    ]
  },
  {
    categoryOrder: 8,
    id: 8,
    name: 'Auth User',
    icon: AuthUser,
    categoryDesc:
      'a place to get a list of authorized users and to create users.',
    faqList: [
      {
        description: `<p>This feature is available for Product Pets, Tracker and Kidswatch.</p><ul><li>Go to <em>'Auth Users</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b> <a href="/auth-users" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist all the authorized users.</p><ul><li>Select the <em>'Create New User</em>' button at the top right of the page.</li><li>A new form will be displayed.</li></ul><p>Fill out the input fields with specific information and select the <em>'Save</em>' button.</p><p>It will trigger the save process and soon a confirmation message will be displayed in toast message.</p><p><strong><em>Available Products &gt; Auth User &gt; Create New User &gt; Fill out the form &gt; Submit</em></strong></p>`,
        faqOrder: 1,
        id: 801,
        title: 'How to create a new authorized user?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for Product Pets, Tracker and Kidswatch.</p><ul><li>Go to <em>'Auth Users</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b> <a href="/auth-users" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist a table with all the authorized users.</p><ul><li>The table has information like Username, subject, created date, created by, modified date, modified by, allowed scopes, app user and delete button).</li><li>This table has pagination too, at a time 25 records per page will display.</li></ul><p>Users can navigate to the page using pagination controls.</p><p><strong><em>Available Products &gt; Auth User &gt; Authorized Users</em></strong></p>`,
        faqOrder: 2,
        id: 802,
        title: 'How to get list of all authorized user?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      },
      {
        description: `<p>This feature is available for Product Pets, Tracker and Kidswatch.</p><ul><li>Go to <em>'Auth Users</em>' from the left menu (<b>select any of product from Pets, Tracker or Kidswatch</b> <a href="/auth-users" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>This will enlist a table with all the authorized users.</p><ul><li>Click on<em>'Delete</em>' button.</li></ul><p>It will trigger the delete process and soon a confirmation message will be displayed in toast message.</p><p><strong><em>Available Products &gt; Auth User &gt; Authorized &gt; Users Table &gt; Delete User</em></strong></p>`,
        faqOrder: 3,
        id: 803,
        title: 'How to delete authorized user?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER, APP_MOBILE.PETS]
      }
    ]
  },
  {
    categoryOrder: 9,
    id: 9,
    name: 'Product FAQ',
    icon: ProductFaq,
    categoryDesc: 'a place for creating and managing product FAQs.',
    faqList: [
      {
        description: `<p>Product FAQs as are displayed on the mobile applications can be added and managed from this feature. This feature is available only for 'Kidswatch' product.There are two ways to add new FAQ entry. User can add FAQ one by one using a form and modify content using rich text editor. Or user can upload a CSV file to perform bulk insert.</p><p>This feature can be reached by -</p><ul><li>Select <em>'Kidswatch'</em> product from the top menu.</li><li>Go to '<em>Product FAQ</em>' from the left menu (<strong><em>select product Kidswatch</strong></em> and <a href="/faq" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>You will be navigated to Product FAQ page and all the existing FAQs will be enlisted by category.</p><ul><li>Select '<strong>&#8942</strong>' vertical ellipsis button at the top right of the page to open feature actions drop down.</li><li>Select '<em>&#43; Add new FAQ</em>' button.</li></ul><p>A new 'Add new FAQ' dialog box will appear.</p><ul><li>Fill out the input fields with specific information and format the content as desired.</li><li> select '<em>Submit</em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>Kidswatch Product &gt; Product FAQ &gt; &#8942 Ellipse Button &gt; Add new FAQ &gt; Fill out the form &gt; Submit button</em></strong></p>`,
        faqOrder: 1,
        id: 901,
        title: 'How to create a FAQ entry?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>Product FAQs as are displayed on the mobile applications can be added and managed from this feature. Bulk Upload operation is performed by uploading a CSV file.</p><p>This feature can be reached by -</p><ul><li>Select <em>'Kidswatch'</em> product from the top menu.</li><li>Go to '<em>Product FAQ</em>' from the left menu (<strong><em>select product Kidswatch</strong></em> and <a href="/faq" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>You will be navigated to Product FAQ page and all the existing FAQs will be enlisted by category.</p><ul><li>Select '<strong>&#8942</strong>' vertical ellipsis button at the top right of the page to open feature actions drop down.</li><li>Select '<em>Upload bulk FAQ</em>' button.</li></ul><p>A new 'Upload bulk FAQ' dialog box will appear. A template with applicable CSV format can also be downloaded here, using <em>'Download CSV template'</em> button.</p><ul><li>CSV files containing FAQ details can be directly uploaded here.</li><li> select '<em>Upload FAQ</em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>Kidswatch Product &gt; Product FAQ &gt; &#8942 Ellipse Button &gt; Upload bulk FAQ &gt; upload CSV file &gt; Upload FAQ button</em></strong></p>`,
        faqOrder: 2,
        id: 902,
        title: 'How to perform FAQ bulk insert?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>Product FAQs as are displayed on the mobile applications can be added and managed from this feature. This feature is available only for 'Kidswatch' product. This feature allows modification of exisiting FAQ content.</p><p>This feature can be reached by -</p><ul><li>Select <em>'Kidswatch'</em> product from the top menu.</li><li>Go to '<em>Product FAQ</em>' from the left menu (<strong><em>select product Kidswatch</strong></em> and <a href="/faq" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>You will be navigated to Product FAQ page and all the existing FAQs will be enlisted by category.</p><ul><li>Select the derised FAQ entry and click on it to expand its content.</li><li>Select '<em>Edit FAQ</em>' button at the bottom right of expanded content.</li></ul><p>A new  'Edit FAQ' dialog box will appear.</p><ul><li>Modify the input fields with derised information and format the content as required.</li><li> select '<em>Submit</em>' button.</li></ul><p>It will trigger save process and soon a confirmation message will be displayed in toast message.<p><strong><em>Kidswatch Product &gt; Product FAQ &gt; Expand FAQ entry &gt; Edit FAQ &gt; modify content &gt; Submit button</em></strong></p>`,
        faqOrder: 4,
        id: 904,
        title: 'How to modify existing FAQ?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      },
      {
        description: `<p>Product FAQs as are displayed on the mobile applications can be added and managed from this feature. This feature allows deletion of multiple FAQs at the same time.</p><p>This feature can be reached by -</p><ul><li>Select <em>'Kidswatch'</em> product from the top menu.</li><li>Go to '<em>Product FAQ</em>' from the left menu (<strong><em>select product Kidswatch</strong></em> and <a href="/faq" rel="noopener noreferrer" target="_blank">click here</a> to reach till this step).</li></ul><p>You will be navigated to Product FAQ page and all the existing FAQs will be enlisted by category.</p><ul><li>Select '<strong>&#8942</strong>' vertical ellipsis button at the top right of the page to open feature actions drop down.</li><li>Select '<em>Bulk Delete</em>' button.</li></ul><p> This will unable checkbox selections on each FAQ entry, a the bottom panel will display number of selected items along with 'Cancel' and 'Delete' buttons.</p><ul><li>Select FAQs which needs to be deleted.</li><li> select '<em>Delete FAQs</em>' button on the bottom panel.</li><li> A confirmation dialog box will appear, Type confirmation word and select '<em>Delete</em>' button.</li></ul><p>It will trigger delete process and soon a confirmation message will be displayed in toast message.<p><strong><em>Kidswatch Product &gt; Product FAQ &gt; &#8942 Ellipse Button &gt; Bulk Delete &gt; Select FAQs to Delete &gt; Confirm Deletion</em></strong></p>`,
        faqOrder: 5,
        id: 905,
        title: 'How to perform bulk delete on FAQs?',
        shortDesc: '',
        products: [APP_MOBILE.WATCH]
      }
    ]
  }
];
