import React from 'react';
import classNames from 'classnames';

import style from './TmoInput.module.css';

function TmoInput({
  value = '',
  onChange,
  type = 'text',
  size = 'md',
  className,
  ...rest
}) {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <input
      className={classNames(style.input, style[size], className)}
      type={type}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default TmoInput;
