import React, { useState } from 'react';
import { DynamicList } from 'tmo-bps-syncup-web-component-lib';

import analyticsService from '../../../services/analyticsService';
import Fetcher from '../../../components/Fetcher';

import style from './AppCenterStatus.module.css';

function AppCenterStatus({ match }) {
  const [appCenterStatus, setAppCenterStatus] = useState();
  const [appCenterIncidents, setAppCenterIncidents] = useState();

  const getDynamicListHeader = [
    {
      listItemKey: 'name',
      headerDisplayName: 'Name'
    },
    {
      listItemKey: 'status',
      headerDisplayName: 'Status'
    },
    {
      listItemKey: 'impact',
      headerDisplayName: 'Impact'
    },
    {
      listItemKey: 'created_at',
      headerDisplayName: 'Created At'
    },
    {
      listItemKey: 'resolved_at',
      headerDisplayName: 'Resolved At'
    }
  ];

  return (
    <>
      <Fetcher
        action={analyticsService.getAppCenterStatus}
        onLoad={setAppCenterStatus}
        render={() =>
          appCenterStatus ? (
            <span className={style.list_incidents}>
              Current Status: {appCenterStatus.status.description}{' '}
            </span>
          ) : (
            'No Results'
          )
        }
      />
      <Fetcher
        action={analyticsService.getAppCenterIncidents}
        onLoad={setAppCenterIncidents}
        render={() =>
          appCenterIncidents ? (
            <div className={style.list_container}>
              <span className={style.list_incidents}>Incidents List:</span>
              <div className={style.incidents_container}>
                <DynamicList
                  className=""
                  headerNameListItemMap={getDynamicListHeader}
                  listItems={appCenterIncidents.incidents}
                />
              </div>
            </div>
          ) : (
            'No Results'
          )
        }
      />
    </>
  );
}

export default AppCenterStatus;
