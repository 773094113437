import React, { useState, useContext, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TmoH3 } from 'tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import SearchedUserContext from '../../context/SearchedUserContext';
import thingService from '../../services/thingService';
import DeviceRoadsideServiceGrid from './DeviceRoadsideServiceGrid';
import { getRandomString } from '../../utils/stringUtils';
import SyncUpProductContext from '../../context/SyncUpProductContext';

import { getUserThingBackUrl, hasValue } from 'utils/helper_functions';
import GoBack from 'components/uiHelpers/goBack/GoBack';

import style from './DeviceRoadsideServices.module.css';

function DeviceRoadsideServices({ match, hideTab }) {
  const {
    user: { userId }
  } = useContext(SearchedUserContext);

  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  const [roadsideServices, setRoadsideServices] = useState();
  const syncUpProductContext = useContext(SyncUpProductContext);

  const actionCallback = useCallback(() => {
    const thingId = match.params.thingId;
    return thingService.getRoadsideServices({
      thingId,
      userId,
      syncUpAppName: syncUpProductContext.syncUpAppName
    });
  }, [match.params.thingId, userId, syncUpProductContext.syncUpAppName]);

  useEffect(() => {
    hideTab && hideTab(true);
  }, [hideTab]);

  return (
    <div className={style.container}>
      <div className={style.sub_header}>
        <GoBack
          url={getUserThingBackUrl(userId, 'things')}
          primary
          hideTab={hideTab}
        />
        <div className={style.title_group}>
          <TmoH3 className={style.subheading}>
            Roadside Services: {match.params.thingId}{' '}
          </TmoH3>
        </div>
      </div>
      <div className={style.container}>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={setRoadsideServices}
          render={() =>
            hasValue(roadsideServices) ? (
              <div className={style.items}>
                {roadsideServices.map(service => (
                  <DeviceRoadsideServiceGrid
                    roadsideServices={service}
                    forceRefresh={forceRefresh}
                  />
                ))}
              </div>
            ) : (
              <div className={style.message}>No roadside services found</div>
            )
          }
        />
      </div>
    </div>
  );
}

export default withRouter(DeviceRoadsideServices);
