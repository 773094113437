import React from 'react';
import { withRouter } from 'react-router-dom';
import { withModal } from 'tmo-bps-syncup-web-component-lib';

import TimeDisplay from '../../uiHelpers/TimeDisplay';
import ItemDetailList from '../../ItemDetailList/ItemDetailList';
import ItemDetailsModal from 'components/ItemDetailsModal';
import { GRID } from 'utils/app_constants';

import style from './PetDetails.module.css';

function PetDetails({
  pet: {
    id,
    name,
    notes,
    status,
    petType,
    birthdate,
    identifyingMarks,
    gender,
    breed = 'Key Not Available',
    weight = 'Key Not Available',
    createdDateTime,
    modifiedDateTime,
    safezone
  }
}) {
  return (
    <div className={style.container}>
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          id,
          name,
          status,
          petType,
          gender,
          birthdate,
          breed,
          identifyingMarks,
          notes,
          weight,
          safezone
        }}
      />
      <div className={style.container}>
        <ItemDetailsModal
          buttonText={'View Dates'}
          label="Created/Modified Dates"
          className={GRID.COLUMN_FORTH}
        >
          <TimeDisplay
            title="Created Date"
            timestamp={createdDateTime}
            type="primary_time_display"
          />
          <div className={style.separator}></div>
          <TimeDisplay
            title="Modified Date"
            timestamp={modifiedDateTime}
            type="primary_time_display"
          />
        </ItemDetailsModal>
      </div>
    </div>
  );
}

export default withRouter(withModal(PetDetails));
