import React, { useState, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import Authorized from '../../auth/Authorized';
import ThemeContext from '../../context/ThemeContext';
import { SUA_ROLES } from '../../utils/app_constants';
import { getRandomString } from '../../utils/stringUtils';
import TrackerDashboard from '../../components/commercialTracker/dashboard/TrackerDashboard';
import Devices from '../../components/commercialTracker/devices/Devices';
import Alerts from '../../components/commercialTracker/alerts/Alerts';
import CommercialLeftPanel from '../../components/commercialTracker/leftPanel/CommercialLeftPanel';
import ContentContainer from '../../components/uiHelpers/ContentContainer';
import CommercialHeader from '../../components/commercialTracker/header/CommercialHeader';
import AddDevice from '../../components/commercialTracker/devices/addDevice/AddDevice';
import AddPackForBulk from '../../components/commercialTracker/devices/addDevice/AddPackForBulk';
import ActivateDevice from '../../components/commercialTracker/devices/addDevice/ActivateDevice';

import '../../index.css';
import style from './CommercialTracker.module.css';

function CommercialTracker({ match: { url } }) {
  const { setTheme } = useContext(ThemeContext);
  setTheme('theme-commercial');

  const [lastRefresh] = useState(getRandomString()); // TODO: Add refresh button
  const dashboardUrl = `/commercial-tracker/dashboard`;
  const devicesUrl = `/commercial-tracker/devices`;
  const alertsUrl = '/commercial-tracker/alerts';
  const addDeviceUrl = '/commercial-tracker/devices/add';
  const addDevicePackUrl = '/commercial-tracker/devices/add/pack/:packId';
  const addDevicePackSuccessUrl =
    '/commercial-tracker/devices/add/pack/:packId/success';

  return (
    <div className={style.container}>
      <Authorized
        roles={[SUA_ROLES.ADMIN, SUA_ROLES.TAG_COMMERCIAL_USER]}
        willShowErrorModal
      >
        <CommercialLeftPanel />
        <CommercialHeader />
        <ContentContainer>
          <Switch key={lastRefresh}>
            <Route exact path={dashboardUrl} component={TrackerDashboard} />
            <Route exact path={devicesUrl} component={Devices} />
            <Route exact path={alertsUrl} component={Alerts} />
            <Route exact path={addDeviceUrl} component={AddDevice} />
            <Route exact path={addDevicePackUrl} component={AddPackForBulk} />
            <Route
              exact
              path={addDevicePackSuccessUrl}
              component={ActivateDevice}
            />
          </Switch>
        </ContentContainer>
      </Authorized>
    </div>
  );
}

export default CommercialTracker;
