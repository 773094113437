import { useState, useCallback } from 'react';
import {
  storageRead,
  storageRemoveItem,
  storageWrite
} from '../utils/storageUtils';

function usePersistableState({ initialValue, storageKey, storageType }) {
  const [value, setValue] = useState(
    initialValue || storageRead({ key: storageKey, storageType })
  );

  const setValueWithStorage = useCallback(
    newValue => {
      setValue(newValue);
      if (newValue) {
        storageWrite({
          key: storageKey,
          value: newValue,
          storageType
        });
      } else {
        storageRemoveItem({ key: storageKey, storageType });
      }
    },
    [storageKey, storageType]
  );

  return [value, setValueWithStorage];
}

export default usePersistableState;
