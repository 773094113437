import React, { useContext } from 'react';
import { FaPlus } from 'react-icons/all';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import userService from '../../services/userService';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import StatusContext from '../../context/StatusContext';
import Operationsred from '../../static/assets/icons/leftpanel/operationsred.svg';

import {
  TmoButtons as TmoButton,
  TmoSvg
} from 'tmo-bps-syncup-web-component-lib';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';
import useVisible from '../../hooks/useVisible';

import style from './OperationsList.module.css';

function OperationsList() {
  const { userInfo } = useContext(LoggedInUserContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const { visible, setVisible } = useVisible(false);

  const registerUser = async () => {
    setVisible(true);
    const body = {
      userProfile: {
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        email: userInfo.email,
        msisdns: [],
        demoUser: true
      },
      applicationProfile: {}
    };
    try {
      await userService.registerUser({
        body,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      setVisible(false);
      addSuccessMessage({
        message: `Successfully user added to the database`
      });
    } catch (error) {
      setVisible(false);
      console.log(error);
      addErrorMessage({ error });
    }
  };

  return (
    <>
      <PageHeader primary title={'Operations'}></PageHeader>
      {visible && <TmoSvg className={style.spinner} svgId="loading-spinner" />}
      <div className={style.opList}>
        <>
          <div className={style.infoIcon}>
            <img className={style.icon} alt="info icon" src={Operationsred} />
            <h3>Add User to Database</h3>
          </div>
          <div className={style.card_container}>
            <div className={style.row}>
              <div>
                <label className={style.label}>First Name</label>
                <div className={style.value}>{userInfo.given_name}</div>
              </div>
              <div>
                <label className={style.label}>Last Name</label>
                <div className={style.value}>{userInfo.family_name}</div>
              </div>
            </div>
            <div className={style}>
              <label className={style.label}>Email</label>
              <div className={style.value}>{userInfo.email}</div>
            </div>
          </div>

          <TmoButton
            className={style.opButton}
            type="magenta_primary"
            icon={<FaPlus />}
            onClick={registerUser}
          >
            Add user to Database
          </TmoButton>
        </>
      </div>
    </>
  );
}

export default OperationsList;
