import React from 'react';
import classNames from 'classnames';

import config from '../../../configs/config';

import style from './StaticMapImage.module.css';

const { googleMapSettings, reactGoogleStaticImage } = config;

export default function StaticMapImage({
  center,
  className,
  customResolution,
  zoom,
  marker,
  children
}) {
  let staticMapUrl = reactGoogleStaticImage.url;
  staticMapUrl += `?center=${center}&zoom=${zoom ? zoom : 10}&size=${
    customResolution ? customResolution : '1800x1350'
  }`;
  staticMapUrl += marker
    ? `&markers=icon:https://${window.location.hostname}/icons/map_marker.png|${center}`
    : '';
  staticMapUrl += `&key=${googleMapSettings.apiKey}`;

  const imgStyle = {
    backgroundImage: `url(${staticMapUrl})`
  };

  return (
    <div className={classNames(style.container, className)} style={imgStyle}>
      {children}
    </div>
  );
}
