import faqContentRepository from '../repositories/faqContentRepository';

const faqContentService = {
  getAllFAQ: async ({ product }) => {
    return await faqContentRepository.getAllFAQ({ product });
  },
  createFAQ: async ({ product, body }) => {
    return await faqContentRepository.createFAQ({
      product,
      body
    });
  },
  deleteFAQ: async ({ product, faqId }) => {
    return await faqContentRepository.deleteFAQ({
      product,
      faqId
    });
  },
  deleteBulkFAQ: async ({ product, faqIds }) => {
    return await faqContentRepository.deleteBulkFAQ({
      product,
      faqIds
    });
  },
  updateFAQ: async ({ product, body }) => {
    return await faqContentRepository.updateFAQ({
      product,
      body
    });
  },
  updateFAQCategory: async ({ product, body }) => {
    return await faqContentRepository.updateFAQCategory({
      product,
      body
    });
  },
  faqVote: async ({ product, body }) => {
    return await faqContentRepository.faqVote({
      product,
      body
    });
  },
  getFAQbyCategories: async ({ product, language = 'en', isProdEnv }) => {
    const faqByCategory = await faqContentRepository.getFAQbyCategories({
      product,
      language
    });
    if (isProdEnv) {
      return faqByCategory.map(obj => {
        const faqList = obj?.faqList?.filter(
          item => !item.title.includes('demo ')
        );
        return { ...obj, faqList };
      });
    } else {
      return faqByCategory;
    }
  }
};

export default faqContentService;
