import React, { Fragment } from 'react';

import { getTimestampList } from '../../utils/dateUtils';

import style from './TimeDisplay.module.css';

function TimeDisplay({ title, timestamp, type }) {
  return (
    <div
      className={type === 'primary_time_display' && style.time_display_wrapper}
    >
      <div className={style.container}>
        {title && <span className={style.title}>{title} </span>}
      </div>
      <ul className={style.list}>
        {getTimestampList(timestamp, null, null).map((item, index) => (
          <Fragment key={index}>
            <li className={style.list_item}>
              <span className={style.label}>User Time</span>
              <span className={style.label_item}>{item.User}</span>
            </li>
            <li className={style.list_item}>
              <span className={style.label}>Local Time</span>
              <span className={style.label_item}>{item.Care}</span>
            </li>
            <li className={style.list_item}>
              <span className={style.label}>System</span>
              <span className={style.label_item}>{item.System}</span>
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

export default TimeDisplay;
