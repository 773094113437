import { APP_MOBILE } from './app_constants';
import { prettifyCamelCase } from '../utils/stringUtils';
import { getEpochTime } from '../utils/dateUtils';

export const DEVICE_ONBOARDING_STATUS = {
  ONBOARDED: 'ONBOARDED',
  ONBOARDING: 'ONBOARDING',
  FAILED: 'FAILED'
};

export const DEVICE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED'
};

export const DEVICE_MODE = {
  MODE_UNKNOWN: 'MODE_UNKNOWN',
  WIFI_ZONE_NO_MOTION: 'WIFI_ZONE_NO_MOTION',
  WIFI_ZONE_WITH_MOTION: 'WIFI_ZONE_WITH_MOTION',
  BLE_ZONE_NO_MOTION: 'BLE_ZONE_NO_MOTION',
  BLE_ZONE_WITH_MOTION: 'BLE_ZONE_WITH_MOTION',
  OUTSIDE_ZONE_NO_MOTION: 'OUTSIDE_ZONE_NO_MOTION',
  OUTSIDE_ZONE_WITH_MOTION: 'OUTSIDE_ZONE_WITH_MOTION',
  CHARGING_ZONE_NO_MOTION: 'CHARGING_ZONE_NO_MOTION',
  CHARGING_ZONE_WITH_MOTION: 'CHARGING_ZONE_WITH_MOTION',
  LOST_ZONE_NO_MOTION: 'LOST_ZONE_NO_MOTION',
  LOST_ZONE_WITH_MOTION: 'LOST_ZONE_WITH_MOTION'
};

export const DEVICE_MODELS = {
  PETS1: 'PETS',
  WATCH1: 'KIDSWATCH',
  TAG1: 'TRACKER',
  PHONE1: 'FAMILYLOCATION'
};

export const DEVICE_TRACKING_MODE = {
  LIVE: 'LIVE',
  NORMAL: 'NORMAL'
};

export const DEVICE_LIGHT_BUZZER_MODE = {
  OFF: false,
  ON: true
};

export const DEVICE_DRIVE_ROADSIDE_SERVICES = {
  TIRE: 'TIRE',
  LOCKOUT: 'LOCKOUT',
  TOW: 'TOW',
  FUEL: 'FUEL',
  BATTERY: 'BATTERY'
};

export const DEVICE_USER_TASK_NOTIFICATION = {
  complete: 'COMPLETE',
  incomplete: 'INCOMPLETE'
};

export const DEVICE_BOOT_LEVEL_NOTIFICATION = {
  OFF: 'off',
  ON: 'on'
};

export const DEVICE_LOW_BATTERY_NOTIFICATION = {
  battery_level_12: '12',
  battery_level_25: '25',
  battery_level_50: '50'
};

export const DEVICE_LIGHT_SENSOR_NOTIFICATION = {
  light_to_dark: 'ltd',
  dark_to_light: 'dtl'
};

export const DEVICE_MOTION_LOCK = {
  start: 'start',
  stop: 'stop'
};

export const DRIVE_BEHAVIOR = 'drive_behavior';

export const DRIVE_SIMULATED_EVENTS = [
  {
    name: 'Trip Events',
    categories: [
      {
        name: 'Trip',
        events: [
          {
            eventName: 'trip_start'
          },
          {
            eventName: 'location'
          },
          {
            eventName: 'idling_start'
          },
          {
            eventName: 'idling_stop'
          },
          {
            eventName: 'trip_end'
          },
          {
            eventName: 'idling_end_trip'
          }
        ]
      },
      {
        name: 'Battery',
        events: [
          {
            displayName: 'Low Battery',
            eventName: 'low_battery_ignition_on',
            params: [
              {
                name: 'batteryWarning',
                value: true
              }
            ]
          },
          {
            eventName: 'unhealthy_battery'
          },
          {
            eventName: 'extremely_unhealthy_battery'
          }
        ]
      },
      {
        name: 'Seatbelt',
        events: [
          {
            displayName: 'Fastened',
            eventName: 'seatbelt',
            params: [
              {
                name: 'seatbeltWarning',
                value: false
              }
            ]
          },
          {
            displayName: 'Unfastened',
            eventName: 'seatbelt',
            params: [
              {
                name: 'seatbeltWarning',
                value: true
              }
            ]
          },
          {
            eventName: 'seatbelt_fastened_driver'
          },
          {
            eventName: 'seatbelt_unfastened_driver'
          },
          {
            eventName: 'seatbelt_fastened_passenger'
          },
          {
            eventName: 'seatbelt_unfastened_passenger'
          }
        ]
      },
      {
        name: 'Fuel',
        events: [
          {
            displayName: 'Low Fuel',
            eventName: 'fuel',
            params: [
              {
                name: 'fuelLevelPercent',
                value: 20
              }
            ]
          },

          {
            displayName: 'Very Low Fuel ',
            eventName: 'fuel',
            params: [
              {
                name: 'fuelLevelPercent',
                value: 10
              }
            ]
          }
        ]
      },
      {
        name: 'Check Engine',
        events: [
          {
            displayName: 'Check Engine Light On',
            eventName: 'check_engine',
            params: [
              {
                name: 'checkEngineLightState',
                value: true
              }
            ]
          },
          {
            displayName: 'Check Engine Light Off',
            eventName: 'check_engine',
            params: [
              {
                name: 'checkEngineLightState',
                value: false
              }
            ]
          }
        ]
      },
      {
        name: 'Odometer',
        events: [
          {
            eventName: 'odometer_reading',
            params: [
              {
                name: 'odometer',
                isVisible: true
              }
            ]
          }
        ]
      },
      {
        name: 'Vehicle Orientation',
        events: [
          {
            eventName: 'vehicle_orientation',
            params: [
              {
                name: 'vehicleOrientation',
                value: 21757
              }
            ]
          }
        ]
      },
      {
        name: 'DTC',
        events: [
          {
            eventName: 'dtc_minor'
          },
          {
            eventName: 'dtc_non_critical'
          },
          {
            eventName: 'dtc_critical'
          },
          {
            displayName: 'Dtc Unknown',
            eventName: 'dtc',
            params: [
              {
                name: 'dtcList',
                value: 'P2064'
              },
              {
                name: 'dtcCount',
                value: 1
              }
            ]
          },
          {
            displayName: 'Dtc Clear',
            eventName: 'dtc',
            params: [
              {
                name: 'dtcList',
                value: ''
              },
              {
                name: 'dtcCount',
                value: 0
              }
            ]
          }
        ]
      },
      {
        name: 'Engine Oil',
        events: [
          {
            eventName: 'low_oil_pressure'
          },
          {
            eventName: 'high_oil_temperature'
          }
        ]
      },
      {
        name: 'Tire Pressure',
        events: [
          {
            eventName: 'low_tire_pressure'
          }
        ]
      },
      {
        name: 'Carsh',
        events: [
          {
            eventName: 'crash_detected',
            params: [
              {
                name: 'crashCode',
                value: '00095'
              }
            ]
          }
        ]
      },
      {
        name: 'Speed',
        events: [
          {
            eventName: 'speed',
            params: [
              {
                name: 'speed'
              }
            ]
          },
          {
            eventName: 'speed_limit'
          }
        ]
      },
      {
        name: 'Drive Behavior',
        events: [
          {
            displayName: 'Left Turn',
            eventName: DRIVE_BEHAVIOR
          },
          {
            displayName: 'Right Turn',
            eventName: DRIVE_BEHAVIOR
          },
          {
            displayName: 'Rapid Accelaration',
            eventName: DRIVE_BEHAVIOR
          },
          {
            displayName: 'Harsh Braking',
            eventName: DRIVE_BEHAVIOR
          }
        ]
      }
    ]
  },
  {
    name: 'Non Trip Events',
    categories: [
      {
        name: 'Disturbance / No VIN / Tow',
        events: [
          {
            eventName: 'disturbance'
          },
          {
            eventName: 'no_vin'
          },
          {
            eventName: 'tow'
          }
        ]
      },
      {
        name: 'Vin',
        events: [
          {
            eventName: 'vin',
            params: [
              {
                name: 'vin',
                isVisible: true
              }
            ]
          }
        ]
      },
      {
        name: 'Device Status',
        events: [
          {
            eventName: 'device_connect'
          },
          {
            eventName: 'device_disconnect'
          },
          {
            eventName: 'device_offline'
          },
          {
            eventName: 'device_online'
          }
        ]
      },
      {
        name: 'Unplug',
        events: [
          {
            eventName: 'device_unplugged'
          }
        ]
      },
      {
        name: 'Battery Drain',
        events: [
          {
            eventName: 'draining_battery_ignition_off'
          }
        ]
      },
      {
        name: 'WiFi',
        events: [
          {
            eventName: 'wifi_update',
            params: [
              {
                name: 'wifirequestid'
              }
            ]
          }
        ]
      },
      {
        name: 'Firmware',
        events: [
          {
            eventName: 'firmware'
          }
        ]
      }
    ]
  }
];

export const COMMANDS = {
  TRACKING: 'Tracking',
  LIGHT: 'Light',
  BUZZER: 'Buzzer',
  LOST_MODE: 'Tracking Lost Mode',
  BOOT_LEVEL: 'Boot Level',
  LOW_BATTERY: 'Low Battery',
  SOS_NOTIFICATION: 'SOS',
  ADMIN_911_NOTIFICATION: 'Admin 911',
  USER_TASK_NOTIFICATION: 'User Task',
  LIGHT_SENSOR: 'Light Sensor',
  MOTION_LOCK: 'Motion Lock',
  GEOFENCE: 'Geofence',
  PROXIMITY_NOTIFICATION: 'Proximity',
  LOCATION_NOTIFICATION: 'Location',
  UPLOAD_DEVICE_LOGS: 'Upload Device logs'
};

export const SIMULATED_COMMANDS = {
  name: 'Commands',
  categories: [
    {
      name: COMMANDS.TRACKING,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_TRACKING_MODE).map(value => ({
            key: DEVICE_TRACKING_MODE[value],
            value: value
          })),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.LIGHT,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_LIGHT_BUZZER_MODE).map(value => ({
            key: DEVICE_LIGHT_BUZZER_MODE[value],
            value: value
          })),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.PETS]
    },
    {
      name: COMMANDS.BUZZER,
      constEventName: DEVICE_LIGHT_BUZZER_MODE,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_LIGHT_BUZZER_MODE).map(value => ({
            key: DEVICE_LIGHT_BUZZER_MODE[value],
            value: value
          })),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [
        APP_MOBILE.PETS,
        APP_MOBILE.TRACKER,
        APP_MOBILE.WATCH
      ]
    },
    {
      name: COMMANDS.LOST_MODE,
      params: [
        {
          name: 'Command Type',
          selectSpecs: Object.keys(DEVICE_TRACKING_MODE).map(value => ({
            key: DEVICE_TRACKING_MODE[value],
            value: value
          })),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.PETS]
    },
    {
      name: COMMANDS.BOOT_LEVEL,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_BOOT_LEVEL_NOTIFICATION).map(
            value => ({
              key: DEVICE_BOOT_LEVEL_NOTIFICATION[value],
              value: value
            })
          ),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.LOW_BATTERY,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_LOW_BATTERY_NOTIFICATION).map(
            value => ({
              key: DEVICE_LOW_BATTERY_NOTIFICATION[value],
              value: prettifyCamelCase(value)
            })
          ),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.SOS_NOTIFICATION,
      authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.LIGHT_SENSOR,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_LIGHT_SENSOR_NOTIFICATION).map(
            value => ({
              key: DEVICE_LIGHT_SENSOR_NOTIFICATION[value],
              value: prettifyCamelCase(value)
            })
          ),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.TRACKER]
    },
    {
      name: COMMANDS.MOTION_LOCK,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_MOTION_LOCK).map(value => ({
            key: DEVICE_MOTION_LOCK[value],
            value: prettifyCamelCase(value)
          })),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.TRACKER]
    },
    {
      name: COMMANDS.GEOFENCE,
      params: [
        {
          name: 'la',
          displayName: 'Latitude',
          isVisible: true
        },
        {
          name: 'lo',
          displayName: 'Longitude',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER]
    },
    {
      name: COMMANDS.LOCATION_NOTIFICATION,
      params: [
        {
          name: 'la',
          displayName: 'Latitude',
          isVisible: true
        },
        {
          name: 'lo',
          displayName: 'Longitude',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.PROXIMITY_NOTIFICATION,
      params: [
        {
          name: 'la',
          displayName: 'Latitude',
          isVisible: true
        },
        {
          name: 'lo',
          displayName: 'Longitude',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.ADMIN_911_NOTIFICATION,
      authorizedProducts: [APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.UPLOAD_DEVICE_LOGS,
      authorizedProducts: [APP_MOBILE.WATCH]
    },
    {
      name: COMMANDS.USER_TASK_NOTIFICATION,
      params: [
        {
          selectSpecs: Object.keys(DEVICE_USER_TASK_NOTIFICATION).map(
            value => ({
              key: DEVICE_USER_TASK_NOTIFICATION[value],
              value: prettifyCamelCase(value)
            })
          ),
          type: 'select',
          isVisible: true
        }
      ],
      authorizedProducts: [APP_MOBILE.WATCH]
    }
  ]
};

export const getSimulatedCommands = product => {
  if (product) {
    let filteredList = [];
    SIMULATED_COMMANDS.categories &&
      SIMULATED_COMMANDS.categories.forEach(item => {
        if (item.authorizedProducts) {
          if (item.authorizedProducts.includes(product)) {
            filteredList = [...filteredList, item];
          }
        } else filteredList = [...filteredList, item];
      });
    return filteredList;
  } else {
    return SIMULATED_COMMANDS.categories;
  }
};

export const getSimulatedEventParams = (category, selectedOption, param) => {
  let body =
    category === COMMANDS.BOOT_LEVEL
      ? {
          boot: {
            bt: selectedOption
          }
        }
      : category === COMMANDS.LOW_BATTERY
      ? {
          batt: {
            bl: selectedOption
          }
        }
      : category === COMMANDS.SOS_NOTIFICATION
      ? {
          sos: {
            ts: getEpochTime()
          }
        }
      : category === COMMANDS.LIGHT_SENSOR
      ? {
          lit: {
            lc: selectedOption
          }
        }
      : category === COMMANDS.GEOFENCE
      ? {
          loc: {
            ...param
          }
        }
      : category === COMMANDS.PROXIMITY_NOTIFICATION
      ? {
          loc: {
            ...param
          }
        }
      : category === COMMANDS.LOCATION_NOTIFICATION
      ? {
          loc: {
            ...param
          }
        }
      : category === COMMANDS.ADMIN_911_NOTIFICATION
      ? {
          911: {
            ts: getEpochTime()
          }
        }
      : category === COMMANDS.USER_TASK_NOTIFICATION
      ? {
          tComp: {
            st: selectedOption
          }
        }
      : {};
  return body;
};
