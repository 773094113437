import React from 'react';

import AlertsWidget from '../dashboard/AlertsWidget';

import './Alerts.css';

function Alerts() {
  return (
    <div className="Alerts-container">
      <AlertsWidget />
    </div>
  );
}

export default Alerts;
