import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DIMENSIONS,
  MISC_PRODUCTS,
  SUA_PAGES
} from '../../utils/app_constants';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { getOktaGroups } from '../../utils/helper_functions';
import useWindowWidth from '../../hooks/useWindowWidth';

import style from './ThingsLogo.module.css';

import pennystoneLogo from '../../static/assets/TMO_Logo_Pennystone.png';
import tmoLogo from '../../static/assets/Tmo-T-Logo.jpg';
import watchLogo from '../../static/assets/TMO_Logo_Watch.png';
import trackerLogo from '../../static/assets/TMO_Logo_Tracker.png';
import portalLogo from '../../static/assets/TMO_Logo_Portal.png';
import petLogo from '../../static/assets/TMO_Logo_Pets.png';
import driveLogo from '../../static/assets/TMO_Logo_Drive.png';
import familyLogo from '../../static/assets/TMO_Logo_Family.png';

import watchMobileLogo from '../../static/assets/app-icons/kidswatch-mobile-icon.svg';
import petMobileLogo from '../../static/assets/app-icons/pets-mobile-icon.svg';
import driveMobileLogo from '../../static/assets/app-icons/drive-mobile-icon.svg';
import trackerMobileLogo from '../../static/assets/app-icons/tracker-mobile-icon.svg';
import familyMobileLogo from '../../static/assets/TMO_Logo_Family.png';
import portalMobileLogo from '../../static/assets/app-icons/portal-mobile-icon.svg';

function ThingsLogo({ className }) {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { userInfo, homePageUrl } = useContext(LoggedInUserContext);
  const groups = userInfo ? getOktaGroups(userInfo) : [];
  const location = useLocation();
  const width = useWindowWidth();

  const getProductLogo = () => {
    if (
      location.pathname.includes(SUA_PAGES.TEST_HARNESS.subPages.KIDSWATCH.path)
    ) {
      return pennystoneLogo;
    }
    if (
      location.pathname.includes(SUA_PAGES.TEST_HARNESS.path) ||
      location.pathname.includes(SUA_PAGES.DPI_SUPPORT.path)
    ) {
      return tmoLogo;
    }
    if (
      userInfo &&
      groups.length >= 0 &&
      groups[0] === 'SyncUpPetsSupplyChain'
    ) {
      return width <= DIMENSIONS.HYBRID_WIDTH ? portalMobileLogo : portalLogo;
    }
    switch (syncUpProductContext.syncUpAppName) {
      case MISC_PRODUCTS.QA_DASHBOARD:
        return width <= DIMENSIONS.HYBRID_WIDTH ? portalMobileLogo : portalLogo;
      case MISC_PRODUCTS.INTAKE_FORMS:
        return width <= DIMENSIONS.HYBRID_WIDTH ? portalMobileLogo : portalLogo;
      case 'KIDSWATCH':
        return width <= DIMENSIONS.HYBRID_WIDTH ? watchMobileLogo : watchLogo;
      case 'TRACKER':
        return width <= DIMENSIONS.HYBRID_WIDTH
          ? trackerMobileLogo
          : trackerLogo;
      case 'DRIVE':
        return width <= DIMENSIONS.HYBRID_WIDTH ? driveMobileLogo : driveLogo;
      case 'FAMILYLOCATION':
        return width <= DIMENSIONS.HYBRID_WIDTH ? familyMobileLogo : familyLogo;
      default:
        return width <= DIMENSIONS.HYBRID_WIDTH ? petMobileLogo : petLogo;
    }
  };

  return (
    <a className={style.logo} href={homePageUrl}>
      <img
        className={className}
        src={getProductLogo()}
        alt="T-Mobile"
        width="180"
      />
    </a>
  );
}

export default ThingsLogo;
