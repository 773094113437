export const APPCENTER_SUGGESTED_QUERIES_PETS = [
  {
    name: 'Wifi Connect Pairing Flow',
    query: `customEvents
    | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-wifi-unsuccessful" or name == "syncup-pets-wifi-attempt" or name == "syncup-pets-wifi-successful")
    | summarize dcount(user_Id) by name
    | order by dcount_user_Id desc`
  },
  {
    name: 'Wifi Manage',
    query: `customEvents
    | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-manage-wifi-try-again" or name == "syncup-pets-manage-wifi-success-done" or name == "syncup-pets-manage-wifi-try-again" or name == "syncup-pets-manage-wifi-cancel" or name == "syncup-pets-manage-wifi-next" or name == "syncup-pets-manage-wifi-report-issue")
    | summarize dcount(user_Id) by name
    | order by dcount_user_Id desc`
  },
  {
    name: 'BLE Pairing',
    query: `customEvents
    | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-ble-number-of-attempts-to-pair" or name == "syncup-pets-ble-successful" or name == "syncup-pets-ble-error" or name == "syncup-pets-ble-unsuccessful")
    | summarize dcount(user_Id) by name
    | order by dcount_user_Id desc`
  },
  {
    name: 'BLE Beacon',
    query: `customEvents
    | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "beacon-ble-device-report-timer-reset" or name == "beacon-ble-data-fetch-success" or name == "beacon-ble-connect-failed" or name == "beacon-ble-connect-success" or name == "beacon-ble-data-fetch-failed")
    | summarize dcount(user_Id) by name
    | order by dcount_user_Id desc`
  },
  {
    name: 'Pet Profile',
    query: `customEvents
    | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-pet-dashboard-devicelight-on" or name == "syncup-pets-pet-dashboard-devicelight-off" or name == "syncup-pets-pet-name" or name == "syncup-pets-pet-profile-deleted" or name == "syncup-pets-pet-type-dog" or name == "syncup-pets-pet-profile-created-onboarding" or name == "syncup-pets-pet-type-cat" or name == "syncup-pets-pet-profile-delete-dismissed" or name == "syncup-pets-pet-type-other")
    | summarize dcount(user_Id) by name
    | order by dcount_user_Id desc`
  },
  {
    name: 'Safe Zone',
    query: `customEvents
    | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-safe-zone-tile" or name == "syncup-pets-safe-zone-toggle-on" or name == "syncup-pets-safe-zone-edit" or name == "syncup-pets-safe-zone-updated" or name == "syncup-pets-safe-zone-assigned" or name == "syncup-pets-safe-zone-created" or name == "syncup-pets-safe-zone-size" or name == "syncup-pets-safe-zone-toggle-off" or name == "syncup-pets-safe-zone-deleted")
    | summarize dcount(user_Id) by name
    | order by dcount_user_Id desc`
  },
  {
    name: 'Bio Login',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-bio-login-off" or name == "syncup-pets-bio-login-on" )
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'Errors',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "EXCEPTION" or name == "ERROR CODE" )
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'Activity',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-tab-activity-tile" or name == "syncup-pets-tab-activity-tile-weekly" or name == "syncup-pets-tab-activity-tile-monthly" or name == "syncup-pets-tab-activity-tile-daily")
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'Location',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-location-request-accept" or name == "syncup-pets-locationicon" or name == "syncup-pets-location-request-decline")
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'ToS and ICN',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-tos-new-user-decline" or name == "syncup-pets-tos-new-user-accept" or name == "syncup-pets-tos-returning-user-accept" or name == "syncup-pets-tos-returning-user-decline" or name=="syncup-pets-icn-new-user-decline" or name=="syncup-pets-icn-new-user-accept" or name=="syncup-pets-icn-returning-user-accept" or name=="syncup-pets-icn-returning-user-decline")
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'Pairing',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-pair-device" or name == "syncup-pets-pair-next" or name == "syncup-pets-pair-device-skip" or name == "syncup-pets-pair-device-cancel" or name=="syncup-pets-pair-device-nodevice-found")
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'Login',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-app-login-success" or name == "syncup-pets-app-login-failure")
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'Push Notifications',
    query: `customEvents
  | where timestamp > datetime(2020-02-21 11:00:00) and timestamp < ago(1m) and (name == "syncup-pets-push-notification-safe-zone-entered-viewed" or name == "syncup-pets-push-notification-safe-zone-exited-viewed" or name=="syncup-pets-push-notification-firmware-updated-viewed" or name == "syncup-pets-push-notification-device-activated-viewed")
  | summarize dcount(user_Id) by name
  | order by dcount_user_Id desc`
  },
  {
    name: 'Onboarding Duration minutes',
    query: `customMetrics
    | extend timeInMinutes = round(valueSum /60000)
    | extend PST_time_DST = timestamp-7h
    | where timestamp > datetime(2020-02-21 11:00:00) and name == "device onboarded total time to process"
    | project timestamp, PST_time_DST, timeInMinutes, customDimensions
    | order by timestamp desc`
  },
  {
    name: 'Average Onboarding Time In Minutes',
    query: `customMetrics
    | where timestamp > datetime(2020-02-21 11:00:00) and name == "device onboarded total time to process"
    | summarize average_onbaording_time_minutes = round(avg(valueSum) /60000)`
  }
];
