import config from '../configs/config';
import { createBrowserHistory } from 'history';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { PublicClientApplication } from '@azure/msal-browser';

import { STORAGE_TYPES } from '../utils/app_constants';

const { oktaSettings, msalSettings } = config;
const { issuer, clientId, redirectUri, scopes } = oktaSettings;

const msalInstance = new PublicClientApplication(msalSettings);
msalInstance.initialize();
const history = createBrowserHistory();

const OktaConfig = {
  history,
  issuer,
  clientId,
  redirectUri,
  scopes,
  tokenManager: {
    autoRenew: true,
    storage: STORAGE_TYPES.LOCAL
  }
};
const authClient = new OktaAuth(OktaConfig);

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};

const getMSALToken = async () => {
  var request = {
    scopes: ['User.Read']
  };
  try {
    const login = await msalInstance.acquireTokenSilent(request);
    return login.idToken;
  } catch (error) {
    return msalInstance.acquireTokenRedirect(request);
  }
};

export { authClient, history, restoreOriginalUri, getMSALToken };
