import React from 'react';
import Poc from './Poc';

import { useParams } from 'react-router-dom';

import './GenericPoc.module.css';

function GenericPoc({ className }) {
  const { productId } = useParams();

  return <Poc productId={productId} />;
}

export default GenericPoc;
