import React, { useState } from 'react';
import TmoCheckbox from './TmoCheckbox';

import './MultipleSelect.css';

function MultipleSelect() {
  const [checkedItems, setCheckedItems] = useState({});

  const handleChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  const checkboxes = [
    {
      name: 'TMO-103-1051',
      label: 'TMO-103-1051',
      key: 'Today 08:45 am'
    },
    {
      name: 'TMO-103-1053',
      label: 'TMO-103-1053',
      key: 'Today 09:45 am'
    },
    {
      name: 'TMO-103-1054',
      label: 'TMO-103-1054',
      key: 'Yesterday 09:45 am'
    },
    {
      name: 'TMO-103-1055',
      label: 'TMO-103-1055',
      key: 'Yesterday 08:45 am'
    },
    {
      name: 'TMO-103-1056',
      label: 'TMO-103-1056',
      key: 'Yesterday 07:45 am'
    },
    {
      name: 'TMO-101-1087',
      label: 'TMO-103-1087',

      key: 'Yesterday 06:45 am'
    },
    {
      name: 'TMO-102-1089',
      label: 'TMO-103-1089',
      key: 'Yesterday 05:45 am'
    },
    {
      name: 'TMO-103-1050',
      label: 'TMO-103-1050',
      key: 'Yesterday 04:45 am'
    },
    {
      name: 'TMO-103-1056',
      label: 'TMO-103-1056',
      key: 'Yesterday 07:45 am'
    },
    {
      name: 'TMO-101-1087',
      label: 'TMO-103-1087',

      key: 'Yesterday 06:45 am'
    },
    {
      name: 'TMO-102-1089',
      label: 'TMO-103-1089',
      key: 'Yesterday 05:45 am'
    },
    {
      name: 'TMO-103-1050',
      label: 'TMO-103-1050',
      key: 'Yesterday 04:45 am'
    }
  ];

  return (
    <div className="MultipleSelect-container">
      {checkboxes.map(item => (
        <div className="MultipleSelect-Checkbox-container">
          <label key={item.key}>
            {item.name}
            <TmoCheckbox
              name={item.name}
              checked={checkedItems[item.name]}
              onChange={handleChange}
            />
          </label>
        </div>
      ))}
    </div>
  );
}

export default MultipleSelect;
