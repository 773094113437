import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Redirect } from 'react-router-dom';

import ErrorMessageModal from '../components/errorHandler/ErrorMessageModal';
import { STORAGE_KEYS, STORAGE_TYPES } from '../utils/app_constants';
import { getHomePageUrl } from '../utils/helper_functions';
import {
  storageRead,
  storageRemoveItem,
  storageWrite
} from '../utils/storageUtils';

const CallbackMsal = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const authCheck = async () => {
      if (isAuthenticated) {
        try {
          let user = instance.getActiveAccount();
          let thingsPlatformGroups = user?.idTokenClaims?.ThingsPlatformGroups;
          user.ThingsPlatformGroups = Array.isArray(thingsPlatformGroups)
            ? thingsPlatformGroups
            : [thingsPlatformGroups];
          user.family_name =
            user.name && user.name.split(',').length > 1
              ? user.name.split(',')[1]
              : '';
          user.given_name = user.name ? user.name.split(',')[0] : '';
          user.email = user.username ? user.username : '';
          let response;
          if (user) {
            response = await instance.acquireTokenSilent({ account: user });
          }
          const idToken = response?.idToken;
          storageWrite({
            key: STORAGE_KEYS.OKTA_USER_INFO,
            value: JSON.stringify(user),
            storageType: STORAGE_TYPES.LOCAL
          });
          storageWrite({
            key: STORAGE_KEYS.OKTA_ID_TOKEN,
            value: idToken,
            storageType: STORAGE_TYPES.LOCAL
          });

          const intendedUrl = storageRead({
            key: STORAGE_KEYS.INTENDED_URL,
            storageType: STORAGE_TYPES.SESSION
          });
          setRedirectUrl(
            intendedUrl ? intendedUrl : getHomePageUrl(user.Groups)
          );
          storageRemoveItem({
            key: STORAGE_KEYS.INTENDED_URL,
            storageType: STORAGE_TYPES.SESSION
          });
        } catch (error) {
          handleADAuthenticationError(error);
        }
      }
    };

    authCheck();
  }, [instance, isAuthenticated]);

  const handleADAuthenticationError = error => {
    setError(error);
  };

  const logout = () => {
    instance.logoutRedirect();
  };
  return isAuthenticated === true ? (
    <Redirect to={redirectUrl} />
  ) : (
    error && <ErrorMessageModal message={error} onClose={logout} />
  );
};
export default CallbackMsal;
