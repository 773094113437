import React, { useState, useCallback, useContext } from 'react';
import { RawData } from 'tmo-bps-syncup-web-component-lib';

import Fetcher from '../../components/Fetcher';
import userService from '../../services/userService';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import SafeZone from './SafeZone';
import Authorized from '../../auth/Authorized';
import { getMapCenter } from '../../utils/mapUtils';
import appConfig from '../../configs/config';
import { prepareSafeZonePlottingData } from '../../utils/SafeZoneHelper';
import { SUA_ROLES } from '../../utils/app_constants';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';

import style from './UserSafeZones.module.css';

const { googleMapSettings, reactGoogleMaps } = appConfig;

function UserSafeZones({ match }) {
  const [safeZones, setSafeZones] = useState();
  const [safeZoneLength, setSafeZoneLength] = useState(0);

  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const actionCallback = useCallback(
    () =>
      userService.getUserSafeZones({
        id: userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  const mapContainer = <div className={style.map} />;

  const loadSafeZones = useCallback(safeZones => {
    setSafeZones(safeZones);
    setSafeZoneLength(safeZones.length);
  }, []);

  return (
    <>
      <Authorized roles={[SUA_ROLES.ADMIN]} willShowErrorModal>
        <PageHeader
          title={'Virtual Boundary'}
          subtitle={safeZoneLength > 0 && `Count: ${safeZoneLength}`}
          tabHeader={true}
          className={style.header}
        >
          {safeZones && (
            <RawData
              rawObject={safeZones}
              title="Virtual Boundary"
              type="User Safe Zone"
              modalType={'slide'}
              buttonType="magenta_link"
            />
          )}
        </PageHeader>
        <Fetcher
          action={actionCallback}
          onLoad={loadSafeZones}
          render={() =>
            safeZones && safeZoneLength > 0 ? (
              <SafeZone
                center={getMapCenter(safeZones)}
                zoom={4}
                places={prepareSafeZonePlottingData(safeZones)}
                googleMapURL={`${reactGoogleMaps.url}${googleMapSettings.apiKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `600px` }} />}
                mapElement={mapContainer}
              />
            ) : (
              <div className={style.message}>
                User does not have any Virtual Boundaries
              </div>
            )
          }
        />
      </Authorized>
    </>
  );
}

export default UserSafeZones;
