import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import TmoToggle from '../../forms/TmoToggle';
import { getRandomString } from '../../../utils/stringUtils';
import moment from 'moment';
import TmoSvg from '../../uiHelpers/TmoSvg';

import style from './TagsDeviceToggle.module.css';

function TagsDeviceToggle({
  isLocked,
  name,
  currentState,
  tagsDeviceStatusChanged,
  lockStatusTrue,
  lockStatusFalse,
  lockStatusTurningTrue,
  lockStatusTurningFalse
}) {
  const [loading, setLoading] = useState(false);
  const [lockStatus, setLockStatus] = useState(isLocked);

  const toggle = () => {
    setLockStatus(!lockStatus);
    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      tagsDeviceStatusChanged(name, lockStatus);
      setTimeout(() => {
        setLockStatus(lockStatus);
        setLoading(false);
      }, 8000);
    }
    return () => clearTimeout();
  }, [loading, lockStatus, tagsDeviceStatusChanged, name]);

  return (
    <div className={style.status}>
      <TmoToggle
        className={style.toggle}
        id={getRandomString()}
        isChecked={lockStatus}
        onChange={toggle}
        disabled={loading}
      />
      {loading && (
        <div className={classNames(style.loading, lockStatus && style.locked)}>
          <div className={style.loading_inner}>
            <TmoSvg className={style.loading_spinner} svgId="loading-spinner" />
            <span className={style.loading_text}>
              <span>
                {lockStatus ? lockStatusTurningTrue : lockStatusTurningFalse}
              </span>{' '}
              {name}, please wait...
            </span>
          </div>
        </div>
      )}
      <div className={style.info}>
        <span>{lockStatus ? lockStatusTrue : lockStatusFalse}</span>
        {!lockStatus && (
          <span className={style.info_since}>
            Since {moment().format('LT')}
          </span>
        )}
      </div>
    </div>
  );
}

export default TagsDeviceToggle;
