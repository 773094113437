import React from 'react';

import style from './Svgs.module.css';

function Svgs() {
  return (
    <div className={style.container}>
      <svg>
        <defs>
          <symbol
            id="loading-spinner"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <path
              d="M10 50a40 40 0 0 0 80 0 40 42 0 0 1-80 0"
              fill="#e20074"
              transform="rotate(11.308 50 51)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 51;360 50 51"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
          </symbol>

          <symbol id="add" viewBox="0 0 24 24">
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
          </symbol>

          <symbol viewBox="0 0 512 512" id="pencil">
            <g>
              <path d="M422.953,176.019c0.549-0.48,1.09-0.975,1.612-1.498l21.772-21.772c12.883-12.883,12.883-33.771,0-46.654   l-40.434-40.434c-12.883-12.883-33.771-12.883-46.653,0l-21.772,21.772c-0.523,0.523-1.018,1.064-1.498,1.613L422.953,176.019z" />
              <polygon points="114.317,397.684 157.317,440.684 106.658,448.342 56,456 63.658,405.341 71.316,354.683  " />
              <polygon points="349.143,125.535 118.982,355.694 106.541,343.253 336.701,113.094 324.26,100.653 81.659,343.253    168.747,430.341 411.348,187.74  " />
            </g>
          </symbol>

          <symbol id="x" viewBox="0 0 24 24">
            <path d="M24 20.188l-8.315-8.209 8.2-8.282L20.188 0l-8.212 8.318L3.666.115 0 3.781l8.321 8.24-8.206 8.313L3.781 24l8.237-8.318 8.285 8.203z" />
          </symbol>

          {/* Commercial Tracker POC Icons */}
          <symbol id="icon-ac-power" viewBox="0 0 35 18">
            <path fill="#FFF" d="M31.5 1v16h-30V1z" />
            <path
              fill="#666"
              d="M2 2.55c0-.39.364-.708.813-.708h27.441c.41 0 .746.292.746.65v13.016c0 .357-.336.65-.746.65H2.813c-.449 0-.813-.317-.813-.709V2.551zm30.403 10.507H34.5v-8.17h-2.097v-1.92c0-1.37-1.271-2.467-2.86-2.467H3.36C1.77.5.5 1.597.5 2.967v12.064c0 1.37 1.271 2.467 2.86 2.467h26.12c1.588.054 2.923-1.097 2.923-2.467v-1.974z"
            />
            <path fill="#6A6A6A" d="M22 4v10h-3a5 5 0 110-10h3z" />
            <path
              stroke="#6A6A6A"
              strokeLinecap="square"
              strokeWidth="2"
              d="M18 11h7m-7-4h7M7 9h10"
            />
          </symbol>

          <symbol id="icon-alert-closed">
            <circle cx="6" cy="6" r="6" fillOpacity=".85" />
          </symbol>

          <symbol id="icon-alert-open">
            <circle
              cx="6"
              cy="6"
              r="4.5"
              fill="none"
              stroke="#FF5438"
              strokeWidth="3"
            />
          </symbol>

          <symbol id="icon-battery-0">
            <path fill="#FFF" d="M31.5 1v16h-30V1z" />
            <path
              fill="#666"
              d="M2 2.55c0-.39.364-.708.813-.708h27.441c.41 0 .746.292.746.65v13.016c0 .357-.336.65-.746.65H2.813c-.449 0-.813-.317-.813-.709V2.551zm30.403 10.507H34.5v-8.17h-2.097v-1.92c0-1.37-1.271-2.467-2.86-2.467H3.36C1.77.5.5 1.597.5 2.967v12.064c0 1.37 1.271 2.467 2.86 2.467h26.12c1.588.054 2.923-1.097 2.923-2.467v-1.974z"
            />
          </symbol>

          <symbol id="icon-battery-25">
            <path fill="#FFF" d="M31.5 1v16h-30V1z" />
            <path
              fill="#666"
              d="M2 2.55c0-.39.364-.708.813-.708h27.441c.41 0 .746.292.746.65v13.016c0 .357-.336.65-.746.65H2.813c-.449 0-.813-.317-.813-.709V2.551zm30.403 10.507H34.5v-8.17h-2.097v-1.92c0-1.37-1.271-2.467-2.86-2.467H3.36C1.77.5.5 1.597.5 2.967v12.064c0 1.37 1.271 2.467 2.86 2.467h26.12c1.588.054 2.923-1.097 2.923-2.467v-1.974z"
            />
            <g transform="rotate(90 4.5 6)">
              <rect
                width="11"
                height="6"
                x="2"
                y="1"
                fill="#FF3641"
                rx=".175"
              />
              <path fill="#FFF" fillRule="nonzero" d="M.5 2h13V0H.5z" />
            </g>
          </symbol>

          <symbol id="icon-battery-50">
            <path fill="#FFF" d="M31.5 1v16h-30V1z" />
            <path
              fill="#666"
              d="M2 2.55c0-.39.364-.708.813-.708h27.441c.41 0 .746.292.746.65v13.016c0 .357-.336.65-.746.65H2.813c-.449 0-.813-.317-.813-.709V2.551zm30.403 10.507H34.5v-8.17h-2.097v-1.92c0-1.37-1.271-2.467-2.86-2.467H3.36C1.77.5.5 1.597.5 2.967v12.064c0 1.37 1.271 2.467 2.86 2.467h26.12c1.588.054 2.923-1.097 2.923-2.467v-1.974z"
            />
            <g transform="rotate(90 8 9.5)">
              <rect
                width="11"
                height="13"
                x="2"
                y="1"
                fill="#F8C603"
                rx=".175"
              />
              <path fill="#FFF" fillRule="nonzero" d="M.5 2h13V0H.5z" />
            </g>
          </symbol>

          <symbol id="icon-battery-75">
            <path fill="#FFF" d="M31.5 1v16h-30V1z" />
            <path
              fill="#666"
              d="M2 2.55c0-.39.364-.708.813-.708h27.441c.41 0 .746.292.746.65v13.016c0 .357-.336.65-.746.65H2.813c-.449 0-.813-.317-.813-.709V2.551zm30.403 10.507H34.5v-8.17h-2.097v-1.92c0-1.37-1.271-2.467-2.86-2.467H3.36C1.77.5.5 1.597.5 2.967v12.064c0 1.37 1.271 2.467 2.86 2.467h26.12c1.588.054 2.923-1.097 2.923-2.467v-1.974z"
            />
            <g transform="rotate(90 12.5 15)">
              <rect
                width="11"
                height="21"
                x="1"
                y="3"
                fill="#78D804"
                rx=".175"
              />
              <path fill="#FFF" d="M.5 0h13v5H.5z" />
            </g>
          </symbol>

          <symbol id="icon-battery-100">
            <path fill="#FFF" d="M31.5 1v16h-30V1z" />
            <path
              fill="#666"
              d="M2 2.55c0-.39.364-.708.813-.708h27.441c.41 0 .746.292.746.65v13.016c0 .357-.336.65-.746.65H2.813c-.449 0-.813-.317-.813-.709V2.551zm30.403 10.507H34.5v-8.17h-2.097v-1.92c0-1.37-1.271-2.467-2.86-2.467H3.36C1.77.5.5 1.597.5 2.967v12.064c0 1.37 1.271 2.467 2.86 2.467h26.12c1.588.054 2.923-1.097 2.923-2.467v-1.974z"
            />
            <rect
              width="11"
              height="26"
              x="3"
              fill="#78D804"
              rx=".175"
              transform="rotate(90 14.5 15)"
            />
          </symbol>

          <symbol id="icon-check">
            <path d="M-2-2h24v24H-2z" />
            <path
              fill="#078A14"
              d="M10 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8m0-18C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0"
            />
            <path
              fill="#078A14"
              d="M14.7 6.5l-6.286 6.286L5.9 10.27l-1.257 1.26 3.771 3.77 7.542-7.543z"
            />
          </symbol>

          <symbol id="icon-dashboard-layout">
            <g fill="#CCC" fillRule="evenodd">
              <rect width="16" height="20" rx="1" />
              <rect width="8" height="20" x="20" rx="1" />
            </g>
          </symbol>

          <symbol id="icon-dashboard" viewBox="0 0 24 24">
            <path d="M-2-4h24v29H-2z" />
            <path
              fill="#FFF"
              d="M6 11.708v8.459H0v-8.459h6zM13 .833v19.334H7V.833h6zm7 6.042v13.292h-6V6.875h6z"
            />
          </symbol>

          <symbol id="icon-devices">
            <g fill="none" fillRule="evenodd">
              <path d="M-1-4h24v29H-1z" />
              <path
                fill="#FFF"
                d="M20 3.25V.833H2C.9.833 0 1.921 0 3.25v14.5c0 1.33.9 2.417 2 2.417h2V17.75H2V3.25h18zM5 15.333C5 12.66 6.787 10.5 9 10.5s4 2.159 4 4.833c0 2.675-1.787 4.834-4 4.834s-4-2.16-4-4.834zm2 0c0-1.337.893-2.416 2-2.416s2 1.08 2 2.416c0 1.338-.893 2.417-2 2.417s-2-1.08-2-2.417zm8-9.666c-.5 0-1 .604-1 1.208v12.083c0 .605.5 1.209 1 1.209h6c.5 0 1-.605 1-1.209V6.875c0-.604-.5-1.208-1-1.208h-6zm1 12.083V8.083h4v9.667h-4z"
              />
            </g>
          </symbol>

          <symbol id="icon-ellipsis">
            <path d="M-10-4h24v24h-24z" />
            <path
              fill="#6A6A6A"
              fillRule="nonzero"
              d="M2 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            />
          </symbol>

          <symbol id="icon-error-triangle">
            <path d="M.336 0h20.16v20H.336z" />
            <g fill="#E8200D">
              <path d="M10.174.862L.336 18.32h19.677L10.173.86zm0 3.357l6.97 12.423H3.204l6.97-12.422z" />
              <path d="M9.354 15.467h1.64v-1.679h-1.64zm0-3.357h1.64V8.751h-1.64z" />
            </g>
          </symbol>

          <symbol id="icon-error">
            <path d="M.336 0h20.16v20H.336z" />
            <path
              fill="#E8200D"
              fillRule="evenodd"
              d="M10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM9 11h2V5H9v6zm0 4h2v-2H9v2z"
            />
          </symbol>

          <symbol id="icon-info">
            <path
              fillOpacity=".85"
              fillRule="evenodd"
              d="M10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM9 15h2V9H9v6zm0-8h2V5H9v2z"
            />
          </symbol>

          <symbol id="icon-locked">
            <path d="M-4-1h20v20H-4z" />
            <path
              fill="#346DCD"
              fillRule="nonzero"
              d="M6.5 0c2.242 0 4.063 1.92 4.063 4.286V6h.812C12.269 6 13 6.771 13 7.714v8.572c0 .943-.731 1.714-1.625 1.714h-9.75C.731 18 0 17.229 0 16.286V7.714C0 6.771.731 6 1.625 6h7.394V4.286c0-1.466-1.13-2.657-2.519-2.657-1.39 0-2.519 1.191-2.519 2.657H2.438C2.438 1.92 4.258 0 6.5 0zm4.875 7.714h-9.75v8.572h9.75V7.714z"
            />
            <path
              fill="#346DCD"
              fillRule="nonzero"
              d="M2.44 4h1.55v2H2.44zm4.06 9.714c.894 0 1.625-.771 1.625-1.714s-.731-1.714-1.625-1.714-1.625.771-1.625 1.714.731 1.714 1.625 1.714z"
            />
          </symbol>

          <symbol id="icon-unlocked">
            <path d="M-4-1h20v20H-4z" />
            <path
              fill="#979797"
              fillRule="nonzero"
              d="M6.5 13.714c.894 0 1.625-.771 1.625-1.714s-.731-1.714-1.625-1.714-1.625.771-1.625 1.714.731 1.714 1.625 1.714zM11.375 6h-.813V4.286C10.563 1.92 8.742 0 6.5 0S2.437 1.92 2.437 4.286h1.544c0-1.466 1.13-2.657 2.519-2.657 1.39 0 2.519 1.191 2.519 2.657V6H1.625C.731 6 0 6.771 0 7.714v8.572C0 17.229.731 18 1.625 18h9.75c.894 0 1.625-.771 1.625-1.714V7.714C13 6.771 12.269 6 11.375 6zm0 10.286h-9.75V7.714h9.75v8.572z"
            />
          </symbol>

          <symbol id="icon-user">
            <path d="M-4-4h24v29H-4z" />
            <path
              fill="#FFF"
              d="M8 10.5c2.21 0 4-2.163 4-4.833 0-2.67-1.79-4.834-4-4.834S4 2.996 4 5.667C4 8.337 5.79 10.5 8 10.5zm0 2.417c-2.67 0-8 1.619-8 4.833v2.417h16V17.75c0-3.214-5.33-4.833-8-4.833z"
            />
          </symbol>

          <symbol id="close">
            <path
              d="M12 1.05L10.95 0L6 4.95L1.05 0L0 1.05L4.95 6L0 10.95L1.05 12L6 7.05L10.95 12L12 10.95L7.05 6L12 1.05Z"
              fill="#4C4C4C"
            />
          </symbol>
          <symbol id="icon-close-modal">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 7.05L16.95 6L12 10.95L7.05 6L6 7.05L10.95 12L6 16.95L7.05 18L12 13.05L16.95 18L18 16.95L13.05 12L18 7.05Z"
              fill="#E20074"
            />
          </symbol>
          <symbol id="icon-copy">
            <path
              d="M21 7.5V21H7.5V7.5H21ZM21 6H7.5C7.10218 6 6.72064 6.15804 6.43934 6.43934C6.15804 6.72064 6 7.10218 6 7.5V21C6 21.3978 6.15804 21.7794 6.43934 22.0607C6.72064 22.342 7.10218 22.5 7.5 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V7.5C22.5 7.10218 22.342 6.72064 22.0607 6.43934C21.7794 6.15804 21.3978 6 21 6Z"
              fill="#fff"
            />
            <path
              d="M3 13.5H1.5V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H13.5V3H3V13.5Z"
              fill="#fff"
            />
          </symbol>
          <symbol id="icon-check-modal">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0001 15.9L4.7001 10.6L3.6001 11.6L8.9001 16.9L10.0001 18L20.6001 7.40005L19.5001 6.30005L10.0001 15.9Z"
              fill="white"
            />
          </symbol>
          <symbol id="icon-file-download">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.5 11.25L18.4425 10.1925L12.75 15.8775V1.5H11.25V15.8775L5.5575 10.1925L4.5 11.25L12 18.75L19.5 11.25ZM19.5 18V21H4.5V18H3V21C3 21.8284 3.67157 22.5 4.5 22.5H19.5C20.3284 22.5 21 21.8284 21 21V18H19.5Z"
              fill="white"
            />
          </symbol>
          <symbol id="icon-sort">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.34615 3.5L1 9.84615L1.89481 10.741L6.71154 5.93058V20H7.98077V5.93058L12.7975 10.741L13.6923 9.84615L7.34615 3.5Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.1051 14.4504L17.2884 19.2608V5.19141H16.0192V19.2608L11.2024 14.4504L10.3076 15.3453L16.6538 21.6914L22.9999 15.3453L22.1051 14.4504Z"
              fill="currentColor"
            />
          </symbol>
          <symbol id="icon-filter">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.87646 6.30109H22.1237V4.99609H1.87646V6.30109ZM17.3783 10.8686H1.87646V9.5636H17.3783V10.8686ZM1.87646 15.4361H12.3165V14.1311H1.87646V15.4361ZM1.87646 20.0036H7.25465V18.6986H1.87646V20.0036Z"
              fill="currentColor"
            />
          </symbol>
        </defs>
      </svg>
    </div>
  );
}

export default Svgs;
