import React from 'react';
import classNames from 'classnames';

import './TmoCheckbox.css';

function TmoCheckbox({
  isChecked = false,
  onChange,
  onClick,
  id,
  value,
  disabled,
  className,
  hideLabel = false
}) {
  const attributes = {};
  if (onClick) {
    attributes['onClick'] = e => {
      e.stopPropagation();
    };
  }
  const handleChange = target => {
    onChange(target);
  };

  return (
    <div>
      <label />
      <input
        className={classNames('TmoCheckbox', className)}
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        {...attributes}
      />
      {!hideLabel && <label htmlFor={id}> {value}</label>}
    </div>
  );
}

export default TmoCheckbox;
