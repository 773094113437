import React from 'react';
import { ReactSVG } from 'react-svg';
import { TmoCard, ConfirmationButton } from 'tmo-bps-syncup-web-component-lib';

import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './AuthUserItem.module.css';
import delete_icon from '../../static/assets/icons/action/delete.svg';

function AuthUserItem({ authUser, onDelete }) {
  const {
    username,
    userId,
    createdDateTime,
    createdBy,
    allowedScopes,
    modifiedDateTime,
    subject,
    lastModifiedBy
  } = authUser;

  const handleDelete = () => {
    subject && onDelete(subject);
  };

  return (
    <TmoCard className={style.container}>
      <div className={style.authUser_wrapper}>
        <div className={style.card_header}>
          <div className={style.card_title}>
            <span className={style.user_name}>{username ? username : '-'}</span>
            <label className={style.scope_name}>
              {' '}
              {allowedScopes && allowedScopes.join(', ')}{' '}
            </label>
          </div>
          <div className={style.action_controls}>
            <div className={style.delete_btn}>
              <ConfirmationButton
                type="delete"
                buttonType="magenta_secondary"
                handleDeleteClicked={handleDelete}
                modalMessage={<>Are you sure you want to delete {subject}</>}
                modalTitle="Delete Auth User"
                modalAcceptButtonText={'Yes,Delete'}
                buttonIcon={<ReactSVG src={delete_icon} />}
                tooltip="Delete"
              />
            </div>
          </div>
        </div>

        <div className={style.content}>
          <ItemDetailList
            rowSet={[
              { Subject: subject },
              {
                'Created Date & Time': createdDateTime,
                'Created By': createdBy
              },
              {
                'Modified Date & Time': modifiedDateTime,
                'Modified By': lastModifiedBy
              },
              { 'App User': userId }
            ]}
          />
        </div>
      </div>
    </TmoCard>
  );
}

export default AuthUserItem;
