import React, { useMemo, useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { withRouter, Switch, Route } from 'react-router-dom';
import {
  NavigationTabGroup as TabGroup,
  NavigationTab as Tab
} from 'tmo-bps-syncup-web-component-lib';

import UserDetails from './UserDetails';
import Notifications from '../notifications/Notifications';
import EmergencyContactsList from '../contacts/EmergencyContactsList';
import SchoolMode from '../schoolMode/SchoolMode';
import PhoneLocation from 'components/phoneLocation/PhoneLocation';
import PhoneLocationGroups from 'components/phoneLocation/PhoneLocationGroups';
import PhoneLocationGroupUsers from 'components/phoneLocation/PhoneLocationGroupUsers';
import PhoneLocationGroupVB from 'components/phoneLocation/PhoneLocationGroupVB';
import PrivacyStatus from 'components/privacyStatus/PrivacyStatus';
import UserTask from 'components/tasks/UserTask';
import IssueCommands from '../issueCommands/IssueCommands';
import ThingEventsGrid from '../drive/ThingEventsGrid';
import ThingTripsGrid from '../drive/ThingTripsGrid';
import Things from '../things/Things';
import SharedThings from 'components/things/SharedThings';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import Devices from '../devices/Devices';
import HealthCheck from '../../containers/HealthCheck';
import DeviceLogs from '../devices/logs/DeviceLogs';
import UserSafeZones from '../safeZones/UserSafeZones';
import Feature from '../featureWrappers/Feature';
import {
  APP_MOBILE,
  STORAGE_KEYS,
  STORAGE_TYPES
} from '../../utils/app_constants';
import { getRandomString } from '../../utils/stringUtils';
import DeviceRoadsideServices from '../drive/DeviceRoadsideServices';
import { storageRead, storageWrite } from 'utils/storageUtils';
import Logs from 'components/logs/Logs';

import style from './User.module.css';

function User({ location, match: { url }, user, onTabClose }) {
  const [hideTab, setHideTab] = useState(false);
  const [thingsCount, setThingsCount] = useState();
  const thingsUrl = useMemo(() => `${url}/things`, [url]);
  const sharedThingsUrl = useMemo(() => `${url}/shared-things`, [url]);
  const notificationsUrl = useMemo(() => `${url}/notifications`, [url]);
  const emergencyContactsUrl = useMemo(() => `${url}/contacts`, [url]);
  const schoolModeUrl = useMemo(() => `${url}/school-mode`, [url]);
  const userTaskUrl = useMemo(() => `${url}/user-task`, [url]);
  const safeZonesUrl = useMemo(() => `${url}/safezones`, [url]);

  const devicesUrl = useMemo(() => `${url}/devices`, [url]);
  const phoneLocationUrl = useMemo(() => `${url}/phone-location`, [url]);
  const phoneLocationGroupsUrl = useMemo(() => `${url}/phone-location-groups`, [
    url
  ]);

  const healthUrl = `${devicesUrl}/:deviceId/health`;
  const logsUrl = `${devicesUrl}/:deviceId/hardware/:hardwareId/logs`;
  const phoneLocationGroupUsersUrl = `${phoneLocationGroupsUrl}/:groupId/users`;
  const phoneLocationGroupVBUrl = `${phoneLocationGroupsUrl}/:groupId/virtual-boundary`;
  const commandUrl = `${devicesUrl}/:deviceId/hardware/:hardwareId/command`;
  const thingEventsUrl = `${thingsUrl}/:thingId/device/:deviceId/events`;
  const thingTripsUrl = `${thingsUrl}/:thingId/trips`;
  const thingLogsUrl = `${thingsUrl}/:thingId/device/:deviceId/logs`;
  const deviceRoadsideServiceUrl = `${devicesUrl}/:thingId/roadside-services`;
  const devicePrivacyStatusUrl = `${thingsUrl}/device/:deviceId/privacy-status`;

  const activeUserTabContext = useContext(ActiveUserTabContext);
  const [lastRefresh, setLastRefresh] = useState(getRandomString());
  const productType = activeUserTabContext.selectedTab.product;

  const refreshDetails = () => {
    setLastRefresh(getRandomString());
  };

  const isTabDisabled = thingsCount === 0;

  const thingTypeFriendlyName =
    productType === 'KIDSWATCH'
      ? 'WATCHES'
      : productType === 'TRACKER'
      ? 'TRACKERS'
      : productType === 'FAMILYLOCATION'
      ? 'Family Members'
      : productType === 'DRIVE'
      ? 'Vehicles'
      : productType;

  useEffect(() => {
    if (location.pathname.includes(`/search/users/${user.userId}`)) {
      const sessiondata = storageRead({
        key: STORAGE_KEYS.USER_TABS,
        storageType: STORAGE_TYPES.SESSION
      });
      let updatedItems = JSON.parse(sessiondata);
      updatedItems.forEach(item => {
        item.selected = false;
        if (item.userId === user.userId && item.product === productType) {
          item.url = location.pathname;
          item.selected = true;
        }
      });
      storageWrite({
        key: STORAGE_KEYS.USER_TABS,
        value: JSON.stringify(updatedItems),
        storageType: STORAGE_TYPES.SESSION
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  return (
    <>
      <div className={style.details}>
        <UserDetails
          user={user}
          userRefreshDetails={refreshDetails}
          onTabClose={onTabClose}
        />
      </div>
      <Feature
        isUserDetails
        Products={[
          APP_MOBILE.PETS,
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH,
          APP_MOBILE.FAMILYLOCATION,
          APP_MOBILE.DRIVE
        ]}
      >
        <div className={style.things_container}>
          {!hideTab && (
            <TabGroup className={classNames(style.things_tab)}>
              <Tab
                to={thingsUrl}
                text={thingTypeFriendlyName}
                className={style.text}
                type="primary-tab"
              />
              <Feature isUserDetails Products={[APP_MOBILE.TRACKER]}>
                <Tab
                  to={sharedThingsUrl}
                  text="Shared"
                  className={style.text}
                  type="primary-tab"
                />
              </Feature>
              <Tab
                disabled={isTabDisabled && productType !== 'DRIVE'}
                to={devicesUrl}
                text="devices"
                className={classNames(
                  style.text,
                  isTabDisabled && style.disabled
                )}
                type="primary-tab"
              />
              <Feature isUserDetails Products={[APP_MOBILE.WATCH]}>
                <Tab
                  disabled={false}
                  to={phoneLocationUrl}
                  text="phone location"
                  className={style.text}
                  type="primary-tab"
                />
                <Tab
                  disabled={false}
                  to={phoneLocationGroupsUrl}
                  text="Location Groups"
                  className={style.text}
                  type="primary-tab"
                />
              </Feature>
              <Feature
                isUserDetails
                Products={[
                  APP_MOBILE.PETS,
                  APP_MOBILE.TRACKER,
                  APP_MOBILE.WATCH,
                  APP_MOBILE.FAMILYLOCATION,
                  APP_MOBILE.DRIVE
                ]}
              >
                <Tab
                  disabled={isTabDisabled}
                  to={notificationsUrl}
                  text="notifications"
                  className={classNames(
                    style.text,
                    isTabDisabled && style.disabled
                  )}
                  type="primary-tab"
                />
              </Feature>
              <Feature
                isUserDetails
                Products={[
                  APP_MOBILE.PETS,
                  APP_MOBILE.TRACKER,
                  APP_MOBILE.WATCH,
                  APP_MOBILE.DRIVE,
                  APP_MOBILE.FAMILYLOCATION
                ]}
              >
                <Tab
                  disabled={isTabDisabled}
                  to={safeZonesUrl}
                  text="virtual boundary"
                  className={classNames(
                    style.text,
                    isTabDisabled && style.disabled
                  )}
                  type="primary-tab"
                />
              </Feature>
              <Feature isUserDetails Products={[APP_MOBILE.WATCH]}>
                <Tab
                  disabled={isTabDisabled}
                  to={emergencyContactsUrl}
                  text="contacts"
                  className={classNames(
                    style.text,
                    isTabDisabled && style.disabled
                  )}
                  type="primary-tab"
                />
                <Tab
                  disabled={isTabDisabled}
                  to={schoolModeUrl}
                  text="school mode"
                  className={classNames(
                    style.text,
                    isTabDisabled && style.disabled
                  )}
                  type="primary-tab"
                />
                <Tab
                  disabled={isTabDisabled}
                  to={userTaskUrl}
                  text="Tasks"
                  className={classNames(
                    style.text,
                    isTabDisabled && style.disabled
                  )}
                  type="primary-tab"
                />
              </Feature>
            </TabGroup>
          )}
          <div className={style.things_content}>
            <Switch>
              <Route
                exact
                path={thingsUrl}
                render={() => (
                  <Things
                    thingsCount={setThingsCount}
                    userId={user.userId}
                    productType={productType}
                    thingTypeName={thingTypeFriendlyName}
                    refresh={lastRefresh}
                    hideTab={setHideTab}
                  />
                )}
              />
              <Route
                exact
                path={sharedThingsUrl}
                render={() => (
                  <SharedThings
                    userId={user.userId}
                    productType={productType}
                    thingTypeName={thingTypeFriendlyName}
                    refresh={lastRefresh}
                  />
                )}
              />
              <Route exact path={notificationsUrl} component={Notifications} />
              <Route
                exact
                path={devicesUrl}
                render={() => <Devices refresh={lastRefresh} />}
              />
              <Route
                exact
                path={phoneLocationUrl}
                render={() => <PhoneLocation refresh={lastRefresh} />}
              />
              <Route
                exact
                path={phoneLocationGroupsUrl}
                render={() => <PhoneLocationGroups refresh={lastRefresh} />}
              />
              <Route exact path={safeZonesUrl} component={UserSafeZones} />
              <Route
                exact
                path={emergencyContactsUrl}
                component={EmergencyContactsList}
              />
              <Route exact path={schoolModeUrl} component={SchoolMode} />
              <Route exact path={userTaskUrl} component={UserTask} />
              <Route exact path={commandUrl} component={IssueCommands} />
              <Route exact path={healthUrl} component={HealthCheck} />
              <Route exact path={logsUrl} component={DeviceLogs} />
              <Route
                exact
                path={phoneLocationGroupUsersUrl}
                render={() => <PhoneLocationGroupUsers hideTab={setHideTab} />}
              />
              <Route
                exact
                path={phoneLocationGroupVBUrl}
                render={() => <PhoneLocationGroupVB hideTab={setHideTab} />}
              />
              <Route
                exact
                path={devicePrivacyStatusUrl}
                render={() => <PrivacyStatus hideTab={setHideTab} />}
              />
              <Route
                exact
                path={thingEventsUrl}
                render={() => <ThingEventsGrid hideTab={setHideTab} />}
              />
              <Route
                exact
                path={thingTripsUrl}
                render={() => <ThingTripsGrid hideTab={setHideTab} />}
              />
              <Route
                exact
                path={thingLogsUrl}
                render={() => <Logs hideTab={setHideTab} />}
              />
              <Route
                exact
                path={deviceRoadsideServiceUrl}
                render={() => <DeviceRoadsideServices hideTab={setHideTab} />}
              />
            </Switch>
          </div>
        </div>
      </Feature>
    </>
  );
}

export default withRouter(User);
