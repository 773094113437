import React from 'react';

import CommercialLeftPanelNavigation from './CommercialLeftPanelNavigation';

import './CommercialLeftPanel.css';

import TLogo from '../../../static/assets/Tmo-T-Logo.jpg';

function CommercialLeftPanel() {
  const dashboardUrl = `/commercial-tracker/dashboard`;

  return (
    <div className="CommercialLeftPanel-container">
      <div className="CommercialLeftPanel-logo-container">
        <a href={dashboardUrl}>
          <img
            className="CommercialLeftPanel-logo"
            alt="T-Mobile"
            src={TLogo}
          />
        </a>
      </div>
      <CommercialLeftPanelNavigation />
    </div>
  );
}

export default CommercialLeftPanel;
