import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atelierCaveLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { TmoButtons } from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import { trackCustomEvents } from '../utils/appInsight_analytics';

import style from './Clipboard.module.css';
import copyIcon from '../static/assets/icons/action/copy.svg';

const Clipboard = ({
  value,
  heading,
  type = 'textarea',
  lang = 'htmlbars',
  clipboardSty,
  buttonPositon = 'top'
}) => {
  const [copyState, setCopyState] = useState(false);
  const textAreaRef = useRef(null);
  const buttonText = () => {
    return (
      <TmoButtons
        icon={<ReactSVG src={copyIcon} />}
        className={classNames(style.margin)}
        onClick={copyToClipboard}
        type="magenta_primary"
      >
        {copyState ? 'Copied' : 'Copy'}
      </TmoButtons>
    );
  };

  const copyToClipboard = e => {
    if (!navigator.clipboard) {
      textAreaRef.current.select();
      document.execCommand('copy');
      e.target.focus();
      setCopyState(true);
    } else {
      navigator.clipboard.writeText(value);
      setCopyState(true);
    }
    trackCustomEvents(`copy to clickboard`);
  };

  useEffect(() => {
    copyState &&
      setTimeout(async () => {
        setCopyState(false);
      }, 2000);
  }, [copyState, setCopyState]);

  return (
    <div className={classNames(style.container)}>
      {heading && <h3 className={style.inner_sub_heading}>{heading}</h3>}
      {buttonPositon === 'top' && buttonText()}
      <form>
        {type === 'textarea' && (
          <textarea
            className={classNames(style.card, clipboardSty)}
            ref={textAreaRef}
            value={value}
          />
        )}
        {type === 'codeSnippet' && (
          <div className={style.card}>
            <SyntaxHighlighter language={lang} style={atelierCaveLight}>
              {value}
            </SyntaxHighlighter>
            <div className={style.btn_wrapper}>
              <div className={style.copy_btn} onClick={copyToClipboard}>
                {copyState ? 'Copied' : 'copy'}
              </div>
            </div>
          </div>
        )}
      </form>
      {buttonPositon === 'bottom' && buttonText()}
    </div>
  );
};

export default Clipboard;
