import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import TmoH2 from '../../../controlWrappers/TmoH2';
import TmoH3 from '../../../controlWrappers/TmoH3';
import TmoButton from '../../../forms/TmoButton';
import MultipleSelect from './MultipleSelect';
import DropDown from '../../DropDown';

import './AddPackForBulk.css';

const dropDownSiteItems = [
  { label: 'Bellevue Golf Course', value: 'Bellevue Golf Course' },
  { label: 'Sahalee Country Club', value: 'Sahalee Country Club' },
  { label: 'Willows Run Golf Course', value: 'Willows Run Golf Course' }
];

const dropDownZoneItems = [
  { label: '1st Floor', value: '1st Floor' },
  { label: '2nd Floor', value: '2nd Floor' },
  { label: '3rd Floort', value: '3rd Floort' },
  { label: 'Restaurant', value: 'Restaurant' },
  { label: 'Spa', value: 'Spa' },
  { label: 'Golf Course', value: 'Golf Course' }
];

function AddPackForBulk({ history, match: { url } }) {
  const handleSiteFilterClick = e => {};
  const handleZoneFilterClick = e => {};
  const parseUserIdFromURL = () => {
    return url.substring(url.lastIndexOf('/pack/') + 6);
  };

  return (
    <div className="AddPackForBulk-container">
      <div className="AddDevice-back">
        <button className="AddDevice-back-btn" onClick={() => history.goBack()}>
          <FaAngleLeft className="AddDevice-angle-left" />
          Add devices
        </button>
      </div>
      <div className="AddPackForBulk-main">
        <div className="AddPackForBulk-header">
          <TmoH2>Bulk Activation</TmoH2>
          <TmoH3>{parseUserIdFromURL()} : Pack of 30 Devices</TmoH3>
        </div>
        <div className="AddPackForBulk-select-device">
          <div className="AddPackForBulk-devices-header">
            <TmoH3>Select devices to activate:</TmoH3>
          </div>
          <div className="AddPackForBulk-devices-list">
            <MultipleSelect />
          </div>
        </div>
        <div className="AddPackForBulk-select-device">
          <div className="AddPackForBulk-devices-header">
            <TmoH3>Assign devices to:</TmoH3>
          </div>
          <div className="AddPackForBulk-devices-header">
            <div className="AddPackForBulk-assign-site">
              <span>Site:</span>
              <div className="Devices-site-dropdown">
                <DropDown
                  dropDownItems={dropDownSiteItems}
                  handleFilterClick={handleSiteFilterClick}
                />
              </div>
            </div>
            <div className="AddPackForBulk-assign-zone">
              <span>Zone:</span>
              <div className="Devices-site-dropdown">
                <DropDown
                  dropDownItems={dropDownZoneItems}
                  handleFilterClick={handleZoneFilterClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="AddPackForBulk-add-div">
          <TmoButton
            type="secondary-blue"
            onClick={() => history.push(`${parseUserIdFromURL()}/success`)}
          >
            Activate Device
          </TmoButton>
        </div>
        <div className="AddPackForBulk-add-later">
          <Link>Activate later</Link>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AddPackForBulk);
