import React from 'react';
import classNames from 'classnames';
import useVisible from '../../hooks/useVisible';
import { ReactSVG } from 'react-svg';

import style from './Dropdown.module.css';
import union from '../../static/assets/icons/header/union.svg';

function Dropdown({
  triggerText,
  triggerIcon,
  className,
  theme,
  children,
  IconTitle,
  contentClass,
  userInfo
}) {
  const { visibilityRef, visible, setVisible } = useVisible(false);
  const attributes = {};
  if (IconTitle) {
    attributes['title'] = IconTitle;
  }

  return (
    <div
      className={classNames(
        style.container,
        visible && style.container_open,
        theme && style[theme],
        className
      )}
      ref={visibilityRef}
    >
      <span className={style.trigger} onClick={() => setVisible(!visible)}>
        {userInfo && (
          <div className={style.user_wrapper}>
            <div className={style.avatar}>{userInfo.iconInfo}</div>
            <div className={style.info}>
              <div>{userInfo.name}</div>
              <div className={style.email}>{userInfo.email}</div>
            </div>
            <div className={style.union}>
              <ReactSVG src={union} />
            </div>
          </div>
        )}
        {triggerText && (
          <span className={style.trigger_text}>{triggerText}</span>
        )}
        {triggerIcon && (
          <span className={style.trigger_icon} {...attributes}>
            {triggerIcon}
          </span>
        )}
      </span>
      {visible && (
        <div
          className={classNames(
            style.content,
            visible && style.content_open,
            contentClass
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
