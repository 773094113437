import React, { useCallback, useState } from 'react';
import { DynamicList } from 'tmo-bps-syncup-web-component-lib';

import Fetcher from 'components/Fetcher';
import qaService from 'services/qaService';
import Error from 'pages/Error';
import { getDateDifference, momentToFormattedDate } from 'utils/dateUtils';

import style from './QADashboard.module.css';

const BuildDetails = ({ build }) => {
  const { build_number, executed_on, pipeline_name } = build;
  const [buildDetails, setBuildDetails] = useState();
  const handleLoadData = useCallback(data => {
    setBuildDetails(data);
  }, []);

  const actionCallback = useCallback(() => {
    return qaService.getBuildFailuresDetails(build_number);
  }, [build_number]);

  const headerNameListItemMap = [
    {
      listItemKey: 'test_method_name',
      headerDisplayName: 'Test Method Name',
      columnClass: style.test_method_name,
      render: ({ ...item }) =>
        item.test_method_name && (
          <span title={item.test_method_name}> {item.test_method_name} </span>
        )
    },
    {
      listItemKey: 'status',
      headerDisplayName: 'Status'
    },
    {
      listItemKey: 'error_message',
      headerDisplayName: 'Error Message',
      columnClass: style.error_message
    },

    {
      listItemKey: 'failing_since',
      headerDisplayName: 'Failing Since',
      render: ({ ...item }) =>
        item.failing_since && getDateDifference(item.failing_since)
    }
  ];
  return (
    <>
      <div className={style.buildInfo}>
        <div className={style.pipeline_name}>{pipeline_name}</div>
        <div className={style.pipeline_date}>
          <div>
            {' '}
            <strong>Build Number </strong> : {build_number}{' '}
          </div>
          <div>
            {' '}
            <strong> Executed On </strong>:
            {momentToFormattedDate(executed_on, 'MM/DD/YYYY hh:mm:ss')}{' '}
          </div>
        </div>
      </div>
      <Fetcher
        action={actionCallback}
        onLoad={handleLoadData}
        render={() =>
          buildDetails ? (
            <DynamicList
              className={style.build_details}
              type="primary"
              headerNameListItemMap={headerNameListItemMap}
              listItems={buildDetails}
            />
          ) : (
            <Error heading="Build details not found" />
          )
        }
      />
    </>
  );
};

export default BuildDetails;
