import React, { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper';

import DragableItem from './DragableItem';

import style from './DragableContainer.module.css';

function DragableContainer({ details, reOrderingList, setReOrderingList }) {
  const [cards, setCards] = useState(details);
  const onCardMove = useCallback((dragIndex, hoverIndex) => {
    setCards(prevCards =>
      update(prevCards, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, prevCards[dragIndex]]]
      })
    );
  }, []);
  const renderCard = useCallback(
    (card, index) => {
      return (
        <DragableItem
          key={card.id}
          index={index}
          id={card.id}
          text={card.title}
          onCardMove={onCardMove}
        />
      );
    },
    [onCardMove]
  );
  useEffect(() => {
    const findDifference = function(a1, a2) {
      let result = [];
      for (let i = 0; i < a1.length; i++) {
        if (a1[i].id !== a2[i].id) {
          result.push({ id: a1[i].id, newIndex: i + 1 });
        }
      }
      return result;
    };
    if (cards) {
      let results = findDifference(cards, details);
      if (results && results.length) {
        let list = [...reOrderingList];
        results.forEach(item => {
          const index = reOrderingList.findIndex(e => e.id === item.id);
          if (index >= 0) {
            list[index] = { ...list[index], newIndex: item.newIndex };
          } else {
            list.push(item);
          }
        });
        setReOrderingList(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, details, setReOrderingList]);
  return (
    <div className={style.content_width}>
      {cards.map((card, i) => renderCard(card, i))}
    </div>
  );
}

export default DragableContainer;
