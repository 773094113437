import React from 'react';
import {
  TmoH3,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import { FaExclamationTriangle } from 'react-icons/all';

import style from './ErrorMessageModal.module.css';

function ErrorMessageModal({ message, onClose }) {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style.exclamation}>
          <FaExclamationTriangle />
        </span>
      </div>
      <div className={style.body}>
        <TmoH3>Uh oh! An error occurred</TmoH3>
        <p className={style.message}>{message}</p>
      </div>
      <div className={style.footer}>
        <TmoButton type="secondary" onClick={onClose}>
          Close
        </TmoButton>
      </div>
    </div>
  );
}

export default ErrorMessageModal;
