import React from 'react';

import ContentContainer from '../../components/uiHelpers/ContentContainer';
import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import DeviceExistance from '../../components/validateDeviceExistance/DeviceExistance';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';

import style from './BulkRecommission.module.css';

function BulkDeviceExistence({ match: { url } }) {
  return (
    <Authorized
      roles={[SUA_ROLES.ADMIN, SUA_ROLES.SUPPLY_CHAIN]}
      willShowErrorModal
    >
      <ContentContainer className={style.container}>
        <div className={style.header}>
          <PageHeader title={'VALIDATE DEVICES'} className={style.heading} />
        </div>
        <div className={style.content}>
          <DeviceExistance />
        </div>
      </ContentContainer>
    </Authorized>
  );
}

export default BulkDeviceExistence;
