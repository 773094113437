import React from 'react';
import { IFrame as Iframe, TmoCard } from 'tmo-bps-syncup-web-component-lib';

import config from '../../configs/config';

const { iframeInstabugAccessFormURL } = config.instabugSettings;

function InstabugAccessForm() {
  return (
    <TmoCard>
      <Iframe src={iframeInstabugAccessFormURL} width="100%" height="836px" />
    </TmoCard>
  );
}

export default InstabugAccessForm;
