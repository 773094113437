import React from 'react';

import {
  TmoButtons,
  ConfirmationModalV2,
  withModal,
  TmoH3,
  StringUtilities
} from 'tmo-bps-syncup-web-component-lib';

import RoleFeature from '../../featureWrappers/RoleFeature';
import { getTimestampList } from '../../../utils/dateUtils';
import { DATE_IN_TIME_ZONE } from '../../../utils/app_constants';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';

import style from './FirmwareDetails.module.css';

function FirmwareDetails({ details, onRemovePackage, openModal }) {
  const linkUrl = v => {
    return (
      <a className={style.download} href={v}>
        Download FW Package
      </a>
    );
  };

  const handleRemovePackageClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Firmaware Package`, {
        id: details.id
      });
      await onRemovePackage(details.id);
    }
  };

  const removePackageModalMessage = (
    <div className={style.remove_message}>
      <TmoH3 flush>
        Are you sure you want to remove this Firmware Package?
      </TmoH3>
      <ul className={style.remove_message_list}>
        <li className={style.remove_message_list_item}>
          <label className={style.remove_message_label}>ID:</label> {details.id}
        </li>
        <li className={style.remove_message_list_item}>
          <label className={style.remove_message_label}>Package Name:</label>{' '}
          {details.packageName}
        </li>
        <li className={style.remove_message_list_item}>
          <label className={style.remove_message_label}>Version:</label>{' '}
          {details.version}
        </li>
      </ul>
    </div>
  );

  return (
    <>
      {Object.entries(details)?.map(
        ([key, value], index) =>
          value &&
          (typeof value === 'object' ? (
            Object.entries(value).map(([k, v]) => (
              <div className={style.row} key={index}>
                <label className={style.label}>
                  {StringUtilities.prettifyCamelCase(k)}:{' '}
                  <span className={style.label_span}>{v}</span>
                </label>
              </div>
            ))
          ) : (
            <div className={style.row} key={index}>
              <label className={style.label}>
                {StringUtilities.prettifyCamelCase(key)}:{' '}
                <span className={style.label_span}>
                  {key === 'packageUrl'
                    ? linkUrl(value)
                    : (key === 'createdDateTime' ||
                        key === 'expirationDateTime' ||
                        key === 'modifiedDateTime') &&
                      value
                    ? getTimestampList(value, null, DATE_IN_TIME_ZONE.SYSTEM)
                    : value}
                </span>
              </label>
            </div>
          ))
      )}
      {onRemovePackage && (
        <div className={style.remove}>
          <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
            <TmoButtons
              onClick={handleRemovePackageClicked}
              className={style.remove_button}
              type="danger"
            >
              Delete Package
            </TmoButtons>
          </RoleFeature>
          <ConfirmationModalV2
            message={removePackageModalMessage}
            title="Confirm Firmware Removal"
            acceptButtonText="Delete Package"
            type="danger"
            confirmInput
          />
        </div>
      )}
    </>
  );
}

export default withModal(FirmwareDetails);
