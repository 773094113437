import React from 'react';

import SyntaxHighlighter from 'react-syntax-highlighter';

import style from './RawDataObject.module.css';

function RawDataObject({ rawObject }) {
  return (
    <div className={style.container}>
      <SyntaxHighlighter className={style.pre} language="json">
        {JSON.stringify(rawObject, null, 2)}
      </SyntaxHighlighter>
    </div>
  );
}

export default RawDataObject;
