import React, { useState } from 'react';

import DynamicList from '../../uiHelpers/DynamicList';
import DeviceBatteryLevel from './DeviceBatteryLevel';
import TmoH2 from '../../controlWrappers/TmoH2';
import { sortList } from '../../../utils/helper_functions';
import TrackerImg from '../../../static/assets/commercial/images/tracker.png';
import { SORT_DIRECTION } from '../../../utils/app_constants';
import { commercialLocationTrackers } from '../../../utils/pocDummyData';
import { FaEllipsisV } from 'react-icons/all';
import TmoCard from '../../uiHelpers/TmoCard';

import style from './Trackers.module.css';

const LIST_HEADER_OBJECT_MAPPING = [
  {
    listItemKey: 'name',
    headerDisplayName: 'DEVICE NAME',
    columnClass: 'Heading'
  },
  {
    listItemKey: 'power',
    headerDisplayName: 'POWER',
    columnClass: 'Heading',
    render: ({ batteryLevel }) => <DeviceBatteryLevel battery={batteryLevel} />
  },
  {
    listItemKey: 'status',
    headerDisplayName: 'STATUS',
    columnClass: 'Heading'
  },
  {
    listItemKey: 'lastUpdate',
    headerDisplayName: 'LAST UPDATE',
    columnClass: 'Heading'
  }
];

function Trackers() {
  const [trackers, setTrackers] = useState();
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);

  const handleOnListSort = ({ listItemKey }) => {
    const sortedTrackers = trackers;
    sortList(sortedTrackers, { listItemKey }, sortDirection);
    sortDirection === SORT_DIRECTION.ASC
      ? setSortDirection(SORT_DIRECTION.DESC)
      : setSortDirection(SORT_DIRECTION.ASC);
    setTrackers(sortedTrackers);
  };

  return (
    <TmoCard className={style.container}>
      <div className={style.header}>
        <div className={style.image}>
          <img alt="tracker" src={TrackerImg} />
        </div>
        <TmoH2 className={style.title}>Trackers</TmoH2>
        <div className="Trackers-count">
          {commercialLocationTrackers.length} devices
        </div>
        <FaEllipsisV className={style.title_icon} />
      </div>
      <DynamicList
        className="border-lite"
        headerNameListItemMap={LIST_HEADER_OBJECT_MAPPING}
        listItems={commercialLocationTrackers}
        onSort={handleOnListSort}
        sortDirection={sortDirection}
      />
    </TmoCard>
  );
}

export default Trackers;
