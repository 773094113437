import React, { useContext, useState, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  ModalV2,
  TmoButtons as TmoButton,
  LabelWithInput,
  TmoInput
} from 'tmo-bps-syncup-web-component-lib';

import simulatorService from '../../services/simulatorService';
import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { getEpochTime } from '../../utils/dateUtils';
import DateRangePicker from '../uiHelpers/DateRangePicker';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './UpdateStepCount.module.css';
import step_count from '../../static/assets/icons/step_count.svg';

function UpdateStepCount({
  simulator: { deviceId },
  openModal,
  closeModal,
  setVisible,
  minValueForPicker
}) {
  const [stepsCount, setStepsCount] = useState();
  const [startTime, setStartTime] = useState();
  const [stopTime, setStopTime] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const dateRef = useRef();
  const handleCustomDatePicked = ({ startDateTime, endDateTime }) => {
    setStartTime(startDateTime);
    setStopTime(endDateTime);
    console.log(startDateTime);
    console.log(endDateTime);
  };

  const createRequestBody = () => {
    return {
      activity: {
        ts: getEpochTime(),
        res: [
          {
            count: stepsCount,
            sTs: getEpochTime(startTime),
            eTs: getEpochTime(stopTime)
          }
        ]
      }
    };
  };
  const handleUpdateStepCount = async e => {
    e.preventDefault();
    try {
      let proceed = false;
      if (dateRef?.current?.TriggerApply) {
        proceed = dateRef.current.TriggerApply();
      }
      if (proceed) {
        setVisible(true);
        closeModal();
        trackCustomEvents(`Watch Simular Step Count`, {
          deviceId: deviceId,
          syncUpAppName: syncUpProductContext.syncUpAppName
        });
        await simulatorService.updateWatchSimulatorStepCount({
          deviceId: deviceId,
          body: createRequestBody(),
          syncUpAppName: syncUpProductContext.syncUpAppName
        });

        addSuccessMessage({
          message: <>Steps added Successfully.</>
        });
        setVisible(false);
      }
    } catch (error) {
      setVisible(false);
      addErrorMessage({ error });
    }
  };

  return (
    <>
      <TmoButton
        icon={<ReactSVG src={step_count} />}
        onClick={openModal}
        type="magenta_link"
        className={style.custom_step_btn}
      >
        View & Update Step Count
      </TmoButton>
      <ModalV2
        title={`Update Step Count For ${deviceId}`}
        portal={false}
        modalType={'slide'}
        className={style.custom_slide}
      >
        <div className={style.container}>
          <div className={style.input_container}>
            <LabelWithInput
              labelText="Steps"
              formComponent={TmoInput}
              name="name"
              value={stepsCount}
              onChange={setStepsCount}
              inputType="primary"
            />
            <DateRangePicker
              ref={dateRef}
              onApply={handleCustomDatePicked}
              minValueForPicker={minValueForPicker}
              className={style.custom_datepicker}
              isPrimary={true}
              noMargin={true}
            />
          </div>
          <div className={style.button_wrapper}>
            <TmoButton onClick={closeModal} type="magenta_secondary">
              Cancel
            </TmoButton>
            <TmoButton
              type="magenta_primary"
              onClick={handleUpdateStepCount}
              className={style.action}
            >
              Update Step Count
            </TmoButton>
          </div>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(UpdateStepCount);
