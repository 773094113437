import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoButtons,
  TmoCard,
  RawData,
  withModal,
  ConfirmationModalV2
} from 'tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import userService from '../../services/userService';
import { prettifyCamelCase } from 'utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import StaticMapImage from 'components/map/StaticImage/StaticMapImage';
import deleteIcon from '../../static/assets/icons/action/delete.svg';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import { mapStyle } from 'utils/app_constants';

import style from './Location.module.css';

function Location({ location, openModal, forceRefresh }) {
  const {
    id,
    userId,
    deviceId,
    name,
    latitude,
    longitude,
    batteryLevel,
    thingType
  } = location;

  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);

  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const handleRemovePhoneLocation = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete PhoneLocation`, {
        thingId: id,
        product: product
      });
      try {
        await userService.deletePhoneLocation({
          thingId: id,
          userId: userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Successfully deleted phone location: ${id}`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <TmoCard shadow={false} key={id}>
      {latitude && longitude && (
        <div className={style.map_container}>
          <StaticMapImage
            center={`${latitude},${longitude}`}
            places={{ latitude: latitude, longitude: longitude }}
            mapStyles={mapStyle}
            className={style.map_img}
            marker
          />
        </div>
      )}
      <div className={style.card_header}>
        <div className={style.card_title}>
          <label
            className={style.display_name}
            title={prettifyCamelCase(name.toLowerCase())}
          >
            {prettifyCamelCase(name.toLowerCase())}
          </label>
        </div>
        <div className={style.action_controls}>
          <RawData
            rawObject={location}
            tooltip="Full User Object"
            title={`Full User Object for ${name}`}
            className={style.object}
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary_icon"
            type=" "
          />
          <>
            <TmoButtons
              onClick={handleRemovePhoneLocation}
              type="magenta_secondary"
              icon={<ReactSVG src={deleteIcon} />}
              className={style.delete_btn}
              tooltip="Delete"
            />
            <ConfirmationModalV2
              modalType="delete"
              message={
                <>
                  Are you sure you want to remove phone location with id:{' '}
                  <strong>{id}</strong>?
                </>
              }
              title="Confirm hone Location Removal"
              acceptButtonText="Yes,Delete"
              type="danger"
              usePortal={false}
              confirmInput
              buttonIcon={<ReactSVG src={deleteIcon} />}
            />
          </>
        </div>
      </div>
      <div className={style.content} key={id}>
        <ItemDetailList
          rowSet={[
            { Id: id },
            { 'User Id': userId },
            { DeviceId: deviceId },
            { Name: name },
            { Latitude: latitude, Longitude: longitude },
            { 'Battery Level': batteryLevel, 'Thing Type': thingType }
          ]}
        />
      </div>
    </TmoCard>
  );
}

export default withModal(Location);
