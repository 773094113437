import config from '../configs/config';
import { APP_MOBILE } from '../utils/app_constants';
import syncUpPlatformRequest from './syncupPlatformRequest';

const simulatorRepository = {
  getSimulators: async ({ syncUpAppName, responseStatus = false }) => {
    const { resources } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      //  url: 'http://localhost:8080/simulators'
      urlSuffix: resources.simulator
    });
  },
  updateWatchSimulatorStepCount: async ({
    deviceId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      urlSuffix: `${resources.simulatorMessage}`.replace(
        ':hardwareId',
        deviceId
      ),
      body: body
    });
  },
  getIotSimulators: async ({
    syncUpAppName,
    pageNumber,
    pageSize,
    responseStatus = false
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    const query = `?productId=${syncUpAppName}`;

    switch (syncUpAppName) {
      case APP_MOBILE.DRIVE:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: `${resources.driveSimulators}?pageNumber=${pageNumber -
            1}&pageSize=${pageSize}`,
          basePath: basePath.adminDrive,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });
      case APP_MOBILE.PETS:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: resources.simulator
        });
      default:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: `${resources.iotSimulator}`.replace(':query', query),
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });
    }
  },
  addSimulator: async ({
    body,
    simulator,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      // url: 'http://localhost:8080/simulators',
      urlSuffix: resources.simulator,
      httpMethod: httpMethods.POST,
      body: body ? body : simulator,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  addIotSimulator: async ({
    body,
    simulator,
    deviceId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      basePath,
      headers
    } = config.apiSettings.syncUpPlatform;

    switch (syncUpAppName) {
      case APP_MOBILE.DRIVE:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          // url: 'http://localhost:8080/simulators',
          urlSuffix: resources.device.replace(':deviceId', deviceId),
          httpMethod: httpMethods.PUT,
          basePath: basePath.adminDrive,
          body: {
            model: 'SyncUP Drive SD7000T',
            firmwareVersion: '1.0',
            isSimulated: true,
            simulatorType: simulator.simulatorType
          },
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });

      default:
        return await syncUpPlatformRequest({
          // url: 'http://localhost:8080/simulators',
          urlSuffix: resources.iotSimulator.replace(':query', ''),
          httpMethod: httpMethods.POST,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          },
          body: body ? body : simulator
        });
    }
  },
  plugInDriveSimulator: async ({
    userId,
    deviceId,
    simulator,
    body = false,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      resources,
      httpMethods,
      basePath,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      urlSuffix: `${resources.drivePlugInSimulator}`.replace(
        ':deviceId',
        deviceId
      ),
      httpMethod: httpMethods.POST,
      responseStatus: responseStatus,
      body: body ? body : simulator,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  saveSimulator: async ({
    body,
    simulator,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      // url: 'http://localhost:8080/simulators',
      urlSuffix: resources.simulator,
      httpMethod: httpMethods.PUT,
      body: body ? body : simulator,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  deleteSimulator: async ({ id, responseStatus = false, syncUpAppName }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      // url: 'http://localhost:8080/simulators/{id}',
      urlSuffix: resources.simulatorId.replace(':id', id),
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  deleteIotSimulator: async ({ id, responseStatus = false, syncUpAppName }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      // url: 'http://localhost:8080/simulators/{id}',
      urlSuffix: resources.iotSimulator.replace(':query', id),
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createSimulatorDeviceEvent: async ({
    hardwareId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      urlSuffix: `${resources.simulatorDeviceEvents}`.replace(
        ':hardwareId',
        hardwareId
      ),
      body: body
    });
  }
};

export default simulatorRepository;
