import React, { useState, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoH2,
  TmoButtons as TmoButton,
  FormModal,
  TmoCard,
  withModal,
  useVisible,
  TmoSvg
} from 'tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import { getEditIoTFromValue } from '../../utils/simulatorConsts';
import simulatorService from '../../services/simulatorService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import UpdateStepCount from './UpdateStepCount';
import Feature from '../featureWrappers/Feature';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import { APP_MOBILE } from '../../utils/app_constants';

import style from './SimulatorCard.module.css';
import edit from '../../static/assets/icons/action/edit.svg';
import deleteIcon from '../../static/assets/icons/action/delete.svg';

function IotHubNewSimulator({
  simulator,
  onRemove,
  openModal,
  forceRefresh,
  product
}) {
  const { deviceId, status, version, tags } = simulator;
  const [editForm, setEditForm] = useState();
  const { visible, setVisible } = useVisible(false);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const handleRemove = () => {
    try {
      onRemove(simulator);
      addSuccessMessage({ message: 'Device deleted successfully' });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const openEditSimulatorModal = async () => {
    setEditForm(getEditIoTFromValue(simulator));
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      let formData = {
        ...simulator,
        deviceId: formValues.deviceId,
        version: formValues.version,
        status: formValues.status,
        tags: { ...simulator.tags, simulatorType: formValues.simulatorType }
      };
      trackCustomEvents(`Update Simulator`, {
        id: simulator.id
      });
      try {
        let result = await simulatorService.saveIotSimulator({
          simulator: formData,
          syncUpAppName: product
        });
        if (result) {
          addSuccessMessage({
            message: 'Successfully updated simulated device'
          });
          forceRefresh();
        } else {
          addErrorMessage({ message: 'Failed to update simulated device' });
        }
      } catch (e) {
        addErrorMessage({ message: e.toString() });
      }
    }
  };
  return (
    <TmoCard className={style.container}>
      <div className={style.iot_header}>
        <div className={style.title}>
          <TmoH2 className={style.heading} title={deviceId}>
            {deviceId}
          </TmoH2>
        </div>
        <div className={style.action_controls}>
          <div className={style.action}>
            <TmoButton
              type="magenta_primary"
              icon={<ReactSVG src={edit} />}
              className={style.save}
              buttonType="submit"
              tooltip="Edit Simulator"
              onClick={openEditSimulatorModal}
            />
            <FormModal
              title={`Edit Simulator`}
              fieldSpecs={editForm}
              modalType={'slide'}
            />
          </div>
          <div className={style.action}>
            <TmoButton
              type="magenta_secondary"
              title="Delete"
              icon={<ReactSVG src={deleteIcon} />}
              onClick={handleRemove}
              onSubmit={handleRemove}
              className={style.delete}
            />
          </div>
        </div>
      </div>
      <div className={style.content}>
        <ItemDetailList
          rowSet={[
            {
              'Product Id': tags.productId,
              'Simulator Type': tags.simulatorType
            },
            { status, version }
          ]}
        />
        <div className={style.footer}></div>
        <div className={style.step_count_wrapper}>
          <Feature Products={[APP_MOBILE.WATCH]}>
            <UpdateStepCount simulator={simulator} setVisible={setVisible} />
            {visible && (
              <TmoSvg className={style.spinner} svgId="loading-spinner" />
            )}
          </Feature>
        </div>
      </div>
    </TmoCard>
  );
}

export default withModal(IotHubNewSimulator);
