import React, { useState, useMemo } from 'react';
import {
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  FlexibleWidthXYPlot,
  MarkSeries
} from 'react-vis';
import HintContent from './HintContent';

const SENSOR_GRAPH = {
  X_TYPE: 'time',
  Y_TYPE: 'ordinal',
  HEIGHT: 200,
  X_TITLE: 'Time',
  Y_TITLE: 'Updates',
  LINE_STROKE: '#ADDDE1',
  TICK_STROKE: '#ADDDE1',
  TEXT_STROKE: 'none',
  TEXT_FILL: '#6b6b76',
  TEXT_FONT_WEIGHT: 600,
  TEXT_FONT_SIZE: 12,
  MARK_COLOR: '#e20074',
  MARK_OPACITY: 0.8,
  MARK_SIZE: 5,
  MARK_STROKE_WIDTH: 0
};

const axisStyle = {
  line: { stroke: SENSOR_GRAPH.LINE_STROKE },
  ticks: { stroke: SENSOR_GRAPH.TICK_STROKE },
  text: {
    stroke: SENSOR_GRAPH.TEXT_STROKE,
    fill: SENSOR_GRAPH.TEXT_FILL,
    fontWeight: SENSOR_GRAPH.TEXT_FONT_WEIGHT,
    fontSize: SENSOR_GRAPH.TEXT_FONT_SIZE
  }
};

function TimeGraph({ data, minTime, maxTime }) {
  const [markerNumber, setMarkerNumber] = useState('');
  const [markerNumberClicked, setMarkerNumberClicked] = useState('');

  const dataArr = useMemo(
    () =>
      data.map(d => {
        let timestamp = new Date(d.timestamp).getTime();
        return {
          x: timestamp,
          y: '',
          ...d
        };
      }),
    [data]
  );

  const calculatedTimeRange = useMemo(() => {
    const minMillis = new Date(minTime).getTime();
    const maxMillis = new Date(maxTime).getTime();

    return [minMillis, maxMillis];
  }, [minTime, maxTime]);

  return (
    <>
      <HintContent value={markerNumberClicked} />
      <FlexibleWidthXYPlot
        xType={SENSOR_GRAPH.X_TYPE}
        yType={SENSOR_GRAPH.Y_TYPE}
        height={SENSOR_GRAPH.HEIGHT}
        xDomain={calculatedTimeRange}
      >
        <HorizontalGridLines />
        <VerticalGridLines />
        <XAxis title={SENSOR_GRAPH.X_TITLE} style={axisStyle} />
        <YAxis
          title={SENSOR_GRAPH.Y_TITLE}
          style={axisStyle}
          tickLabelAngle={1}
        />

        <MarkSeries
          data={dataArr}
          animation
          title={markerNumber}
          strokeWidth={SENSOR_GRAPH.MARK_STROKE_WIDTH}
          opacity={SENSOR_GRAPH.MARK_OPACITY}
          size={SENSOR_GRAPH.MARK_SIZE}
          color={SENSOR_GRAPH.MARK_COLOR}
          onNearestX={(datapoint, event) => {
            setMarkerNumber({
              index: event.index + 1,
              date: datapoint.x,
              source: datapoint.dataSource
            });
          }}
          onValueMouseOver={() => {
            setMarkerNumberClicked(markerNumber);
          }}
        />
      </FlexibleWidthXYPlot>
    </>
  );
}

export default TimeGraph;
