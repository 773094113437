export const APPCENTER_SUGGESTED_QUERIES_TRACKER = [
  {
    name: 'Failures',
    query: `exceptions
    | where timestamp > now(-1d)
    | where cloud_RoleInstance startswith "svc-sms"
    | where details[0].message has "Negative response 0000000b (Invalid Destination Address) found"
    | order by timestamp desc
    | extend msisdnString=tostring(customDimensions['Logger Message'])
    | project timestamp, DeviceId=customDimensions['device-id'], HardwareId=customDimensions['hardware-id'], MSISDN=extract("\\\\[(\\\\d{11})\\\\]", 1, msisdnString)
    | summarize
        DeviceId=any(DeviceId),
        HardwareId=any(HardwareId),
        Count=count(),
        EarliestFailure=min(timestamp),
        LatestFailure=max(timestamp)
        by MSISDN`
  }
];
