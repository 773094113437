import React, { useContext } from 'react';
import TmoForm from '../forms/TmoForm';
import ModalContext from '../../context/ModalContext';
import ModalV2 from './ModalV2';

function FormModal({ title, fieldSpecs, submitText, portal = true }) {
  const { closeModal } = useContext(ModalContext);
  const handleSubmit = formValues => {
    closeModal({
      isConfirmed: true,
      formValues
    });
  };

  return (
    <ModalV2 title={title} portal={portal}>
      <TmoForm
        fieldSpecs={fieldSpecs}
        onSubmit={handleSubmit}
        submitText={submitText}
      />
    </ModalV2>
  );
}

export default FormModal;
