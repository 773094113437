import React from 'react';
import classNames from 'classnames';

import style from './PaginationPage.module.css';

function PaginationPage({
  pageNumber,
  onPageChanged,
  isSelected,
  text = pageNumber
}) {
  return (
    <li
      key={pageNumber}
      className={classNames(style.container, isSelected && style.selected)}
      onClick={() => onPageChanged(pageNumber)}
    >
      {text}
    </li>
  );
}

export default PaginationPage;
