import React from 'react';
import classNames from 'classnames';

import style from './TmoH2.module.css';

function TmoH2({ className, flush, children }) {
  return (
    <h2
      className={classNames(style.container, flush && style.flush, className)}
    >
      {children}
    </h2>
  );
}

export default TmoH2;
