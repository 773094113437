import React, { useState, useCallback, useMemo, useContext } from 'react';

import User from '../../components/user/User';
import SearchedUserContext from '../../context/SearchedUserContext';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import userService from '../../services/userService';
import Fetcher from '../../components/Fetcher';

import style from './UserPage.module.css';

function UserPage({ onTabClose }) {
  const [user, setUser] = useState();
  const {
    selectedTab: { product, userId }
  } = useContext(ActiveUserTabContext);

  const actionCallback = useCallback(async () => {
    return userService.getUserById({
      id: userId,
      syncUpAppName: product
    });
  }, [userId, product]);

  const handleLoad = useCallback(user => {
    const { userProfile, applicationProfile } = user;
    let searchedUser = {
      ...userProfile,
      ...applicationProfile
    };
    searchedUser = searchedUser.userId != null ? searchedUser : user;
    setUser(searchedUser);
  }, []);
  const contextValue = useMemo(() => ({ user }), [user]);

  return (
    <div className={style.container}>
      <Authorized
        roles={[
          SUA_ROLES.ADMIN,
          SUA_ROLES.MOBILE_APP_DEVS,
          SUA_ROLES.SYNCUP_DPI, // TO-DO Rrmove SYNCUP_DPI AFter Testing
          SUA_ROLES.CARE,
          SUA_ROLES.FIRMWARE_SUPPORT,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV
        ]}
        willShowErrorModal
      >
        <Fetcher
          action={actionCallback}
          onLoad={handleLoad}
          render={() =>
            user ? (
              <SearchedUserContext.Provider value={contextValue}>
                <User user={user} onTabClose={onTabClose} />
              </SearchedUserContext.Provider>
            ) : (
              'User not found'
            )
          }
        />
      </Authorized>
    </div>
  );
}

export default UserPage;
