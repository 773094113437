import React, { useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  withModal,
  ConfirmationModalV2,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import faqContentService from '../../services/faqContentService';
import { pluralize } from '../../utils/stringUtils';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import { hasValue } from 'utils/helper_functions';

import style from './DeleteBulkFAQ.module.css';
import delete_icon from '../../static/assets/icons/action/delete.svg';

function DeleteDemoFAQ({
  openModal,
  data,
  onDataChange,
  ClassName,
  buttonText
}) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const [loading, setLoading] = useState(false);
  const chunkSize = 150;

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunks.push(chunk);
    }
    return chunks;
  };

  const handleRemoveFaq = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      let selectedData = [];
      data.forEach(category => {
        let tempIds = category.faqList
          .filter(e => e.title.toLowerCase().includes('demo'))
          .map(e => e.id);
        if (tempIds && tempIds.length > 0) {
          selectedData = [...selectedData, ...tempIds];
        }
      });
      if (hasValue(selectedData)) {
        const chunkedArray = chunkArray(selectedData, chunkSize);
        const promises = chunkedArray.map((param, i) => {
          let ids = param.join(',');
          return faqContentService.deleteBulkFAQ({
            faqIds: ids,
            product: syncUpProductContext.syncUpAppName
          });
        });
        try {
          setLoading(true);
          await Promise.all(promises);
          onDataChange();
          trackCustomEvents(`Demo Faq Delete`, {
            faqIds: selectedData,
            product: syncUpProductContext.syncUpAppName
          });
          addSuccessMessage({
            message: `Successfully deleted ${selectedData.length} demo FAQs`
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          addErrorMessage({ error });
        }
      } else {
        setLoading(false);
        addErrorMessage({ message: 'Demo FAQs not found' });
      }
    }
  };
  const deleteMessage = () => {
    let selectedData = [];
    data &&
      data.forEach(category => {
        let tempIds = category.faqList
          .filter(e => e.title.toLowerCase().includes('demo'))
          .map(e => e.title);
        if (tempIds && tempIds.length > 0) {
          selectedData = [...selectedData, ...tempIds];
        }
      });
    return (
      <>
        Are you sure you want to remove {selectedData.length}{' '}
        {pluralize({
          string: 'item',
          count: selectedData.length
        })}{' '}
        with demo in the title: <br />
      </>
    );
  };
  return (
    <>
      <TmoButton
        icon={<ReactSVG src={delete_icon} />}
        onClick={handleRemoveFaq}
        disabled={loading}
        className={classNames(ClassName, style.delete_demo_btn)}
        type="magenta_secondary"
        textVisible
      >
        {buttonText ? buttonText : `Delete Demo FAQs`}
      </TmoButton>
      <ConfirmationModalV2
        message={deleteMessage()}
        title="Confirm FAQ Removal"
        acceptButtonText="Delete"
        type="danger"
        confirmInput
        usePortal={false}
        modalType="delete"
        confirmText="delete"
      />
    </>
  );
}

export default withModal(DeleteDemoFAQ);
