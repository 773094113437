import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react';
import { useParams } from 'react-router-dom';
import { DynamicSort, FilterModal } from 'tmo-bps-syncup-web-component-lib';

import thingService from '../../services/thingService';
import Fetcher from '../../components/Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import ThingEvent from './ThingEvent';
import { getUserThingBackUrl, hasValue } from 'utils/helper_functions';
import CreateDeviceEvent from './CreateDeviceEvent';
import { getRandomString } from '../../utils/stringUtils';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

import style from './ThingEventsGrid.module.css';
import { BsFilterLeft, IoIosArrowDown } from 'react-icons/all';
import Error from 'components/Error/Error';

function ThingEventsGrid({ hideTab }) {
  const [filteredList, setFilteredList] = useState();
  const [refresh, setRefresh] = useState(getRandomString());
  const [thingEvents, setThingEvents] = useState();
  const [sortOptions] = useState([
    { key: 'eventType', value: 'Event Type' },
    { key: 'eventAt', value: 'Event At' }
  ]);
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  let { thingId, deviceId } = useParams();
  const sortRef = useRef();

  const getThingEvents = useCallback(() => {
    return thingService.getThingEvents({
      userId: userId,
      thingId: thingId,
      syncUpAppName: product
    });
  }, [thingId, product, userId]);

  const handleOnSort = list => {
    setFilteredList(list);
  };
  const [filterOptions] = useState([{ key: 'eventType', value: 'Event Type' }]);
  const handleOnFilter = data => {
    if (data) {
      setFilteredList(data.list);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    }
  };
  const handleEvent = () => {
    setRefresh(getRandomString());
  };
  useEffect(() => {
    hideTab && hideTab(true);
  }, [hideTab]);

  useEffect(() => {
    thingEvents && thingEvents.length
      ? setFilteredList(thingEvents)
      : setFilteredList([]);
  }, [thingEvents]);

  return (
    <>
      <PageHeader
        backUrl={getUserThingBackUrl(userId, 'things')}
        title={'Events'}
        tabHeader={true}
        hideTab={hideTab}
      >
        <DynamicSort
          ref={sortRef}
          listItems={filteredList}
          optionsList={sortOptions}
          onSort={e => {
            handleOnSort(e);
          }}
          renderIcon={<IoIosArrowDown />}
        />
        <FilterModal
          options={filterOptions}
          originalList={thingEvents}
          listItems={filteredList}
          onFilter={handleOnFilter}
          renderIcon={<BsFilterLeft />}
        />
        <CreateDeviceEvent
          deviceId={deviceId}
          isEventLink
          onEventCreated={handleEvent}
        />
      </PageHeader>
      <Fetcher
        action={getThingEvents}
        onLoad={setThingEvents}
        data={thingEvents}
        key={refresh}
        render={() =>
          hasValue(thingEvents) ? (
            <div className={style.results}>
              {filteredList &&
                filteredList.map(s => (
                  <ThingEvent key={s.mapKey || s.id} event={s} />
                ))}
            </div>
          ) : (
            <Error heading="No Events are available" />
          )
        }
      />
    </>
  );
}

export default ThingEventsGrid;
