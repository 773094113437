import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  FormModal,
  TmoInput,
  TmoButtons,
  TmoRadioGroup,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';

import mediaService from '../../services/mediaService';
import iotFirmwareService from '../../services/iotFirmwareService';
import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { IoIosArrowDown } from 'react-icons/all';
import {
  MEDIA_TYPES,
  MEDIA_ACTIONS,
  MEDIA_PRIORITY
} from '../../utils/mediaConsts';
import { IOT_ASSET_TYPES } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './EditAsset.module.css';
import editIcon from '../../static/assets/icons/action/edit.svg';

function EditAsset({ asset, mediaType, openModal, onEdit, isAssetMedia }) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const {
    assetId,
    assetName,
    deviceGroup,
    deviceId,
    type,
    id,
    groups,
    isActive,
    appliesTo,
    targetVersion,
    priority
  } = asset;

  const mediaFormFieldSpecs = () => [
    {
      labelText: 'Media Config Name',
      fieldName: 'name',
      formComponent: TmoInput,
      inputType: isAssetMedia ? 'primary' : '',
      defaultValue: asset.configName,
      placeholder: 'Configuration name'
    },
    {
      labelText: 'Group IDs',
      fieldName: 'groups',
      formComponent: TmoInput,
      inputType: isAssetMedia ? 'primary' : '',
      defaultValue: deviceGroup,
      placeholder: 'Assign config to device groups'
    },
    {
      labelText: 'Device IDs',
      fieldName: 'devices',
      formComponent: TmoInput,
      inputType: isAssetMedia ? 'primary' : '',
      defaultValue: deviceId,
      placeholder: 'Assign config to devices by id'
    },
    {
      labelText: 'Media Type',
      fieldName: 'type',
      formComponent: TmoDropDownSelect,
      optionsList: Object.keys(MEDIA_TYPES).map(k => ({
        key: k,
        value: k
      })),
      defaultSelectedValue: Object.keys(MEDIA_TYPES).map(k => ({
        key: k,
        value: k
      }))[0],
      defaultValue: type,
      renderIcon: <IoIosArrowDown />,
      formMargin: true
    },
    {
      labelText: 'Media Action',
      fieldName: 'media_action',
      formComponent: TmoDropDownSelect,
      optionsList: Object.keys(MEDIA_ACTIONS).map(k => ({
        key: k,
        value: k
      })),
      defaultSelectedValue: Object.keys(MEDIA_ACTIONS).map(k => ({
        key: k,
        value: k
      }))[0],
      defaultValue: Object.keys(MEDIA_ACTIONS)[0],
      renderIcon: <IoIosArrowDown />,
      formMargin: true,
      required: true
    }
  ];

  const firmwareFormFieldSpecs = () => [
    {
      labelText: 'ID',
      fieldName: 'id',
      formComponent: TmoInput,
      required: false,
      defaultValue: id,
      placeholder: 'Firmware ID',
      disabled: true,
      inputType: 'primary'
    },
    {
      labelText: `Publish Config`,
      fieldName: 'isActive',
      formComponent: TmoRadioGroup,
      className: style.publish_config,
      data: {
        radioGrp: 'iconPosition-radio',
        defaultValue: isActive ? 'Yes' : 'No',
        options: ['Yes', 'No']
      },
      disabled: Object.keys(groups).length <= 0
    },
    {
      labelText: 'Target Version',
      fieldName: 'targetVersion',
      formComponent: TmoInput,
      inputType: 'primary',
      required: false,
      defaultValue: targetVersion,
      placeholder: 'Target Firmware Version'
    },
    {
      labelText: 'Applies To',
      fieldName: 'appliesTo',
      formComponent: TmoInput,
      inputType: 'primary',
      required: false,
      defaultValue: appliesTo,
      placeholder: 'applies to v1.0'
    },
    {
      labelText: 'Priority',
      fieldName: 'priority',
      inputType: 'primary',
      formComponent: TmoDropDownSelect,
      required: false,
      placeholder: 'priority',
      optionsList: Object.keys(MEDIA_PRIORITY).map(k => ({
        key: k,
        value: k
      })),
      defaultSelectedValue: {
        key: priority.toUpperCase(),
        value: priority.toUpperCase()
      },
      defaultValue: priority.toUpperCase()
    }
  ];

  const openEditModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    const mediaFileType = () => {
      switch (formValues.type) {
        case MEDIA_TYPES.THEME:
          return 'JPG';
        case MEDIA_TYPES.BACKGROUND:
          return 'PNG';
        case MEDIA_TYPES.RINGTONE:
          return 'MP3';
        case MEDIA_TYPES.SOUND:
          return 'MP3';
        case MEDIA_TYPES.VIDEO:
          return 'MP4';
        default:
          return 'ZIP';
      }
    };
    if (isConfirmed) {
      trackCustomEvents(
        `Edit ${mediaType} Config`,
        mediaType === IOT_ASSET_TYPES.MEDIA
          ? { assetId: assetId, assetName: assetName }
          : { firmwareId: id }
      );
      const mediaBody = {
        configType: 'MEDIA_DEFAULT',
        configName: formValues.name,
        fileType: mediaFileType(),
        productId: syncUpProductContext.syncUpAppName,
        deviceGroup: formValues.groups,
        deviceId: formValues.devices,
        type: formValues.type,
        action: formValues.media_action
      };
      const isActiveEdited = vals => {
        const active =
          formValues.isActive === undefined
            ? isActive
            : formValues.isActive === 'Yes'
            ? true
            : false;
        return {
          ...vals,
          priority: formValues.priority.toLowerCase(),
          isActive: active
        };
      };

      try {
        if (mediaType === IOT_ASSET_TYPES.MEDIA) {
          await mediaService.updateMediaMeta({
            assetId: assetId,
            assetName: assetName,
            syncUpAppName: syncUpProductContext.syncUpAppName,
            body: mediaBody
          });
        } else {
          await iotFirmwareService.updateIotFirmware({
            firmwareId: id,
            body: { ...isActiveEdited(formValues) },
            product: syncUpProductContext.syncUpAppName
          });
        }
        onEdit();
        addSuccessMessage({
          message: `Successfully updated ${formValues.asset_name ||
            id} configuration`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <div className={style.container}>
      <TmoButtons
        onClick={openEditModal}
        type={'magenta_primary_icon'}
        icon={<ReactSVG src={editIcon} />}
        className={style.button}
        tooltip={'Edit Configuration'}
      />
      <FormModal
        title="Edit configuration"
        portal={false}
        fieldSpecs={
          mediaType === IOT_ASSET_TYPES.MEDIA
            ? mediaFormFieldSpecs(asset)
            : firmwareFormFieldSpecs(asset)
        }
        modalType={'slide'}
      />
    </div>
  );
}

export default withModal(EditAsset);
