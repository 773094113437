export const SPLINE_CHART_OPTIONS = {
  chart: {
    type: 'spline',
    scrollablePlotArea: {
      minWidth: 600,
      maxWidth: 600,
      scrollPositionX: 1
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: '',
    align: 'left'
  },
  xAxis: {
    tickInterval: 7 * 24 * 3600 * 1000, // one week
    tickWidth: 0,
    gridLineWidth: 1,
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e%b%y',
      month: "%b '%y"
    },
    labels: {
      text: {},
      style: {
        color: '#333',
        fontWeight: 'bold',
        fontSize: '12px',
        fontFamily: 'Tele-Medium'
      }
    }
  },
  yAxis: {
    title: {
      text: {},
      style: {
        color: '#333',
        fontWeight: 'bold',
        fontSize: '12px',
        fontFamily: 'Tele-Medium'
      }
    },
    labels: {
      style: {
        color: '#333',
        fontWeight: 'bold',
        fontSize: '12px',
        fontFamily: 'Tele-Medium'
      }
    },
    minorGridLineWidth: 0,
    gridLineWidth: 0,
    alternateGridColor: null,
    plotBands: [
      {
        color: 'rgba(68, 170, 213, 0.1)',
        label: {
          style: {
            color: '#606060'
          }
        }
      }
    ]
  },
  tooltip: {},
  plotOptions: {
    spline: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5
        }
      },
      marker: {
        enabled: false
      }
    }
  },
  series: [],
  navigation: {
    menuItemStyle: {
      fontSize: '10px'
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        text: 'Download PDF',
        y: '-100',
        menuItems: null,
        onclick: function() {
          this.exportChart({
            type: 'application/pdf'
          });
        }
      }
    }
  }
};

export const DONUT_CHART_OPTIONS = {
  chart: {
    type: 'pie',
    options3d: {
      enabled: true,
      alpha: 45
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  tooltip: {},

  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
      dataLabels: {
        enabled: true,
        format: '{point.name}: <b>{point.percentage:.1f}%</b>'
      }
    }
  },
  series: [],
  exporting: {
    buttons: {
      contextButton: {
        text: 'Download PDF',
        y: '-100',
        menuItems: null,
        onclick: function() {
          this.exportChart({
            type: 'application/pdf'
          });
        }
      }
    }
  }
};

export const PIE_CHART_OPTIONS = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  title: {
    text: ''
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
      }
    }
  },
  credits: {
    enabled: false
  },
  tooltip: {},
  series: [],
  exporting: {
    buttons: {
      contextButton: {
        text: 'Download PDF',
        y: '-100',
        menuItems: null,
        onclick: function() {
          this.exportChart({
            type: 'application/pdf'
          });
        }
      }
    }
  }
};

export const COLUMN_CHART_OPTIONS = {
  chart: {
    type: 'column',
    fontFamily: 'Tele-Medium'
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  tooltip: {},
  yAxis: {
    min: 0,
    title: {
      text: {},
      style: {
        color: '#333',
        fontWeight: 'bold',
        fontSize: '12px',
        fontFamily: 'Tele-Medium'
      }
    }
  },
  xAxis: {
    type: 'category',
    title: {
      text: ''
    },
    labels: {
      style: {
        fontSize: '10px',
        fontFamily: 'Tele-Medium'
      }
    }
  },
  series: [],
  exporting: {
    buttons: {
      contextButton: {
        text: 'Download PDF',
        y: '-100',
        menuItems: null,
        onclick: function() {
          this.exportChart({
            type: 'application/pdf'
          });
        }
      }
    }
  }
};
