import React, { useState, useContext } from 'react';
import Select from 'react-select';
import {
  LabelWithInput,
  TmoInput,
  TmoTextarea,
  TmoFieldset,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import UserService from '../../services/userService';
import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { getSearchedUsers } from '../../utils/helper_functions';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';

import style from './BulkNotificationFormAutocomplete.module.css';

function BulkNotificationFormAutocomplete() {
  const [userSearchResults, setUserSearchResults] = useState([{ value: '' }]);
  const [userIds, setUserIds] = useState([]);
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const actionCallback = async key => {
    const users = await UserService.getUsersBySearch({
      q: key,
      syncUpAppName: syncUpProductContext.syncUpAppName
    });
    var searchedUsers = getSearchedUsers(users);

    const newOptions = searchedUsers.map(user => ({
      value: user.userId,
      label: user.firstName + ' ' + user.lastName,
      isDisabled: !user.preferences.pushNotificationsEnabled
    }));
    setUserSearchResults([...newOptions]);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const data = {
      userIds,
      title,
      body
    };
    try {
      trackCustomEvents(`Bulk Notification Autocomplete`, {
        userIds,
        title
      });
      await UserService.postBulkNotification({
        data,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      setTitle();
      setBody();
      setUserIds([]);
      addSuccessMessage({ message: 'Notifications sent successfully' });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  //TODO: move the required check from the button to the select component
  const isSendEnabled = userIds && userIds.length && title && body;

  const handleOnChange = selection => {
    //set the initial state to null when the last name is removed
    if (selection) {
      setUserIds(selection.map(v => v.value));
    } else {
      setUserIds([]);
    }
  };

  const getUsers = e => {
    if (e && e.length >= 2) {
      actionCallback(e);
    }
  };

  const selectStyles = {
    control: provided => ({
      ...provided,
      border: '0.12rem #f2f2f2',
      backgroundColor: '#f2f2f2',
      borderRadius: '0.25rem',
      boxShadow: null,
      // eslint-disable-next-line no-useless-computed-key
      ['&:hover']: null
    })
  };

  const handleReset = () => {
    setTitle();
    setBody();
    setUserIds([]);
  };

  return (
    <>
      <PageHeader title={'BULK NOTIFICATION AUTOCOMPLETE'} tabHeader />
      <form onSubmit={handleSubmit}>
        <TmoFieldset className={style.container}>
          <div className={style.user_container}>
            <LabelWithInput
              labelText="Search for users"
              styles={selectStyles}
              formComponent={Select}
              isMulti
              isSearchable
              openMenuOnClick={false}
              openMenuOnFocus={false}
              options={userSearchResults}
              onInputChange={getUsers}
              onChange={handleOnChange}
              classNamePrefix="select"
              className={style.user}
            />
            <LabelWithInput
              labelText="Title"
              formComponent={TmoInput}
              name="name"
              value={title}
              onChange={setTitle}
              inputType="primary"
              className={style.user}
              placeholder="Title"
            />
          </div>
          <div>
            <LabelWithInput
              labelText="Body"
              className={style.text_area}
              formComponent={TmoTextarea}
              value={body}
              onChange={setBody}
              type="primary"
              placeholder="Enter here..."
            />
          </div>
          <div className={style.action}>
            <TmoButton type="magenta_secondary" onClick={handleReset}>
              Clear
            </TmoButton>
            <TmoButton
              buttonType="submit"
              className={style.send}
              disabled={!isSendEnabled}
              type="magenta_primary"
            >
              Send
            </TmoButton>
          </div>
        </TmoFieldset>
      </form>
    </>
  );
}
export default BulkNotificationFormAutocomplete;
