import {
  TmoInput,
  TmoSelect,
  TmoTextarea
} from 'tmo-bps-syncup-web-component-lib';

import { APP_MOBILE } from './app_constants';
import {
  PET_GENDERS,
  PET_TYPES,
  THING_TYPE_PET,
  TRACKER_TYPES,
  THING_TYPE_TRACKER,
  THING_TYPE_WATCH,
  PENNYSTONE_GENDERS,
  THING_TYPE_FAMILYLOCATION
} from './app_constants';

const addThingFieldSpecsPets = [
  {
    labelText: 'Thing Type*',
    defaultValue: THING_TYPE_PET.PET,
    fieldName: 'thingType',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    disabled: true
  },
  {
    labelText: 'Pet Type*',
    fieldName: 'petType',
    formComponent: TmoSelect,
    type: 'primary-select',
    selectSpecs: {
      options: Object.values(PET_TYPES).map(t => ({
        value: t,
        text: t
      }))
    },
    defaultValue: Object.values(PET_TYPES)[0],
    required: true
  },
  {
    labelText: 'Pet Name*',
    fieldName: 'name',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    placeholder: 'Enter the Pet Name'
  },
  {
    labelText: 'Gender*',
    fieldName: 'gender',
    formComponent: TmoSelect,
    type: 'primary-select',
    selectSpecs: {
      options: Object.values(PET_GENDERS).map(g => ({
        value: g,
        text: g
      }))
    },
    defaultValue: Object.values(PET_GENDERS)[0],
    required: true
  },
  {
    labelText: 'Birthdate',
    fieldName: 'birthdate',
    formComponent: TmoInput,
    inputType: 'primary',
    placeholder: 'yyyy-mm-dd',
    required: false
  },
  {
    labelText: 'Identifying Marks',
    fieldName: 'identifyingMarks',
    formComponent: TmoTextarea,
    placeholder: 'Enter here...',
    type: 'primary',
    required: false
  },
  {
    labelText: 'Notes',
    fieldName: 'notes',
    formComponent: TmoTextarea,
    placeholder: 'Enter here...',
    type: 'primary',
    required: false
  }
];

const addThingFieldSpecsTracker = [
  {
    labelText: 'Thing Type*',
    defaultValue: THING_TYPE_TRACKER.TRACKER,
    fieldName: 'thingType',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    disabled: true
  },
  {
    labelText: 'Tracker Type*',
    fieldName: 'trackerType',
    formComponent: TmoSelect,
    type: 'primary-select',
    selectSpecs: {
      options: Object.values(TRACKER_TYPES).map(t => ({
        value: t,
        text: t
      }))
    },
    defaultValue: Object.values(TRACKER_TYPES)[0],
    required: true
  },
  {
    labelText: 'Name*',
    fieldName: 'name',
    formComponent: TmoInput,
    inputType: 'primary',
    placeholder: 'Enter the Tracker Name',
    required: true
  },
  {
    labelText: 'IMEI*',
    fieldName: 'identifier',
    formComponent: TmoInput,
    placeholder: 'Enter here...',
    inputType: 'primary',
    required: true
  },
  {
    labelText: 'Notes',
    fieldName: 'notes',
    formComponent: TmoTextarea,
    placeholder: 'Enter here...',
    type: 'primary',
    required: false
  }
];

const addThingFieldSpecsWatch = [
  {
    labelText: 'Thing Type*',
    defaultValue: THING_TYPE_WATCH.KIDSWATCH,
    fieldName: 'thingType',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    disabled: true
  },
  {
    labelText: 'Name*',
    fieldName: 'name',
    formComponent: TmoInput,
    placeholder: 'Enter the Watch Name',
    inputType: 'primary',
    required: true
  },
  {
    labelText: 'IMEI*',
    fieldName: 'identifier',
    formComponent: TmoInput,
    placeholder: 'Enter here...',
    inputType: 'primary',
    required: true
  },
  {
    labelText: 'Relation*',
    fieldName: 'relation',
    formComponent: TmoInput,
    placeholder: 'Enter here...',
    inputType: 'primary',
    required: true
  },
  {
    labelText: 'Gender*',
    fieldName: 'gender',
    formComponent: TmoSelect,
    type: 'primary-select',
    selectSpecs: {
      options: Object.values(PENNYSTONE_GENDERS).map(g => ({
        value: g,
        text: g
      }))
    },
    defaultValue: Object.values(PENNYSTONE_GENDERS)[0],
    required: true
  },
  {
    labelText: 'Birthdate*',
    fieldName: 'birthdate',
    formComponent: TmoInput,
    inputType: 'primary',
    placeholder: 'yyyy-mm-dd',
    required: false
  },
  {
    labelText: 'Notes',
    fieldName: 'notes',
    formComponent: TmoTextarea,
    placeholder: 'Enter here...',
    type: 'primary',
    required: false
  },
  {
    labelText: 'ICCID',
    fieldName: 'lastFourIcc',
    formComponent: TmoTextarea,
    placeholder: 'six digits...',
    type: 'primary',
    required: true
  }
];

const addThingFieldSpecsFamilyLocation = [
  {
    labelText: 'Thing Type*',
    defaultValue: THING_TYPE_FAMILYLOCATION.FAMILYLOCATION,
    fieldName: 'thingType',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    disabled: true
  },
  {
    labelText: 'Name*',
    fieldName: 'name',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true
  },
  {
    labelText: 'Notes',
    fieldName: 'notes',
    formComponent: TmoTextarea,
    placeholder: 'Enter here...',
    type: 'primary',
    required: false
  },
  {
    labelText: 'Line ID',
    fieldName: 'lineId',
    formComponent: TmoInput,
    inputType: 'primary',
    required: false
  },
  {
    labelText: 'Display Name',
    fieldName: 'displayName',
    formComponent: TmoInput,
    inputType: 'primary',
    required: false
  }
];

export const generateThingsFieldSpecs = ({ syncUpAppName }) => {
  switch (syncUpAppName) {
    case APP_MOBILE.PETS:
      return addThingFieldSpecsPets;
    case APP_MOBILE.TRACKER:
      return addThingFieldSpecsTracker;
    case APP_MOBILE.WATCH:
      return addThingFieldSpecsWatch;
    case APP_MOBILE.FAMILYLOCATION:
      return addThingFieldSpecsFamilyLocation;
    default:
      return null;
  }
};
