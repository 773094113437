import React from 'react';

import { ContentContainer } from 'tmo-bps-syncup-web-component-lib';
import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import OperationsList from '../../components/operations/OperationsList';

import style from '../../components/operations/OperationsList.module.css';

function OperationsPage() {
  return (
    <Authorized
      roles={[SUA_ROLES.ADMIN, SUA_ROLES.MOBILE_APP_DEVS, SUA_ROLES.PO]}
      willShowErrorModal
    >
      <ContentContainer className={style.container}>
        <OperationsList></OperationsList>
      </ContentContainer>
    </Authorized>
  );
}
export default OperationsPage;
