import React from 'react';

import ItemDetailsModal from 'components/ItemDetailsModal';
import { GRID } from 'utils/app_constants';
import { prettifyCamelCase } from 'utils/stringUtils';

import style from './TaskDetails.module.css';

const RecurringSchedule = ({ scheduleRecurring, hideLabel, buttonText }) => {
  return (
    <ItemDetailsModal
      className={GRID.COLUMN_HALF}
      buttonText={buttonText}
      label="Recurring Schedule"
      hideLabel={hideLabel}
    >
      {Object.keys(scheduleRecurring).map(schedule => (
        <div key={schedule} className={style.schedule}>
          {schedule != null && (
            <>
              <div className={style.schedule_day}>{schedule} </div>
              <div className={style.items}>
                {scheduleRecurring[schedule] != null ? (
                  Object.keys(scheduleRecurring[schedule]).map(item => (
                    <div className={style.row} key={item}>
                      <label className={style.label}>
                        {prettifyCamelCase(item)}
                      </label>
                      <span className={style.value}>
                        {scheduleRecurring[schedule][item]}
                      </span>
                    </div>
                  ))
                ) : (
                  <> -</>
                )}
              </div>
            </>
          )}
        </div>
      ))}
    </ItemDetailsModal>
  );
};

export default RecurringSchedule;
