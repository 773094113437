import React, { useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { TmoCard } from 'tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import deviceService from '../../services/deviceService';
import HealthCheckTimeStamps from './HealthCheckTimeStamps';
import Accordion from '../uiHelpers/Accordion';
import GoBack from 'components/uiHelpers/goBack/GoBack';
import {
  capitalCase,
  getUserThingBackUrl,
  parseDeviceIdFromURL,
  parseUserIdFromURL
} from '../../utils/helper_functions';
import { getTimestampList } from '../../utils/dateUtils';
import SyncUpProductContext from '../../context/SyncUpProductContext';

import style from './HealthCheck.module.css';

function HealthCheck({ history, match: { url } }) {
  const [health, setHealth] = useState();
  const deviceId = parseDeviceIdFromURL(url, 'health');
  const userId = parseUserIdFromURL(url, 'devices');
  const syncUpProductContext = useContext(SyncUpProductContext);
  const actionCallback = useCallback(
    () =>
      deviceService.healthCheck({
        userId,
        deviceId,
        syncUpAppName: syncUpProductContext.syncUpAppName
      }),
    [userId, deviceId, syncUpProductContext.syncUpAppName]
  );

  const getLastUpdate = latestSensorTimestamps => {
    let sortedupdatedAt = [];
    if (latestSensorTimestamps) {
      const updatedAt = Object.values(latestSensorTimestamps).filter(Boolean);

      sortedupdatedAt = updatedAt.sort((a, b) =>
        a
          .split('/')
          .reverse()
          .join()
          .localeCompare(
            b
              .split('/')
              .reverse()
              .join()
          )
      );
    }
    return sortedupdatedAt.length > 0
      ? sortedupdatedAt[sortedupdatedAt.length - 1]
      : 'Not Available';
  };

  const accordionTitle = sensor => (
    <div className={style.row}>
      <div className={style.title}>{capitalCase(sensor)} Sensor</div>
      <label className={style.status}>
        Status:{' '}
        <span>
          {health.observationsHealth[sensor]
            ? health.observationsHealth[sensor].status
            : 'Not Available'}
        </span>
      </label>
    </div>
  );

  return (
    <Fetcher
      action={actionCallback}
      onLoad={setHealth}
      render={() => (
        <TmoCard className={style.container}>
          <div className={style.heading}>
            <GoBack url={getUserThingBackUrl(userId, 'devices')} />
            <label className={style.heading_label}> Health Check</label>
            <label
              className={classNames(
                style.heading_label,
                style.label_lastupdated
              )}
            >
              Last updated at:
            </label>
            <label className={style.heading_label_margin}>
              {health?.latestSensorTimestamps &&
                getTimestampList(
                  getLastUpdate(health.latestSensorTimestamps),
                  null,
                  'USER'
                )}
            </label>
          </div>
          <div className={style.sensors_container}>
            {!health && <h4>Hang-on a second, we are working on it...</h4>}
            {health?.observationsHealth &&
              Object.keys(health.observationsHealth).map((sensor, index) => (
                <div className={style.sensors_row} key={index} id={sensor}>
                  <Accordion
                    title={accordionTitle(sensor)}
                    className={style.row}
                  >
                    <HealthCheckTimeStamps
                      latestSensorTimestamps={health.latestSensorTimestamps}
                      sensorHistoryTimestamps={health.sensorHistoryTimestamps}
                      sensor={sensor}
                    />
                  </Accordion>
                </div>
              ))}
          </div>
        </TmoCard>
      )}
    />
  );
}

export default HealthCheck;
