import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { sortList } from 'utils/helper_functions';
import { SORT_DIRECTION } from 'utils/app_constants';
import { FaCaretDown } from 'react-icons/all';
import { FaCaretUp } from 'react-icons/all';

import style from './DynamicList.module.css';

function DynamicList({
  className,
  headerNameListItemMap,
  listItems,
  onSort,
  sortDirection
}) {
  const [sortedList, setSortedList] = useState(listItems);
  const handleSorting = object => {
    sortDirection && onSort(object);
    if (object.sortable) {
      object.sortDirection =
        object.sortDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC;
      const listItemKey = object.listItemKey;
      const list = [...listItems];
      sortList(list, { listItemKey }, object.sortDirection);
      setSortedList(list);
    }
  };

  useEffect(() => {
    setSortedList(listItems);
  }, [listItems]);

  return (
    <div className={style.container}>
      <table className={classNames(style.table, className)}>
        <thead>
          <tr>
            {headerNameListItemMap.map((object, index) => (
              <th
                key={index}
                onClick={() => handleSorting(object)}
                className={classNames(style.column, style.header)}
              >
                {object.headerDisplayName || object.listItemKey}
                <span className={style.sort_icon}>
                  {(sortDirection || object.sortable) &&
                    ((sortDirection || object.sortDirection) === 'ASC' ? (
                      <FaCaretDown />
                    ) : (
                      <FaCaretUp />
                    ))}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedList.map((item, index) => (
            <tr key={index} className={style.item}>
              {headerNameListItemMap.map((object, index) => (
                <td
                  key={index}
                  className={classNames(style.column, object.columnClass)}
                >
                  {object.render?.(item) || item[object.listItemKey]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DynamicList;
