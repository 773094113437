export const USER_TYPES = ['Non TMO User', 'TMO User'];
export const NON_TMO_CHANNEL_ID = 'SyncUP_GPS';

export const CHANNEL_ID = [
  {
    name: 'SyncUp_KidsWatch',
    product: 'KidsWatch'
  },
  {
    name: 'SyncUP_GPS_TMO',
    product: 'Flywheel'
  }
];

export const NON_TMO_USER_CONSENT = [
  {
    name: 'consentProfileId',
    placeHolder: 'childTmoId',
    required: true
  },
  {
    name: 'consentOnly',
    value: 'Yes'
  },
  {
    name: 'ConsentProviderTmoId',
    placeHolder: 'ConsentProviderTmoId',
    displayName: 'Consent Provider TMO Id',
    required: true
  },
  {
    name: 'ConsentProviderEmail',
    placeHolder: 'ConsentProviderEmail',
    required: true
  },
  {
    name: 'ConsentLanguage',
    placeHolder: 'EN or ES',
    required: true
  },
  {
    name: 'ConsentVersion',
    value:
      'SyncUp_FamilyApp_Flywheel - Kids Line Parent Direct Notice - 20230911'
  }
];
export const TMO_USER_CONSENT = [
  {
    name: 'ban',
    placeHolder: 'childAccepterBan',
    required: true
  },
  {
    name: 'msisdn',
    placeHolder: 'msisdn',
    required: true
  },
  {
    name: 'brand',
    value: 'Magenta Postpaid'
  },
  {
    name: 'lineType',
    value: 'KidOnlyLine'
  },
  {
    name: 'consentType',
    value: 'PARENTAL_CONSENT'
  },
  {
    name: 'consentProviderType',
    value: 'TMOID'
  },
  {
    name: 'preferredContactMethod',
    value: 'Email'
  },
  {
    name: 'consentProviderTmoId',
    placeHolder: 'consentAccepterTmoid',
    displayName: 'Consent Provider TMO Id',
    required: true
  },
  {
    name: 'consentProviderEmail',
    placeHolder: 'consentAccepterEmail',
    required: true
  },
  {
    name: 'consentLanguage',
    placeHolder: 'EN or ES',
    required: true
  },
  {
    name: 'consentVersion',
    value:
      'SyncUp_FamilyApp_Flywheel - Kids Line Parent Direct Notice - 20230911'
  }
];
