import React, { useState, useCallback, useContext } from 'react';
import {
  RawData,
  ModalV2,
  TmoButtons as TmoButton,
  TextAlert,
  withModal
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import Fetcher from '../../Fetcher';
import deviceService from '../../../services/deviceService';
import ActiveUserTabContext from '../../../context/ActiveUserTabContext';
import FirmwareDetails from '../firmware/FirmwareDetails';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';
import firmwareService from '../../../services/firmwareService';

import style from './HardwareDetailsHeader.module.css';
import acPlug from '../../../static/assets/icons/leftpanel/ac_plug.svg';

function HardwareDetailsHeader({ imei, openModal }) {
  const [hardwareDetails, setHardwareDetails] = useState({});

  const [firmware, setFirmware] = useState();
  const imeiVal = imei?.split(':').pop();
  const action = useCallback(async () => {
    trackCustomEvents(`Get Firmware Details`, {
      imeiVal
    });
    return firmwareService.getFirmwarePackageByImei(imeiVal);
  }, [imeiVal]);
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);

  const actionCallback = useCallback(async () => {
    return deviceService.getHardwareDetails({
      imei,
      syncUpAppName: product
    });
  }, [imei, product]);

  return (
    <Fetcher
      action={actionCallback}
      onLoad={setHardwareDetails}
      render={() =>
        hardwareDetails ? (
          <div className={style.action}>
            <>
              <RawData
                rawObject={hardwareDetails}
                title="Full Hardware Details"
                tooltip="Full Hardware Details"
                type=" "
                modalType={'slide'}
                buttonType="magenta_primary"
              />
              <TmoButton
                onClick={openModal}
                className={style.action_btn}
                type="magenta_primary"
                tooltip="Plug"
                icon={<ReactSVG src={acPlug} />}
              />
              <ModalV2
                title="Target Firmware Details"
                className={style.fw_modal}
                modalType="slide"
              >
                <TextAlert
                  message="* We do not keep track of the current firmware package for an
                imei, the firmware package below represents a target."
                />
                <Fetcher
                  action={action}
                  onLoad={setFirmware}
                  render={() =>
                    firmware ? (
                      <FirmwareDetails details={firmware} />
                    ) : (
                      'No Firmware details'
                    )
                  }
                />
              </ModalV2>
            </>
          </div>
        ) : (
          'No Hardware Details'
        )
      }
    />
  );
}

export default withModal(HardwareDetailsHeader);
