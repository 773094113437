import React from 'react';

import ModalV2 from '../../modal/ModalV2';
import TmoSvg from '../../uiHelpers/TmoSvg';
import StatusText from '../../textHelpers/StatusText';
import { capitalCase } from '../../../utils/helper_functions';

import './FloorPlanModal.css';

function FloorPlanModal({ device }) {
  const {
    name,
    location,
    deviceType,
    locked,
    batteryLevel,
    recentActivities
  } = device;

  return (
    <ModalV2
      title={`${capitalCase(name)} / ${capitalCase(location?.floor)}`}
      className="FloorPlanModal"
    >
      <div className="FloorPlanModal-content">
        <div className="FloorPlanModal-row">
          <label>Device Type:</label> {capitalCase(deviceType)}
        </div>
        <div className="FloorPlanModal-row">
          <label>Status:</label>
          <StatusText status={locked ? 'success' : 'alert'}>
            {locked ? 'Locked' : 'Unlocked'}
          </StatusText>
          <TmoSvg
            svgId={locked ? 'icon-locked' : 'icon-unlocked'}
            className="FloorPlanModal-lock-icon"
          />
        </div>
        <div className="FloorPlanModal-row">
          <label>Battery Level:</label>
          <TmoSvg
            svgId={
              batteryLevel > 80
                ? 'icon-battery-100'
                : batteryLevel >= 61 && batteryLevel <= 80
                ? 'icon-battery-75'
                : batteryLevel >= 31 && batteryLevel <= 60
                ? 'icon-battery-50'
                : batteryLevel >= 10 && batteryLevel <= 30
                ? 'icon-battery-25'
                : batteryLevel < 10
                ? 'icon-battery-0'
                : null
            }
            className="FloorPlanModal-battery-icon"
          />{' '}
          <small>{batteryLevel}%</small>
        </div>
        <div className="FloorPlanModal-row">
          <label>Recent Activities:</label> {JSON.stringify(recentActivities)}
        </div>
      </div>
    </ModalV2>
  );
}

export default FloorPlanModal;
