import config from '../configs/config';
import syncUpPlatformRequest from './syncupPlatformRequest';

const platformRepository = {
  getOnCallSupportGroup: async (responseStatus = false) => {
    const { resources, basePath } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.oncallPlatformSupport,
      basePath: basePath.coreAdmin
    });
  },
  getSupportGroupsList: async (responseStatus = false) => {
    const { resources, basePath } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.platformSupportGroups,
      basePath: basePath.coreAdmin
    });
  }
};

export default platformRepository;
