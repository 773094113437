import environment, { ENVIRONMENTS } from '../utils/environment';
import { deepMerge } from '../utils/objectUtils';

const baseConfig = require(`./config.json`);
const devConfig = require(`./config.dev.json`);
const stagingConfig = require(`./config.staging.json`);
const prodConfig = require(`./config.prod.json`);

let environmentOverride;

switch (environment) {
  case ENVIRONMENTS.DEV:
    environmentOverride = devConfig;
    break;
  case ENVIRONMENTS.STAGING:
    environmentOverride = stagingConfig;
    break;
  case ENVIRONMENTS.PROD:
    environmentOverride = prodConfig;
    break;
  default:
    environmentOverride = {};
}

const config = deepMerge(baseConfig, environmentOverride);

export default config;
