import React from 'react';
import { FaCaretDown } from 'react-icons/all';
import SyntaxHighlighter from 'react-syntax-highlighter';

import {
  TmoCard,
  RawData,
  ExpandableText
} from 'tmo-bps-syncup-web-component-lib';

import style from './DeviceLogsTable.module.css';

function DeviceLogsTable({ table }) {
  return (
    <div className={style.container}>
      {table &&
        table.map((hardwareLog, index) => (
          <TmoCard className={style.card} key={index}>
            <div className={style.row}>
              <label className={style.label_heading}>Message:</label>
              <label className={style.label_content}>
                {hardwareLog.message}
              </label>
            </div>
            <div className={style.row}>
              <label className={style.label_heading}>Id:</label>
              <label className={style.label_content}>
                {hardwareLog.RawMessageKey.id}
              </label>
            </div>
            <div className={style.row}>
              <label className={style.label_heading}>Status:</label>
              <label className={style.label_content}>
                {hardwareLog.RawMessageKey.status}
              </label>
            </div>
            <div className={style.row}>
              <label className={style.label_heading}>Timestamp:</label>
              <label className={style.label_content}>
                {hardwareLog.timestamp}
              </label>
            </div>
            <div className={style.row}>
              <label className={style.label_heading}>Fault Code:</label>
              <label className={style.label_content}>
                {hardwareLog.RawMessageKey.faultcode}
              </label>
            </div>
            <div className={style.row}>
              <label className={style.label_heading}>Fault String:</label>
              <label className={style.label_content}>
                {hardwareLog.RawMessageKey.faultstring}
              </label>
            </div>
            <div className={style.row}>
              <label className={style.label_heading}>Expired Time:</label>
              <label className={style.label_content}>
                {hardwareLog.timestamp}
              </label>
            </div>
            <div className={style.row}>
              <label className={style.label_heading}>Endpoint:</label>
              <label className={style.label_content}>
                {hardwareLog.RawMessageKey.endpoint}
              </label>
            </div>
            {hardwareLog.RawMessageKey.result && (
              <ExpandableText
                triggerText="Result:"
                triggerIcon={<FaCaretDown />}
                className={style.results}
              >
                <SyntaxHighlighter language="json">
                  {JSON.stringify(hardwareLog.RawMessageKey.result, null, 2)}
                </SyntaxHighlighter>
              </ExpandableText>
            )}
            <div className={style.modal}>
              <RawData rawObject={hardwareLog} title="Full Hardware Log" />
            </div>
          </TmoCard>
        ))}
    </div>
  );
}

export default DeviceLogsTable;
