import thingRepository from '../repositories/thingRepository';
import { capitalCase } from '../utils/helper_functions';
import { getUnixToFormattedDateTimeSecond } from '../utils/dateUtils';

const thingService = {
  getThingsByUserId: async ({ userId, syncUpAppName }) => {
    return syncUpAppName === 'DRIVE'
      ? await thingRepository.getThingsDrive({
          userId,
          syncUpAppName
        })
      : await thingRepository.getThingsByUserId({
          userId,
          syncUpAppName
        });
  },
  getSharedThingsByUserId: async ({ userId, syncUpAppName }) => {
    const allThings = await thingRepository.getSharedThingsByUserId({
      userId,
      syncUpAppName
    });

    const sharedThings = allThings.filter(
      p => p.currentDevice && p.currentDevice.userId !== userId
    );
    return sharedThings;
  },

  linkDevice: async ({
    thingId,
    hardwareId,
    product,
    model,
    lastFourIcc,
    userId,
    syncUpAppName
  }) => {
    return await thingRepository.linkDevice({
      thingId,
      hardwareId,
      product,
      model,
      lastFourIcc,
      userId,
      syncUpAppName
    });
  },
  getBreeds: async () => {
    const breeds = await thingRepository.getBreeds();
    return breeds.map(breed => ({ ...breed, name: capitalCase(breed.name) }));
  },
  createBreed: async ({ name, petType }) => {
    return await thingRepository.postBreed({ name, petType });
  },
  deleteBreed: async breedId => {
    return await thingRepository.deleteBreed(breedId);
  },
  addThing: async ({ thing, userId, syncUpAppName }) => {
    return await thingRepository.addThing({ thing, userId, syncUpAppName });
  },
  deleteThing: async ({ thingId, userId, syncUpAppName }) => {
    return syncUpAppName === 'DRIVE'
      ? await thingRepository.deleteDriveThing({
          thingId,
          userId,
          syncUpAppName
        })
      : await thingRepository.deleteThing({
          thingId,
          userId,
          syncUpAppName
        });
  },
  getThingEvents: async ({ thingId, userId, syncUpAppName }) => {
    return await thingRepository.getThingEvents({
      thingId,
      userId,
      syncUpAppName
    });
  },
  getThingTrips: async ({ thingId, userId, syncUpAppName }) => {
    return await thingRepository.getThingTrips({
      thingId,
      userId,
      syncUpAppName
    });
  },
  getRoadsideServices: async ({ thingId, userId, syncUpAppName }) => {
    return await thingRepository.getRoadsideServices({
      thingId,
      userId,
      syncUpAppName
    });
  },
  deleteRoadsideServices: async ({ thingId, serviceId, syncUpAppName }) => {
    return await thingRepository.deleteRoadsideServices({
      thingId,
      serviceId,
      syncUpAppName
    });
  },
  addRoadsideServices: async ({ thingId, userId, data, syncUpAppName }) => {
    return await thingRepository.addRoadsideServices({
      thingId,
      userId,
      data,
      syncUpAppName
    });
  },

  getThingLogs: async ({
    thingId,
    query,
    syncUpAppName,
    continuationToken
  }) => {
    query = continuationToken
      ? `${query}&continuationToken=${continuationToken}`
      : query;
    let result = await thingRepository.getThingLogs({
      thingId,
      query,
      syncUpAppName
    });

    result = {
      ...result,
      communicationLogs: result?.communicationLogs?.map(x => ({
        ...x,
        timestamp: getUnixToFormattedDateTimeSecond(x.timestamp)
      }))
    };
    return result;
  },

  getThingLogsCount: async ({ thingId, syncUpAppName, query }) => {
    return await thingRepository.getThingLogsCount({
      thingId,
      syncUpAppName,
      query
    });
  },

  getThingCallStatus: async ({
    deviceId,
    query,
    continuationToken,
    syncUpAppName
  }) => {
    query = continuationToken
      ? `${query}&continuationToken=${continuationToken}`
      : query;
    let result = await thingRepository.getThingCallStatus({
      deviceId,
      syncUpAppName,
      query
    });

    result = {
      ...result,
      videoCallStatusLogs: result?.videoCallStatusLogs?.map(x => ({
        ...x,
        timestamp: getUnixToFormattedDateTimeSecond(x.timestamp)
      }))
    };
    return result;
  },

  getThingCallStatusCount: async ({ deviceId, syncUpAppName, query }) => {
    return await thingRepository.getThingCallStatusCount({
      deviceId,
      syncUpAppName,
      query
    });
  }
};

export default thingService;
