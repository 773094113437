export const prepareSafeZonePlottingData = (safeZones, singleThingAsCenter) => {
  let dataSet = [];
  if (singleThingAsCenter) {
    dataSet = prepareSafeZones(
      filterSafeZonesForDevice(safeZones, singleThingAsCenter.deviceId)
    );
    dataSet.push(prepareMarker(singleThingAsCenter));
  } else {
    dataSet = prepareSafeZones(safeZones);
  }
  return dataSet;
};

export const filterSafeZonesForDevice = (safeZones, deviceId) =>
  safeZones.filter(zone => zone.deviceIds.includes(deviceId));

export const prepareSafeZoneAndLocationHistories = (
  safeZones,
  locationSensor
) => {
  const filteredSafeZones = filterSafeZonesForDevice(
    safeZones,
    locationSensor[0].deviceId
  );
  const histories = prepareLocationSensorHistories(locationSensor);
  const zones = prepareSafeZones(filteredSafeZones);
  return [...histories, ...zones];
};

const prepareLocationSensorHistories = locationSensor => {
  if (locationSensor) {
    return locationSensor.map((s, index) => ({
      history: true,
      index: ++index,
      id: s.id,
      deviceId: s.deviceId,
      hardwareId: s.hardwareId,
      type: s.type,
      timestamp: s.timestamp,
      updateDate: s.updateDate,
      latitude: s.latitude,
      longitude: s.longitude,
      altitude: s.altitude,
      radius: s.radius,
      speed: s.speed,
      source: s.source,
      options: {
        strokeColor: '#E20074',
        fillColor: '#E20074',
        strokeWeight: 2,
        fillOpacity: 0.2
      }
    }));
  }
};

export const preparePhoneLocationSafeZones = safeZones => {
  if (safeZones) {
    return safeZones.map((safeZone, index) => ({
      safeZone: true,
      index: ++index,
      id: safeZone.id,
      heading: safeZone.name,
      info: {
        keyValuePairs: {
          name: safeZone.name,
          groupId: safeZone.groupId,
          isEnabledForMember: safeZone.isEnabledForMember ? 'Yes' : 'No',
          address: safeZone.address,
          addressName: safeZone.addressName,
          displayZoomLevel: safeZone.displayZoomLevel
        }
      },
      marker: {},
      circle: {
        radius: safeZone.radius,
        center: {
          latitude: safeZone.latitude,
          longitude: safeZone.longitude
        },
        options: {
          strokeColor: '#28a745',
          strokeWeight: 2,
          fillOpacity: 0.2,
          fillColor: '#28a745'
        }
      }
    }));
  }
};

const prepareSafeZones = safeZones => {
  if (safeZones) {
    return safeZones.map((safeZone, index) => ({
      safeZone: true,
      index: ++index,
      id: safeZone.id,
      heading: safeZone.name,
      info: {
        keyValuePairs: {
          name: safeZone.name,
          status: safeZone.status
        },
        lists: {
          deviceIds: safeZone.deviceIds
        },
        objects: [safeZone.deviceGeofenceStates]
      },
      marker: {},
      circle: {
        radius: safeZone.geofence.radius,
        center: {
          latitude: safeZone.geofence.point?.latitude,
          longitude: safeZone.geofence.point?.longitude
        },
        options: {
          strokeColor: '#28a745',
          strokeWeight: 2,
          fillOpacity: 0.2,
          fillColor: '#28a745'
        }
      }
    }));
  }
};

const prepareMarker = singleThingAsCenter => {
  return {
    singleThingAsCenter: true,
    id: singleThingAsCenter.id,
    heading: 'current Location',
    info: {
      keyValuePairs: {
        name: 'Current Location',
        status: ''
      },
      lists: {
        deviceIds: ''
      },
      objects: []
    },
    marker: {
      latitude: singleThingAsCenter.latitude,
      longitude: singleThingAsCenter.longitude,
      radius: singleThingAsCenter.radius,

      options: {
        strokeColor: '#E20074',
        strokeWeight: 2,
        fillOpacity: 0.2
      }
    },
    circle: {}
  };
};
