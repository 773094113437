import {
  TmoInput,
  TmoTextarea,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';

import { IoIosArrowDown } from 'react-icons/all';

const dictionaryToSelectSpec = ([key, value]) => ({
  key: value,
  value: key
});

export const LOCATION_MODES = {
  STATIONARY: 'STATIONARY',
  HUB_AND_SPOKE_WANDERING: 'HUB_AND_SPOKE_WANDERING'
};

export const BATTERY_STATUS = {
  NORMAL: 0,
  CHARGING: 1,
  CHARGING_COMPLETE: 2,
  DAMAGED: 3,
  LOW_BATTERY: 4,
  NOT_INSTALLED: 5,
  UNKNOWN: 6
};

export const BATTERY_LEVEL_MODES = {
  FIXED: 'FIXED',
  DRAIN_AND_CHARGE: 'DRAIN_AND_CHARGE'
};

export const ACTIVITY_MODES = {
  SENSOR_DISABLED: 'SENSOR_DISABLED',
  CIRCADIAN: 'CIRCADIAN'
};

export const getPetsFormValue = userInfo => {
  return [
    {
      labelText: 'Hardware Id',
      fieldName: 'hardwareId',
      defaultValue: '',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Hardware ID / IMEI',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Powered On',
      fieldName: 'isPoweredOn',
      defaultValue: true,
      formInputType: 'TmoDropDownSelect',
      formComponent: TmoDropDownSelect,
      optionsList: [
        { key: 'On', value: true },
        { key: 'Off', value: false }
      ].map(k => ({
        key: k.value,
        value: k.key
      })),
      defaultSelectedValue: [
        { key: 'On', value: true },
        { key: 'Off', value: false }
      ].map(k => ({
        key: k.value,
        value: k.key
      }))[0],
      renderIcon: <IoIosArrowDown />,
      formMargin: true
    },
    {
      labelText: 'Security Key',
      fieldName: 'securityKey',
      defaultValue: '123456',
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Security Key'
    },
    {
      labelText: 'Owned By',
      fieldName: 'ownedBy',
      defaultValue: userInfo ? userInfo.email : '',
      formComponent: TmoTextarea,
      formInputType: 'TmoTextarea',
      type: 'primary',
      required: true,
      placeholder: 'Owned By'
    },
    {
      labelText: 'Temperature',
      fieldName: 'temperature',
      defaultValue: 98.6,
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Temperature'
    },
    {
      labelText: 'Buzzer Enabled',
      fieldName: 'isBuzzerOn',
      defaultValue: true,
      formInputType: 'TmoDropDownSelect',
      formComponent: TmoDropDownSelect,
      optionsList: [
        { key: 'On', value: true },
        { key: 'Off', value: false }
      ].map(k => ({
        key: k.value,
        value: k.key
      })),
      defaultSelectedValue: [
        { key: 'On', value: true },
        { key: 'Off', value: false }
      ].map(k => ({
        key: k.value,
        value: k.key
      }))[0],
      renderIcon: <IoIosArrowDown />,
      formMargin: true
    },
    {
      labelText: 'Activity Mode',
      fieldName: 'activityMode',
      defaultValue: ACTIVITY_MODES.CIRCADIAN,
      formInputType: 'TmoDropDownSelect',
      formComponent: TmoDropDownSelect,
      optionsList: Object.entries(ACTIVITY_MODES).map(dictionaryToSelectSpec),
      defaultSelectedValue: Object.entries(ACTIVITY_MODES)
        .map(dictionaryToSelectSpec)
        .filter(x => x.key === ACTIVITY_MODES.CIRCADIAN)[0],
      renderIcon: <IoIosArrowDown />,
      formMargin: true
    },
    {
      labelText: 'Location Mode',
      fieldName: 'locationMode',
      defaultValue: LOCATION_MODES.HUB_AND_SPOKE_WANDERING,
      formInputType: 'TmoDropDownSelect',
      formComponent: TmoDropDownSelect,
      optionsList: Object.entries(LOCATION_MODES).map(dictionaryToSelectSpec),
      defaultSelectedValue: Object.entries(LOCATION_MODES)
        .map(dictionaryToSelectSpec)
        .filter(x => x.key === LOCATION_MODES.HUB_AND_SPOKE_WANDERING)[0],
      renderIcon: <IoIosArrowDown />,
      formMargin: true
    },
    {
      labelText: 'Latitude',
      fieldName: 'latitude',
      defaultValue: 47.578651,
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Latitude'
    },
    {
      labelText: 'Longitude',
      fieldName: 'longitude',
      defaultValue: -122.165176,
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Longitude'
    },
    {
      labelText: 'Altitude',
      fieldName: 'altitude',
      defaultValue: 55,
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Altitude'
    },
    {
      labelText: 'Radius',
      fieldName: 'radius',
      defaultValue: 50,
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Radius'
    },
    {
      labelText: 'battery level mode',
      fieldName: 'batteryLevelMode',
      defaultValue: BATTERY_LEVEL_MODES.FIXED,
      formInputType: 'TmoDropDownSelect',
      formComponent: TmoDropDownSelect,
      optionsList: Object.entries(BATTERY_LEVEL_MODES).map(
        dictionaryToSelectSpec
      ),
      defaultSelectedValue: Object.entries(BATTERY_LEVEL_MODES)
        .map(dictionaryToSelectSpec)
        .filter(x => x.key === BATTERY_LEVEL_MODES.FIXED)[0],
      renderIcon: <IoIosArrowDown />,
      formMargin: true
    },
    {
      labelText: 'battery level',
      fieldName: 'batteryLevel',
      defaultValue: 98,
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'battery level'
    },
    {
      labelText: 'battery status',
      fieldName: 'batteryStatus',
      defaultValue: BATTERY_STATUS.CHARGING,
      formInputType: 'TmoDropDownSelect',
      formComponent: TmoDropDownSelect,
      optionsList: Object.entries(BATTERY_STATUS).map(dictionaryToSelectSpec),
      defaultSelectedValue: Object.entries(BATTERY_STATUS)
        .map(dictionaryToSelectSpec)
        .filter(x => x.key === BATTERY_STATUS.CHARGING)[0],
      renderIcon: <IoIosArrowDown />,
      formMargin: true
    }
  ];
};

export const getIoTFromValue = (simulator, product) => {
  return [
    {
      labelText: 'Hardware Id',
      fieldName: 'hardwareId',
      defaultValue: simulator?.deviceId,
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Hardware ID / IMEI',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Product ID',
      fieldName: 'productId',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      defaultValue: product ? product : simulator?.tags?.productId,
      placeholder: 'Product ID',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Simulator Type',
      fieldName: 'simulatorType',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      defaultValue: simulator?.tags?.simulatorType,
      placeholder: 'Simulator Type',
      formInputType: 'TmoInput'
    }
  ];
};
export const getIoTTagFromValue = (simulator, product) => {
  return [
    {
      labelText: 'Hardware Id',
      fieldName: 'hardwareId',
      defaultValue: simulator?.deviceId,
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Hardware ID / IMEI',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Product ID',
      fieldName: 'productId',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      defaultValue: product ? product : simulator?.tags?.productId,
      placeholder: 'Product ID',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Simulator Type',
      fieldName: 'simulatorType',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      defaultValue: simulator?.tags?.simulatorType,
      placeholder: 'Simulator Type',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Model',
      fieldName: 'model',
      defaultValue: 'TAG1',
      formInputType: 'TmoDropDownSelect',
      formComponent: TmoDropDownSelect,
      optionsList: [
        { key: 'TAG1', value: 'TAG1' },
        { key: 'TAG2', value: 'TAG2' }
      ].map(k => ({
        key: k.value,
        value: k.key
      })),
      defaultSelectedValue: [
        { key: 'TAG1', value: 'TAG1' },
        { key: 'TAG2', value: 'TAG2' }
      ].map(k => ({
        key: k.value,
        value: k.key
      }))[0]
    }
  ];
};

export const getEditIoTFromValue = simulator => {
  const editValues = [
    {
      labelText: 'Status',
      fieldName: 'status',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      defaultValue: simulator?.status,
      placeholder: 'Status',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Version',
      fieldName: 'version',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      defaultValue: simulator?.version,
      placeholder: 'Version',
      formInputType: 'TmoInput'
    }
  ];
  return [...getIoTFromValue(simulator), ...editValues];
};

export const getDriveFormValue = () => {
  return [
    {
      labelText: 'Hardware Id',
      fieldName: 'hardwareId',
      defaultValue: '',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Hardware ID / IMEI',
      formInputType: 'TmoInput'
    },
    {
      labelText: 'Model',
      fieldName: 'model',
      defaultValue: 'SyncUP Drive SD7000T',
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'model'
    },
    {
      labelText: 'Manufacturer',
      fieldName: 'manufacturer',
      defaultValue: '',
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Manufacturer'
    },
    {
      labelText: 'Simulator Type',
      fieldName: 'simulatorType',
      defaultValue: 'AUTO',
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Simulator Type'
    },

    {
      labelText: 'isSimulated',
      fieldName: 'isSimulated',
      defaultValue: true,
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'Is Simulated'
    },
    {
      labelText: 'firmwareVersion',
      fieldName: 'firmwareVersion',
      defaultValue: '1.0',
      formComponent: TmoInput,
      formInputType: 'TmoInput',
      inputType: 'primary',
      required: true,
      placeholder: 'firmwareVersion'
    }
  ];
};
