import React, { useState, useContext } from 'react';

import {
  TmoButtons as TmoButton,
  withModal,
  ModalV2
} from 'tmo-bps-syncup-web-component-lib';

import ActiveUserTabContext from '../../../context/ActiveUserTabContext';
import SearchedUserContext from '../../../context/SearchedUserContext';
import StatusContext from '../../../context/StatusContext';
import useManualFetcher from '../../../hooks/useManualFetcher';
import Feature from '../../featureWrappers/Feature';
import thingService from '../../../services/thingService';
import AddRoadsideService from './AddRoadsideService';
import { APP_MOBILE } from '../../../utils/app_constants';
import { DEVICE_DRIVE_ROADSIDE_SERVICES } from '../../../utils/deviceConsts';

import style from './AddRoadsideService.module.css';
import addService from '../../../static/assets/icons/services.svg';
import { ReactSVG } from 'react-svg';

const selectSpecsRoadSideServices = Object.keys(
  DEVICE_DRIVE_ROADSIDE_SERVICES
).map(value => ({
  key: value,
  value: value
}));

function AddDeviceRoadsideServices({ thingId, openModal }) {
  const {
    user: { userId, firstName, lastName, msisdns }
  } = useContext(SearchedUserContext);

  const [isModalOpenEvent, setIsModalOpenEvent] = useState(false);

  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);
  const [latValue, setLatValue] = useState();
  const [longValue, setLongValue] = useState();
  const [latValueDest, setLatValueDest] = useState();
  const [longValueDest, setLongValueDest] = useState();
  const [addressDest, setAddressDest] = useState();

  const [roadsideService, setRoadsideService] = useState(
    Object.keys(DEVICE_DRIVE_ROADSIDE_SERVICES)[0]
  );

  const updateLatLong = latLongObj => {
    setLatValue(latLongObj.lat);
    setLongValue(latLongObj.long);
    setLatValueDest(latLongObj.latDest);
    setLongValueDest(latLongObj.longValueDest);
    setAddressDest(latLongObj.addressDest);
  };

  const showExecuteCommandModal = event => {
    setIsModalOpenEvent(true);
  };

  const closeModal = () => {
    resetFetchState();
    setIsModalOpenEvent(false);
  };

  const [fetchState, actionTrigger, error, resetFetchState] = useManualFetcher({
    action: () => {
      const body = {
        FirstName: firstName,
        LastName: lastName,
        PrimaryPhoneNumber: msisdns
          ? msisdns.length > 0
            ? msisdns[0]
            : ''
          : '',
        ServiceType: roadsideService,
        Notes: 'test',
        TroubleCode: 'T1',
        TroubleCodeDescription: 'TTTTT',
        Latitude: latValue,
        Longitude: longValue,
        DestinationLatitude: latValueDest,
        DestinationLongitude: longValueDest,
        TowDestinationName: addressDest,
        BackupPhoneNumber: msisdns
          ? msisdns.length > 1
            ? msisdns[1]
            : ''
          : '',
        AllowTextMessageUpdates: true
      };
      return thingService.addRoadsideServices({
        userId: userId,
        thingId: thingId,
        syncUpAppName: product,
        data: body
      });
    },
    onLoad: data => {
      addSuccessMessage({
        message: 'Command request sent successfully'
      });
    },
    onError: error => {
      addErrorMessage({ error });
    },
    onComplete: () => {
      closeModal();
    }
  });
  return (
    <>
      <TmoButton
        onClick={openModal}
        type="magenta_primary"
        tooltip="Add Service"
        icon={<ReactSVG src={addService} />}
      />
      <ModalV2
        modalType="slide"
        title={`Create Service For Thing ${thingId}`}
        portal={false}
      >
        <div className={style.container}>
          <Feature isUserDetails Products={[APP_MOBILE.DRIVE]}>
            <AddRoadsideService
              label="Service"
              selectSpecs={selectSpecsRoadSideServices}
              updateLatLong={updateLatLong}
              onSelectChange={setRoadsideService}
              selectValue={roadsideService}
              onExecuteClick={showExecuteCommandModal}
              isModalOpen={isModalOpenEvent}
              actionTrigger={actionTrigger}
              closeConfirmationModal={closeModal}
              fetchState={fetchState}
              error={error}
            />
          </Feature>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(AddDeviceRoadsideServices);
