import React, { useMemo, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'underscore';

import ErrorMessageModal from '../components/errorHandler/ErrorMessageModal';
import { ERROR_SCREEN_MESSAGE } from '../utils/app_constants';
import { getOktaGroups } from '../utils/helper_functions';
import LoggedInUserContext from '../context/LoggedInUserContext';

function Authorized({ children, history, roles, willShowErrorModal }) {
  const { userInfo = {}, homePageUrl } = useContext(LoggedInUserContext);

  const isAuthorized = useMemo(
    () => !!_.intersection(getOktaGroups(userInfo), roles).length,
    [userInfo, roles]
  );

  return (
    <>
      {!isAuthorized && willShowErrorModal && (
        <ErrorMessageModal
          message={ERROR_SCREEN_MESSAGE.UNAUTHORIZED.message}
          onClose={() => history.push(homePageUrl)}
        />
      )}
      {isAuthorized && children}
    </>
  );
}

export default withRouter(Authorized);
