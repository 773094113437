import React, { useState, useContext, useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import {
  FormModal,
  TmoButtons as TmoButton,
  TmoDropDownSelect,
  TmoInput,
  TmoToggle,
  withModal
} from 'tmo-bps-syncup-web-component-lib';

import userService from '../../services/userService';
import LocationGroups from './LocationGroups';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';
import { trackCustomEvents } from 'utils/appInsight_analytics';
import StatusContext from 'context/StatusContext';
import { hasValue } from 'utils/helper_functions';

import style from './PhoneLocationGroups.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';
import { FaPlus } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

function PhoneLocationGroups({ openModal }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [phoneLocationGroups, setPhoneLocationGroups] = useState([]);
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const [formTitle, setFormTitle] = useState();
  const [formFields, setFormFields] = useState();

  const formFieldSpecs = [
    {
      labelText: 'Group Name',
      fieldName: 'name',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Group Name'
    }
  ];

  const inviteFormFieldSpecs = [
    {
      labelText: 'Select Group',
      fieldName: 'groupId',
      formComponent: TmoDropDownSelect,
      optionsList: phoneLocationGroups.map(group => ({
        key: group.id,
        value: `${group.name} (${group.id})`
      })),
      defaultSelectedValue: phoneLocationGroups.map(group => ({
        key: group.id,
        value: `${group.name} (${group.id})`
      }))[0],
      defaultValue: phoneLocationGroups[0]?.id,
      renderIcon: <IoIosArrowDown />,
      formMargin: true,
      required: true
    },
    {
      labelText: 'First Name',
      fieldName: 'firstName',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'First Name'
    },
    {
      labelText: 'Last Name',
      fieldName: 'lastName',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Last Name'
    },
    {
      labelText: 'MSISDN',
      fieldName: 'msisdn',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true
    },
    {
      labelText: 'Emergency Contact',
      fieldName: 'emergencyContact',
      formComponent: TmoToggle,
      inputType: 'primary'
    }
  ];

  const actionCallback = useCallback(
    () =>
      userService.getPhoneLocationGroups({
        userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  const openAddModal = async () => {
    setFormTitle('Add new Group');
    setFormFields(formFieldSpecs);
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Add New Location Group`);
      const body = {
        name: formValues.name
      };
      try {
        await userService.createPhoneLocationGroups({
          userId,
          syncUpAppName: product,
          body: body
        });
        forceRefresh();
        addSuccessMessage({
          message: `Successfully added Group: ${formValues.name}`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  const openInviteModal = async () => {
    setFormTitle('Invite Member');
    setFormFields(inviteFormFieldSpecs);
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(
        `Add new member invite to Group(${formValues.groupId})`
      );

      try {
        await userService.createInviteLocationGroups({
          userId,
          syncUpAppName: product,
          body: formValues
        });
        forceRefresh();
        addSuccessMessage({
          message: `Successfully invited member: ${formValues.firstName}`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <>
      <PageHeader
        title={'Phone Location Groups'}
        tabHeader={true}
        subtitle={
          phoneLocationGroups.length > 1 &&
          `${phoneLocationGroups.length} Phone location groups are available`
        }
      >
        <TmoButton
          className={style.button}
          onClick={forceRefresh}
          type="magenta_secondary"
          tooltip="Refresh"
          icon={<ReactSVG src={refreshIcon} />}
        />
        {hasValue(phoneLocationGroups) && (
          <TmoButton
            type="magenta_primary"
            onClick={openInviteModal}
            icon={<FaPlus />}
          >
            Invite Member
          </TmoButton>
        )}
        <TmoButton
          type="magenta_primary"
          icon={<FaPlus />}
          onClick={openAddModal}
        >
          Add New Group
        </TmoButton>

        <FormModal
          title={formTitle}
          fieldSpecs={formFields}
          modalType={'slide'}
        />
      </PageHeader>
      <>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={setPhoneLocationGroups}
          render={() =>
            phoneLocationGroups?.length > 0 ? (
              <div className={style.items}>
                {phoneLocationGroups.map(group => (
                  <LocationGroups group={group} forceRefresh={forceRefresh} />
                ))}
              </div>
            ) : (
              <span className={style.message}>User does not have Groups</span>
            )
          }
        />
      </>
    </>
  );
}

export default withModal(PhoneLocationGroups);
