import React from 'react';
import classNames from 'classnames';
import {
  ConfirmationModalV2,
  RawData,
  TmoButtons
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import RoleFeature from 'components/featureWrappers/RoleFeature';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import UploadAsset from './UploadAsset';
import EditAsset from './EditAsset';
import { prettifyCamelCase } from '../../utils/stringUtils';
import { GRID } from 'utils/app_constants';

import style from './SingleAsset.module.css';
import deleteIcon from '../../static/assets/icons/action/delete.svg';

function SingleAssetMedia({
  asset,
  onEdit,
  setVisible,
  mediaType,
  details,
  group,
  editFieldSpecs,
  onDelete,
  assetName,
  assetId,
  onAssetUpload
}) {
  return (
    <div className={style.items}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <label className={style.display_name}>
            {prettifyCamelCase(asset.configName.toLowerCase())}
          </label>
          <span className={style.product}>
            {prettifyCamelCase(asset.configType.toLowerCase())}
          </span>
        </div>
        <div className={style.action_controls}>
          <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
            <div className={style.toolbar_item}>
              <EditAsset
                mediaType={mediaType}
                asset={details || group}
                onEdit={onEdit}
                editFieldSpecs={editFieldSpecs}
                isAssetMedia
              />
            </div>
            <div className={style.toolbar_item}>
              <TmoButtons
                onClick={onDelete}
                type="magenta_secondary"
                icon={<ReactSVG src={deleteIcon} />}
                tooltip="Delete"
              />
              <ConfirmationModalV2
                message={`Are you sure you want to remove ${assetName}?`}
                title={`Confirm ${mediaType} Config Removal`}
                acceptButtonText="Delete"
                type="danger"
                confirmInput
                usePortal={false}
                modalType="delete"
                confirmText="delete"
              />
            </div>
          </RoleFeature>
        </div>
      </div>
      <div className={style.content}>
        <ItemDetailList
          className={GRID.COLUMN_FULL}
          rowSet={[{ 'Device Id': asset.deviceId }, { Id: asset.id }]}
        />
        <ItemDetailList
          rowSet={[
            { deviceGroup: asset.deviceGroup, productId: asset.productId },
            {
              'Asset Name': asset.assetName,
              'Asset Id': asset.assetId
            },
            {
              'Asset Url': asset.assetUrl ? { '': asset.assetUrl } : '',
              'Blob Name': asset.blobName
            },
            {
              Version: asset.version,
              Type: asset.type
            },
            {
              'File Type': asset.fileType,
              'Mime Type': asset.mimeType
            },
            {
              Action: asset.action,
              'Create By': asset.createdBy
            },
            {
              'Created Date & Time': asset.createdDateTime,
              'Last Modified By': asset.lastModifiedBy
            },
            {
              'Modified Date & Time': asset.modifiedDateTime
            }
          ]}
        />
      </div>
      <div className={style.card_footer}>
        <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
          <div className={style.toolbar_item}>
            <UploadAsset
              assetId={assetId}
              assetName={assetName}
              mediaType={mediaType}
              uploadedAsset={onAssetUpload}
              onEdit={onEdit}
              setVisible={setVisible}
              isAssetMedia
            />
          </div>
        </RoleFeature>
        <div className={classNames(style.toolbar_item, style.config_button)}>
          <RawData
            rawObject={details}
            title="Full Configuration Details"
            type="Config"
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_link"
          />
        </div>
      </div>
    </div>
  );
}

export default SingleAssetMedia;
