import React from 'react';
import { TmoCard, RawData } from 'tmo-bps-syncup-web-component-lib';
import classNames from 'classnames';

import { formatPhoneNumber, capitalize } from '../../utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import { shuffleObjectKeysAndValues } from '../../utils/helper_functions';

import style from './ContactCard.module.css';

function ContactCard({ contact }) {
  const {
    id,
    msisdn,
    countryCode,
    firstName,
    lastName,
    type,
    emergency,
    iconUrl,
    email,
    things,
    entityDisplayNames
  } = contact;

  return (
    <TmoCard shadow={false}>
      <div className={style.card_header}>
        <div className={style.avatar_container}>
          {iconUrl && iconUrl !== '' ? (
            <div
              style={{ backgroundImage: 'url(' + iconUrl + ')' }}
              className={style.avatar}
            />
          ) : (
            firstName &&
            lastName && (
              <div className={classNames(style.avatar, style.avatar_bg)}>
                {`${firstName?.charAt(0).toUpperCase()}${lastName
                  ?.charAt(0)
                  .toUpperCase()}`}
              </div>
            )
          )}
        </div>
        <div className={style.user_info}>
          <div className={style.name}>
            {firstName && capitalize(firstName)}{' '}
            {lastName && capitalize(lastName)}
          </div>
          <div> {email}</div>
        </div>
      </div>
      <div className={style.content}>
        <ItemDetailList
          rowSet={[
            { id },
            { msisdn: msisdn ? formatPhoneNumber(msisdn) : null, countryCode },
            { type, emergency: emergency ? ' Yes' : ' No' },
            {
              things: things ? shuffleObjectKeysAndValues(things) : null,
              entityDisplayNames: entityDisplayNames
                ? shuffleObjectKeysAndValues(entityDisplayNames)
                : null
            }
          ]}
        />
      </div>
      <div className={style.footer_action}>
        <RawData
          rawObject={contact}
          title={`Full Contact object for ${firstName}`}
          type={'Contact Details'}
          usePortal={false}
          modalType={'slide'}
          buttonType="magenta_link"
        />
      </div>
    </TmoCard>
  );
}

export default ContactCard;
