import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TmoH3, TmoNumberInput } from 'tmo-bps-syncup-web-component-lib';

import Accordion from '../../components/uiHelpers/Accordion';
import { capitalCase } from '../../utils/helper_functions';
import Details from './Details';
import DragableContainer from './DragableContainer';

import style from './Content.module.css';

import chevronDown from '../../static/assets/icons/chevron_down.svg';
import chevronUp from '../../static/assets/icons/chevron_right.svg';

function Content({
  item,
  faqContent,
  onDataChange,
  handleSelection,
  deleteFlag,
  orderingFlag,
  setFaqContent,
  reOrderingList,
  setReOrderingList,
  language
}) {
  const { category, details, order, categoryId } = item;
  const [showContent, setShowContent] = useState(true);
  const [categoryOrder, setCategoryOrder] = useState(order);
  const accordionTitle = title => (
    <div className={style.row}>
      <div className={style.title} aria-label={title}>
        {title}
      </div>
    </div>
  );
  const onCategoryOrderChange = e => {
    let list = [...faqContent];
    const index = faqContent.findIndex(item => item.id === categoryId);
    if (index >= 0) {
      list[index] = { ...list[index], newCategoryOrder: e };
      setFaqContent(list);
    }
    setCategoryOrder(e);
  };
  useEffect(() => {
    if (order) {
      setCategoryOrder(order);
    }
  }, [order]);
  return (
    <>
      <div className={style.category_wrapper}>
        <TmoH3
          className={style.sticky_tiles}
          flush
          ariaLabel={capitalCase(category)}
          ariaRole="heading"
        >
          <span>{capitalCase(category)}</span>
        </TmoH3>
        <div className={style.input_wrapper}>
          {orderingFlag && (
            <TmoNumberInput
              value={categoryOrder}
              onChange={onCategoryOrderChange}
            />
          )}
          {showContent ? (
            <ReactSVG src={chevronDown} onClick={() => setShowContent(false)} />
          ) : (
            <ReactSVG src={chevronUp} onClick={() => setShowContent(true)} />
          )}
        </div>
      </div>
      {showContent && (
        <>
          {orderingFlag ? (
            <DndProvider backend={HTML5Backend}>
              <DragableContainer
                details={details}
                reOrderingList={reOrderingList}
                setReOrderingList={setReOrderingList}
              />
            </DndProvider>
          ) : (
            <>
              {details &&
                details.map((data, index) => (
                  <Accordion
                    key={`${data.id}-${index}`}
                    className={style.accordion_row}
                    title={accordionTitle(data.title)}
                    titleClassName={style.accordion_column}
                    contentClassName={style.accordion_content}
                    customIcon
                    ariaLabel={data.title}
                    ariaRole="accordion"
                    showCheckBox={deleteFlag}
                    data={data}
                    onSelection={handleSelection}
                    isAdmin
                  >
                    <Details
                      details={data}
                      onDataChange={onDataChange}
                      faqContent={faqContent}
                      category={category}
                      language={language}
                      isAdmin
                    />
                  </Accordion>
                ))}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Content;
