import React, { useContext } from 'react';
import { FaEdit } from 'react-icons/all';
import classNames from 'classnames';
import {
  TmoCard,
  withModal,
  TmoButtons,
  TmoInput,
  TmoTextarea,
  FormModal,
  TmoSelect,
  StatusPill
} from 'tmo-bps-syncup-web-component-lib';

import firmwareService from '../../services/firmwareService';
import FirmwareGroupDelete from './GroupDelete';
import RoleFeature from '../featureWrappers/RoleFeature';
import { removeDashes } from '../../utils/helper_functions';
import { pluralize } from '../../utils/stringUtils';
import StatusContext from '../../context/StatusContext';
import { DEVICE_STATUS } from '../../utils/deviceConsts';
import FirmwareGroupItemDetails from './GroupItemDetails';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './Groups.module.css';

function GroupItem({
  className,
  openModal,
  group,
  onEdit,
  onRemove,
  group: { name, id, firmwarePackageId, status, imeis = [], createdBy }
}) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const formFieldSpecs = [
    {
      labelText: 'Name',
      fieldName: 'name',
      formComponent: TmoInput,
      required: false,
      defaultValue: name,
      placeholder: 'Group name'
    },
    {
      labelText: 'IMEI List',
      fieldName: 'imeis',
      formComponent: TmoTextarea,
      required: false,
      defaultValue: imeis,
      placeholder: 'Comma separated imei'
    },
    {
      labelText: 'Group Status',
      fieldName: 'status',
      formComponent: TmoSelect,
      required: true,
      selectSpecs: {
        options: Object.entries(DEVICE_STATUS).map(([k, v]) => ({
          value: k,
          text: v
        }))
      },
      defaultValue: status
    }
  ];

  const openEditModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    const formatImei =
      imeis !== formValues?.imeis
        ? formValues?.imeis?.replace(/\s/g, '').split(',')
        : imeis;
    const patchBody = [
      {
        op: 'replace',
        path: '/name',
        value: formValues?.name
      },
      {
        op: 'replace',
        path: '/imeis',
        value: formatImei
      },
      {
        op: 'replace',
        path: '/status',
        value: formValues?.status
      }
    ];
    if (isConfirmed) {
      trackCustomEvents(`Update Firmware Group`, {
        groupId: id
      });
      try {
        onEdit(
          await firmwareService.patchFirmwareGroup({
            body: patchBody,
            groupId: id
          })
        );
        addSuccessMessage({
          message: `Successfully updated Firmware Group: ${formValues.name}`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <TmoCard
      shadow={false}
      key={id}
      className={classNames(style.item, className)}
    >
      <div className={style.row}>
        <label className={style.label}>
          Name: <span className={style.label_span}>{name}</span>
        </label>
      </div>
      <div className={style.row}>
        <label className={style.label}>
          Group ID: <span className={style.label_span}>{id}</span>
        </label>
      </div>
      {firmwarePackageId && (
        <div className={style.row}>
          <label className={style.label}>
            Firmware Package ID:{' '}
            <span className={style.label_span}>{firmwarePackageId}</span>
          </label>
        </div>
      )}
      {imeis.length && (
        <div className={style.row}>
          <label className={style.label}>
            {imeis.length}{' '}
            {pluralize({
              string: 'Device',
              count: imeis.length
            })}
            :{' '}
            <ol className={style.imei}>
              {imeis.map(i => (
                <FirmwareGroupItemDetails key={i} imei={i} />
              ))}
            </ol>
          </label>
        </div>
      )}
      {status && (
        <div className={style.row}>
          <label className={style.label}>
            Status:{' '}
            <StatusPill status={removeDashes(status)}>{status}</StatusPill>
          </label>
        </div>
      )}
      {createdBy && (
        <div className={style.row}>
          <label className={style.label}>
            Created By: <span className={style.label_span}>{createdBy}</span>
          </label>
        </div>
      )}
      <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
        <div className={classNames(style.row, style.action)}>
          <FirmwareGroupDelete onRemove={onRemove} group={group} />
          <TmoButtons
            className={style.edit}
            type="secondary"
            icon={<FaEdit />}
            onClick={openEditModal}
          >
            Edit
          </TmoButtons>
        </div>
      </RoleFeature>
      <FormModal
        title={`Update ${name} Firmware Group`}
        fieldSpecs={formFieldSpecs}
      />
    </TmoCard>
  );
}

export default withModal(GroupItem);
