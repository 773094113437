import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect
} from 'react';
import { ReactSVG } from 'react-svg';
import { FaPlus } from 'react-icons/all';
import {
  TmoButtons as TmoButton,
  withModal,
  TmoH2,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import Thing from './Thing';
import AddThing from './AddThing';
import thingService from '../../services/thingService';
import deviceService from '../../services/deviceService';
import Feature from '../featureWrappers/Feature';
import { APP_MOBILE } from '../../utils/app_constants';
import { generateThingsFieldSpecs } from '../../utils/addThingsFieldsSpecHelper';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { hasValue } from '../../utils/helper_functions';
import StatusContext from '../../context/StatusContext';
import { getRandomString } from '../../utils/stringUtils';
import { getTimestampRangeForLastHours } from '../../utils/dateUtils';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';

import style from './Things.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';
import { IoIosArrowDown } from 'react-icons/all';

function Things({
  openModal,
  thingsCount,
  productType,
  thingTypeName,
  refresh
}) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [currentThingId, setCurrentThingId] = useState();
  const [things, setThings] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const [lastRefresh, setLastRefresh] = useState(getRandomString());
  const [firstUpdate] = useState(refresh);

  const handleManualRefresh = () => {
    setLastRefresh(getRandomString());
  };

  const actionCallback = useCallback(
    () =>
      thingService.getThingsByUserId({
        userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  const handleSetThings = useCallback(things => {
    setCurrentThingId(null);
    setThings(things);
  }, []);

  const activeThings = useMemo(
    () => things?.filter(t => t.status !== 'DELETED'),
    [things]
  );

  useEffect(() => {
    refresh !== firstUpdate && handleManualRefresh(); //children function of interest
  }, [refresh, firstUpdate]);

  useEffect(() => {
    return things && thingsCount(things.length);
  }, [thingsCount, things]);

  const thing = useMemo(() => {
    const safeThings = activeThings || [];
    return (
      safeThings.find(thing => String(thing.id) === String(currentThingId)) ||
      safeThings[0]
    );
  }, [currentThingId, activeThings]);

  const handleRemoveDevice = async ({ deviceId, thingId }) => {
    setThings(things =>
      things.map(t => {
        if (t.id === thingId) {
          return {
            ...t,
            currentDevice: null
          };
        }
        return t;
      })
    );
    try {
      await deviceService.unregisterDevice({
        userId,
        thingId,
        deviceId,
        syncUpAppName: product
      });
      setThings(await actionCallback());
      addSuccessMessage({
        message: `${thing.currentDevice.id} was successfully deleted.`
      });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const handleAddDevice = async ({
    thingId,
    hardwareId,
    product,
    model,
    lastFourIcc
  }) => {
    try {
      await thingService.linkDevice({
        thingId,
        hardwareId,
        product,
        model,
        lastFourIcc,
        userId,
        syncUpAppName: product
      });
      setThings(await actionCallback());
      addSuccessMessage({ message: `Onboarding started for ${hardwareId}` });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const returnThingObject = ({ formValues }) => {
    switch (product) {
      case APP_MOBILE.PETS:
        return formValues;
      case APP_MOBILE.TRACKER:
        return {
          name: formValues.name,
          notes: formValues.notes,
          thingType: formValues.thingType,
          trackerType: formValues.trackerType,
          locationRateProfile: 'CUSTOM',
          locationRateValueInSeconds: 10,
          deviceLinkOperation: {
            kind: 'REGISTER',
            identifier: formValues.identifier,
            product: 'TRACKER',
            model: 'TAG1'
          }
        };
      case APP_MOBILE.WATCH:
        return {
          name: formValues.name,
          notes: formValues.notes,
          thingType: 'WATCH',
          watchType: 'CHILD',
          locationRateProfile: 'CUSTOM',
          locationRateValueInSeconds: 10,
          relation: formValues.relation,
          gender: formValues.gender,
          birthdate: formValues.birthdate,
          coppa: {
            version: 3,
            modifiedDateTime: getTimestampRangeForLastHours(14 * 24).endTime
          },
          deviceLinkOperation: {
            kind: 'REGISTER',
            identifier: formValues.identifier,
            product: 'KIDSWATCH',
            model: 'WATCH1',
            lastFourIcc: formValues.lastFourIcc
          }
        };
      case APP_MOBILE.FAMILYLOCATION:
        return {
          name: formValues.name,
          notes: formValues.notes,
          thingType: formValues.thingType,
          deviceLinkOperation: null,
          lineId: formValues.lineId,
          displayName: formValues.displayName,
          realtimeNotifications: false
        };
      default:
        return null;
    }
  };

  const openAddThingModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Add Thing - ${thingTypeName}`, {
        userId,
        syncUpAppName: product
      });
      try {
        await thingService.addThing({
          thing: returnThingObject({ formValues }),
          userId,
          syncUpAppName: product
        });
        setThings(await actionCallback());
        addSuccessMessage({
          message: `${formValues.name} was successfully added!`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  const handleRemoveThing = async ({ thingId, name }) => {
    try {
      await thingService.deleteThing({
        thingId,
        userId,
        syncUpAppName: product
      });
      setThings(await actionCallback());
      addSuccessMessage({ message: `${name} was successfully deleted.` });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const deletedThingsCount = useMemo(() => {
    return things?.filter(t => t.status === 'DELETED').length;
  }, [things]);

  const thingAction = () => {
    return (
      <div className={style.thing_actions}>
        {thing && (
          <TmoButton
            onClick={handleManualRefresh}
            type="magenta_secondary"
            tooltip="Refresh"
            icon={<ReactSVG src={refreshIcon} />}
          />
        )}
        <Feature
          isUserDetails
          Products={[
            APP_MOBILE.PETS,
            APP_MOBILE.TRACKER,
            APP_MOBILE.WATCH,
            APP_MOBILE.FAMILYLOCATION
          ]}
        >
          <TmoButton
            className={style.add_thing}
            onClick={openAddThingModal}
            icon={<FaPlus />}
            type="magenta_primary"
          >
            Add New {thingTypeName}
          </TmoButton>
          <AddThing
            thingType={productType}
            fieldSpecs={generateThingsFieldSpecs({
              syncUpAppName: productType
            })}
          />
        </Feature>
      </div>
    );
  };

  return (
    <Fetcher
      action={actionCallback}
      onLoad={handleSetThings}
      key={lastRefresh}
      render={() => (
        <>
          {things?.length < 2 ? (
            <PageHeader title={`${thingTypeName}`}>{thingAction()}</PageHeader>
          ) : (
            <div className={style.heading}>
              <div className={style.thing_details}>
                <div className={style.thing_number}>
                  <TmoH2 flush className={style.label}>
                    {activeThings.length > 1 ? 'Select' : ''} {thingTypeName}
                  </TmoH2>
                  <div className={style.subtitle}>
                    {things?.length} {thingTypeName.toLowerCase()} are in this
                    list
                    {deletedThingsCount > 0 && (
                      <small className={style.heading_deleted_things}>
                        (*Not displaying <strong>{deletedThingsCount}</strong>{' '}
                        deleted {thingTypeName})
                      </small>
                    )}
                  </div>
                </div>
                <div className={style.custom_dropdown}>
                  {hasValue(activeThings) && activeThings.length > 1 && (
                    <TmoDropDownSelect
                      optionsList={activeThings?.map(o => ({
                        key: o.id,
                        value: o.name
                      }))}
                      onChange={setCurrentThingId}
                      renderIcon={<IoIosArrowDown />}
                      defaultSelectedValue={
                        activeThings &&
                        activeThings.map(o => ({
                          key: o.id,
                          value: o.name
                        }))[0]
                      }
                    />
                  )}
                </div>
              </div>
              {thingAction()}
            </div>
          )}
          {thing ? (
            <Thing
              thing={thing}
              onAddDevice={handleAddDevice}
              onRemoveDevice={handleRemoveDevice}
              onRemoveThing={handleRemoveThing}
              productType={productType}
            />
          ) : (
            <div className={style.no_things}>
              No <strong>{thingTypeName}</strong> found
            </div>
          )}
        </>
      )}
    />
  );
}

export default withModal(Things);
