import React from 'react';
import classNames from 'classnames';

import { pluralize } from '../../utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import { getMediaPriorityName } from '../../utils/mediaUtils';

import gridStyle from '../ItemDetailList/ItemDetailList.module.css';
import style from './SingleAssetFirmware.module.css';

function SingleAssetFirmware({ asset, isFirmware, updateConfigDetails }) {
  const {
    appliesTo,
    priority,
    fileName,
    groups = {},
    isActive,
    hash,
    metrics,
    targetVersion,
    url,
    product
  } = asset;
  const { Total, Queued } = metrics || {};
  const groupLabel = pluralize({
    string: 'Group',
    count: Object.entries(groups).length
  });

  return (
    <div
      className={classNames(
        style.container,
        updateConfigDetails && style.group_details_container
      )}
    >
      <ItemDetailList
        rowSet={[
          {
            [groupLabel]:
              Object.entries(groups).length > 0
                ? Object.values(groups).join(', ')
                : 'Not Available'
          },
          {
            packageName: fileName ? fileName : 'Not Available',
            priority: isFirmware ? priority : getMediaPriorityName(priority)
          },
          { targetVersion: targetVersion, appliesTo: appliesTo }
        ]}
      />
      {!isFirmware && <ItemDetailList rowSet={[{ product, isActive }]} />}

      <div className={classNames(gridStyle.row, style.row)}>
        <label className={gridStyle.label}>Asset URL</label>
        <div className={classNames(gridStyle.value, style.value)} title={url}>
          {url ? url : 'Not Available'}
        </div>
      </div>
      <div className={classNames(gridStyle.row, style.row)}>
        <label className={gridStyle.label}>Hash</label>
        <div className={classNames(gridStyle.value, style.value)} title={hash}>
          {hash ? hash : 'Not Available'}
        </div>
      </div>
      <ItemDetailList
        rowSet={[
          {
            deviceMetricsTotal: Total ? Total : 'Not Available',
            deviceMetricsQueued: Queued ? Queued : 'Not Available'
          }
        ]}
      />
    </div>
  );
}

export default SingleAssetFirmware;
