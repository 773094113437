import React from 'react';
import classNames from 'classnames';
import {
  TmoButtons as TmoButton,
  TmoSelect,
  withModal,
  FormModal,
  TmoInput
} from 'tmo-bps-syncup-web-component-lib';
import { FaPlus } from 'react-icons/all';

import Feature from '../../featureWrappers/Feature';
import { APP_MOBILE } from '../../../utils/app_constants';
import { DEVICE_MODELS } from '../../../utils/deviceConsts';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';

import style from './NoDeviceOnThing.module.css';

const addDeviceFieldSpecs = [
  {
    labelText: 'Device Model',
    fieldName: 'model',
    formComponent: TmoSelect,
    type: 'primary-select',
    selectSpecs: {
      options: Object.keys(DEVICE_MODELS).map(value => ({ value }))
    },
    defaultValue: Object.keys(DEVICE_MODELS)[0]
  },
  {
    labelText: <span>Device IMEI</span>,
    fieldName: 'hardwareId',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    placeholder: 'urn:imei:xxxxxxxxxxxxxxx'
  },
  {
    labelText: <span>ICCID</span>,
    fieldName: 'lastFourIcc',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    placeholder: 'six digits..'
  }
];

function NoDeviceOnThing({
  className,
  thingId,
  thingName,
  onAddDevice,
  openModal
}) {
  const handleOpenDeviceModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      const product = DEVICE_MODELS[formValues.model];
      trackCustomEvents(`Add Device to ${thingName}`, {
        thingId,
        product
      });
      await onAddDevice({ thingId, product, ...formValues });
    }
  };

  return (
    <div className={classNames(style.container, className)}>
      <Feature
        isUserDetails
        Products={[APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH]}
      >
        <TmoButton
          onClick={handleOpenDeviceModal}
          icon={<FaPlus />}
          type="magenta_link"
        >
          Add Device
        </TmoButton>
        <FormModal
          title={`Add Device to ${thingName}`}
          fieldSpecs={addDeviceFieldSpecs}
          modalType={'slide'}
        />
      </Feature>
    </div>
  );
}

export default withModal(NoDeviceOnThing);
