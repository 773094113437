import React from 'react';
import { FaEllipsisV } from 'react-icons/all';
import { TmoCard, RawData, Dropdown } from 'tmo-bps-syncup-web-component-lib';

import style from './SupportGroup.module.css';

function SupportGroup({ data }) {
  return (
    <TmoCard shadow={false} className={style.container}>
      <Dropdown className={style.toolbar} triggerIcon={<FaEllipsisV />}>
        <div className={style.toolbar_item}>
          <RawData
            rawObject={data}
            title="Support Groups Details"
            type="Support Groups"
            usePortal={false}
          />
        </div>
      </Dropdown>
      <div className={style.container} key={data.start}>
        <div className={style.row}>
          <label className={style.label}>
            Type:
            <span> {data.group.recipientType}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Id:
            <span> {data.group.id}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Name:
            <span> {data.group.targetName}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Member Type:
            <span> {data.member.recipientType}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Member Id:
            <span> {data.member.id}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Name:
            <span>
              {' '}
              {data.member.firstName} {data.member.lastName}
            </span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Target Name:
            <span> {data.member.targetName}</span>
          </label>
        </div>

        {/* <div className={classNames(style.row, style.action)}>
          <RawData
            rawObject={contact}
            title={`Full User Object for ${contact.firstName}`}
            className={style.object}
          />
        </div> */}
      </div>
    </TmoCard>
  );
}

export default SupportGroup;
