import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoButtons as TmoButton,
  withModal,
  ConfirmationModalV2
} from 'tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import userService from '../../services/userService';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './DeleteSchoolMode.module.css';
import deleteIcon from '../../static/assets/icons/action/delete.svg';

function DeleteSchoolMode({ schoolMode, openModal, forceRefresh, isListView }) {
  const { id, displayName } = schoolMode;

  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const handleRemoveServiceClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete School Mode`, {
        userId: userId,
        scheduledTaskId: id,
        syncUpAppName: product
      });
      try {
        await userService.deleteSchoolModeDetails({
          userId: userId,
          scheduledTaskId: id,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Successfully deleted roadside service`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <>
      <TmoButton
        onClick={handleRemoveServiceClicked}
        type="magenta_secondary"
        icon={<ReactSVG src={deleteIcon} />}
        tooltip="Delete"
        className={isListView && style.show_icon}
        position={isListView ? 'left' : 'bottom'}
      />
      <ConfirmationModalV2
        message={`Are you sure you want to remove school mode ${displayName}?`}
        title="Confirm Service Removal"
        acceptButtonText="Yes,Delete"
        type="danger"
        usePortal={false}
        confirmInput
        modalType="delete"
        confirmText="delete"
      />
    </>
  );
}

export default withModal(DeleteSchoolMode);
