import { useState } from 'react';
import { FETCH_STATE } from '../utils/app_constants';

function useManualFetcher({
  action,
  onLoad = () => {},
  onComplete = () => {},
  onError = () => {}
}) {
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [error, setError] = useState();

  const actionTrigger = async () => {
    try {
      setError();
      setFetchState(FETCH_STATE.LOADING);
      onLoad(await action());
      setFetchState(FETCH_STATE.COMPLETE);
    } catch (e) {
      setError(e);
      onError(e);
      setFetchState(FETCH_STATE.ERROR);
    }
    onComplete();
  };

  const resetFetchState = () => {
    setError();
    setFetchState(FETCH_STATE.IDLE);
  };

  return [fetchState, actionTrigger, error, resetFetchState];
}

export default useManualFetcher;
