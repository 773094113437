import React from 'react';
import { ContentContainer } from 'tmo-bps-syncup-web-component-lib';

import Dashboard from './Dashboard';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

const QADashboardPage = () => {
  return (
    <ContentContainer>
      <PageHeader title={'QA Dashboard'}></PageHeader>
      <Dashboard />
    </ContentContainer>
  );
};
export default QADashboardPage;
