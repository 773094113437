import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Route, Switch, withRouter } from 'react-router-dom';
import {
  ContentContainer,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import {
  ERROR_SCREEN_MESSAGE,
  STORAGE_KEYS,
  STORAGE_TYPES,
  SUA_PAGES
} from 'utils/app_constants';
import { storageRead, storageWrite } from 'utils/storageUtils';
import Error from 'pages/Error';
import ErrorWithImage from '../components/Error/Error';
import UserPage from 'pages/users/UserPage';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import { hasValue } from 'utils/helper_functions';
import ProductIcon from 'components/uiHelpers/ProductIcon';

import style from './UserSearchPage.module.css';
import { ReactSVG } from 'react-svg';
import close from '../static/assets/icons/close.svg';
import no_data_user from '../static/assets/icons/no_data_users.svg';

function UserSearchPage({ location, history }) {
  const { selectedTab, setSelectedTab } = useContext(ActiveUserTabContext);
  const searchUrl = '/search';
  const sessionData = storageRead({
    key: STORAGE_KEYS.USER_TABS,
    storageType: STORAGE_TYPES.SESSION
  });
  const [usersTab, setUserTab] = useState(
    (sessionData && JSON.parse(sessionData)) || []
  );
  useEffect(() => {
    let pageUrl;
    if (selectedTab) {
      if (
        usersTab &&
        !(
          usersTab.filter(
            u =>
              u.userId === selectedTab.userId &&
              u.product === selectedTab.product
          ).length > 0
        )
      ) {
        setUserTab([...usersTab, selectedTab]);
      }
      let tempSessionData = storageRead({
        key: STORAGE_KEYS.USER_TABS,
        storageType: STORAGE_TYPES.SESSION
      });
      let tempData = JSON.parse(tempSessionData);
      let filteredData = tempData.filter(
        e =>
          e.userId === selectedTab.userId && e.product === selectedTab.product
      );
      if (hasValue(filteredData)) {
        pageUrl = filteredData[0].url;
      } else {
        pageUrl = selectedTab.url;
      }
    } else {
      pageUrl = searchUrl;
    }
    history.push(pageUrl);
  }, [selectedTab, history, usersTab]);

  useEffect(() => {
    if (hasValue(usersTab) && !selectedTab) {
      let filteredData = usersTab.filter(
        e => location.pathname.includes(e.userId) && e.selected
      );
      setSelectedTab(filteredData[0]);
    }
    let tempData = sessionData && JSON.parse(sessionData);
    let updatedItems = [];
    usersTab &&
      usersTab.forEach(tab => {
        let savedTab = tempData.filter(
          x => x.userId === tab.userId && x.product === tab.product
        );
        if (hasValue(savedTab)) {
          tab.url = savedTab[0].url;
        }
        updatedItems = [...updatedItems, tab];
      });
    storageWrite({
      key: STORAGE_KEYS.USER_TABS,
      value: JSON.stringify(updatedItems),
      storageType: STORAGE_TYPES.SESSION
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersTab]);

  const removeAllTabs = () => {
    setUserTab([]);
    setSelectedTab(null);
  };
  const handleCloseTab = (user = selectedTab, index) => {
    if (index === undefined) {
      let tempUser = usersTab.filter(
        x => x.userId === user.userId && x.product === user.product
      )[0];
      index = usersTab.indexOf(tempUser);
    }

    let filtered = [...usersTab];
    filtered.splice(index, 1);
    setUserTab(filtered);
    if (hasValue(filtered)) {
      if (
        selectedTab &&
        selectedTab.userId === user.userId &&
        selectedTab.product === user.product
      ) {
        let i = !index ? index : index - 1;
        setSelectedTab(filtered[i]);
      }
    } else {
      setSelectedTab(null);
    }
  };

  return (
    <ContentContainer className={style.container}>
      {hasValue(usersTab) && (
        <div className={style.tab_container}>
          <div className={style.tabs}>
            {usersTab.map((tab, index) => (
              /* location.pathname.includes(`/search/users/${tab.userId}`)*/
              <div
                key={`${tab.userId}-${index}`}
                title={tab.name}
                className={classNames(
                  style.link,
                  selectedTab?.userId === tab.userId &&
                    selectedTab?.product === tab.product
                    ? style.link_active
                    : null
                )}
              >
                <div
                  className={style.title}
                  onClick={() => {
                    let temp = { ...tab };
                    setSelectedTab(temp);
                  }}
                >
                  <ProductIcon product={tab.product} />
                  {tab.name}
                </div>
                <ReactSVG
                  onClick={() => handleCloseTab(tab, index)}
                  className={style.close_icon}
                  src={close}
                />
              </div>
            ))}
          </div>
          {usersTab && usersTab.length > 1 && (
            <TmoButton
              className={style.close_tabs_button}
              type="magenta_link"
              onClick={() => removeAllTabs()}
            >
              Close All Tabs
            </TmoButton>
          )}
        </div>
      )}
      <Switch>
        <Route
          exact
          path={searchUrl}
          render={() => (
            <ErrorWithImage
              heading={'No User Selected'}
              message={'Select a user to view details.'}
              icon={no_data_user}
            />
          )}
        />

        {selectedTab && (
          <Route
            path={SUA_PAGES.USER.path}
            render={() => <UserPage onTabClose={handleCloseTab} />}
          />
        )}
        <Route
          render={() => (
            <Error
              heading={ERROR_SCREEN_MESSAGE.PAGE_NOT_FOUND.heading}
              message={ERROR_SCREEN_MESSAGE.PAGE_NOT_FOUND.message}
            />
          )}
        />
      </Switch>
    </ContentContainer>
  );
}
export default withRouter(UserSearchPage);
