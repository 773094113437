import React from 'react';

import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import ContentContainer from '../../components/uiHelpers/ContentContainer';
import style from './CommercialTrackerUserManagement.module.css';

function CommercialTrackerUserManagement({ match: { url } }) {
  return (
    <div className={style.container}>
      <Authorized
        roles={[SUA_ROLES.ADMIN, SUA_ROLES.TAG_COMMERCIAL_USER]}
        willShowErrorModal
      >
        <div className={style.overview}>
          <ContentContainer>this is User Management Page</ContentContainer>
        </div>
      </Authorized>
    </div>
  );
}

export default CommercialTrackerUserManagement;
