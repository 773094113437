import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import {
  TmoFieldset,
  TmoButtons,
  TmoTextarea
} from 'tmo-bps-syncup-web-component-lib';

import withModal from '../../../modal/withModal';
import iotFirmwareService from '../../../../services/iotFirmwareService';
import StatusContext from '../../../../context/StatusContext';
import SyncUpProductContext from '../../../../context/SyncUpProductContext';
import useVisible from '../../../../hooks/useVisible';
import { trackCustomEvents } from '../../../../utils/appInsight_analytics';
import TmoSvg from '../../../uiHelpers/TmoSvg';

import style from '../Groups.module.css';
import update from '../../../../static/assets/icons/check.svg';

function AddDevices({ group, onAdd }) {
  const [input, setInput] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const { visible, setVisible } = useVisible(false);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const { name, id } = group;

  const onAddDevices = async () => {
    if (input) {
      const hardware = input
        ?.split(/\n/g)
        .join(',')
        .split(',');
      trackCustomEvents(`Add Devices`, {
        groupId: id,
        hardwareIds: hardware
      });
      try {
        setVisible(true);
        await iotFirmwareService.addDevicesToFirmwareGroup({
          groupId: id,
          body: {
            hardwareIds: hardware
          },
          product: syncUpProductContext.syncUpAppName
        });
        await onAdd();
        setVisible(false);
        addSuccessMessage({
          message: `Successfully added devices to group: ${name}`
        });
      } catch (error) {
        setVisible(false);
        addErrorMessage({ error });
      }
    }
  };

  return (
    <>
      {!visible ? (
        <form>
          <TmoFieldset className={style.container}>
            <div className={style.form_container}>
              <label className={style.label}>Hardware Id's </label>
              <TmoTextarea
                onChange={setInput}
                className={style.textarea}
                rows="5"
                name="hardwareIds"
                value={input}
                placeholder="Comma separated device ID's"
              />
            </div>
            <div className={style.add_device_action}>
              <TmoButtons
                type="magenta_primary"
                onClick={onAddDevices}
                disabled={!input}
                icon={<ReactSVG src={update} className={style.icon} />}
              >
                Add Devices
              </TmoButtons>
            </div>
          </TmoFieldset>
        </form>
      ) : (
        <TmoSvg
          className={classNames(
            style.spinner,
            visible && style.spinner_deleting
          )}
          svgId="loading-spinner"
        />
      )}
    </>
  );
}

export default withModal(AddDevices);
