import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const logoutRepository = {
  invalidateToken: async () => {
    const {
      resources,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.logout,
      basePath: basePath.platform,
      httpMethod: httpMethods.GET
    });
  },
  logOutUser: async ({ responseStatus = false, idToken }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.logout,
      basePath: basePath.platform,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.authorization.key]: idToken,
        [headers.subscriptionKey.key]: headers.subscriptionKey.value
      },
      ignoreDefaultHeaders: true
    });
  }
};

export default logoutRepository;
