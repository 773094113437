export const alertListDurationData = {
  Today: [
    {
      site: 'Bellevue Golf Course',
      isOpen: true,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '10:45 am'
    },
    {
      site: 'Sahalee Country Club',
      isOpen: false,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '08:45 am'
    },
    {
      site: 'Bellevue Golf Course',
      isOpen: true,
      title:
        'Golf cart #37 and golf cart #1 are inside safe zone. Deactivating tracking',
      date: '04/22/2020',
      time: '08:40 am'
    }
  ],
  ThisWeek: [
    {
      site: 'Willows Run Golf Course',
      isOpen: false,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '07:45 am'
    },
    {
      site: 'Orlando World Centre',
      isOpen: true,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '12:11 pm'
    },
    {
      site: 'Falcon Fire Golf Course',
      isOpen: false,
      title: 'Blanco entered safe zone',
      date: '04/27/2020',
      time: '09:16 am'
    },
    {
      site: 'Bellevue Golf Course',
      isOpen: true,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '10:45 am'
    },
    {
      site: 'Sahalee Country Club',
      isOpen: false,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '08:45 am'
    },
    {
      site: 'Bellevue Golf Course',
      isOpen: true,
      title:
        'Golf cart #37 and golf cart #1 are inside safe zone. Deactivating tracking',
      date: '04/22/2020',
      time: '08:40 am'
    },
    {
      site: 'Willows Run Golf Course',
      isOpen: false,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '07:45 am'
    }
  ],
  LastWeek: [
    {
      site: 'Bellevue Golf Course',
      isOpen: true,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '10:45 am'
    },
    {
      site: 'Sahalee Country Club',
      isOpen: false,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '08:45 am'
    },
    {
      site: 'Bellevue Golf Course',
      isOpen: true,
      title:
        'Golf cart #37 and golf cart #1 are inside safe zone. Deactivating tracking',
      date: '04/22/2020',
      time: '08:40 am'
    },
    {
      site: 'Willows Run Golf Course',
      isOpen: false,
      title:
        'Golf cart #37 and golf cart #1 left safe zone. Active tracking initiated',
      date: '04/22/2020',
      time: '07:45 am'
    }
  ]
};

export const DURATION_TYPES = {
  TODAY: 'Today',
  THIS_WEEK: 'ThisWeek',
  LAST_WEEK: 'LastWeek'
};

export const tagLocations = [
  {
    lat: 47.591103901368946,
    lng: -122.28524455501115,
    device: 'smart lock',
    name: 'Rm 104 Door Lock',
    online: false,
    alerts: {
      type: 'offline',
      text: 'The smart lock at Rm 104 is offline.'
    }
  },
  { lat: 47.614253050874666, lng: -122.20971354915177 },
  { lat: 47.62628656040036, lng: -122.1781278557924 },
  {
    lat: 47.60869818921477,
    lng: -122.19598063899552,
    device: 'smart lock',
    name: 'Rm 225 Door Lock',
    online: true,
    alerts: {
      type: 'unlocked',
      text: 'The smart lock at Rm 225 has been unlocked for 45 minutes.'
    }
  },
  { lat: 47.65867070443881, lng: -122.11083659602677 },
  { lat: 47.648494993782236, lng: -122.19323405696427 },
  { lat: 47.63831730023258, lng: -122.22893962337052 },
  { lat: 47.63091409579667, lng: -122.14791545344865 },
  { lat: 47.609624040465235, lng: -122.15340861751115 },
  {
    lat: 47.606846437544846,
    lng: -122.19323405696427,
    device: 'smart lock',
    name: 'Rm 309 Door Lock',
    online: false,
    alerts: {
      type: 'low battery',
      text: 'The smart lock at Rm 309 has 12% battery left.'
    }
  },
  { lat: 47.62998862149455, lng: -122.22619304133927 },
  { lat: 47.65959567072096, lng: -122.19323405696427 },
  { lat: 47.64664465153576, lng: -122.13006267024552 },
  { lat: 47.62628656040036, lng: -122.15478190852677 },
  { lat: 47.619807322517985, lng: -122.20147380305802 },
  {
    lat: 47.661445554124406,
    lng: -122.1946073479799,
    device: 'smart lock',
    name: 'Rm 104 Door Lock',
    online: false,
    alerts: {
      type: 'offline',
      text: 'The smart lock at Rm 104 is offline.'
    }
  },
  {
    lat: 47.689185939705716,
    lng: -122.11907634212052,
    device: 'smart lock',
    name: 'Rm 104 Door Lock',
    online: false,
    alerts: {
      type: 'offline',
      text: 'The smart lock at Rm 104 is offline.'
    }
  },
  { lat: 47.699353719446734, lng: -122.04491862727677 },
  { lat: 47.625361004155145, lng: -122.04903850032365 },
  { lat: 47.59666063148373, lng: -122.03393229915177 },
  { lat: 47.555897574376225, lng: -122.03118571712052 },
  { lat: 47.55126340080615, lng: -122.11495646907365 },
  { lat: 47.584620303797394, lng: -122.14928874446427 },
  { lat: 47.60129078917986, lng: -122.1891141839174 }
];

export const commercialLocationTrackers = [
  {
    lat: 33.503872,
    lng: -82.02443,
    offline: false,
    deviceType: 'location tracker',
    name: 'Golf cart # 45',
    batteryLevel: 42,
    status: 'Locked',
    lastUpdate: 'Today 08:45 am'
  },
  {
    lat: 33.502315,
    lng: -82.024666,
    offline: false,
    deviceType: 'location tracker',
    name: 'Golf cart # 60',
    batteryLevel: 63,
    status: 'Active',
    lastUpdate: 'Today 09:45 am'
  },
  {
    lat: 33.500795,
    lng: -82.023872,
    offline: true,
    deviceType: 'location tracker',
    name: 'Golf cart # 39',
    batteryLevel: 25,
    status: 'Offline',
    lastUpdate: 'Yesterday 09:45 am'
  },
  {
    lat: 33.500455,
    lng: -82.022885,
    offline: false,
    deviceType: 'location tracker',
    name: 'Golf cart # 69',
    batteryLevel: 100,
    status: 'Maintenance',
    lastUpdate: 'Yesterday 08:45 am'
  },
  {
    lat: 33.500365,
    lng: -82.0284,
    offline: false,
    deviceType: 'location tracker',
    name: 'Golf cart # 31',
    batteryLevel: 5,
    status: 'Active',
    lastUpdate: 'Yesterday 07:45 am'
  },
  {
    lat: 33.499095,
    lng: -82.025889,
    offline: true,
    deviceType: 'location tracker',
    name: 'Golf cart # 67',
    batteryLevel: 16,
    status: 'Active',
    lastUpdate: 'Yesterday 06:45 am'
  },
  {
    lat: 33.499864,
    lng: -82.021555,
    offline: false,
    deviceType: 'location tracker',
    name: 'Golf cart # 19',
    batteryLevel: 55,
    status: 'Locked',
    lastUpdate: 'Yesterday 05:45 am'
  },
  {
    lat: 33.499264,
    lng: -82.028555,
    offline: false,
    deviceType: 'location tracker',
    name: 'Golf cart # 66',
    batteryLevel: 53,
    status: 'Offline',
    lastUpdate: 'Yesterday 04:45 am'
  },
  {
    lat: 33.499861,
    lng: -82.021551,
    offline: false,
    deviceType: 'location tracker',
    name: 'Golf cart # 29',
    batteryLevel: 21,
    status: 'Active',
    lastUpdate: 'Yesterday 03:45 am'
  },
  {
    lat: 33.493864,
    lng: -82.021255,
    offline: true,
    deviceType: 'location tracker',
    name: 'Golf cart # 05',
    batteryLevel: 75,
    status: 'Active',
    lastUpdate: 'Yesterday 02:45 am'
  }
];

export const commercialDoorLocks = [
  {
    name: `Tyler's Office Door`,
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'office #3'
    },
    locked: false,
    batteryLevel: 98,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  },
  {
    name: 'War Room Door',
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'war room'
    },
    locked: true,
    batteryLevel: 75,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  },
  {
    name: 'Server Room',
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'server room'
    },
    locked: false,
    batteryLevel: 26,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  },
  {
    name: 'Document Storage Room',
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'empty office'
    },
    locked: true,
    batteryLevel: 14,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  },
  {
    name: 'Room 102',
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'empty office'
    },
    locked: true,
    batteryLevel: 3,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  },
  {
    name: 'Room 104',
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'empty office'
    },
    locked: false,
    batteryLevel: 36,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  },
  {
    name: 'Room 106',
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'empty office'
    },
    locked: true,
    batteryLevel: 81,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  },
  {
    name: 'Room 108',
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'empty office'
    },
    locked: false,
    batteryLevel: 56,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  }
];

export const commercialDeviceChartData = {
  chart: {
    type: 'pie',
    height: 300,
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false
  },
  exporting: {
    enabled: false
  },
  title: {
    text: ''
  },
  legend: {
    verticalAlign: 'bottom',
    layout: 'vertical',
    itemMarginBottom: 10
  },
  plotOptions: {
    pie: {
      innerSize: '65%',
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    }
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: 'Status',
      colorByPoint: true,
      data: [
        {
          name: 'In Service',
          y: 312,
          drilldown: 'In Service',
          color: '#20cb7e'
        },
        {
          name: 'Out of Service',
          y: 40,
          drilldown: 'Out of Service',
          color: '#ff5438'
        },
        {
          name: 'Needs Firmware Update',
          y: 48,
          drilldown: 'Needs Firmware Update',
          color: '#ffc830'
        }
      ]
    }
  ]
};

export const commercialAccessPoints = [
  {
    name: 'TMO-103-1051',
    deviceType: 'access point',
    batteryLevel: 75,
    status: 'Locked',
    lastUpdate: 'Today 08:45 am'
  }
];
