import React from 'react';
import { withModal } from 'tmo-bps-syncup-web-component-lib';

import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './HardwareDetails.module.css';

function HardwareDetailsGridItem({
  list: {
    id,
    iccid,
    imeival,
    blemac,
    psk,
    manufacturingGroup,
    wifimac,
    imsi,
    msisdn,
    ssid,
    hardwareRevision,
    product,
    status
  }
}) {
  return (
    <>
      <div className={style.container}>
        <ItemDetailList
          items={{
            id,
            iccid,
            imeival,
            blemac,
            psk,
            manufacturingGroup,
            wifimac,
            imsi,
            msisdn,
            ssid,
            hardwareRevision,
            product,
            status
          }}
        />
      </div>
    </>
  );
}

export default withModal(HardwareDetailsGridItem);
