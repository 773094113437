import React from 'react';
import { withModal, RawData } from 'tmo-bps-syncup-web-component-lib';

import ThingEventMap from './ThingEventMap';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './ThingEvent.module.css';

function ThingEvent({ event }) {
  return (
    <div className={style.container}>
      <div className={style.mapContainer}>
        <ThingEventMap thingEvent={event} />
        <RawData
          className={style.raw_data}
          rawObject={event}
          title="Full Event Details"
          tooltip="Full Event Details"
          type=" "
          modalType={'slide'}
          usePortal={false}
          buttonType="magenta_primary_icon"
        />
      </div>
      <div className={style.details}>
        <ItemDetailList
          rowSet={[
            { 'Event Type ': event.eventType },
            { 'Event Address': event.eventAddress },
            {
              'Event Latitude/Longitude ': `${event.eventLatitude}; ${event.eventLongitude}`
            },
            { 'Event at ': event.eventAt }
          ]}
        />
      </div>
    </div>
  );
}

export default withModal(ThingEvent);
