import {
  HEALTH_CHECK_DEVICE_REQUEST,
  HEALTH_CHECK_DEVICE_SUCCESS,
  HEALTH_CHECK_DEVICE_ERROR,
  HEALTH_CHECK_DEVICE_INITIAL_STATE
} from '../utils/app_constants';

const HEALTH_CHECK_INITIAL_STATE = {
  data: [],
  isLoading: false,
  isError: false
};

export const healthCheckReducer = (
  state = HEALTH_CHECK_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case HEALTH_CHECK_DEVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: []
      };
    case HEALTH_CHECK_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case HEALTH_CHECK_DEVICE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload
      };
    case HEALTH_CHECK_DEVICE_INITIAL_STATE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: []
      };
    default:
      return state;
  }
};
