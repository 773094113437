import React from 'react';
import { withRouter } from 'react-router-dom';

import { TmoCard, withModal, RawData } from 'tmo-bps-syncup-web-component-lib';
import { prettifyCamelCase } from 'utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './LocationGroupUsers.module.css';

function PhoneLocationGroupVBList({
  virtualBoundary,
  openModal,
  history,
  forceRefresh
}) {
  const {
    id,
    groupId,
    longitude,
    latitude,
    radius,
    isEnabledForMember,
    address,
    addressName,
    displayZoomLevel,
    name
  } = virtualBoundary;

  return (
    <TmoCard shadow={false} key={id}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <div className={style.avatar_details}>
            <label className={style.display_name}>
              {prettifyCamelCase(name?.toLowerCase())}
            </label>
          </div>
        </div>
        <div className={style.action_controls}>
          <RawData
            rawObject={virtualBoundary}
            tooltip="VB Object"
            title={`VB Object for ${name}`}
            className={style.object}
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary_icon"
            type=" "
          />
        </div>
      </div>
      <div className={style.content} key={id}>
        <ItemDetailList
          rowSet={[
            { Id: id },
            { groupId: groupId },
            { Name: name },
            { longitude: longitude, latitude: latitude },
            { radius: radius },
            { isEnabledForMember: isEnabledForMember },
            { address: address },
            { addressName: addressName },
            { displayZoomLevel: displayZoomLevel }
          ]}
        />
      </div>
    </TmoCard>
  );
}

export default withModal(withRouter(PhoneLocationGroupVBList));
