import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TmoDropDownSelect, TmoInput } from 'tmo-bps-syncup-web-component-lib';

import AssetManager from '../assetManager/AssetManager';
import { MEDIA_TYPES } from '../../utils/mediaConsts';
import mediaService from '../../services/mediaService';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { getRandomString } from '../../utils/stringUtils';
import { IOT_ASSET_TYPES } from '../../utils/app_constants';
import { IoIosArrowDown } from 'react-icons/io';

const formFieldSpecs = [
  {
    labelText: 'Media Config Name',
    fieldName: 'config_name',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    placeholder: 'Media configuration name'
  },
  {
    labelText: 'Group Id/s',
    fieldName: 'group_id',
    formComponent: TmoInput,
    inputType: 'primary',
    required: false,
    placeholder: 'Assign config to device groups'
  },
  {
    labelText: 'Device Ids',
    fieldName: 'device_id',
    formComponent: TmoInput,
    inputType: 'primary',
    required: false,
    placeholder: 'Assign config to devices by id'
  },
  {
    labelText: 'Media Type',
    fieldName: 'media_type',
    formComponent: TmoDropDownSelect,
    required: true,
    optionsList: Object.keys(MEDIA_TYPES).map(k => ({
      key: k,
      value: k
    })),
    defaultSelectedValue: Object.keys(MEDIA_TYPES).map(k => ({
      key: k,
      value: k
    }))[0],
    defaultValue: Object.keys(MEDIA_TYPES)[0],
    renderIcon: <IoIosArrowDown />
  }
];

function MediaManager() {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const [generatedConfigName, setGeneratedConfigName] = useState();
  const filters = ['configName', 'deviceGroup', 'type', 'fileType'];
  const mediaType = IOT_ASSET_TYPES.MEDIA;
  const requestBody = formValues => ({
    deviceId: formValues.device_id || '',
    deviceGroup: formValues.group_id || ''
  });

  const actionCallback = useCallback(() => {
    return mediaService.getAllMedia({
      syncUpAppName: syncUpProductContext.syncUpAppName
    });
  }, [syncUpProductContext]);

  useEffect(() => {
    setGeneratedConfigName(`${mediaType.toLowerCase()}_${getRandomString()}`);
  }, [mediaType]);

  return (
    <AssetManager
      assetFormFieldSpecs={formFieldSpecs}
      mediaType={mediaType}
      filterKeys={filters}
      requestBody={requestBody}
      action={actionCallback}
      configName={generatedConfigName}
    />
  );
}

export default MediaManager;
