import React from 'react';
import { withRouter } from 'react-router-dom';
import { withModal } from 'tmo-bps-syncup-web-component-lib';

import ItemDetailsModal from 'components/ItemDetailsModal';
import Feature from '../../featureWrappers/Feature';
import { APP_MOBILE, GRID } from '../../../utils/app_constants';
import TimeDisplay from '../../uiHelpers/TimeDisplay';
import ItemDetailList from '../../ItemDetailList/ItemDetailList';

import style from './PetDetails.module.css';

function ThingDetails({
  thing: {
    id,
    name,
    status,
    thingType,
    trackerType,
    createdDateTime,
    modifiedDateTime,
    safezone,
    dailySteps,
    goals,
    enableLightSensor,
    enableTrackMode,
    liveLocationRateValue,
    locationRateProfile,
    locationRateValueInSeconds
  }
}) {
  return (
    <div className={style.container}>
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          id,
          status,
          thingType: trackerType ? `${trackerType} ${thingType}` : thingType,
          safezone
        }}
      />
      <Feature isUserDetails Products={[APP_MOBILE.WATCH]}>
        <ItemDetailList
          className={GRID.COLUMN_FORTH}
          items={{
            dailySteps,
            dailyActivityGoal: goals ? goals.dailyActivityGoal : ''
          }}
        />
      </Feature>
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          enableLightSensor,
          enableTrackMode,
          liveLocationRateValue,
          locationRateProfile,
          locationRateValueInSeconds
        }}
      />
      <ItemDetailsModal
        label={`Created/Modified Dates`}
        buttonText={'View Dates'}
      >
        <TimeDisplay
          title="Created Date"
          timestamp={createdDateTime}
          type="primary_time_display"
        />
        <div className={style.separator}></div>
        <TimeDisplay
          title="Modified Date"
          timestamp={modifiedDateTime}
          type="primary_time_display"
        />
      </ItemDetailsModal>
    </div>
  );
}

export default withRouter(withModal(ThingDetails));
