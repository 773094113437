import React from 'react';
import classNames from 'classnames';

import style from './TmoSvg.module.css';

function TmoSvg({ className, svgId, onClick }) {
  return (
    <svg className={classNames(style.container, className)} onClick={onClick}>
      <use href={`#${svgId}`} xlinkHref={`#${svgId}`} />
    </svg>
  );
}

export default TmoSvg;
