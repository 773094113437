import React from 'react';
import DriveLiteControls from './DriveLiteControls';
import Poc from '../Poc';

import tmologo from '../../../static/assets/TMO_Logo_Drive.png';

import './DriveLite.module.css';

function DriveLite({ className }) {
  return (
    <Poc productId="drivelite" logo={tmologo} controls={DriveLiteControls} />
  );
}

export default DriveLite;
