import React, { useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { TmoButtons } from 'tmo-bps-syncup-web-component-lib';

import iotFirmwareService from '../../../../services/iotFirmwareService';
import SyncUpProductContext from '../../../../context/SyncUpProductContext';
import StatusContext from '../../../../context/StatusContext';
import { trackCustomEvents } from '../../../../utils/appInsight_analytics';

import style from './ManageDevices.module.css';
import deleteIcon from '../../../../static/assets/icons/action/delete.svg';

function DeleteDevice({ group, onRemove, device }) {
  const [deleting, setDeleting] = useState(false);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const { name, id } = group;

  const handleRemoveDevice = async device => {
    try {
      trackCustomEvents(`Remove Devices`, {
        groupId: id,
        hardwareId: device
      });
      setDeleting(true);
      await iotFirmwareService.deleteDeviceFromFirmwareGroup({
        groupId: id,
        body: { hardwareId: device },
        product: syncUpProductContext.syncUpAppName
      });
      await onRemove();
      addSuccessMessage({
        message: `Successfully deleted device ${device} from ${name}`
      });
      setTimeout(async () => {
        setDeleting(false);
      }, 3000);
    } catch (error) {
      setDeleting(false);
      addErrorMessage({ error });
    }
  };

  return (
    <div key={device} className={style.list_item}>
      <span className={style.list_item_name}>{device}</span>
      <TmoButtons
        className={deleting && style.btn_deleting}
        type="magenta_link"
        icon={<ReactSVG src={deleteIcon} />}
        onClick={() => handleRemoveDevice(device)}
      />
    </div>
  );
}

export default DeleteDevice;
