import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'tmo-bps-syncup-web-component-lib';

import LoggedInUserContext from '../../context/LoggedInUserContext';
import ThemeContext from '../../context/ThemeContext';
import PortalFaq from 'pages/portalHelp/PortalFaq';
import { THEMES, SUA_ROLES, SUA_ENVIRONMENTS } from '../../utils/app_constants';
import LeftPanelUser from 'components/leftPanel/LeftPanelUser';
import config from '../../configs/config';
import { getOktaGroups } from 'utils/helper_functions';

import style from './HeaderNew.module.css';
import logo from '../../static/assets/TMO_logo.svg';
import { FaAngleDown } from 'react-icons/fa';

function HeaderNew() {
  const { isAuthenticated, userInfo } = useContext(LoggedInUserContext);
  const { theme } = useContext(ThemeContext);
  const env = config.apiSettings.environment;
  const groups = getOktaGroups(userInfo);
  const appEnvironment = () => {
    return Object.entries(SUA_ENVIRONMENTS)
      .filter(env => !env.includes('local'))
      .map(([k, v], index) => (
        <a
          key={`${k}-${index}`}
          href={
            v === SUA_ENVIRONMENTS.PRODUCTION
              ? 'https://syncup-admin.t-mobile.com/'
              : `https://syncup-admin-${v}.t-mobile.com/`
          }
          className={style.active_item}
        >
          <div className={style.item} key={index}>
            {k}
          </div>
        </a>
      ));
  };

  const triggerText = () => {
    return (
      <>
        <div className={style.dropdown_text}>
          {env === SUA_ENVIRONMENTS.LOCAL
            ? 'DEVELOPMENT'
            : env === SUA_ENVIRONMENTS.DEVELOPMENT
            ? 'DEVELOPMENT'
            : env === SUA_ENVIRONMENTS.STAGING
            ? 'STAGING'
            : env === SUA_ENVIRONMENTS.PRODUCTION
            ? 'PRODUCTION'
            : ''}
        </div>
        <div className={style.dropdown_text_mobile}>
          {env === SUA_ENVIRONMENTS.LOCAL
            ? 'DEV'
            : env === SUA_ENVIRONMENTS.DEVELOPMENT
            ? 'DEV'
            : env === SUA_ENVIRONMENTS.STAGING
            ? 'STG'
            : env === SUA_ENVIRONMENTS.PRODUCTION
            ? 'PROD'
            : ''}
        </div>
      </>
    );
  };

  return (
    isAuthenticated &&
    theme === THEMES.THEME_DEFAULT && (
      <div className={style.wrapper}>
        <div className={style.left_section}>
          <ReactSVG src={logo} className={style.logo} />
        </div>
        <div className={style.right_section}>
          <div className={style.env_wrapper}>
            {!groups?.includes(SUA_ROLES.SUPPLY_CHAIN) && (
              <Dropdown
                className={style.env_dropdown}
                triggerIcon={<FaAngleDown />}
                triggerText={triggerText()}
                ariaLabel={env}
                tabIndex="1"
              >
                <div className={style.sdjkd}>{appEnvironment()}</div>
              </Dropdown>
            )}
          </div>
          <PortalFaq />
          <div className={style.user}>{userInfo && <LeftPanelUser />}</div>
        </div>
      </div>
    )
  );
}

export default withRouter(HeaderNew);
