import React, { useContext, useState } from 'react';

import TmoButton from '../forms/TmoButton';
import ModalV2 from './ModalV2';
import ModalContext from '../../context/ModalContext';
import TmoInput from '../forms/TmoInput';
import LabelWithInput from '../forms/LabelWithInput';

import style from './ConfirmationModalV2.module.css';

const ConfirmationModalV2 = ({
  title,
  message,
  acceptButtonText = 'Accept',
  denyButtonText = 'Cancel',
  confirmInput = false,
  type = 'primary',
  usePortal
}) => {
  const { closeModal } = useContext(ModalContext);
  const [confirmationString, setConfirmationString] = useState();

  const handleAcceptClick = () => {
    closeModal({ isConfirmed: true });
  };

  return (
    <ModalV2 type={type} title={title} portal={usePortal}>
      {message}
      {confirmInput && (
        <div className={style.confirm}>
          <LabelWithInput
            className={style.confirm_input}
            labelText={
              <>
                Type <strong>{acceptButtonText.toUpperCase()}</strong> to
                confirm
              </>
            }
            formComponent={TmoInput}
            value={confirmationString}
            onChange={setConfirmationString}
          />
        </div>
      )}
      <div className={style.button_container}>
        <TmoButton onClick={closeModal} type="secondary">
          {denyButtonText}
        </TmoButton>
        <TmoButton
          disabled={acceptButtonText.toUpperCase() !== confirmationString}
          onClick={handleAcceptClick}
        >
          {acceptButtonText}
        </TmoButton>
      </div>
    </ModalV2>
  );
};

export default ConfirmationModalV2;
