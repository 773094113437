import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import Fetcher from '../../../components/Fetcher';
import commercialTrackerService from '../../../services/commercialTrackerService';
import appConfig from '../../../configs/config';
import { SORT_DIRECTION } from '../../../utils/app_constants';
import InfoBlock from '../InfoBlock';
import TrackersSiteList from './TrackersSiteList';
import TmoButton from '../../forms/TmoButton';
import TrackerDashboardGridHeader from './TrackerDashboardGridHeader';
import DeviceStatus from '../devices/DeviceStatus';
import CommercialMap from '../map/CommercialMap';
import AlertsWidget from './AlertsWidget';
import { getMapCenter } from '../../../utils/mapUtils';
import { sortList } from '../../../utils/helper_functions';

import './TrackerDashboardGrid.css';

const LIST_HEADER_OBJECT_MAPPING = [
  {
    listItemKey: 'site',
    headerDisplayName: 'Site',
    columnClass: 'Heading'
  },
  {
    listItemKey: 'noOfTrackers',
    headerDisplayName: '# OF TRACKERS',
    columnClass: 'Heading'
  },
  {
    listItemKey: 'insideSafeZone',
    headerDisplayName: 'INSIDE SAFE ZONE',
    columnClass: 'Heading'
  }
];

const TABLE_OBJECT_MAPPING = [
  {
    site: <Link to="/commercial-tracker/devices">Bellevue Golf Course</Link>,
    noOfTrackers: '45',
    insideSafeZone: '42'
  },
  {
    site: <Link to="/commercial-tracker/devices">Sahalee Country Club</Link>,
    noOfTrackers: '65',
    insideSafeZone: '63'
  },
  {
    site: <Link to="/commercial-tracker/devices">Willows Run Golf Course</Link>,
    noOfTrackers: '39',
    insideSafeZone: '25'
  }
];

const { googleMapSettings, reactGoogleMaps } = appConfig;

function TrackerDashboardGrid({ dataSet, history }) {
  const mapContainer = (
    <div className="TrackerDashboardGrid-MapView-loadingElement" />
  );

  const [trackerLocations, setTrackerLocations] = useState();
  const [siteList, setSiteList] = useState(TABLE_OBJECT_MAPPING);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);

  const handleOnListSort = ({ listItemKey }) => {
    const sortedSiteList = siteList;
    sortList(sortedSiteList, { listItemKey }, sortDirection);
    sortDirection === SORT_DIRECTION.ASC
      ? setSortDirection(SORT_DIRECTION.DESC)
      : setSortDirection(SORT_DIRECTION.ASC);
    setSiteList(sortedSiteList);
  };

  function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  const prepareTagLocation = () => {
    const uniqueArray = removeDuplicates(trackerLocations, 'deviceId').filter(
      tracker => tracker.latitude & tracker.longitude
    );

    return uniqueArray.map(obj => ({
      lat: obj.latitude,
      lng: obj.longitude,
      deviceId: obj.deviceId
    }));
  };

  return (
    <div className="TrackerDashboardGrid">
      <div className="TrackerDashboardGrid-main">
        <div className="TrackerDashboardGrid-map-container">
          <Fetcher
            action={
              commercialTrackerService.getCoolpadGetFormattedLocationForAllAssets
            }
            onLoad={setTrackerLocations}
            render={() =>
              trackerLocations ? (
                <CommercialMap
                  center={getMapCenter(prepareTagLocation())}
                  zoom={10}
                  places={prepareTagLocation()}
                  googleMapURL={`${reactGoogleMaps.url}${googleMapSettings.apiKey}`}
                  loadingElement={mapContainer}
                  mapElement={mapContainer}
                  containerElement={mapContainer}
                />
              ) : (
                'No Results'
              )
            }
          />
        </div>

        <div className="TrackerDashboardGrid-row">
          <div className="TrackerDashboardGrid-column TrackerDashboardGrid-graph">
            <div className="TrackerDashboardGrid-heading-container ">
              <TrackerDashboardGridHeader title="Devices - Overall Status" />
            </div>
            <DeviceStatus />
          </div>
          <div className="TrackerDashboardGrid-column TrackerDashboardGrid-infoblock">
            <div className="TrackerDashboardGrid-heading-container ">
              <TrackerDashboardGridHeader title="Devices - Trackers" />
            </div>
            <InfoBlock dataSet={dataSet} />
          </div>
        </div>

        <div className="TrackerDashboardGrid-row">
          <div className="TrackerDashboardGrid-column TrackerDashboardGrid-sites">
            <div className="TrackerDashboardGrid-heading-container TrackerDashboardGrid-heading-container-bg">
              <TrackerDashboardGridHeader title="Site List" />
            </div>
            <TrackersSiteList
              headerNameListItemMap={LIST_HEADER_OBJECT_MAPPING}
              listItems={siteList}
              onSort={handleOnListSort}
              sortDirection={sortDirection}
            />
          </div>
        </div>
      </div>

      <div className="TrackerDashboardGrid-right">
        <div className="TrackerDashboardGrid-row">
          <div className="TrackerDashboardGrid-column TrackerDashboardGrid-alerts">
            <AlertsWidget />
            <TmoButton
              onClick={() => history.push(`alerts`)}
              className="TrackerDashboardGrid-alerts-button"
              type="secondary"
            >
              Show More
            </TmoButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TrackerDashboardGrid);
