import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  TmoCard,
  TmoInput,
  withModal,
  TmoSvg,
  LabelWithInput,
  ConfirmationButton
} from 'tmo-bps-syncup-web-component-lib';

import classNames from 'classnames';
import config from '../../configs/config';
import Clipboard from 'components/Clipboard';
import Authorized from '../../auth/Authorized';
import { authClient, getMSALToken } from '../../auth/authClient';
import { SUA_ROLES, IS_AZURE_AUTH } from '../../utils/app_constants';
import StatusContext from '../../context/StatusContext';
import useVisible from '../../hooks/useVisible';
import { getRandomString } from '../../utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import deviceService from 'services/deviceService';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import upload_icon from '../../static/assets/icons/action/upload.svg';

import style from './CoapSupport.module.css';

const {
  schema,
  hostName,
  services,
  resources,
  httpMethods,
  headers,
  basePath
} = config.apiSettings.syncUpPlatform;

const fullUrl = `${schema}://${hostName}${basePath.adminTracker}${services.internal}${resources.coaptrackercommand}`;

const defaultRequestBody =
  '{"commands":[{"setExternalReportTimer":{"priorityConnection":"BLE", "holdoffTimeInms":30000}}], "deviceId":"351521105204929"}';
function CoapSupport({ match: { url }, history, openModal }) {
  const [token, setToken] = useState();
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { visible, setVisible } = useVisible(false);
  const [error, setError] = useState(false);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const [requestBody, setRequestBody] = useState(defaultRequestBody);
  const curlRequest = `curl --location --request POST '${fullUrl}' \
  --header '${headers.subscriptionKey.key}: ${headers.subscriptionKey.value}' \
  --header 'Authorization: ${token}'
  --header 'Content-Type: application/json' \
  --data-raw ${requestBody}`;

  const onCommandChange = val => {
    setRequestBody(val);
    try {
      JSON.parse(val);
      setError(false);
    } catch (error) {
      setError(true);
    }
  };

  const handleExecute = async () => {
    try {
      await deviceService.executeCOAPCommand({
        syncUpAppName: syncUpProductContext.syncUpAppName,
        requestObj: requestBody
      });
      forceRefresh();
      addSuccessMessage({
        message: `Command Sent Successfully`
      });
      setVisible(false);
    } catch (error) {
      setVisible(false);
      addErrorMessage({ error });
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = IS_AZURE_AUTH
        ? await getMSALToken()
        : authClient.getIdToken();
      setToken(token);
    };
    fetchToken();
  }, []);
  return (
    <Authorized
      roles={[SUA_ROLES.ADMIN, SUA_ROLES.MOBILE_APP_DEVS]}
      willShowErrorModal
    >
      <div key={refreshKey}>
        <div className={style.content}>
          <ItemDetailList
            rowSet={[{ Endpoint: httpMethods.POST + ' ' + fullUrl }]}
          />
        </div>
        <TmoCard className={style.container}>
          <div className={style.row}>
            <LabelWithInput
              labelText="Execute Command"
              formComponent={TmoInput}
              value={requestBody}
              required
              onChange={onCommandChange}
            />
            {error && (
              <label className={style.label_error}>
                JSON Format is incorrect
              </label>
            )}

            <ConfirmationButton
              className={style.button}
              buttonType="magenta_secondary"
              handleDeleteClicked={handleExecute}
              modalMessage={<>Are you sure you want to execute command</>}
              modalTitle="Execute Command"
              modalAcceptButtonText={'Yes,Execute'}
              buttonIcon={<ReactSVG src={upload_icon} alt="Execute" />}
              tooltip="Execute"
            />
            {visible && (
              <TmoSvg className={style.spinner} svgId="loading-spinner" />
            )}
          </div>
          <div className={style.row}>
            <label
              className={classNames(style.label, style.clipboard_container)}
            >
              Curl Request
              <Clipboard
                value={curlRequest}
                clipboardSty={style.clipboard}
                buttonPositon="bottom"
              />
            </label>
          </div>
        </TmoCard>
      </div>
    </Authorized>
  );
}

export default withModal(withRouter(CoapSupport));
