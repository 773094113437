import React from 'react';
import classNames from 'classnames';

import style from './TmoH1.module.css';

function TmoH1({ className, flush, children }) {
  return (
    <h1
      className={classNames(style.container, flush && style.flush, className)}
    >
      {children}
    </h1>
  );
}

export default TmoH1;
