import { APP_MOBILE } from 'utils/app_constants';
import config from '../configs/config';
import syncUpPlatformRequest from './syncupPlatformRequest';

const qaRepository = {
  getPipelineData: async ({ responseStatus = false }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.pipelineData,
      basePath: basePath.admin,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: APP_MOBILE.DRIVE
      }
    });
  },
  getBuildFailuresDetails: async ({ buildId, responseStatus = false }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.buildFailuresDetails.replace(':buildId', buildId),
      basePath: basePath.admin,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: APP_MOBILE.DRIVE
      }
    });
  }
};

export default qaRepository;
