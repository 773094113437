import React, { useState, useRef } from 'react';
import {
  withModal,
  RawData,
  DynamicSort,
  FilterModal
} from 'tmo-bps-syncup-web-component-lib';

import ThingTripMap from './ThingTripMap';
import ThingEvent from './ThingEvent';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './ThingTrip.module.css';
import { BsFilterLeft, IoIosArrowDown } from 'react-icons/all';

function ThingTrip({ trip, hideTab }) {
  const {
    startedAddress,
    startedAt,
    endedAddress,
    endedAt,
    startedLatitude,
    startedLongitude,
    endedLatitude,
    endedLongitude,
    duration,
    distance,
    avgSpeed,
    maxSpeed,
    fuelEfficiency,
    isBusiness
  } = trip;

  const [filteredList, setFilteredList] = useState(trip.tripEvents);
  const [sortOptions] = useState([
    { key: 'eventType', value: 'Event Type' },
    { key: 'eventAt', value: 'Event At' }
  ]);
  const [filterOptions] = useState([{ key: 'eventType', value: 'Event Type' }]);
  const sortRef = useRef();

  const handleOnSort = list => {
    setFilteredList(list);
  };
  const handleOnFilter = data => {
    if (data) {
      setFilteredList(data.list);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    }
  };
  return (
    <div className={style.trip_item}>
      <div className={style.map_container}>
        <ThingTripMap thingTrip={trip} />
      </div>
      <PageHeader title={'Trip Details'} tabHeader={true} hideTab={hideTab}>
        <RawData
          className={style.raw_data}
          rawObject={trip}
          title="Full Trip Details"
          tooltip="Full Trip Details"
          type=" "
          modalType={'slide'}
          usePortal={false}
          buttonType="magenta_primary_icon"
        />
      </PageHeader>
      <div className={style.details} key={trip.thingId}>
        <ItemDetailList
          rowSet={[
            {
              'Started Address': startedAddress,
              'Started at': startedAt,
              'Ended Address': endedAddress
            },
            {
              'Ended at': endedAt,
              'Started Latitude/Longitude': `${startedLatitude}; ${startedLongitude}`,
              'Ended Latitude/Longitude': `${endedLatitude}; ${endedLongitude}`
            },
            {
              'Duration ': duration,
              Distance: distance,
              'Average Speed': avgSpeed
            },
            {
              'Maximum Speed ': maxSpeed,
              'Fuel Efficiency': fuelEfficiency,
              'Is Business ': isBusiness
            }
          ]}
        />
      </div>
      <div className={style.event_container} key={trip.id}>
        <PageHeader title={'Trip Events'} tabHeader={true} hideTab={hideTab}>
          <DynamicSort
            ref={sortRef}
            listItems={filteredList}
            optionsList={sortOptions}
            onSort={e => {
              handleOnSort(e);
            }}
            renderIcon={<IoIosArrowDown />}
          />
          <FilterModal
            options={filterOptions}
            originalList={trip.tripEvents}
            listItems={filteredList}
            onFilter={handleOnFilter}
            renderIcon={<BsFilterLeft />}
          />
        </PageHeader>
        <div className={style.column}>
          {!filteredList.length && 'No events found'}
          {filteredList.map(s => (
            <ThingEvent key={s.mapKey || s.id} event={s} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default withModal(ThingTrip);
