import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';

import {
  withModal,
  ConfirmationModalV2,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import faqContentService from '../../services/faqContentService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './DeleteFAQ.module.css';
import deleteIcon from '../../static/assets/icons/action/delete.svg';

function DeleteFAQ({ openModal, title, id, onDataChange }) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const handleRemoveFaq = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Faq`, {
        faqId: id,
        product: syncUpProductContext.syncUpAppName
      });
      try {
        await faqContentService.deleteFAQ({
          faqId: id,
          product: syncUpProductContext.syncUpAppName
        });
        onDataChange();
        addSuccessMessage({
          message: `Successfully deleted FAQ: ${title}`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };
  const deleteMessage = (
    <>
      Are you sure you want to remove FAQ: <strong>{title}</strong>
    </>
  );
  return (
    <>
      <TmoButton
        type="magenta_secondary"
        icon={<ReactSVG src={deleteIcon} />}
        className={style.delete_btn}
        onClick={handleRemoveFaq}
      >
        Delete FAQ
      </TmoButton>
      <ConfirmationModalV2
        message={deleteMessage}
        title="Confirm FAQ Removal"
        acceptButtonText="Delete"
        type="danger"
        confirmInput
        usePortal={false}
        modalType="delete"
        confirmText="delete"
      />
    </>
  );
}

export default withModal(DeleteFAQ);
