import React, { useContext } from 'react';
import { TmoButtons as TmoButton } from 'tmo-bps-syncup-web-component-lib';

import Dropdown from '../uiHelpers/Dropdown';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import { getOktaGroups } from '../../utils/helper_functions';

import style from './LeftPanelUser.module.css';

function LeftPanelUser() {
  const { userInfo, logout } = useContext(LoggedInUserContext);

  return (
    <div className={style.user}>
      <Dropdown
        className={style.user_dropdown}
        theme="user"
        userInfo={{
          iconInfo:
            userInfo.given_name && userInfo.family_name
              ? `${userInfo.given_name
                  ?.charAt(0)
                  .toUpperCase()}${userInfo.family_name
                  ?.charAt(0)
                  .toUpperCase()}`
              : `${userInfo.name
                  .split(',')[0]
                  ?.charAt(0)
                  .toUpperCase()}${userInfo.name
                  .split(',')[1]
                  ?.charAt(0)
                  .toUpperCase()}`,
          name: userInfo.name,
          email: userInfo.email
        }}
      >
        <ul className={style.user_list}>
          <li className={style.user_list_item}>
            <span className={style.user_list_item_heading}>
              Permission Groups
            </span>
            <ul className={style.groups_list}>
              {getOktaGroups(userInfo).map(g => (
                <li key={g} className={style.groups_list_item}>
                  {g}
                </li>
              ))}
            </ul>
          </li>
        </ul>
        <div className={style.logout}>
          <TmoButton
            type="magenta_primary"
            onClick={logout}
            className={style.logout_btn}
          >
            Logout
          </TmoButton>
        </div>
      </Dropdown>
    </div>
  );
}

export default LeftPanelUser;
