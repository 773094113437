import React from 'react';
import { TmoCard, withModal, RawData } from 'tmo-bps-syncup-web-component-lib';

import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './NotificationItem.module.css';
import rowStyle from '../ItemDetailList/ItemDetailRow.module.css';

function NotificationItem({ notifications }) {
  const { title, body, event, thingId, deviceId, timeSent } = notifications;

  return (
    <TmoCard className={style.container}>
      <div className={style.content}>
        <ItemDetailList rowSet={[{ Type: title }]} />
        <div className={rowStyle.row_half_card}>
          <label className={rowStyle.label}> Message</label>
          <span className={rowStyle.value}>
            <RawData
              rawObject={body}
              title="Show Message"
              type=" "
              text="Show Message"
              usePortal={false}
              modalType={'slide'}
              buttonType="magenta_link"
              className={style.object_button}
              hideIcon={true}
            />
          </span>
        </div>
        <ItemDetailList rowSet={[{ event, thingId }]} />
        <ItemDetailList rowSet={[{ deviceId, timeSent }]} />
      </div>
    </TmoCard>
  );
}

export default withModal(NotificationItem);
