import React from 'react';

import classNames from 'classnames';

import style from './StatusText.module.css';

function StatusText({ className, status, bold = false, children }) {
  return (
    <span className={classNames(style.container, style[status], className)}>
      {bold ? <strong>{children}</strong> : children}
    </span>
  );
}

export default StatusText;
