import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { TmoBackToTop } from 'tmo-bps-syncup-web-component-lib';

import LeftPanel from '../components/leftPanel/LeftPanel';
import AsidePanel from '../components/asidePanel/AsidePanel';
import LoggedInUser from '../components/auth/LoggedInUser';
import MainRoutes from './MainRoutes';
import withTheme from '../components/WithTheme';
import HeaderNew from '../components/header/HeaderNew';
import WithAtiveUserTab from 'components/WithAtiveUserTab';
import { useLocation } from 'react-router-dom';
import { SUA_PAGES, IS_AZURE_AUTH } from 'utils/app_constants';
import MobileHeader from 'components/header/MobileHeader';
import LoggedInUserMsal from 'components/auth/LoggedInUserMsal';

import style from './Main.module.css';

function Main() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const isSearchPage = location.pathname.includes(SUA_PAGES.SEARCH.path);
  const handleToggler = () => {
    setIsExpanded(!isExpanded);
  };
  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const mainContent = (
    <div id="Main-container" className={style.container}>
      <div className={style.top_header}>
        <HeaderNew />
        <div className={style.mobile}>
          <MobileHeader onToggled={handleToggleMenu} />
        </div>
      </div>
      <div className={style.main_container}>
        <AsidePanel />
        <LeftPanel
          isOpen={isMenuOpen}
          isExpanded={isExpanded}
          onToggled={handleToggler}
        />
        <div
          className={classNames(
            isExpanded || isSearchPage
              ? style.right_panel
              : style.collapsed_panel
          )}
        >
          <MainRoutes />
        </div>
      </div>
      <TmoBackToTop />
    </div>
  );

  return IS_AZURE_AUTH ? (
    <LoggedInUserMsal> {mainContent}</LoggedInUserMsal>
  ) : (
    <LoggedInUser>{mainContent}</LoggedInUser>
  );
}

export default WithAtiveUserTab(withTheme(Main));
