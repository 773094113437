import React from 'react';
import Highcharts from 'highcharts';

import Chart from '../../charts/Chart';
import { commercialDeviceChartData } from '../../../utils/pocDummyData';

import style from './DeviceStatus.module.css';

function DeviceStatus() {
  return (
    <div className={style.container}>
      <div className={style.chart}>
        <Chart options={commercialDeviceChartData} highcharts={Highcharts} />
      </div>
    </div>
  );
}

export default DeviceStatus;
