import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  ModalV2,
  TmoButtons,
  TmoH3,
  TmoSvg,
  useVisible
} from 'tmo-bps-syncup-web-component-lib';

import RoleFeature from '../../featureWrappers/RoleFeature';
import iotFirmwareService from '../../../services/iotFirmwareService';
import SyncUpProductContext from '../../../context/SyncUpProductContext';
import GroupFWDetails from '../GroupFWDetails';
import { trackCustomEvents } from 'utils/appInsight_analytics';
import AssignConfig from './AssignConfig';

import style from './AssignConfigWrapper.module.css';
import add from '../../../static/assets/icons/add.svg';
import closeIcon from '../../../static/assets/icons/action/close.svg';

function AssignConfigWrapper({
  openModal,
  className,
  details,
  onAssignConfig,
  closeModal
}) {
  const [options, setOptions] = useState([]);
  const { visible, setVisible } = useVisible(false);
  const [showConfigDetails, setShowConfigDetails] = useState(false);
  const { firmwareId } = details;
  const syncUpProductContext = useContext(SyncUpProductContext);

  const handleConfigClick = async () => {
    setShowConfigDetails(!showConfigDetails);
    setVisible(true);
    try {
      trackCustomEvents(`Assign Config`);
      let result = await iotFirmwareService.getAllIotFirmware({
        product: syncUpProductContext.syncUpAppName
      });
      setVisible(false);
      setOptions(
        result?.map(k => ({
          key: k.id,
          value: `${k.id} (${k.appliesTo})`
        }))
      );
    } catch {
      setVisible(false);
      setOptions([]);
    }
  };
  return (
    <>
      <TmoButtons
        type="magenta_primary"
        onClick={openModal}
        className={classNames(style.action_button, className)}
      >
        Assigned FW Config Details
      </TmoButtons>
      <ModalV2
        title={`Assigned FW Config Details`}
        modalType="slide"
        portal={true}
      >
        <div className={style.container}>
          {firmwareId ? (
            <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
              <div id={firmwareId} className={style.scrollable_content}>
                <GroupFWDetails
                  firmwareId={firmwareId}
                  onAssignConfigs={onAssignConfig}
                  details={details}
                />
              </div>
            </RoleFeature>
          ) : (
            <div className={style.add_config_wrapper}>
              {!showConfigDetails && (
                <>
                  <TmoH3 className={style.error}>
                    No FW Config are assigned.
                  </TmoH3>
                  <TmoButtons
                    type="magenta_link"
                    onClick={handleConfigClick}
                    className={style.config_button}
                    icon={<ReactSVG src={add} />}
                  >
                    Assign FW Config
                  </TmoButtons>
                </>
              )}
              {visible ? (
                <TmoSvg className={style.spinner} svgId="loading-spinner" />
              ) : (
                showConfigDetails && (
                  <div className={style.scrollable_content}>
                    <div className={style.assign_config_wrapper}>
                      <AssignConfig
                        onAssignConfigs={onAssignConfig}
                        selectOptions={options}
                        group={details}
                        onCancel={setShowConfigDetails}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          )}
          <div className={style.button_wrapper}>
            <TmoButtons
              onClick={closeModal}
              type="magenta_secondary"
              icon={<ReactSVG src={closeIcon} />}
            >
              Close
            </TmoButtons>
          </div>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(AssignConfigWrapper);
