import React, { useState, useContext, useCallback } from 'react';
import {
  DynamicList,
  TmoButtons as TmoButton,
  TmoToggle,
  RawData,
  Tooltip,
  StatusText
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import TaskDetails from './TaskDetails';
import userService from '../../services/userService';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import RecurringSchedule from './RecurrringSchedule';
import DeleteTask from './DeleteTask';
import { hasValue } from 'utils/helper_functions';

import style from './UserTask.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';
import sort_icon from '../../static/assets/icons/sort_icon.svg';

function UserTask() {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [userTasks, setUserTasks] = useState();
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  const [listView, setlistView] = useState(false);

  const actionCallback = useCallback(
    () =>
      userService.getUserTasksDetails({
        userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  const headerNameMap = [
    {
      listItemKey: 'title',
      headerDisplayName: 'Title',
      columnClass: style.title_column,
      sortable: true
    },
    {
      listItemKey: 'thingId',
      headerDisplayName: 'Thing Id',
      columnClass: style.thing_column,
      sortable: true
    },
    {
      listItemKey: 'message',
      headerDisplayName: 'Message',
      columnClass: style.message_column
    },
    {
      listItemKey: 'rewardId',
      headerDisplayName: 'Reward Id',
      sortable: true,
      columnClass: style.reward_column
    },
    {
      listItemKey: 'status',
      headerDisplayName: 'Status',
      render: ({ status }) => (
        <StatusText status={status === 'ACTIVE' ? 'success' : 'alert'}>
          {status}
        </StatusText>
      )
    },
    {
      listItemKey: '',
      headerDisplayName: 'Schedule Type',

      render: ({ ...userTask }) =>
        userTask.scheduleSingle ? (
          <ItemDetailList
            hideLabel
            buttonText={'Single'}
            rowSet={[{ scheduleSingle: userTask.scheduleSingle }]}
          />
        ) : (
          <RecurringSchedule
            buttonText={'Recurring'}
            scheduleRecurring={userTask.scheduleRecurring}
            hideLabel
          />
        )
    },

    {
      listItemKey: 'silentMode',
      headerDisplayName: 'Silent Mode',
      render: ({ silentMode }) => (silentMode ? 'On' : 'Off')
    },
    {
      listItemKey: 'reminders',
      headerDisplayName: 'Reminders',
      render: ({ reminders }) => (reminders ? 'On' : 'Off')
    },
    {
      listItemKey: 'active',
      headerDisplayName: 'Active',
      render: ({ active }) => (active ? 'On' : 'Off')
    },
    {
      listItemKey: '',
      headerDisplayName: 'Action',
      columnClass: style.action_column,
      render: ({ ...userTask }) => (
        <div className={style.table_action}>
          <Tooltip
            className={style.raw_object_tooltip}
            tooltip="Full Task Object"
            position={'left'}
          >
            <RawData
              rawObject={userTask}
              title={`Full Taks Object for ${userTask.title}`}
              type=" "
              usePortal={false}
              modalType={'slide'}
              buttonType="magenta_link"
            />
          </Tooltip>
          <DeleteTask
            id={userTask.id}
            title={userTask.title}
            forceRefresh={forceRefresh}
          />
        </div>
      )
    }
  ];

  return (
    <div className={style.container}>
      <PageHeader title="Task" tabHeader>
        <TmoToggle
          label={'List View'}
          disabled={!hasValue(userTasks)}
          isChecked={listView}
          onChange={() => setlistView(!listView)}
        />
        <TmoButton
          onClick={forceRefresh}
          type="magenta_secondary"
          tooltip="Refresh"
          icon={<ReactSVG src={refreshIcon} />}
        />
      </PageHeader>
      <div className={style.items}>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={setUserTasks}
          render={() =>
            userTasks?.length > 0 ? (
              <>
                {listView ? (
                  <DynamicList
                    headerNameListItemMap={headerNameMap}
                    listItems={userTasks}
                    type="primary"
                    sortIcon={sort_icon}
                  />
                ) : (
                  userTasks.map(userTask => (
                    <TaskDetails
                      userTask={userTask}
                      forceRefresh={forceRefresh}
                    />
                  ))
                )}
              </>
            ) : (
              <span className={style.message}>
                User does not have any active Tasks
              </span>
            )
          }
        />
      </div>
    </div>
  );
}

export default UserTask;
