import React from 'react';
import classNames from 'classnames';

import style from './TmoH4.module.css';

function TmoH4({ className, flush, children }) {
  return (
    <h4
      className={classNames(style.container, flush && style.flush, className)}
    >
      {children}
    </h4>
  );
}

export default TmoH4;
