import React, { useState, useCallback, useContext } from 'react';
import {
  TmoButtons,
  useVisible,
  TmoSvg
} from 'tmo-bps-syncup-web-component-lib';

import iotFirmwareService from '../../services/iotFirmwareService';
import Fetcher from '../Fetcher';
import SingleAssetFirmware from './SingleAssetFirmware';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import Error from '../Error/Error';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import AssignConfig from './groups/AssignConfig';
import { trackCustomEvents } from 'utils/appInsight_analytics';

import style from './AssetManager.module.css';

function GroupFWDetails({ firmwareId, onAssignConfigs, details }) {
  const [firmware, setFirmware] = useState();
  const [updateConfigDetails, setUpdateConfigDetails] = useState();
  const { visible, setVisible } = useVisible(false);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const [options, setOptions] = useState([]);

  const actionCallback = useCallback(() => {
    return iotFirmwareService.getIotFirmwareById({
      firmwareId: firmwareId,
      product: syncUpProductContext.syncUpAppName
    });
  }, [syncUpProductContext, firmwareId]);

  const handleUpdateConfig = async () => {
    setUpdateConfigDetails(!updateConfigDetails);
    setVisible(true);
    try {
      trackCustomEvents(`Assign Config`);
      let result = await iotFirmwareService.getAllIotFirmware({
        product: syncUpProductContext.syncUpAppName
      });
      setVisible(false);
      setOptions(
        result?.map(k => ({
          key: k.id,
          value: `${k.id} (${k.appliesTo})`
        }))
      );
    } catch {
      setVisible(false);
      setOptions([]);
    }
  };
  return (
    <>
      <Fetcher
        action={actionCallback}
        onLoad={setFirmware}
        render={() =>
          firmware ? (
            <>
              {!updateConfigDetails && (
                <div className={style.config_wrapper}>
                  <ItemDetailList rowSet={[{ 'config Id': firmwareId }]} />
                  <TmoButtons
                    type="magenta_link"
                    onClick={handleUpdateConfig}
                    className={style.update_config}
                  >
                    Update Config
                  </TmoButtons>
                </div>
              )}
              <div className={style.wrapper}>
                {visible ? (
                  <TmoSvg className={style.spinner} svgId="loading-spinner" />
                ) : (
                  updateConfigDetails && (
                    <AssignConfig
                      onAssignConfigs={onAssignConfigs}
                      selectOptions={options}
                      group={details}
                      onCancel={setUpdateConfigDetails}
                      firmwareId={firmwareId}
                    />
                  )
                )}
              </div>
              <SingleAssetFirmware
                asset={firmware}
                updateConfigDetails={updateConfigDetails}
              />
            </>
          ) : (
            <Error message="No FW Config are assigned." />
          )
        }
      />
    </>
  );
}

export default GroupFWDetails;
