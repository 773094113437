import React from 'react';

import TmoH2 from '../../../controlWrappers/TmoH2';
import TmoButton from '../../../forms/TmoButton';

import './AddSingleDevice.css';

function AddSingleDevice() {
  return (
    <div className="AddSingleDevice-container">
      <div className="AddSingleDevice-header">
        <TmoH2>Add Single Device</TmoH2>
      </div>
      <div className="AddSingleDevice-body">
        <input
          className="AddSingleDevice-input"
          type="text"
          id="deviceId"
          placeholder="Device ID"
        />
        <br />
        <label className="AddSingleDevice-label" for="deviceId">
          This can be found at the bottom of the device
        </label>

        <div className="AddSingleDevice-add-div">
          <TmoButton type="secondary-blue">Add Device</TmoButton>
        </div>
      </div>
    </div>
  );
}

export default AddSingleDevice;
