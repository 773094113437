export const APPCENTER_SUGGESTED_QUERIES_WATCH = [
  {
    name: 'Union Error',
    query: `union isfuzzy=true availabilityResults,requests,exceptions,pageViews,traces,customEvents,dependencies
    | where timestamp > datetime(":StartDate")
    | where * has "kotlinx.coroutines.TimeoutCancellationException"
    | order by timestamp desc
    | take 100`
  },
  {
    name: 'DPS Error',
    query: `union isfuzzy=true exceptions
    | where timestamp > datetime(":StartDate")
    | where outerMessage startswith "404 NOT_FOUND \\"Failure retrieving [" and  outerMessage endswith "] Provisioned Device Registration\\""   | extend IMEI = substring(outerMessage, 35, 15)
    | distinct IMEI`
  },
  {
    name: 'Precheck',
    query: `union isfuzzy=true requests
    | where * has "devices/onboarding/precheck"
    | summarize by hardwareId = tostring(customDimensions.["hardware-id"]), timestamp
    | order by timestamp desc`
  },
  {
    name: 'Direct Method',
    query: `union isfuzzy=true
      traces, requests, pageViews, customEvents, exceptions, dependencies, availabilityResults
    | where timestamp > datetime("2021-11-05T14:00:00.000Z") and timestamp < datetime("2021-11-07T06:59:59.000Z")
    | where name == "DIRECT_METHOD"
    | project IMEI = tostring(customDimensions["hardware-id"])
    | distinct IMEI 
    | order by IMEI desc`
  }
];
