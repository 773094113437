import classNames from 'classnames';
import React from 'react';
import {
  ModalV2,
  withModal,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import style from './ItemDetailsModal.module.css';

function ItemDetailsModal({
  label,
  openModal,
  children,
  buttonText,
  className,
  hideLabel
}) {
  return (
    <>
      <div className={classNames(style.items, className && style[className])}>
        {!hideLabel && <label className={style.label}> {label}</label>}
        <TmoButton
          type="magenta_link"
          className={style.button}
          onClick={openModal}
        >
          {buttonText}
        </TmoButton>
      </div>
      <ModalV2 modalType="slide" title={label} portal={false}>
        <div className={style.modal_content}>
          <div className={style.modal_content_scrollbar}>{children}</div>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(ItemDetailsModal);
