import React, { useContext, useState } from 'react';
import {
  TmoButtons as TmoButton,
  TmoInput,
  LabelWithInput,
  ConfirmationModal,
  ModalContext,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';

import { FETCH_STATE } from '../../../utils/app_constants';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';

import style from './AddRoadsideService.module.css';
import { IoIosArrowDown } from 'react-icons/all';

function AddRoadsideService({
  selectSpecs,
  updateLatLong,
  selectValue,
  onSelectChange,
  label,
  onExecuteClick,
  isModalOpen,
  actionTrigger,
  closeConfirmationModal,
  fetchState,
  error
}) {
  const [latValue, setLatValue] = useState();
  const [longValue, setLongValue] = useState();
  const [latValueDest, setLatValueDest] = useState();
  const [longValueDest, setLongValueDest] = useState();
  const [addressDest, setAddressDest] = useState();
  const { closeModal } = useContext(ModalContext);
  const onLatChange = val => {
    setLatValue(val);
    updateLatLong({
      lat: val,
      long: longValue,
      latDest: latValueDest,
      longDest: longValueDest,
      addressDest: addressDest
    });
  };
  const onLongChange = val => {
    setLongValue(val);
    updateLatLong({
      lat: latValue,
      long: val,
      latDest: latValueDest,
      longDest: longValueDest,
      addressDest: addressDest
    });
  };
  const onLatDestChange = val => {
    setLatValueDest(val);
    updateLatLong({
      lat: val,
      long: longValue,
      latDest: val,
      longDest: longValueDest,
      addressDest: addressDest
    });
  };
  const onLongDestChange = val => {
    setLongValueDest(val);
    updateLatLong({
      lat: latValue,
      long: longValue,
      latDest: latValueDest,
      longDest: val,
      addressDest: addressDest
    });
  };
  const onaAdressDestChange = val => {
    setAddressDest(val);
    updateLatLong({
      lat: latValue,
      long: longValue,
      latDest: latValueDest,
      longDest: longValueDest,
      addressDest: val
    });
  };

  return (
    <>
      <div className={style.content}>
        <label> {label}</label>
        <TmoDropDownSelect
          className={style.select_margin}
          optionsList={selectSpecs}
          onChange={onSelectChange}
          renderIcon={<IoIosArrowDown />}
          defaultSelectedValue={selectSpecs[0]}
        />
        <>
          <LabelWithInput
            labelText="Lat"
            inputType="primary"
            formComponent={TmoInput}
            value={latValue}
            required
            onChange={onLatChange}
          />

          <LabelWithInput
            labelText="Long"
            inputType="primary"
            formComponent={TmoInput}
            value={longValue}
            required
            onChange={onLongChange}
          />
          <LabelWithInput
            labelText="Destination Lat"
            formComponent={TmoInput}
            inputType="primary"
            value={latValueDest}
            required
            onChange={onLatDestChange}
          />

          <LabelWithInput
            labelText="Destination Long"
            formComponent={TmoInput}
            inputType="primary"
            value={longValueDest}
            required
            onChange={onLongDestChange}
          />
          <LabelWithInput
            inputType="primary"
            labelText="Destination Address"
            formComponent={TmoInput}
            value={addressDest}
            required
            onChange={onaAdressDestChange}
          />
        </>
      </div>
      <div className={style.button_wrapper}>
        <TmoButton onClick={closeModal} type="magenta_secondary">
          Cancel
        </TmoButton>
        <TmoButton type="magenta_primary" onClick={onExecuteClick} name={label}>
          Execute
        </TmoButton>
      </div>
      <>
        <ConfirmationModal
          isOpen={isModalOpen}
          modalType="primary"
          onAcceptBtnClicked={() => {
            trackCustomEvents(`Add RoadSide Service - ${selectValue}`);
            actionTrigger();
          }}
          onDenyBtnClicked={closeConfirmationModal}
          buttonsDisabled={fetchState === FETCH_STATE.LOADING}
          message={`Are you sure you want to create ${selectValue} Service on this device?`}
          acceptBtnTitle="Execute"
          denyBtnTitle="Cancel"
        />
        {error && `There was an error ${error}}`}
      </>
    </>
  );
}

export default AddRoadsideService;
