import React, { useState } from 'react';

import DynamicList from '../../uiHelpers/DynamicList';
import TagsDeviceToggle from './TagsDeviceToggle';
import TmoH2 from '../../controlWrappers/TmoH2';
import TmoCard from '../../uiHelpers/TmoCard';
import { sortList } from '../../../utils/helper_functions';
import { SORT_DIRECTION } from '../../../utils/app_constants';
import { FaEllipsisV } from 'react-icons/all';
import DoorLockImg from '../../../static/assets/commercial/images/door-lock.png';
import TmoButton from '../../forms/TmoButton';
import commercialTrackerService from '../../../services/commercialTrackerService';

import style from './LightingControl.module.css';

const LIST_HEADER_OBJECT_MAPPING = [
  {
    listItemKey: 'name',
    headerDisplayName: 'ASSET NAME',
    columnClass: 'Heading'
  },
  {
    listItemKey: 'locked',
    headerDisplayName: 'STATUS',
    columnClass: 'Heading',
    render: ({ locked, name, currentState, lightingControlStatusChanged }) => (
      <TagsDeviceToggle
        name={name}
        isLocked={locked}
        currentState={currentState}
        tagsDeviceStatusChanged={lightingControlStatusChanged}
        lockStatusTrue="On"
        lockStatusFalse="Off"
        lockStatusTurningTrue="Turning On"
        lockStatusTurningFalse="Turning Off"
      />
    )
  },
  {
    listItemKey: 'lastUpdate',
    headerDisplayName: 'LAST UPDATE',
    columnClass: 'Heading'
  }
];

function LightingControl({ forceRefresh, tagsDoorLocks, productId, deviceId }) {
  const lightingControlStatusChanged = (name, lockStatus) => {
    const desiredState = {
      lamps: {
        name: name,
        isOn: lockStatus
      }
    };
    commercialTrackerService.updateCommercialDoorLocksStatus({
      productId,
      deviceId,
      desiredState
    });
  };
  tagsDoorLocks = tagsDoorLocks.map(obj => ({
    name: obj.reportedState.lamps.name,
    deviceType: 'smart lock',
    location: {
      floor: 'floor 1',
      room: 'office #3'
    },
    lightingControlStatusChanged: lightingControlStatusChanged,
    locked: obj.reportedState.lamps.isOn,
    currentState: !obj.desiredState.hasOwnProperty('lamps')
      ? obj.reportedState.lamps.isOn
      : obj.reportedState.lamps.isOn === obj.desiredState.lamps.isOn
      ? obj.reportedState.lamps.isOn
      : obj.desiredState.lamps.isOn === false
      ? 'Turning Off'
      : 'Turning On',
    batteryLevel: 98,
    lastUpdate: 'Today 08:45 am',
    recentActivities: [
      {
        activity: 'Lock offline',
        date: '04/22/2020',
        time: '10:45 am'
      }
    ]
  }));

  const [doorLocks, setDoorLocks] = useState(tagsDoorLocks);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);

  const handleOnListSort = ({ listItemKey }) => {
    const sortedDoorLocks = doorLocks;
    sortList(sortedDoorLocks, { listItemKey }, sortDirection);
    sortDirection === SORT_DIRECTION.ASC
      ? setSortDirection(SORT_DIRECTION.DESC)
      : setSortDirection(SORT_DIRECTION.ASC);
    setDoorLocks(sortedDoorLocks);
  };

  return (
    <TmoCard className={style.container}>
      <div className={style.header}>
        <div className={style.image}>
          <img alt="lighting control" src={DoorLockImg} />
        </div>
        <TmoH2 className={style.title}>Lighting Control</TmoH2>
        <div className={style.count}>{doorLocks.length} devices</div>
        <FaEllipsisV className={style.title_icon} />
        <TmoButton onClick={forceRefresh}>Refresh</TmoButton>
      </div>
      <DynamicList
        className="border-lite"
        headerNameListItemMap={LIST_HEADER_OBJECT_MAPPING}
        listItems={doorLocks}
        onSort={handleOnListSort}
        sortDirection={sortDirection}
      />
    </TmoCard>
  );
}

export default LightingControl;
