import React, { useEffect, useState } from 'react';
import {
  ExportJSONDataAsFile,
  ModalV2,
  NavigationTab,
  NavigationTabGroup,
  RawDataObject,
  TmoButtons,
  Tooltip,
  withModal
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import APIDetails from './APIDetails';
import Clipboard from 'components/Clipboard';
import TryItOut from './TryItOut';
import { APIACTION } from '../../utils/app_constants';

import style from './APIActions.module.css';
import cloudIcon from '../../static/assets/icons/cloud.svg';

const APIActions = ({ item, openModal, onExecuteApi, isCardView }) => {
  const [curl, setCurl] = useState();
  const { apiDetails, name } = item;
  const { curlRequest, responseStructure } = apiDetails;
  const tabs = [
    APIACTION.APIDETAILS,
    APIACTION.CURLREQUEST,
    ...(responseStructure ? [APIACTION.REPONSESTRUCTURE] : []),
    APIACTION.TRYITOUT
  ];
  const [tabSelected, setTabSelected] = useState();

  useEffect(() => {
    let stringModify = curlStirng => {
      return (
        curlStirng &&
        curlStirng
          .replace(/ -H /g, '<br/> &nbsp; &nbsp; <span> -H </span>')
          .replace(/ -d /g, '<br/> &nbsp; &nbsp; <span> -d </span>')
      );
    };
    setCurl(stringModify(curlRequest));
  }, [apiDetails, curlRequest]);

  const onTabChanged = value => {
    setTabSelected(value);
  };

  const tabDetials = () => {
    switch (tabSelected) {
      case APIACTION.APIDETAILS:
        return apiDetails && <APIDetails apiDetails={apiDetails} />;
      case APIACTION.CURLREQUEST:
        return (
          curlRequest && (
            <div className={style.copy_clipboard}>
              <Clipboard value={curlRequest} />
              <div
                className={style.curl_request}
                dangerouslySetInnerHTML={{ __html: curl }}
              />
            </div>
          )
        );
      case APIACTION.REPONSESTRUCTURE:
        return (
          responseStructure && (
            <div className={style.response_structure}>
              <div className={style.response_action}>
                <Clipboard type="" value={JSON.stringify(responseStructure)} />
                <ExportJSONDataAsFile
                  buttonText="Export JSON"
                  data={responseStructure}
                  buttonType={'magenta_primary'}
                  fileName={tabSelected}
                  fileExtension="json"
                />
              </div>
              <RawDataObject rawObject={responseStructure} />
            </div>
          )
        );
      case APIACTION.TRYITOUT:
        return <TryItOut item={item} onExecuteApi={onExecuteApi} />;
      default:
        return null;
    }
  };
  return (
    <>
      {isCardView ? (
        <TmoButtons
          onClick={() => {
            setTabSelected(APIACTION.APIDETAILS);
            openModal();
          }}
          type="magenta_link"
          icon={<ReactSVG src={cloudIcon} />}
        >
          API Informations
        </TmoButtons>
      ) : (
        <Tooltip
          className={style.tooltip}
          tooltip="API Informations"
          position="left"
        >
          <ReactSVG
            src={cloudIcon}
            onClick={() => {
              setTabSelected(APIACTION.APIDETAILS);
              openModal();
            }}
          />
        </Tooltip>
      )}

      <ModalV2 portal={true} title={name} modalType="slide">
        <NavigationTabGroup className={style.tabs_groups} type="primary">
          {tabs.map(t => (
            <NavigationTab
              key={t}
              isSelected={tabSelected === t}
              text={t}
              type="primary-tab"
              value={t}
              className={style.modal_tabs}
              onSelect={onTabChanged}
            />
          ))}
        </NavigationTabGroup>
        <div className={style.scrollable_content}>{tabDetials()} </div>
      </ModalV2>
    </>
  );
};
export default withModal(APIActions);
