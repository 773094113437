import React, {
  useState,
  useCallback,
  useContext,
  Fragment,
  useRef
} from 'react';
import { ReactSVG } from 'react-svg';

import assert from 'assert';
import {
  ContentContainer,
  PaginationV2,
  TmoButtons as TmoButton,
  withModal,
  FormModal,
  DynamicSort,
  FilterModal,
  TmoSearch,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';
import Select from 'react-select';

import StatusContext from '../context/StatusContext';
import simulatorService from '../services/simulatorService';
import SyncUpProductContext from '../context/SyncUpProductContext';
import Fetcher from '../components/Fetcher';
import IotHubNewSimulator from '../components/simulators/IotHubNewSimulator';
import PetsNewSimulator from '../components/simulators/PetsNewSimulator';
import DriveNewSimulator from '../components/simulators/DriveNewSimulator';
import userService from '../services/userService';
import LoggedInUserContext from '../context/LoggedInUserContext';
import { getRandomString } from '../utils/stringUtils';
import { APP_MOBILE, PAGE_SIZE_OPTIONS } from '../utils/app_constants';
import { hasValue } from '../utils/helper_functions';
import PageHeader from '../components/uiHelpers/pageHeader/PageHeader';
import {
  BATTERY_LEVEL_MODES,
  LOCATION_MODES,
  ACTIVITY_MODES,
  BATTERY_STATUS,
  getPetsFormValue,
  getDriveFormValue,
  getIoTFromValue,
  getIoTTagFromValue
} from '../utils/simulatorConsts';
import { trackCustomEvents } from '../utils/appInsight_analytics';
import Error from '../components/Error/Error';

import style from './SimulatorPage.module.css';
import paginationStyle from '../static/assets/css/pagination.module.css';
import {
  RiSearchLine,
  BsFilterLeft,
  IoIosArrowDown,
  HiOutlinePlus
} from 'react-icons/all';
import arrowLeft from '../static/assets/icons/left_arrow.svg';
import arrowRight from '../static/assets/icons/right_arrow.svg';

function SimulatorPage({ openModal }) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const [simulators, setSimulators] = useState();
  const syncUpProductContext = useContext(SyncUpProductContext);
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  const { userInfo } = useContext(LoggedInUserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [simulatorCount, setSimulatorCount] = useState(0);
  const [simulatorPerPage, setSimulatorPerPage] = useState(100);
  const [filteredList, setFilteredList] = useState();
  const [filteredListLength, setFilteredListLength] = useState(0);

  const [sortOptions] = useState(
    syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
      ? [
          { key: 'id', value: 'ID' },
          { key: 'name', value: 'Name' },
          { key: 'statusAt', value: 'Status At' },
          { key: 'status', value: 'Status' }
        ]
      : syncUpProductContext.syncUpAppName === APP_MOBILE.TRACKER ||
        syncUpProductContext.syncUpAppName === APP_MOBILE.WATCH
      ? [
          { key: 'deviceId', value: 'Name' },
          { key: 'version', value: 'Version' },
          { key: 'status', value: 'Status' },
          { key: 'simulatorType', value: 'Simulator Type' }
        ]
      : []
  );

  const [filterOptions] = useState(
    syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
      ? [
          { key: 'roadsideStatus', value: 'Roadside Status' },
          { key: 'simulatorType', value: 'Simulator Type' },
          { key: 'status', value: 'Status' },
          { key: 'wifiStatus', value: 'Wifi Status' }
        ]
      : syncUpProductContext.syncUpAppName === APP_MOBILE.TRACKER ||
        syncUpProductContext.syncUpAppName === APP_MOBILE.WATCH
      ? [
          { key: 'status', value: 'Status' },
          { key: 'simulatorType', value: 'Simulator Type' }
        ]
      : []
  );

  const [searchOptions] = useState(
    syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
      ? ['id', 'wifiSsid']
      : syncUpProductContext.syncUpAppName === APP_MOBILE.TRACKER ||
        syncUpProductContext.syncUpAppName === APP_MOBILE.WATCH
      ? ['deviceId']
      : []
  );

  const sortRef = useRef();
  const searchtRef = useRef();
  const filtertRef = useRef();

  const handlePageSizeChange = value => {
    setSimulatorPerPage(value);
    setCurrentPage(1);
  };

  const selectOptions = PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} Simulator`
  }));

  const handleValueChange = ({ simulator, ...change }) => {
    assert(simulator, 'simulator is required');

    const simulatorIndex = simulators.findIndex(s => s === simulator);

    assert.notStrictEqual(simulatorIndex, -1, `Simulator was not found`);

    setSimulators(
      simulators.map((s, index) => {
        if (simulatorIndex === index) {
          return {
            ...s,
            ...change
          };
        }
        return s;
      })
    );
  };
  const setSimulatorsData = useCallback(
    simulators => {
      if (syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE) {
        setSimulators(simulators.deviceList);
        setSimulatorCount(simulators.deviceCount);
        setFilteredList([...simulators.deviceList]);
        setFilteredListLength(simulators.deviceList.length);
      } else {
        let temp = simulators.map(e => ({
          ...e,
          ...e.tags
        }));

        setSimulators(temp);
        setFilteredList([...temp]);
        setFilteredListLength(temp.length);
      }
    },
    [syncUpProductContext.syncUpAppName]
  );

  const getSimulatorDevices = useCallback(() => {
    // getSimulators
    return simulatorService.getIotSimulators({
      syncUpAppName: syncUpProductContext.syncUpAppName,
      pageNumber: currentPage,
      pageSize: simulatorPerPage
    });
  }, [currentPage, simulatorPerPage, syncUpProductContext.syncUpAppName]);

  const handleSaveChanges = async simulator => {
    if (simulator.id) {
      trackCustomEvents(`Update Simulator`, {
        id: simulator.id,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      await simulatorService.saveSimulator({
        simulator,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
    } else {
      trackCustomEvents(`Add Simulator`, {
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      const { id } = await simulatorService.addSimulator({
        simulator: simulator,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      handleValueChange({ simulator, id });
    }
  };
  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const handleOnFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (searchtRef?.current?.ApplyMultiSelectFilters) {
        searchtRef.current.ApplyMultiSelectFilters(data.list);
      }
    }
  };

  const handleSearchFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (filtertRef?.current?.ApplyFilters) {
        filtertRef.current.ApplyFilters(data.list);
      }
    }
  };

  const handleSaveChangesDriveSimulator = async simulator => {
    if (simulator.id) {
      trackCustomEvents(`Update Drive Simulator`, {
        id: simulator.id,
        deviceId: simulator.deviceId,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      await simulatorService.saveIotSimulator({
        simulator,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
    } else {
      trackCustomEvents(`Add Drive Simulator`, {
        deviceId: simulator.deviceId,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      const { id } = await simulatorService.addIotSimulator({
        syncUpAppName: syncUpProductContext.syncUpAppName,
        deviceId: simulator.deviceId,
        simulator: {
          model: 'SyncUP Drive SD7000T',
          firmwareVersion: '1.0',
          simulatorType: simulator.simulatorType
        }
      });
      handleValueChange({ simulator, id });
      forceRefresh();
    }
  };

  const handleDeleteDevice = async device => {
    trackCustomEvents(`Remove Simulator`, {
      deviceId: device.id,
      syncUpAppName: syncUpProductContext.syncUpAppName
    });
    await simulatorService.deleteSimulator(device.id);
    setSimulators(simulators.filter(s => s !== device));
  };

  const handleDeleteIotDevice = async device => {
    const updatedSimulators = simulators.filter(s => s !== device);
    setSimulators(updatedSimulators);
    trackCustomEvents(`Remove Simulator`, {
      deviceId: device.id,
      syncUpAppName: syncUpProductContext.syncUpAppName
    });
    await simulatorService.deleteIotSimulator(
      device.deviceId,
      syncUpProductContext.syncUpAppName
    );
    forceRefresh();
  };

  const getFormValues = () => {
    switch (syncUpProductContext.syncUpAppName) {
      case APP_MOBILE.PETS:
        return getPetsFormValue(userInfo);
      case APP_MOBILE.DRIVE:
        return getDriveFormValue();
      case APP_MOBILE.TRACKER:
        return getIoTTagFromValue(null, syncUpProductContext.syncUpAppName);
      default:
        return getIoTFromValue(null, syncUpProductContext.syncUpAppName);
    }
  };

  const registerUser = async () => {
    const body = {
      userProfile: {
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        email: userInfo.email,
        msisdns: [],
        demoUser: true
      },
      applicationProfile: {}
    };
    try {
      await userService.registerUser({
        body,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Add Simulator`, {
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      try {
        switch (syncUpProductContext.syncUpAppName) {
          case APP_MOBILE.PETS:
            await simulatorService.addSimulator({
              simulator: {
                manufacturer: 'Simulator',
                firmwareVersion: '1.0.0',
                ...formValues
              },
              syncUpAppName: syncUpProductContext.syncUpAppName
            });
            break;
          case APP_MOBILE.DRIVE:
            await registerUser();
            await simulatorService.addIotSimulator({
              deviceId: formValues.hardwareId,
              simulator: {
                ...formValues
              },
              syncUpAppName: syncUpProductContext.syncUpAppName
            });
            break;
          case APP_MOBILE.TRACKER:
            await simulatorService.addIotSimulator({
              simulator: {
                deviceId: formValues.deviceId,
                hardwareId: formValues.hardwareId,
                securityKey: '123456',
                temperature: 98.6,
                latitude: 47.578651,
                longitude: -122.165176,
                batteryLevel: 98,
                altitude: 55,
                locationMode: LOCATION_MODES.HUB_AND_SPOKE_WANDERING,
                batteryLevelMode: BATTERY_LEVEL_MODES.FIXED,
                activityMode: ACTIVITY_MODES.CIRCADIAN,
                isPoweredOn: true,
                manufacturer: 'Simulator',
                firmwareVersion: '1.0.0',
                isBuzzerOn: true,
                radius: 50,
                batteryStatus: BATTERY_STATUS.CHARGING,
                tags: {
                  productId: syncUpProductContext.syncUpAppName,
                  simulatorType: formValues.simulatorType,
                  model: formValues.model
                },
                simulatorType: formValues.simulatorType,
                productId: syncUpProductContext.syncUpAppName,
                ownedBy: 'System',
                UpdateDeviceDataRequest: '1800'
              },
              syncUpAppName: syncUpProductContext.syncUpAppName
            });
            break;
          default:
            await simulatorService.addIotSimulator({
              simulator: {
                deviceId: formValues.deviceId,
                hardwareId: formValues.hardwareId,
                securityKey: '123456',
                temperature: 98.6,
                latitude: 47.578651,
                longitude: -122.165176,
                batteryLevel: 98,
                altitude: 55,
                locationMode: LOCATION_MODES.HUB_AND_SPOKE_WANDERING,
                batteryLevelMode: BATTERY_LEVEL_MODES.FIXED,
                activityMode: ACTIVITY_MODES.CIRCADIAN,
                isPoweredOn: true,
                manufacturer: 'Simulator',
                firmwareVersion: '1.0.0',
                isBuzzerOn: true,
                radius: 50,
                batteryStatus: BATTERY_STATUS.CHARGING,
                tags: {
                  productId: syncUpProductContext.syncUpAppName,
                  simulatorType: formValues.simulatorType
                },
                simulatorType: formValues.simulatorType,
                productId: syncUpProductContext.syncUpAppName,
                ownedBy: 'System',
                UpdateDeviceDataRequest: '1800'
              },
              syncUpAppName: syncUpProductContext.syncUpAppName
            });
            break;
        }
        addSuccessMessage({ message: 'Added new simulated device' });
        forceRefresh();
      } catch (e) {
        addErrorMessage({ e });
      }
    }
  };

  return (
    <ContentContainer className={style.container}>
      <PageHeader
        primary
        title={'Simulated Device'}
        subtitle={
          filteredListLength > 1 &&
          `${filteredListLength} devices are available`
        }
      >
        <TmoSearch
          placeholder={`Search by ${
            syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
              ? 'Simulator Id, wifiSid'
              : 'Simulator Name'
          }`}
          originalList={simulators}
          options={searchOptions}
          onFilter={handleSearchFilter}
          ref={searchtRef}
          renderIcon={<RiSearchLine />}
          isMultiSelect={true}
          selectComponent={Select}
          className={style.search}
        />
        <DynamicSort
          ref={sortRef}
          listItems={filteredList}
          optionsList={sortOptions}
          onSort={e => {
            handleOnSort(e);
          }}
          renderIcon={<IoIosArrowDown />}
          className={style.sort_control}
        />
        <FilterModal
          ref={filtertRef}
          options={filterOptions}
          originalList={simulators}
          listItems={filteredList}
          onFilter={handleOnFilter}
          renderIcon={<BsFilterLeft />}
        />
        <TmoButton
          type="magenta_primary"
          icon={<HiOutlinePlus />}
          onClick={onAddModal}
        >
          Add New Simulator
        </TmoButton>
        <FormModal
          title={`Add Simulator`}
          fieldSpecs={getFormValues()}
          modalType={'slide'}
        />
      </PageHeader>
      {syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE &&
        hasValue(simulators) && (
          <div className={paginationStyle.pagination_wrapper}>
            <div className={paginationStyle.page_size}>
              <TmoDropDownSelect
                optionsList={selectOptions}
                onChange={e => {
                  handlePageSizeChange(Number(e));
                }}
                renderIcon={<IoIosArrowDown />}
                defaultSelectedValue={
                  selectOptions.filter(e => e.key === simulatorPerPage)[0]
                }
              />
            </div>
            <div>
              <PaginationV2
                className={paginationStyle.custom_pagination}
                totalPages={Math.ceil(simulatorCount / simulatorPerPage)}
                selectedPageNumber={currentPage}
                onPageChanged={currentPage => setCurrentPage(currentPage)}
                type="primary"
                icons={{
                  left: <ReactSVG src={arrowLeft} />,
                  right: <ReactSVG src={arrowRight} />
                }}
              />
            </div>
          </div>
        )}
      <Fetcher
        key={refreshKey}
        action={getSimulatorDevices}
        onLoad={setSimulatorsData}
        data={simulators}
        render={(e, i) =>
          hasValue(simulators) ? (
            <Fragment key={`simulator-${i}`}>
              <div className={style.results}>
                {!simulators.length && 'No simulators found'}
                {syncUpProductContext.syncUpAppName === APP_MOBILE.PETS ? (
                  hasValue(filteredList) ? (
                    filteredList.map(s => {
                      return (
                        <PetsNewSimulator
                          data={s}
                          key={s.mapKey || s.id}
                          onValueChange={handleValueChange}
                          onSaveChanges={handleSaveChanges}
                          onRemove={handleDeleteDevice}
                          forceRefresh={e => forceRefresh()}
                        />
                      );
                    })
                  ) : (
                    <Error heading="No Simulators are available" />
                  )
                ) : syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE ? (
                  hasValue(filteredList) ? (
                    filteredList.map(s => {
                      return (
                        <DriveNewSimulator
                          simulator={s}
                          key={s.mapKey || s.id}
                          onValueChange={handleValueChange}
                          onSaveChanges={handleSaveChangesDriveSimulator}
                          onRemove={handleDeleteIotDevice}
                          forceRefresh={e => forceRefresh()}
                        />
                      );
                    })
                  ) : (
                    <Error heading="No Simulators are available" />
                  )
                ) : hasValue(filteredList) ? (
                  filteredList.map(s => {
                    return (
                      <IotHubNewSimulator
                        key={s.mapKey || s.id}
                        simulator={s}
                        forceRefresh={e => forceRefresh()}
                        onRemove={handleDeleteIotDevice}
                        product={syncUpProductContext.syncUpAppName}
                      />
                    );
                  })
                ) : (
                  <Error heading="No Simulators are available" />
                )}
              </div>
            </Fragment>
          ) : (
            <Error
              heading="No Device Simulators are available"
              message="Please add a new simulator"
            />
          )
        }
      />
    </ContentContainer>
  );
}

export default withModal(SimulatorPage);
