import React from 'react';
import { ContentContainer } from 'tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import Unclaim from '../../components/Unclaim';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

import style from './BulkUnclaim.module.css';

function BulkUnclaim({ match: { url } }) {
  return (
    <Authorized
      roles={[SUA_ROLES.ADMIN, SUA_ROLES.SUPPLY_CHAIN]}
      willShowErrorModal
    >
      <ContentContainer>
        <PageHeader title={'Bulk Unclaim'} />
        <div className={style.content}>
          <Unclaim />
        </div>
      </ContentContainer>
    </Authorized>
  );
}

export default BulkUnclaim;
