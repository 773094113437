import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import { useParams } from 'react-router-dom';
import {
  TmoButtons as TmoButton,
  RawData,
  DynamicList,
  TmoToggle
} from 'tmo-bps-syncup-web-component-lib';

import userService from '../../services/userService';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import { getGroupsBackUrl, hasValue } from 'utils/helper_functions';
import { getMapCenter } from '../../utils/mapUtils';
import GoBack from 'components/uiHelpers/goBack/GoBack';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';
import { preparePhoneLocationSafeZones } from 'utils/SafeZoneHelper';
import appConfig from '../../configs/config';
import SafeZone from 'components/safeZones/SafeZone';
import PhoneLocationGroupVBList from './PhoneLocationGroupVBList';
import StatusText from 'components/textHelpers/StatusText';

import style from './PhoneLocationGroupUsers.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';
import sort_icon from '../../static/assets/icons/sort_icon.svg';

function PhoneLocationGroupVB({ history, hideTab }) {
  const [listView, setlistView] = useState(false);
  const mapContainer = <div className={style.map} />;
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [phoneLocationGroupVB, setPhoneLocationGroupVB] = useState();
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  let { groupId } = useParams();
  const { googleMapSettings, reactGoogleMaps } = appConfig;
  const actionCallback = useCallback(
    () =>
      userService.getPhoneLocationGroupVB({
        userId,
        groupId: groupId,
        syncUpAppName: product
      }),
    [userId, groupId, product]
  );

  const headerNameMap = [
    {
      listItemKey: 'id',
      headerDisplayName: 'Id',
      sortable: true
    },
    {
      listItemKey: 'groupId',
      headerDisplayName: 'Group Id'
    },
    {
      listItemKey: 'name',
      headerDisplayName: 'Name',
      sortable: true
    },
    {
      listItemKey: 'longitude',
      headerDisplayName: 'Longitude'
    },
    {
      listItemKey: 'latitude',
      headerDisplayName: 'Latitude'
    },
    {
      listItemKey: 'radius',
      headerDisplayName: 'Radius'
    },
    {
      listItemKey: 'isEnabledForMember',
      headerDisplayName: 'Is Enabled For Member',
      render: ({ isEnabledForMember }) => (
        <StatusText status={isEnabledForMember} bold>
          {isEnabledForMember === true ? 'Yes' : 'No'}
        </StatusText>
      )
    },
    {
      listItemKey: 'address',
      headerDisplayName: 'Address'
    },
    {
      listItemKey: 'displayZoomLevel',
      headerDisplayName: 'Display Zoom Level'
    }
  ];

  useEffect(() => {
    hideTab && hideTab(true);
  }, [hideTab]);

  return (
    <>
      <GoBack
        primary
        url={getGroupsBackUrl(userId, 'phone-location-groups', history)}
        hideTab={hideTab}
      />
      <PageHeader
        title={'Virtual Boundary'}
        tabHeader={true}
        className={style.header_vb}
      >
        {phoneLocationGroupVB && (
          <RawData
            rawObject={phoneLocationGroupVB}
            title="Virtual Boundary"
            type="Safe Zone"
            modalType={'slide'}
            buttonType="magenta_link"
          />
        )}
        <TmoButton
          className={style.button}
          onClick={forceRefresh}
          type="magenta_secondary"
          tooltip="Refresh"
          icon={<ReactSVG src={refreshIcon} />}
        />
      </PageHeader>
      <Fetcher
        key={refreshKey}
        action={actionCallback}
        onLoad={setPhoneLocationGroupVB}
        render={() =>
          phoneLocationGroupVB?.length > 0 ? (
            <>
              <SafeZone
                center={getMapCenter(phoneLocationGroupVB)}
                zoom={4}
                places={preparePhoneLocationSafeZones(phoneLocationGroupVB)}
                googleMapURL={`${reactGoogleMaps.url}${googleMapSettings.apiKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `600px` }} />}
                mapElement={mapContainer}
              />
              <div className={style.toggle}>
                <TmoToggle
                  label={'List View'}
                  disabled={!hasValue(phoneLocationGroupVB)}
                  isChecked={listView}
                  onChange={() => setlistView(!listView)}
                />
              </div>
              {listView ? (
                <DynamicList
                  headerNameListItemMap={headerNameMap}
                  listItems={phoneLocationGroupVB}
                  type="primary"
                  sortIcon={sort_icon}
                />
              ) : (
                <div className={style.items}>
                  {phoneLocationGroupVB.map(virtualBoundary => (
                    <PhoneLocationGroupVBList
                      virtualBoundary={virtualBoundary}
                      forceRefresh={forceRefresh}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <span className={style.message}>
              Group does not have Virtual Boundary
            </span>
          )
        }
      />
    </>
  );
}

export default PhoneLocationGroupVB;
