import React from 'react';

import ItemDetailList from '../../ItemDetailList/ItemDetailList';
import { GRID } from 'utils/app_constants';

import style from './DeviceTwin.module.css';

function DeviceTwin({ device }) {
  const {
    deviceId,
    version,
    status,
    statusUpdateTime,
    connectionState,
    lastActivityTime,
    cloudToDeviceMessageCount,
    authenticationType,
    configurations = {},
    tags = {}
  } = device;

  return (
    <div className={style.container} data-testid="container-testid">
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          HardwareId: deviceId,
          version,
          status,
          statusUpdateTime,
          connectionState,
          lastActivityTime,
          cloudToDeviceMessageCount,
          authenticationType,
          configurations,
          tags,
          IMEI: tags?.hwInfo?.imei ? tags.hwInfo.imei : 'Not Available'
        }}
      />
    </div>
  );
}

export default DeviceTwin;
