import React, { useState, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import {
  DynamicList,
  RawData,
  StatusText,
  TmoButtons as TmoButton,
  TmoToggle,
  Tooltip
} from 'tmo-bps-syncup-web-component-lib';

import ScheduledTask from './ScheduledTask';
import AddSchoolMode from './AddSchoolMode';
import userService from '../../services/userService';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import {
  getRandomString,
  prettifyCamelCase,
  capitalize
} from '../../utils/stringUtils';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import { DAYS_OF_WEEK } from 'utils/app_constants';
import ItemDetailsModal from 'components/ItemDetailsModal';
import TimeDisplay from 'components/uiHelpers/TimeDisplay';
import DeleteSchoolMode from './DeleteSchoolMode';
import { hasValue } from 'utils/helper_functions';

import style from './SchoolMode.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';
import sort_icon from '../../static/assets/icons/sort_icon.svg';

function SchoolMode() {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [schoolModes, setSchoolModes] = useState();
  const [listView, setlistView] = useState(false);
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  const checkStatus = value => {
    return value === 'ACTIVE' ? 'success' : 'alert';
  };
  const actionCallback = useCallback(
    () =>
      userService.getSchoolModeDetails({
        userId,
        syncUpAppName: product
      }),
    [userId, product]
  );
  const headerNameMap = [
    {
      listItemKey: 'displayName',
      headerDisplayName: 'Name',
      sortable: true
    },
    {
      listItemKey: 'attributes',
      headerDisplayName: 'Product',
      render: ({ attributes }) => (
        <>
          {attributes && prettifyCamelCase(attributes.product.toLowerCase())}{' '}
        </>
      )
    },
    {
      listItemKey: 'attributes',
      headerDisplayName: 'Primary AccountHolder Id',
      render: ({ attributes }) => (
        <>
          {attributes && prettifyCamelCase(attributes.thingId.toLowerCase())}{' '}
        </>
      )
    },
    {
      listItemKey: 'attributes',
      headerDisplayName: 'Thing Id',
      render: ({ attributes }) => (
        <>
          {attributes && prettifyCamelCase(attributes.thingId.toLowerCase())}{' '}
        </>
      )
    },
    {
      listItemKey: 'scheduleType',
      headerDisplayName: 'Schedule Type',
      sortable: true
    },
    {
      listItemKey: 'schedule',
      headerDisplayName: 'Start Time',
      render: ({ schedule, scheduleType }) => (
        <>
          {scheduleType === 'WeekdayDuration' ? (
            <ItemDetailsModal
              label="Date Time"
              buttonText={'View Details'}
              hideLabel
            >
              <div className={classNames(style.sub_row, style.time_display)}>
                <label>Weekdays</label>
                <span>
                  {DAYS_OF_WEEK.map((day, index) => (
                    <>
                      {day != null && (
                        <>
                          <span
                            className={classNames(
                              style.days,
                              schedule.weekdays.includes(
                                day.toLocaleLowerCase()
                              ) && style.active_days
                            )}
                          >
                            {capitalize(day.charAt(0))}
                          </span>
                          {index < DAYS_OF_WEEK.length - 1 && '-'}
                        </>
                      )}
                    </>
                  ))}
                </span>
              </div>
              <div className={classNames(style.sub_row, style.time_display)}>
                <label>Start Time</label>
                <span>{schedule.localStartTime}</span>
              </div>
            </ItemDetailsModal>
          ) : (
            <ItemDetailsModal
              label="Date Time"
              buttonText={'View Details'}
              hideLabel
            >
              <div className={classNames(style.sub_row, style.time_display)}>
                <TimeDisplay
                  timestamp={schedule.localStartTime}
                  type="primary_time_display"
                />
              </div>
            </ItemDetailsModal>
          )}
        </>
      ),
      columnClass: style.start_time
    },
    {
      listItemKey: 'schedule',
      headerDisplayName: 'Duration',
      render: ({ schedule }) => <>{schedule.durationInMinutes}</>
    },
    {
      listItemKey: 'schedule',
      headerDisplayName: 'Time Zone',
      render: ({ schedule }) => (
        <>
          {schedule && prettifyCamelCase(schedule.olsenTimeZone.toLowerCase())}{' '}
        </>
      )
    },
    {
      listItemKey: 'active',
      headerDisplayName: 'Status',
      render: ({ active }) => (
        <StatusText status={checkStatus(active ? 'ACTIVE' : 'Paused')}>
          {active ? 'ACTIVE' : 'Paused'}
        </StatusText>
      )
    },
    {
      listItemKey: 'active',
      headerDisplayName: 'Action',
      render: data => (
        <div className={style.list_action_wrapper}>
          <Tooltip
            className={style.raw_objet_tooltip}
            tooltip="School Mode Object"
            position={'left'}
          >
            <RawData
              rawObject={data}
              title={`Full Object for ${data.displayName}`}
              usePortal={false}
              modalType={'slide'}
              buttonType="magenta_link"
              type=" "
              className={style.show_object_icon}
            />
          </Tooltip>
          <DeleteSchoolMode
            schoolMode={data}
            forceRefresh={forceRefresh}
            isListView
          />
        </div>
      )
    }
  ];
  return (
    <>
      <PageHeader title={'School Mode'} tabHeader={true}>
        <div className={style.toggle_wrapper}>
          <TmoToggle
            label={'List View'}
            isChecked={listView}
            onChange={() => setlistView(!listView)}
            disabled={!hasValue(schoolModes)}
          />
        </div>
        <TmoButton
          className={style.button}
          onClick={forceRefresh}
          type="magenta_secondary"
          tooltip="Refresh"
          icon={<ReactSVG src={refreshIcon} />}
        />
        <AddSchoolMode forceRefresh={forceRefresh} />
      </PageHeader>
      <div className={style.items}>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={setSchoolModes}
          render={() =>
            schoolModes?.length > 0 ? (
              <>
                {listView ? (
                  <DynamicList
                    headerNameListItemMap={headerNameMap}
                    listItems={schoolModes}
                    type="primary"
                    className={style.list_table}
                    sortIcon={sort_icon}
                  />
                ) : (
                  <>
                    {schoolModes.map(schoolMode => (
                      <>
                        <ScheduledTask
                          schoolMode={schoolMode}
                          forceRefresh={forceRefresh}
                        />
                      </>
                    ))}
                  </>
                )}
              </>
            ) : (
              <span className={style.message}>
                User does not have any active School Modes
              </span>
            )
          }
        />
      </div>
    </>
  );
}

export default SchoolMode;
