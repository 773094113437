import React, { useState } from 'react';
import {
  NavigationTab as Tab,
  TmoCard
} from 'tmo-bps-syncup-web-component-lib';

import PowerBi from '../powerBi/PowerBi';
import AppEngagement from '../powerBi/AppEngagement';
import Devices from '../powerBi/Devices';
import Subscriber from '../powerBi/Subscriber';
import RatePlan from '../powerBi/RatePlan';

import style from './Analytics.module.css';

function Analytics() {
  const [currentReportId, setCurrentReportId] = useState(1);

  const activeThings = [
    { name: 'Dashboard', id: 1 },
    { name: 'Devices', id: 2 },
    { name: 'App Engagement', id: 3 },
    { name: 'Sales/Subscribers', id: 4 },
    { name: 'Rate Plan', id: 5 }
  ];

  const reportTabs = () => {
    switch (currentReportId) {
      case 1:
        return <PowerBi />;
      case 2:
        return <Devices />;
      case 3:
        return <AppEngagement />;
      case 4:
        return <Subscriber />;
      case 5:
        return <RatePlan />;
      default:
        return <PowerBi />;
    }
  };
  const tabText = t => <>{t?.name}</>;
  return (
    <TmoCard>
      <div className={style.tabs}>
        {activeThings?.map(t => (
          <Tab
            key={t.id}
            value={t.id}
            text={tabText(t)}
            onSelect={setCurrentReportId}
            isSelected={currentReportId === t.id}
            type="primary-tab"
          />
        ))}
      </div>
      {reportTabs()}
    </TmoCard>
  );
}

export default Analytics;
