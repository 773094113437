import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoButtons as TmoButton,
  withModal,
  FormModal,
  TmoInput
} from 'tmo-bps-syncup-web-component-lib';

import { trackCustomEvents } from '../../utils/appInsight_analytics';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import userService from 'services/userService';
import StatusContext from 'context/StatusContext';

import style from './LocationGroupUsers.module.css';
import redeemIcon from '../../static/assets/icons/action/redeem_user.svg';

const RedeemInvite = ({ openModal, inviteId, groupId, onForceRefresh }) => {
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const handleInviteAccept = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Redeem Invite`, {
        groupId: groupId,
        inviteId: inviteId
      });
      try {
        await userService.redeemInvitesLocationGroups({
          groupId: groupId,
          inviteId: inviteId,
          userId: formValues.userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Redeem Invite: ${inviteId} successfully`
        });
        onForceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  const formFieldSpecs = [
    {
      labelText: 'User Id',
      fieldName: 'userId',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Invited member User Id'
    }
  ];

  return (
    <>
      <TmoButton
        onClick={handleInviteAccept}
        type="magenta_icon_btn"
        className={style.button_redeem}
        tooltip={'Redeem Invite'}
        icon={<ReactSVG src={redeemIcon} />}
      />
      <FormModal
        title={`Redeem Invite`}
        fieldSpecs={formFieldSpecs}
        modalType={'slide'}
      />
    </>
  );
};

export default withModal(RedeemInvite);
