import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';

import ErrorMessageModal from '../components/errorHandler/ErrorMessageModal';
import { STORAGE_KEYS, STORAGE_TYPES } from '../utils/app_constants';
import { getHomePageUrl } from '../utils/helper_functions';
import {
  storageRead,
  storageRemoveItem,
  storageWrite
} from '../utils/storageUtils';

const Callback = () => {
  const { oktaAuth } = useOktaAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const authCheck = async () => {
      await oktaAuth.handleLoginRedirect();
      const user = await oktaAuth.getUser();
      const idToken = oktaAuth.getIdToken();

      storageWrite({
        key: STORAGE_KEYS.OKTA_USER_INFO,
        value: JSON.stringify(user),
        storageType: STORAGE_TYPES.LOCAL
      });
      storageWrite({
        key: STORAGE_KEYS.OKTA_ID_TOKEN,
        value: idToken,
        storageType: STORAGE_TYPES.LOCAL
      });

      const intendedUrl = storageRead({
        key: STORAGE_KEYS.INTENDED_URL,
        storageType: STORAGE_TYPES.SESSION
      });
      setRedirectUrl(intendedUrl ? intendedUrl : getHomePageUrl(user.Groups));
      setIsAuthenticated(true);
      storageRemoveItem({
        key: STORAGE_KEYS.INTENDED_URL,
        storageType: STORAGE_TYPES.SESSION
      });
    };
    try {
      authCheck();
    } catch (error) {
      handleOKTASDKError(error);
    }
  }, [oktaAuth]);

  const handleOKTASDKError = error => {
    setIsAuthenticated(false);
    setError(error);
  };
  const logout = async () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: '/login'
    });
  };
  return isAuthenticated ? (
    <Redirect to={redirectUrl} />
  ) : (
    error && <ErrorMessageModal message={error} onClose={logout} />
  );
};
export default Callback;
