import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const firmwareRepository = {
  getFirmwarePackages: async ({
    page,
    results,
    product,
    responseStatus = false
  }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.firmwarePackage}?page=${page -
        1}&size=${results}`,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  getFirmwareVersionsFromPackages: async () => {
    const { resources } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: `${resources.firmwarePackage}?page=0&size=100`
    });
  },
  deleteFirmwarePackage: async ({ id, responseStatus = false }) => {
    const { resources, httpMethods } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.firmwarePackage}/${id}`,
      httpMethod: httpMethods.DELETE
    });
  },
  getFirmwarePackageByImei: async ({ imei, responseStatus = false }) => {
    const { resources } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.firmwarePackageImei.replace(':imei', imei)
    });
  },
  postFirmwarePackage: async ({
    data,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, httpMethods } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.firmwarePackageUpload.replace(
        ':product',
        syncUpAppName
      ),
      body: data,
      formData: true,
      httpMethod: httpMethods.POST
    });
  },
  getFirmwareGroups: async (responseStatus = false) => {
    const { resources } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.firmwareGroups}`
    });
  },
  patchFirmwareGroup: async ({ body, groupId, responseStatus = false }) => {
    const { resources, httpMethods } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.firmwareGroupById.replace(':groupId', groupId),
      body: body,
      httpMethod: httpMethods.PATCH
    });
  },
  addFirmwareGroup: async ({ group, responseStatus = false }) => {
    const { resources, httpMethods } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.firmwareGroups,
      body: group,
      httpMethod: httpMethods.POST
    });
  },
  deleteFirmwareGroup: async ({ groupId, responseStatus = false }) => {
    const { resources, httpMethods } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.firmwareGroupById.replace(':groupId', groupId),
      httpMethod: httpMethods.DELETE
    });
  },
  getFirmwareDownloadStatusByImei: async ({ imei, responseStatus = false }) => {
    const { resources } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.firmwareDownloadStatus}?imeival=${imei}`
    });
  },
  getFirmwareDownloadStatus: async ({
    query,
    page,
    resultsSize,
    responseStatus = false
  }) => {
    const { resources } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.firmwareDownloadStatus}?${query}&page=${page -
        1}&size=${resultsSize}`
    });
  }
};

export default firmwareRepository;
