import {
  USER_THINGS_REQUEST,
  USER_THINGS_SUCCESS,
  USER_THINGS_ERROR
} from '../utils/app_constants';

const USER_THINGS_INITIAL_STATE = {
  data: [],
  isLoading: false,
  isError: false
};

export const userThingsReducer = (
  state = USER_THINGS_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case USER_THINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: []
      };
    case USER_THINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case USER_THINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload
      };
    default:
      return state;
  }
};
