import React, { useContext, useEffect, useState } from 'react';
import {
  NavigationTab as Tab,
  NavigationTabGroup as TabGroup,
  ContentContainer
} from 'tmo-bps-syncup-web-component-lib';

import StatusTable from './StatusTable';
import { apiStatusData } from '../../utils/apiStatusHelper';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { hasValue } from 'utils/helper_functions';

import style from './StatusTable.module.css';

const APIStatusPage = () => {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const [APIList] = useState(apiStatusData(syncUpProductContext.syncUpAppName));
  const [tabSelected, setTabSelected] = useState(0);
  const [selectedGroup, setSelectedGroupAPI] = useState(APIList[tabSelected]);

  useEffect(() => {
    setSelectedGroupAPI(APIList[tabSelected]);
  }, [tabSelected, APIList]);

  return (
    <ContentContainer className={style.container}>
      <TabGroup className={style.tabs_group} type="secondary">
        {APIList &&
          APIList.map((group, index) => (
            <Tab
              onSelect={val => setTabSelected(val)}
              key={index}
              value={index}
              type="secondary-tab"
              isSelected={index === tabSelected}
              text={group.name}
            />
          ))}
      </TabGroup>
      {hasValue(selectedGroup.categories) && (
        <StatusTable
          name={selectedGroup.name}
          key={`selected-group-table-${selectedGroup.name}`}
          item={selectedGroup.categories}
        />
      )}
    </ContentContainer>
  );
};
export default APIStatusPage;
