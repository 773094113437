import mobileAppRepository from '../repositories/mobileAppRepository';

const mobileAppService = {
  getAppVersions: async ({ osVersion, syncUpAppName }) => {
    return await mobileAppRepository.getAppVersions({
      osVersion,
      syncUpAppName
    });
  },
  deleteAppVersions: async ({ versionID, syncUpAppName }) => {
    return await mobileAppRepository.deleteAppVersions({
      versionID,
      syncUpAppName
    });
  },
  postAppVersion: async ({ body, syncUpAppName }) => {
    return await mobileAppRepository.postAppVersion({ body, syncUpAppName });
  },
  updateAppVersions: async ({
    body,
    syncUpAppName,
    distributionChannel,
    platform
  }) => {
    return await mobileAppRepository.updateAppVersion({
      body,
      syncUpAppName,
      distributionChannel,
      platform
    });
  }
};

export default mobileAppService;
