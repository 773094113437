import React from 'react';
import { FaCaretDown, FaEllipsisV } from 'react-icons/all';
import {
  TmoCard,
  RawData,
  Dropdown,
  ExpandableText
} from 'tmo-bps-syncup-web-component-lib';

import TimeDisplay from '../uiHelpers/TimeDisplay';

import style from './SupportGroup.module.css';

function OncallSupportGroup({ data }) {
  return (
    <TmoCard shadow={false} className={style.container}>
      <Dropdown className={style.toolbar} triggerIcon={<FaEllipsisV />}>
        <div className={style.toolbar_item}>
          <RawData
            rawObject={data}
            title="OnCall Support Details"
            type="OnCall Support"
            usePortal={false}
          />
        </div>
      </Dropdown>
      <div className={style.container} key={data.start}>
        <div className={style.row}>
          <label className={style.label}>
            Type:
            <span> {data.group.recipientType}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Id:
            <span> {data.group.id}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Name:
            <span> {data.group.targetName}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Shift Id:
            <span> {data.shift.id}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            Shift Name:
            <span> {data.shift.name}</span>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            <ExpandableText
              label="Start Time: "
              boldLabel
              triggerText="View"
              closeText="Close"
              triggerIcon={<FaCaretDown />}
            >
              <div className={style.label}>
                <TimeDisplay timestamp={data.start} />
              </div>
            </ExpandableText>
          </label>
        </div>
        <div className={style.row}>
          <label className={style.label}>
            <ExpandableText
              label="End Time: "
              boldLabel
              triggerText="View"
              closeText="Close"
              triggerIcon={<FaCaretDown />}
            >
              <div className={style.label}>
                <TimeDisplay timestamp={data.end} />
              </div>
            </ExpandableText>
          </label>
        </div>

        {/* <div className={classNames(style.row, style.action)}>
          <RawData
            rawObject={contact}
            title={`Full User Object for ${contact.firstName}`}
            className={style.object}
          />
        </div> */}
      </div>
    </TmoCard>
  );
}

export default OncallSupportGroup;
