import React, { useState, useMemo, useEffect } from 'react';
import Mark from '../../../node_modules/mark.js/dist/mark';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  TmoCard,
  TmoButtons as TmoButton,
  TmoInput
} from 'tmo-bps-syncup-web-component-lib';

import { appInsights } from '../../utils/appInsight_analytics';
import { APP_MOBILE } from '../../utils/app_constants';

import style from './Search.module.css';

import search_icon from '../../static/assets/icons/search_icon.svg';
import explore_icon from '../../static/assets/icons/explore_icon.svg';
import close_icon from '../../static/assets/icons/close_icon.svg';
import searchIcon from '../../static/assets/icons/help_search.svg';

function Search({
  renderItem,
  list,
  userId,
  isAdmin,
  showSubmitQuestion,
  features,
  disableInput
}) {
  const [searchText, setSearchText] = useState('');
  const [inputVisibility, setInputVisibility] = useState(false);
  const [agent, setAgent] = useState(null);

  const formatListData = listData => {
    let categories = [...new Set(listData.map(e => e?.categoryId))];
    let formattedList = [];
    categories.forEach(item => {
      let temp = listData.filter(data => data?.categoryId === item);
      formattedList.push({
        category: temp[0].name,
        categoryId: temp[0].categoryId,
        order: temp && temp[0].categoryOrder ? temp[0].categoryOrder : 100,
        categoryOrder:
          temp && temp[0].categoryOrder ? temp[0].categoryOrder : 100,
        details: temp.sort((a, b) => a.faqOrder - b.faqOrder)
      });
    });
    return formattedList.sort((a, b) => a.order - b.order);
  };
  const handleInput = text => {
    setSearchText(text);
    const instance = new Mark(document.querySelector('#searchResult'));
    instance.unmark({
      done: () => {
        instance.mark(text, { separateWordSearch: false });
      }
    });
    appInsights &&
      appInsights.trackEvent({
        name: `${APP_MOBILE.WATCH}-FAQ filter applied`,
        properties: { searchText: text, userId }
      });
  };
  const filteredList = useMemo(() => {
    let faqList = [];
    list.forEach(category => {
      let catTemp = {};
      if (category.faqList) {
        catTemp = { ...category, categoryId: category.id, faqList: [] };
        category.faqList.forEach(item => {
          let temp = { ...catTemp, ...item, faqId: item.id };
          faqList = [...faqList, temp];
        });
      } else {
        catTemp = { ...category, categoryId: category.id, faqList: [] };
        faqList = [...faqList, catTemp];
      }
    });

    return searchText
      ? formatListData(
          faqList?.filter(
            item =>
              item['name'].toLowerCase().includes(searchText.toLowerCase()) ||
              item['description']
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              item['title'].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      : formatListData(faqList);
  }, [list, searchText]);
  const showInstabugDialogAndroid = async () => {
    appInsights &&
      appInsights.trackEvent({
        name: `${APP_MOBILE.WATCH}-Submit a question selected`,
        properties: { userId }
      });
    window.Android && window.Android.showInstabugDialog();
  };
  const showInstabugDialogIos = async () => {
    appInsights &&
      appInsights.trackEvent({
        name: `${APP_MOBILE.WATCH}-Submit a question selected`,
        properties: { userId }
      });
    let message = 'SubmitAQuestion';
    window.webkit &&
      window.webkit.messageHandlers.submitButtonTapped.postMessage(message);
  };
  useEffect(() => {
    const getUserAgent = () => {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    };
    if (!agent) {
      setAgent(getUserAgent());
    }
  }, [agent, setAgent]);
  return (
    <>
      {(inputVisibility || isAdmin) && (
        <form
          className={classNames(
            style.search_section,
            isAdmin && style.no_padding,
            isAdmin && style.search_control
          )}
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          {isAdmin ? (
            <ReactSVG src={searchIcon} className={style.search_icon} />
          ) : (
            <img
              src={explore_icon}
              className={style.explore_input_icon}
              alt="Search icon"
            />
          )}
          <TmoInput
            className={classNames(style.input, isAdmin && style.admin_search)}
            size="lg"
            placeholder="Search"
            type="text"
            autoFocus
            onChange={handleInput}
            value={searchText}
            aria-label="Search"
            disabled={isAdmin && disableInput}
          />
          {!isAdmin && (
            <img
              src={close_icon}
              className={style.close_input_icon}
              alt="close search box"
              aria-label="close search box"
              onClick={() => {
                setInputVisibility(false);
                setSearchText('');
                appInsights &&
                  appInsights.trackEvent({
                    name: `${APP_MOBILE.WATCH}-FAQ filter search data cleared`,
                    properties: { userId }
                  });
                const instance = new Mark(
                  document.querySelector('#searchResult')
                );
                instance.unmark();
              }}
            />
          )}
        </form>
      )}
      {filteredList?.length ? (
        <TmoCard
          shadow={false}
          className={classNames(
            style.no_padding,
            isAdmin && style.search_scroll
          )}
          customId="searchResult"
        >
          {filteredList.map((item, index) => renderItem(item, index))}
        </TmoCard>
      ) : (
        <>
          <div className={style.no_result_wrapper}>
            <div aria-label="No results found">No results found</div>
          </div>
        </>
      )}
      {!isAdmin &&
        (agent === 'Android' || agent === 'iOS') &&
        (showSubmitQuestion === 'true' ||
          (features && features.submitQuestion)) && (
          <div className={style.submit_question_wrapper}>
            <p
              className={style.submit_text}
              aria-label="Help us improve and expand our FAQ"
            >
              Help us improve and expand our FAQ
            </p>
            <TmoButton
              className={style.action_button}
              onClick={
                agent === 'Android'
                  ? showInstabugDialogAndroid
                  : agent === 'iOS'
                  ? showInstabugDialogIos
                  : e => e.preventDefault()
              }
              alt="Submit a question button"
              aria-label="Submit a question button"
              role="button"
              tabIndex={999999}
            >
              Submit a question
            </TmoButton>
          </div>
        )}
      {!isAdmin && !inputVisibility && (
        <div
          className={style.search_wrapper}
          onClick={() => {
            setInputVisibility(true);
            appInsights &&
              appInsights.trackEvent({
                name: `${APP_MOBILE.WATCH}-FAQ filter search selected`,
                properties: { userId }
              });
          }}
        >
          <ReactSVG
            src={search_icon}
            className={style.search_icon}
            alt="search button"
            aria-label="Search button"
            role="button"
            tabIndex={1000000}
          />
        </div>
      )}
    </>
  );
}

export default Search;
