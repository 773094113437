import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  TmoCheckbox,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import { APP_MOBILE } from 'utils/app_constants';

import style from './UserSearchPanel.module.css';
import { BsFilterLeft } from 'react-icons/all';

function ProductFilter({ onFilter, userList }) {
  const [selectedProduct, setSelectedProduct] = useState(
    Object.values(APP_MOBILE)
  );
  const [filteredUserCount, setfilteredUserCount] = useState(0);
  const handleChange = e => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedProduct([...selectedProduct, value]);
    } else {
      const filtered =
        selectedProduct && selectedProduct.filter(p => p !== value);
      setSelectedProduct(filtered);
    }
  };
  useEffect(() => {
    if (userList) {
      let filteredList = [
        ...userList.filter(u => selectedProduct.includes(u.product))
      ];
      onFilter(filteredList);
      setfilteredUserCount(filteredList.length);
    }
  }, [selectedProduct, userList, onFilter]);

  return (
    <div className={style.filter_wrapper}>
      <div>
        {filteredUserCount > 1
          ? `${filteredUserCount} users available`
          : filteredUserCount === 1
          ? `${filteredUserCount} user available`
          : 'No user available'}
      </div>
      <Dropdown
        className={style.filter_button}
        contentClass={style.filter_content}
        triggerIcon={
          <TmoButton
            type="magenta_primary_icon"
            tooltip="Filter"
            icon={<BsFilterLeft />}
          />
        }
      >
        {Object.entries(APP_MOBILE).map(
          ([key, value]) =>
            value !== APP_MOBILE.FAMILYLOCATION &&
            value !== APP_MOBILE.PETS && (
              <TmoCheckbox
                key={key}
                value={value}
                isChecked={selectedProduct.includes(value)}
                onChange={e => handleChange(e)}
                data-name={key}
                checkbox="magentaCheckbox"
              />
            )
        )}
      </Dropdown>
    </div>
  );
}
export default ProductFilter;
