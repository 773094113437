import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';

import LoggedInUserContext from '../../context/LoggedInUserContext';
import ThemeContext from '../../context/ThemeContext';
import { THEMES, SUA_PAGES } from '../../utils/app_constants';
import ThingsLogo from 'components/leftPanel/ThingsLogo';

import style from './MobileHeader.module.css';
import hamburgerMenu from '../../static/assets/icons/leftpanel/hamburger_menu.svg';
import searchIcon from '../../static/assets/icons/help_search.svg';

function MobileHeader({ onToggled }) {
  const location = useLocation();
  const { isAuthenticated } = useContext(LoggedInUserContext);
  const { theme } = useContext(ThemeContext);
  const isSearchPage = location.pathname.includes(SUA_PAGES.SEARCH.path);
  return (
    isAuthenticated &&
    theme === THEMES.THEME_DEFAULT && (
      <div className={style.wrapper}>
        {isSearchPage ? (
          <div>
            <h1 className={style.search_title}> User Search </h1>
            <span className={style.search_description}>
              Click search icon to start/view search
            </span>
          </div>
        ) : (
          <ThingsLogo className={style.product_logo} />
        )}
        <ReactSVG
          className={style.action_icon}
          src={isSearchPage ? searchIcon : hamburgerMenu}
          onClick={() => onToggled()}
        />
      </div>
    )
  );
}

export default MobileHeader;
