import React, { useContext, useState } from 'react';
import TmoButton from '../../forms/TmoButton';
import StatusContext from '../../../context/StatusContext';
import FormModal from '../../modal/FormModal';
import withModal from '../../modal/withModal';
import TmoInput from '../../forms/TmoInput';
import TmoNumberInput from '../../forms/TmoNumberInput';
import TmoToggle from '../../forms/TmoToggle';
import TmoFieldset from '../../forms/TmoFieldset';
import TmoSelect from '../../forms/TmoSelect';
import { getRandomString } from '../../../utils/stringUtils';
import pocService from '../../../services/pocService';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';

import style from './KidswatchControls.module.css';

const contactFieldSpecs = [
  {
    labelText: 'msisdn',
    fieldName: 'msisdn',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'primary',
    fieldName: 'primary',
    formComponent: TmoToggle,
    required: true
  },
  {
    labelText: 'emergency',
    fieldName: 'emergency',
    formComponent: TmoToggle,
    required: true
  },
  {
    labelText: 'guest',
    fieldName: 'guest',
    formComponent: TmoToggle,
    required: true
  },
  {
    labelText: 'emergencySms',
    fieldName: 'emergencySms',
    formComponent: TmoToggle,
    required: true
  },
  {
    labelText: 'First Name',
    fieldName: 'fName',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'Last Name',
    fieldName: 'lName',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'iconUrl',
    fieldName: 'iconUrl',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'relationship',
    fieldName: 'rel',
    formComponent: TmoInput,
    required: true
  }
];

const geofenceFieldSpecs = [
  {
    labelText: 'name',
    fieldName: 'name',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'type',
    fieldName: 'type',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'Latitude',
    fieldName: 'la',
    formComponent: TmoNumberInput,
    required: true,
    min: -180,
    max: 180
  },
  {
    labelText: 'Longitude',
    fieldName: 'lo',
    formComponent: TmoNumberInput,
    required: true,
    min: -180,
    max: 180
  },
  {
    labelText: 'Radius',
    fieldName: 'ra',
    formComponent: TmoNumberInput,
    required: true,
    min: 1,
    max: 500
  }
];

function KidswatchControls({
  className,
  device,
  openModal,
  productId,
  patchDesiredState
}) {
  const [modalTitle, setModalTitle] = useState();
  const [modalFieldSpecs, setModalFieldSpecs] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const handleForceUpdateLocation = async () => {
    if (device.reportedState?.msisdn) {
      trackCustomEvents(`Send SMS to force update`, {
        deviceId: device.deviceId,
        productId
      });
      await pocService.sendSms({
        deviceId: device.deviceId,
        productId
      });
      addSuccessMessage({ message: `SMS queued` });
    } else {
      addErrorMessage({
        message: `${device.deviceId} does not have a msisdn reported state`
      });
    }
  };

  const openAddContactModal = async () => {
    setModalTitle('Add Contact');
    setModalFieldSpecs(contactFieldSpecs);
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      const contact = [
        ...(Array.isArray(device.desiredState?.contact)
          ? device.desiredState.contact
          : []),
        {
          id: getRandomString(),
          ...formValues
        }
      ];
      trackCustomEvents(`Add Contact`, {
        desiredState: { contact }
      });
      await patchDesiredState({
        desiredState: { contact }
      });
      addSuccessMessage({ message: `Successfully added ${formValues.fName}` });
    }
  };

  const openAddGeofenceModal = async () => {
    setModalTitle('Add Geofence');
    setModalFieldSpecs(geofenceFieldSpecs);
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      const geoFen = [
        ...(Array.isArray(device.desiredState?.geoFen)
          ? device.desiredState.geoFen
          : []),
        {
          id: getRandomString(),
          ...generateGeofence(formValues)
        }
      ];
      trackCustomEvents(`Add Geofence`, {
        desiredState: { geoFen }
      });
      await patchDesiredState({
        desiredState: { geoFen }
      });
      addSuccessMessage({ message: `Successfully added ${formValues.name}` });
    }
  };

  const openRemoveGeofenceModal = async () => {
    setModalTitle('Remove Geofence');
    let specKeys = device.desiredState.geoFen.map(c => c.id);
    setModalFieldSpecs([
      {
        labelText: 'Geofence',
        fieldName: 'id',
        formComponent: TmoSelect,
        selectSpecs: {
          options: specKeys.map(k => ({
            value: k,
            text: k
          }))
        },
        defaultValue: specKeys[0]
      }
    ]);
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      const geoFen = device.desiredState.geoFen.filter(
        c => c.id !== formValues.id
      );
      trackCustomEvents(`Remove Geofence`, {
        desiredState: { geoFen }
      });
      patchDesiredState({
        desiredState: { geoFen }
      });
      addSuccessMessage({ message: `Successfully removed geofence` });
    }
  };

  const openRemoveContactModal = async () => {
    setModalTitle('Remove Contact');
    let specKeys = device.desiredState.contact.map(c => c.id);
    setModalFieldSpecs([
      {
        labelText: 'Contact',
        fieldName: 'id',
        formComponent: TmoSelect,
        selectSpecs: {
          options: specKeys.map(k => ({
            value: k,
            text: k
          }))
        },
        defaultValue: specKeys[0]
      }
    ]);

    const { isConfirmed, formValues } = await openModal();

    if (isConfirmed) {
      const contact = device.desiredState.contact.filter(
        c => c.id !== formValues.id
      );
      trackCustomEvents(`Remove Contact`, {
        desiredState: { contact }
      });
      patchDesiredState({
        desiredState: { contact }
      });
      addSuccessMessage({ message: `Successfully removed contact` });
    }
  };

  function generateGeofence(formValues) {
    const points = [
      {
        la: formValues[`la`],
        lo: formValues[`lo`]
      }
    ];

    const opts = {
      silent: Math.random() >= 0.5,
      enabled: Math.random() >= 0.5,
      days: {
        [`1`]: Math.random() >= 0.5,
        [`2`]: Math.random() >= 0.5,
        [`3`]: Math.random() >= 0.5,
        [`4`]: Math.random() >= 0.5,
        [`5`]: Math.random() >= 0.5,
        [`6`]: Math.random() >= 0.5,
        [`7`]: Math.random() >= 0.5
      },
      date: Math.round(new Date().getTime()),
      sTs: '08:00:00+00',
      eTs: '17:00:00+00'
    };

    return {
      id: getRandomString(),
      name: formValues[`name`],
      type: formValues[`type`],
      ra: formValues[`ra`],
      pts: points,
      opts
    };
  }

  return (
    <>
      <TmoButton onClick={handleForceUpdateLocation}>
        Send SMS to force update
      </TmoButton>
      <TmoFieldset
        className={style.fieldset}
        legendText="Contact"
        inline={true}
      >
        <TmoButton onClick={openAddContactModal}>Add</TmoButton>
        <TmoButton
          onClick={openRemoveContactModal}
          disabled={
            !Array.isArray(device.desiredState?.contact) ||
            !device.desiredState.contact.length
          }
        >
          Remove
        </TmoButton>
      </TmoFieldset>
      <TmoFieldset className={style.fieldset} legendText="Geofen" inline={true}>
        <TmoButton onClick={openAddGeofenceModal}>Add</TmoButton>
        <TmoButton
          onClick={openRemoveGeofenceModal}
          disabled={
            !Array.isArray(device.desiredState?.geoFen) ||
            !device.desiredState.geoFen.length
          }
        >
          Remove
        </TmoButton>
      </TmoFieldset>
      <FormModal title={modalTitle} fieldSpecs={modalFieldSpecs} />
    </>
  );
}

export default withModal(KidswatchControls);
