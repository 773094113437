import React, { useContext } from 'react';
import { IFrame as Iframe, TmoCard } from 'tmo-bps-syncup-web-component-lib';

import config from '../../../configs/config';
import SyncUpProductContext from '../../../context/SyncUpProductContext';

const { iframeFindMyWatchWatchURL } = config.powerBiSettings;

function FindMyWatch() {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const iframe = () => {
    switch (syncUpProductContext.syncUpAppName) {
      case 'KIDSWATCH':
        return iframeFindMyWatchWatchURL;
      default:
        return;
    }
  };

  return (
    <TmoCard>
      <Iframe src={iframe()} width="100%" height="836px" />
    </TmoCard>
  );
}

export default FindMyWatch;
