export const BATTERY_STATUS_CODES = [
  {
    type: 'BATTERYSTATUS',
    name: 'Battery Status',
    description: `const val DEVICE_BATTERY_NORMAL_POWER = 0
const val DEVICE_BATTERY_CHARGING = 1
const val DEVICE_BATTERY_CHARGING_COMPLETE = 2
const val DEVICE_BATTERY_DAMAGED = 3
const val DEVICE_BATTERY_LOW = 4
const val DEVICE_BATTERY_NOT_INSTALLED = 5
const val DEVICE_BATTERY_UNKNOWN = 6`
  }
];

export const ERROR_CODES = [
  {
    type: 'BLE',
    name: 'TDUnableToInit',
    code: '701',
    description: 'Thrown when a Tracking Device object fails to initialize.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToConnectToBluetooth',
    code: '702',
    description:
      'Thrown when the Bluetooth Manager fails to connect to the Tracking Device'
  },
  {
    type: 'BLE',
    name: 'TDNoBluetooth',
    code: '703',
    description: 'Thrown when the permission to use Bluetooth is denied.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetInformation',
    code: '704',
    description:
      "Thrown when the Tracking Device's information fails to initialize. (Invalid Data)"
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetActivity',
    code: '705',
    description:
      'Thrown when the Tracking Activity information fails to initialize. (Invalid Data)'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetBatteryLevel',
    code: '706',
    description: 'Thrown when the Battery Level data is invalid.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetComms',
    code: '707',
    description: 'Thrown when the Communications Channel Mode data is invalid.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetLw',
    code: '708',
    description: 'Thrown when the LWM2M protocol data is invalid.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetTemperature',
    code: '709',
    description: 'Thrown when the temperature data is invalid.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetVersion',
    code: '710',
    description: 'Thrown when the version data is invalid.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetNsep',
    code: '711',
    description: 'Thrown when the Communications Channel Mode data is invalid.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToGetLed',
    code: '712',
    description: 'Thrown when the LED status data is invalid.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToSetLed',
    code: '713',
    description: 'Thrown when the command to change the LED status fails.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToReadCharacteristic',
    code: '714',
    description:
      'Thrown when retrieving a characteristic from a Tracking Device fails.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToWriteCharacteristic',
    code: '715',
    description:
      'Thrown when writing a characteristic to a Tracking Device fails.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToUpdateCharacteristic',
    code: '716',
    description:
      'Thrown when updating a characteristic on a Tracking Device Fails.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToDiscoverCharacteristic',
    code: '717',
    description: 'Thrown when discover service for characteristics fails.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToRetrieveFromBluetooth',
    code: '718',
    description:
      'Thrown when the Bluetooth Manager fails to retrieve a Tracking Device.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToScanBluetooth',
    code: '719',
    description: 'Thrown when the BLE scanning for Tracking Devices fails.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToUpdateLocation',
    code: '720',
    description:
      'Thrown when the Location Manager fails to provide an updated location for the Tracking Device.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToConnectWifi',
    code: '723',
    description:
      'Thrown when connecting to WiFi network fails. (User correctable error - invalidSSID, invalidSSIDLength, invalidSecurityKey, invalidSecurityKeyLength)'
  },
  {
    type: 'BLE',
    name: 'TDUnableToConnectWifiFatal',
    code: '724',
    description:
      'Thrown when connecting to WiFi network encounters a fatal error. '
  },
  {
    type: 'BLE',
    name: 'TDUnableToResetReportTimer',
    code: '725',
    description: 'Thrown when resetting the Sensor Data report timer fails. '
  },
  {
    type: 'BLE',
    name: 'TDUnableToScanWifi',
    code: '726',
    description: 'Thrown when scanning wifi fails.'
  },
  {
    type: 'BLE',
    name: 'TDUnableToDisconnectDevice',
    code: '727',
    description: 'Thrown when disconnecting from device fails.'
  },
  {
    type: 'AUTH',
    name: 'AuthUnableToAuthenticate',
    code: '760',
    description: 'Thrown when TMOID fails to authenticate a user.'
  },
  {
    type: 'AUTH',
    name: 'AuthUnableToRetrieveUser',
    code: '761',
    description: 'Thrown when a cached user fails to be retrieved.'
  },
  {
    type: 'LOSTMODE',
    name: 'LMConfirmationNotReceived',
    code: '780',
    description:
      "Thrown when Lost Mode wasn't activated for a pet after 30 minutes."
  },
  {
    type: 'LOCATION',
    name: 'LocLastUserLocationNotAvailable',
    code: '820',
    description: 'Thrown when there is no Last User Location available.'
  },
  {
    type: 'LOCATION',
    name: 'LocUnableToUpdateLocation',
    code: '821',
    description:
      'Thrown when the Location Manager fails to update the current location.'
  },
  {
    type: 'DOCS',
    name: 'DocUnableToWrite',
    code: '790',
    description: 'Thrown when caching a document fails.'
  },
  {
    type: 'DOCS',
    name: 'DocUnableToGet',
    code: '791',
    description: 'Thrown when retrieving a cached document fails.'
  },
  {
    type: 'DOCS',
    name: 'DocUnableToDelete',
    code: '792',
    description: 'Thrown when deleting a cached document fails.'
  },
  {
    type: 'PLA',
    name: 'PUnableToReportSensorData',
    code: '800',
    description: 'Thrown when sending a Sensor Data reporting request fails.'
  },
  {
    type: 'DOCS',
    name: 'PUnableToReportLocation',
    code: '801',
    description: 'Thrown when sending a Location update request fails. '
  },
  {
    type: 'DEFAULT',
    name: 'Default',
    code: '700',
    description: 'Thrown for nonspecific errors.'
  },
  {
    type: 'DOMAINS',
    name: 'Domains',
    code: '',
    description: `enum class PlatformDomain(val code: Int) {
  PASSTHROUGH(0),
  GLOBAL(1),
  ORCHESTRATION(2),
  LOCATION(3),
  THING(4),
  USER(5),
  DEVICE(6),
  NSEP_ADAPTER(7),
  UNKNOWN(99);
}`
  },
  {
    type: 'AUTHENTICATION_CODES',
    name: 'Authentication Codes (Used by all Platform Domains)',
    code: '',
    description: `enum class AuthenticationError(override val problemCode: Int) : ServiceError {
  GENERIC(900),
  OPENID_DISCOVERY(901),
  JWT_VALIDATION_ONE(902),
  JWT_VALIDATION_TWO(903),
  INCORRECT_INITIAL_TOKEN(904),
  UNKNOWN_ISSUER(905),
  ACCESS_DENIED(906), // Likely due to incorrect roles
  AUTHORIZATION_HEADER_MISSING(907),
  JWT_PARSING_FAILED(908),
  REACTIVE_AUTHENTICATION_FAILURE(909),
  UNAUTHENTICATED_DATABASE_WRITE(910),
  SAML_PARSING_FAILED(911),
  UNAUTHORIZED_SAML_TOKEN(912),
  UNAUTHORIZED_HOST_IP(913);
  
  override val errorDomain = ServiceError.defaultError.errorDomain
}`
  },
  {
    type: 'ORCHESTRATION_SERVICE_CODES',
    name: 'Orchestration Service Codes (2)',
    code: '',
    description: `enum class OrchestrationServiceError(override val problemCode: Int) : ServiceError {
  GENERIC(0),
  CONTROLLER_TIMEOUT(1),
  LINK_DEVICE_IN_USE(2),
  CREATE_THING_LINK_DEVICE_IN_USE(3),
  TRANSFER_DEVICE_NOT_CURRENT(4),
  TRANSFER_DEVICE_UNKNOWN(5),
  ADMIN_DEVICE_CLIENT(6),
  DEVICE_CLIENT(7),
  ADMIN_THING_CLIENT(8),
  THING_CLIENT(9),
  USER_CLIENT(10),
  LOCATION_CLIENT(11),
  INVALID_REQUEST_PARAMETERS(12),
  INVALID_FILE_TYPE(13),
  INVALID_FILE(14);
  
  override val errorDomain = PlatformDomain.ORCHESTRATION
}`
  },
  {
    type: 'THING_SERVICE_CODES',
    name: 'Thing Service Codes (4)',
    code: '',
    description: `enum class ThingServiceError(override val problemCode: Int) : ServiceError {
  GENERIC(0),
  FIND_BY_ID_AND_USER_ID(1),
  FIND_NOT_DELETED_BY_ID_AND_USER_ID(2),
  FIND_BY_DEVICE_ID(3),
  GET_LINK(4),
  CREATE_LINK(5),
  DELETE_LINK(6),
  INVALID_THING_SUB_CLASS(7),
  BREED_ALREADY_EXISTS(8),
  NOT_FOUND_OR_INVALID_BREED_ID(9);
  
  override val errorDomain = PlatformDomain.THING
}`
  },
  {
    type: 'DEVICE_SERVICE_CODES',
    name: 'Device Service Codes (6)',
    code: '',
    description: `enum class DeviceServiceError(override val problemCode: Int) : ServiceError {
  GENERIC(0),
  GOOGLE_MAPS_GEOLOCATE(1),
  UNIQUE_HARDWAREID_BY_USER(2),
  DB_QUERY_NO_RESULTS(3),
  GET_SENSORY_HISTORY_PARAMETER_VALIDATION(4),
  HARDWAREID_REGISTERED_REGISTERED_TO_OTHER_USER(5),
  HARDWAREID_ALREADY_REGISTERED_AND_ACTIVE(6),
  SET_LOST_MODE_JOB_STATUS_INCORRECT(7),
  SET_LIGHT_JOB_STATUS_INCORRECT(8),
  SET_BUZZER_JOB_STATUS_INCORRECT(9),
  TEMP_NOT_AVAILABLE(10),
  DEVICE_ACTIVITY_RANGE_TOO_LARGE(11),
  CONTROLLER_INPUT_VALIDATION(12),
  CREATE_VERSION_FAILED(13),
  HARDWAREID_VALIDATION_TIMED_OUT(14),
  DEVICE_PATCH_INVALID(15),
  PATCH_DEVICE_NOT_FOUND(16),
  UPDATE_VERSION_NOT_FOUND(17),
  PATCH_FIRMWARE_VERSION_NOT_FOUND(18),
  FIRMWARE_VERSION_PATCH_INVALID(19),
  HARDWAREID_NOT_FOUND_IN_OBSERVATION_TRACKER(20),
  DEVICE_MSISDN_NOT_AVAILABLE(21),
  HARDWAREID_ALREADY_DECOMMISSIONED(22),
  TMOBILE_SMS_SEND_OUTBOUND_MESSAGE(23),
  CURRENT_FIRMWARE_VERSION_NOT_FOUND(24),
  LATEST_SENSOR_NOT_FOUND(25),
  LOST_DOG_MODE_CALL_NO_COMBINED_SUCCEEDED(26),
  GET_LOST_DOG_MODE_ACTIVATIONS_PARAMETER_VALIDATION(27),
  LOST_DOG_MODE_COMMAND_NOT_FOUND(28),
  SMS_DELIVERY_REPORT_INVALID_CALLBACK_DATA(29),
  GET_DEVICE_ACTIVITY_PARAMETER_VALIDATION(30),
  
  // NSEP
  NSEP_PING_DEVICE(40),
  NSEP_DISCOVER_OBJECT_INSTANCES(41),
  NSEP_CREATE_OBSERVATION_JOB(42),
  NSEP_CREATE_SENSOR_READ_JOB(43),
  NSEP_CREATE_SENSOR_WRITE_JOB(44),
  NSEP_READ_SENSOR_JOB(45),
  NSEP_DELETE_JOB(46),
  NSEP_EXECUTE_JOB(47),
  NSEP_IMEI_LOOKUP(48),
  NSEP_QUERY_ACTIVE_OBSERVATION_JOBS(49),
  NSEP_READ_SIM_INFO(50);
  
  override val errorDomain = PlatformDomain.DEVICE
}`
  },
  {
    type: 'USER_SERVICE_CODES',
    name: 'User Service Codes (5)',
    code: '',
    description: `enum class UserServiceError(override val problemCode: Int) : ServiceError {
  GENERIC(0),
  INVALID_TERMS_VERSION(1),
  USER_SEARCH_INVALID(2),
  USER_PATCH_INVALID(3),
  USER_NOTIFICATION_TOKEN_INVALID(4),
  USER_GET_NOTIFICATION_TIME_RANGE_INVALID(5),
  NOTIFICATION_PLATFORM_TYPE_INVALID(6),
  INVALID_LOCALE(7),
  WEBCLIENT_APP_CENTER_FAILED(8),
  WEBCLIENT_GOOGLE_MAPS_DATA_RETRIEVAL_FAILED(9),
  NOTIFY_USER_NOT_FOUND(10),
  GET_USER_NOT_FOUND(11),
  PATCH_USER_NOT_FOUND(12),
  WEBCLIENT_SENDGRID_FAILED(13),
  UPDATE_VERSION_NOT_FOUND(14),
  APP_VERSION_PATCH_INVALID(15),
  PATCH_APP_VERSION_NOT_FOUND(16),
  CREATE_VERSION_FAILED(17),
  INVALID_SNOOZE_DATE(18),
  NOTIFICATION_PATCH_INVALID(19),
  PATCH_NOTIFICATION_NOT_FOUND(20),
  GET_NOTIFICATION_NOT_FOUND(21),
  NOTIFICATION_NOT_FOUND_IN_REPO(22);

  override val errorDomain = PlatformDomain.USER
}`
  },
  {
    type: 'LOCATION_SERVICE_CODES',
    name: 'Location Service Codes (3)',
    code: '',
    description: `enum class LocationServiceError(override val problemCode: Int) : ServiceError {
  GENERIC(0),
  DELETE_GEOFENCE_NOT_FOUND(1),
  UPDATE_GEOFENCE_NOT_FOUND(2),
  CREATE_GEOFENCE_LIMIT_REACHED(3),
  INVALID_GEOFENCE_NAME(4),
  DUPLICATE_GEOFENCE_NAME(5),
  INVALID_GEOFENCE(6),
  INVALID_GEOFENCE_RADIUS_TOO_SMALL(7),
  INVALID_GEOFENCE_RADIUS_TOO_BIG(8),
  INVALID_GEOFENCE_AREA_TOO_BIG(9);

  override val errorDomain = PlatformDomain.LOCATION
}
  
object LocationServiceErrorConstants {
  const val MAXIMUM_GEOFENCE_AREA = "maximumGeofenceArea"
  const val MINIMUM_GEOFENCE_RADIUS = "minimumGeofenceRadius"
  const val MAXIMUM_GEOFENCE_RADIUS = "maximumGeofenceRadius"
  const val MAXIMUM_GEOFENCES = "maximumGeofences"
}`
  }
];
