import React from 'react';
import classNames from 'classnames';

import style from './TmoFieldset.module.css';

function TmoFieldset({ className, inline, legendText, children }) {
  return (
    <fieldset className={classNames(inline && style.inline, className)}>
      <legend>{legendText}</legend>
      {children}
    </fieldset>
  );
}

export default TmoFieldset;
