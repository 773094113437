import deviceRepository from '../repositories/deviceRepository';
import { APP_MOBILE } from 'utils/app_constants';
import { getFormattedDateTime, getTimestampList } from '../utils/dateUtils';
import { formatPhoneNumber, highlightSearchQuery } from '../utils/stringUtils';

const msisdn = 'msisdn';
const ssid = 'ssid';

let hwFormattedResult = ({ data, query }) => {
  return data.map(hw => {
    let formatSsid = hw[ssid] === hw[msisdn] && {
      [ssid]: formatPhoneNumber(hw[ssid])
    };
    return {
      ...hw,
      ...formatSsid,
      ...{ msisdn: formatPhoneNumber(hw[msisdn]) },
      ...{ id: highlightSearchQuery({ imei: hw.id, query: query }) }
    };
  });
};

const deviceService = {
  getLogsByIMEI: async ({ logsRequestObj, syncUpAppName }) => {
    return await deviceRepository.getLogsByIMEI({
      logsRequestObj,
      syncUpAppName
    });
  },
  getDevicesByUserId: async ({ userId, syncUpAppName }) => {
    const results = await deviceRepository.getDevicesByUserId({
      userId,
      syncUpAppName
    });
    return syncUpAppName === 'DRIVE'
      ? results.map(d => ({
          ...d,
          currentTime: getFormattedDateTime({ dateTime: d['currentTime'] }),
          lastCommunicationDateTime: getFormattedDateTime({
            dateTime: d['lastConnectedAt']
          }),
          onboardingCreatedDateTime: d['claimedAt'],
          onboardingUpdateDateTime: d['statusAt']
        }))
      : results.map(d => ({
          ...d,
          currentTime: getFormattedDateTime({ dateTime: d['currentTime'] }),
          lastCommunicationDateTime: getFormattedDateTime({
            dateTime: d['lastCommunicationDateTime']
          })
        }));
  },
  claimDriveDevice: async ({ userId, deviceId, syncUpAppName }) => {
    return await deviceRepository.claimDriveDevice({
      userId,
      deviceId,
      syncUpAppName
    });
  },
  getPrivacyStatus: async ({ userId, deviceId, syncUpAppName }) => {
    const results = await deviceRepository.getPrivacyStatus({
      userId,
      deviceId,
      syncUpAppName
    });
    return results;
  },
  getSensorHistory: async ({
    userId,
    deviceId,
    sensorType,
    query,
    syncUpAppName
  }) => {
    const result = await deviceRepository.getSensorHistory({
      userId,
      deviceId,
      sensorType,
      query,
      syncUpAppName
    });
    let formatTimeStamps = result.data.map(d => {
      return {
        ...d,
        currentTime: getFormattedDateTime({ dateTime: d['currentTime'] }),
        softwareWifiVersionUpdatedDateTime: getFormattedDateTime({
          dateTime: d['softwareWifiVersionUpdatedDateTime']
        })
      };
    });
    return { ...{ page: result.page }, ...{ data: formatTimeStamps } };
  },
  getDevicesByQuery: async ({ query, page, results, syncUpAppName }) => {
    return await deviceRepository.getDevicesByQuery({
      query,
      page,
      results,
      syncUpAppName
    });
  },
  getSoftwareHardwareVersions: async () => {
    return await deviceRepository.getSoftwareHardwareVersions();
  },
  unregisterDevice: async ({ userId, deviceId, syncUpAppName }) => {
    return await deviceRepository.unregisterDevice({
      userId,
      deviceId,
      syncUpAppName
    });
  },
  removeRealDriveDevice: async ({ userId, deviceId, syncUpAppName }) => {
    return await deviceRepository.removeRealDriveDevice({
      userId,
      deviceId,
      syncUpAppName
    });
  },

  bulkUnregisterDevice: async ({ hardwareIds }) => {
    const syncUpAppName = APP_MOBILE.DRIVE;
    const results = await deviceRepository.bulkUnregisterDevice({
      syncUpAppName,
      hardwareIds
    });
    return results.map(s => ({
      hardwareId: s.hardwareId,
      status: s.status,
      message: s.message,
      product: s.product !== null ? s.product : 'NOT_FOUND'
    }));
  },
  bulkRecommissionrDevice: async ({ hardwareIds, syncUpAppName }) => {
    const results = await deviceRepository.bulkRecommissionrDevice({
      hardwareIds,
      syncUpAppName
    });
    return results?.iothubDevicesResponses.map(s => ({
      hardwareId: s.hardwareId,
      status: s.status,
      message: s.message,
      product: s.product !== null ? s.product : 'NOT_FOUND'
    }));
  },
  bulkvalDeviceExistancerDevice: async ({ hardwareIds, syncUpAppName }) => {
    const results = await deviceRepository.bulkvalDeviceExistancerDevice({
      hardwareIds,
      syncUpAppName
    });
    return results?.iothubDevicesResponses.map(s => ({
      hardwareId: s.hardwareId,
      status: s.status,
      message: s.message,
      product: s.product !== null ? s.product : 'NOT_FOUND'
    }));
  },
  getRecentDevices: async () => {
    const results = await deviceRepository.getRecentDevices();
    results.sort((a, b) => (a.createdDateTime < b.createdDateTime ? 1 : -1));
    return results;
  },
  getLostModeHistory: async ({ deviceId }) => {
    const results = await deviceRepository.getLostModeHistory({ deviceId });

    return results.lostModeStatuses.map(s => ({
      dateTime: getTimestampList(s.dateTime, null, 'USER'),
      status: s.status
    }));
  },
  healthCheck: async ({ userId, deviceId, syncUpAppName }) => {
    return await deviceRepository.healthCheck({
      userId,
      deviceId,
      syncUpAppName
    });
  },
  getHardwareDetails: async ({ imei, syncUpAppName }) => {
    const result = await deviceRepository.getHardwareDetails({
      imei,
      syncUpAppName
    });
    return { ...result, ...{ [msisdn]: formatPhoneNumber(result[msisdn]) } };
  },
  getHardwareByQuery: async ({ query, page, results, syncUpAppName }) => {
    const result = await deviceRepository.getHardwareByQuery({
      query,
      page,
      results,
      syncUpAppName
    });
    return {
      ...{ page: result.page },
      ...{ data: hwFormattedResult({ data: result.data, query }) }
    };
  },
  getIotHardwareByQuery: async ({ query, results, syncUpAppName }) => {
    const result = await deviceRepository.getIotHardwareByQuery({
      query,
      results,
      syncUpAppName
    });
    return {
      ...{ page: result.length },
      ...{ data: result }
    };
  },
  getDeviceTwin: async ({ deviceId, syncUpAppName }) => {
    return await deviceRepository.getDeviceTwin({
      deviceId,
      syncUpAppName
    });
  },
  updateDeviceTrackingRate: async ({
    userId,
    deviceId,
    syncUpAppName,
    requestObj
  }) => {
    return await deviceRepository.updateDeviceTrackingRate({
      userId,
      deviceId,
      syncUpAppName,
      requestObj
    });
  },
  updateDeviceLostMode: async ({
    userId,
    deviceId,
    syncUpAppName,
    requestObj
  }) => {
    return await deviceRepository.updateDeviceLostMode({
      userId,
      deviceId,
      syncUpAppName,
      requestObj
    });
  },
  uploadDeviceLogs: async ({ userId, deviceId, syncUpAppName }) => {
    return await deviceRepository.uploadDeviceLogs({
      userId,
      deviceId,
      syncUpAppName
    });
  },
  updateDeviceBuzzerSensor: async ({
    userId,
    deviceId,
    syncUpAppName,
    requestObj
  }) => {
    return await deviceRepository.updateDeviceBuzzerSensor({
      userId,
      deviceId,
      syncUpAppName,
      requestObj
    });
  },
  executeCOAPCommand: async ({ syncUpAppName, requestObj }) => {
    return await deviceRepository.executeCOAPCommand({
      syncUpAppName,
      requestObj
    });
  },
  updateDeviceLightSensor: async ({
    userId,
    deviceId,
    syncUpAppName,
    requestObj
  }) => {
    return await deviceRepository.updateDeviceLightSensor({
      userId,
      deviceId,
      syncUpAppName,
      requestObj
    });
  }
};

export default deviceService;
