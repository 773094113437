import React, { useContext } from 'react';
import {
  FormModal,
  TmoInput,
  TmoButtons as TmoButton,
  withModal
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import { trackCustomEvents } from '../../utils/appInsight_analytics';
import userService from '../../services/userService';
import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';

import style from './AddAuthUser.module.css';
import userIcon from '../../static/assets/icons/user.svg';

function AddAuthUser({ authUsers, openModal, onAdd }) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const formFieldSpecs = [
    {
      labelText: 'First Name',
      defaultValue: 'Test',
      fieldName: 'firstName',
      formComponent: TmoInput,
      inputType: 'primary',
      minLength: '3',
      required: true,
      placeholder: 'Enter the First Name'
    },
    {
      labelText: 'Last Name',
      defaultValue: 'Tester',
      fieldName: 'lastName',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      minLength: '3',
      placeholder: 'Enter the Last Name'
    },
    {
      labelText: 'User Name',
      defaultValue: 'Tester',
      fieldName: 'userName',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      minLength: '3',
      placeholder: 'Enter the User Name'
    },
    {
      labelText: 'Password',
      defaultValue: '123456',
      fieldName: 'password',
      formComponent: TmoInput,
      minLength: '3',
      type: 'password',
      inputType: 'primary',
      required: true,
      placeholder: 'Enter the Password'
    },
    {
      labelText: 'E-mail ID',
      defaultValue: 'test.tester@test.com',
      fieldName: 'email',
      formComponent: TmoInput,
      type: 'email',
      inputType: 'primary',
      required: true,
      placeholder: 'Enter the Email ID'
    }
  ];

  const openAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Create Auth User`);
      const data = {
        allowedScopes: ['*'],
        coordinates: {
          latitude: 41.889512,
          longitude: -87.617595
        },
        email: formValues.email,
        firstName: formValues.firstName,
        icn: {
          type: 'ICN',
          version: 1
        },
        lastName: formValues.lastName,
        msisdns: ['11234567890'],
        password: formValues.password,
        preferences: {
          pushNotificationsEnabled: false,
          safeZonePushNotificationsEnabled: true
        },
        pushNotificationToken: {
          platform: 'ANDROID',
          token: '829e16b2-8dfe-4c3b-8f83-0fa73f417f87'
        },
        terms: {
          type: 'TERMS',
          version: 1
        },
        username: formValues.userName
      };
      try {
        let newUser = await userService.createAuthUser({
          syncUpAppName: syncUpProductContext.syncUpAppName,
          data
        });
        onAdd(newUser);
        addSuccessMessage({ message: 'User added successfully' });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <>
      <TmoButton
        icon={<ReactSVG src={userIcon} />}
        disabled={!authUsers}
        type="magenta_primary"
        onClick={openAddModal}
        className={style.add_user_btn}
      >
        Create New User
      </TmoButton>
      <FormModal
        title={`Create New User`}
        fieldSpecs={formFieldSpecs}
        modalType={'slide'}
      />
    </>
  );
}

export default withModal(AddAuthUser);
