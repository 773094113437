import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

function TmoEditor({ initialValue, onChange }) {
  const handleChange = value => {
    onChange(value);
  };
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  };
  const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

  return (
    <>
      <ReactQuill
        onChange={handleChange}
        modules={modules}
        formats={formats}
        defaultValue={initialValue}
      />
    </>
  );
}
export default TmoEditor;
