import React, { useState, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { TmoButtons as TmoButton } from 'tmo-bps-syncup-web-component-lib';

import ErrorMessageModal from '../errorHandler/ErrorMessageModal';
import { getFormattedDateForPicker } from '../../utils/dateUtils';

import style from './DateRangePicker.module.css';

const currentDate = getFormattedDateForPicker();
const START_DATE_TIME_KEY = 'start-date-time';
const END_DATE_TIME_KEY = 'end-date-time';

const DateRangePicker = forwardRef(
  (
    {
      minValueForPicker,
      startDateId,
      endDateId,
      onApply,
      className,
      isPrimary,
      noMargin,
      isApply
    },
    ref
  ) => {
    const [timeRange, setTimeRange] = useState({
      startDateTime: moment().toISOString(),
      endDateTime: moment().toISOString()
    });
    const [errorMessage, setErrorMessage] = useState({
      hasError: false,
      message: null
    });
    const { hasError, message } = errorMessage;
    const { startDateTime, endDateTime } = timeRange;

    const convertMinPickerValueFormat = () => {
      return minValueForPicker.substr(5, 5).replace('-', '/');
    };

    const getDefaultStartEndTime = () => {
      return {
        startTime: `${currentDate}T00:00`,
        endTime: `${currentDate}T23:59`
      };
    };

    const handleChange = e => {
      const { id, value } = e.target;
      if (value) {
        setTimeRange({ ...timeRange, [id]: moment(value).toISOString() });
      }
    };

    const handleButtonClick = () => {
      if (!startDateTime && !endDateTime) {
        handleOnError('Please select valid date time range');
        return false;
      }

      if (!startDateTime) {
        handleOnError('Please select start date time');
        return false;
      }

      if (!endDateTime) {
        handleOnError('Please select end date time');
        return false;
      }

      if (Date.parse(startDateTime) > Date.parse(endDateTime)) {
        handleOnError('End date cannot be less than start date');
        return false;
      }

      if (startDateId && endDateId) {
        onApply({
          [startDateId ? startDateId : START_DATE_TIME_KEY]: startDateTime,
          [endDateId ? endDateId : END_DATE_TIME_KEY]: endDateTime
        });
        return true;
      } else {
        onApply({ startDateTime, endDateTime });
        return true;
      }
    };

    const handleOnError = message => {
      setErrorMessage({ hasError: true, message: message });
    };

    const handleErrorMessageClose = () => {
      setErrorMessage({ hasError: false, message: null });
    };

    useImperativeHandle(ref, () => ({
      TriggerApply() {
        return handleButtonClick();
      }
    }));

    return (
      <>
        {hasError && (
          <ErrorMessageModal
            message={message}
            onClose={handleErrorMessageClose}
          />
        )}
        <div className={classNames(style.container, className)}>
          <div className={style.column}>
            <div className={style.row}>
              <label className={style.label}>Start</label>
              <input
                type="datetime-local"
                id="startDateTime"
                className={classNames(
                  style.input_date,
                  isPrimary && style.input_primary,
                  isApply && style.input_primary
                )}
                onChange={handleChange}
                defaultValue={getDefaultStartEndTime().startTime}
                min={minValueForPicker}
              />
            </div>
            <div
              className={classNames(
                style.row,
                noMargin && style.no_margin_right
              )}
            >
              <label className={style.label}>End</label>
              <input
                type="datetime-local"
                id="endDateTime"
                className={classNames(
                  style.input_date,
                  isPrimary && style.input_primary,
                  isApply && style.input_primary
                )}
                onChange={handleChange}
                defaultValue={getDefaultStartEndTime().endTime}
                min={minValueForPicker}
              />
            </div>
            {!isPrimary && (
              <div className={classNames(style.button_container, style.row)}>
                <TmoButton
                  name="btn_apply"
                  className={style.button}
                  onClick={handleButtonClick}
                  type="magenta_primary"
                >
                  Apply
                </TmoButton>
              </div>
            )}
          </div>
          {minValueForPicker && minValueForPicker !== '' && (
            <div className={classNames(style.column, style.note)}>
              <div className={style.row}>
                <span>
                  Data is available starting {convertMinPickerValueFormat()}
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);
export default DateRangePicker;
