import React, { useState, useContext, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  ModalV2,
  TmoButtons,
  RawDataObject,
  ExportJSONDataAsFile
} from 'tmo-bps-syncup-web-component-lib';

import RoleFeature from '../../featureWrappers/RoleFeature';
import iotFirmwareService from '../../../services/iotFirmwareService';
import SyncUpProductContext from '../../../context/SyncUpProductContext';
import Fetcher from '../../Fetcher';
import Error from '../../Error/Error';

import style from './AssignConfigObject.module.css';
import codeIcon from '../../../static/assets/icons/code.svg';
import closeIcon from '../../../static/assets/icons/action/close.svg';
import copyIcon from '../../../static/assets/icons/action/copy.svg';

function AssignConfigObject({ openModal, className, details, closeModal }) {
  const [firmware, setFirmware] = useState();
  const [copyState, setCopyState] = useState(false);
  const { firmwareId } = details;
  const syncUpProductContext = useContext(SyncUpProductContext);

  const actionCallback = useCallback(() => {
    return iotFirmwareService.getIotFirmwareById({
      firmwareId: firmwareId,
      product: syncUpProductContext.syncUpAppName
    });
  }, [syncUpProductContext, firmwareId]);

  const copyToClickBoard = () => {
    navigator.clipboard.writeText(
      JSON.stringify(firmware ? firmware : {}, null, 2)
    );
    setCopyState(true);
  };

  useEffect(() => {
    copyState &&
      setTimeout(async () => {
        setCopyState(false);
      }, 2000);
  }, [copyState, setCopyState]);

  return (
    <>
      <TmoButtons
        type="magenta_link"
        onClick={openModal}
        className={classNames(style.action_button, className)}
        icon={<ReactSVG src={codeIcon} />}
      >
        Assigned Config Object
      </TmoButtons>
      <ModalV2 title={`Assigned Config Object`} modalType="slide" portal={true}>
        <div className={style.container}>
          {firmwareId && (
            <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
              <div id={firmwareId} className={style.scrollable_content}>
                <Fetcher
                  action={actionCallback}
                  onLoad={setFirmware}
                  render={() =>
                    firmware ? (
                      <>
                        <RawDataObject rawObject={firmware} />
                      </>
                    ) : (
                      <Error message="No FW Config are assigned." />
                    )
                  }
                />
              </div>
            </RoleFeature>
          )}
          <div className={style.button_wrapper}>
            <TmoButtons
              icon={<ReactSVG src={closeIcon} />}
              onClick={closeModal}
              type="magenta_secondary"
              position="top"
            >
              Cancel
            </TmoButtons>
            <div className={style.inner_wrapper}>
              <TmoButtons
                icon={<ReactSVG src={copyIcon} />}
                onClick={copyToClickBoard}
                type="magenta_primary"
                position="top"
              >
                {copyState ? 'Copied' : 'Copy'}
              </TmoButtons>
              <ExportJSONDataAsFile
                buttonType={'magenta_primary'}
                buttonText="Export"
                data={firmware ? firmware : {}}
                fileName={'Assigned Config Object'}
                fileExtension="json"
                className={style.export_btn}
                tooltipPosition="top"
              />
            </div>
          </div>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(AssignConfigObject);
