import React, { useState, Fragment } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle,
  InfoWindow
} from 'react-google-maps';
import {
  Tooltip,
  TmoToggle,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';

import Safe_Zone_Green from '../../static/assets/Marker_Green.png';
import SafeZoneInfoWindow from '../safeZones/SafeZoneInfoWindow';
import LocationHistoryInfoWindow from '../sensors/location/LocationHistoryInfoWindow';
import { getCircleCenter, getMarkerCenter } from '../../utils/mapUtils';
import { filterBy } from '../../utils/helper_functions';

import style from './LocationHistorySafeZone.module.css';
import { IoIosArrowDown } from 'react-icons/all';

function LocationHistorySafeZone({
  center,
  zoom,
  places,
  mapStyles,
  onChange
}) {
  const [historyClicked, setHistoryClicked] = useState();
  const [zoneClicked, setZoneClicked] = useState();
  const [toggleSafeZones, setToggleSafeZones] = useState(false);
  const [toggleMarker, setToggleMarker] = useState(false);
  const [filter, setFilter] = useState('');

  const safeZoneClicked = place => {
    setZoneClicked(place);
  };

  const onHistoryClick = (place, index) => {
    setHistoryClicked({ ...place, index });
  };

  const selectOptions = [
    { key: 'All', value: 'View all' },
    {
      key: 'GPS',
      value: `GPS (${filterBy(places, { source: 'GPS' }).length})`
    },
    {
      key: 'WLAN',
      value: `WLAN (${filterBy(places, { source: 'WLAN' }).length})`
    },
    {
      key: 'MOBILE',
      value: `MOBILE (${filterBy(places, { source: 'MOBILE' }).length})`
    }
  ];

  let hasSafeZone = places.some(z => z.safeZone);

  return (
    <>
      <div className={style.action_controls}>
        <div className={style.filter}>
          <TmoDropDownSelect
            id="filterSelect"
            className={style.custom_dropdown}
            optionsList={selectOptions}
            onChange={e => {
              if (e === 'All') {
                setFilter({ source: '' });
                onChange('');
              } else {
                setFilter({ source: e });
                onChange(e);
              }
            }}
            renderIcon={<IoIosArrowDown />}
            defaultSelectedValue={selectOptions[0]}
          />
        </div>
        <div className={style.map_controls}>
          {hasSafeZone && (
            <div className={style.safezone_toggle}>
              <span>Toggle SafeZones</span>
              <TmoToggle
                id="Safezone-toggle-input"
                isChecked={toggleSafeZones}
                onChange={() => setToggleSafeZones(!toggleSafeZones)}
              />
            </div>
          )}
          <div className={style.marker_toggle}>
            <label
              className={style.marker_toggle_label}
              htmlFor="Markers-Movable"
            >
              <Tooltip
                tooltip="Use if there is a layering issue with the marker you are wanting to view. Locations will NOT be changed"
                position="left"
                className={style.tooltip}
              >
                Make Markers Draggable
              </Tooltip>
            </label>
            <TmoToggle
              id="Marker-toggle-input"
              isChecked={toggleMarker}
              onChange={() => setToggleMarker(!toggleMarker)}
            />
          </div>
        </div>
      </div>
      <GoogleMap
        defaultCenter={center}
        defaultZoom={zoom}
        mapContainerStyle={{
          height: '70vh',
          width: '100%'
        }}
        options={{ styles: mapStyles }}
      >
        {filterBy(places, filter).map((place, i) => {
          return (
            <Fragment key={place.id}>
              {place.history && (
                <Marker
                  position={getMarkerCenter(place)}
                  onClick={() => onHistoryClick(place, i + 1)}
                  title={JSON.stringify(getMarkerCenter(place))}
                  label={(i + 1).toString()}
                  key={i}
                  draggable={toggleMarker}
                />
              )}
            </Fragment>
          );
        })}
        {places.map(zone => {
          return (
            <Fragment key={zone.id}>
              {zone.safeZone && toggleSafeZones && (
                <Fragment>
                  <Marker
                    position={getCircleCenter(zone)}
                    onClick={() => safeZoneClicked(zone)}
                    icon={Safe_Zone_Green}
                    title={JSON.stringify(getCircleCenter(zone))}
                    label={zone.index.toString()}
                  />
                  <Circle
                    defaultCenter={getCircleCenter(zone)}
                    radius={zone.circle.radius}
                    title={JSON.stringify(getCircleCenter(zone))}
                    options={zone.circle.options}
                    onClick={() => safeZoneClicked(zone)}
                  />
                </Fragment>
              )}
            </Fragment>
          );
        })}

        {zoneClicked && (
          <InfoWindow
            anchor={zoneClicked.id}
            onCloseClick={() => setZoneClicked(false)}
            position={{
              lat: zoneClicked.circle.center.latitude,
              lng: zoneClicked.circle.center.longitude
            }}
          >
            <SafeZoneInfoWindow safeZoneClicked={zoneClicked} />
          </InfoWindow>
        )}
        {historyClicked && (
          <Fragment>
            <InfoWindow
              anchor={historyClicked.id}
              onCloseClick={() => setHistoryClicked(false)}
              position={{
                lat: historyClicked.latitude,
                lng: historyClicked.longitude
              }}
            >
              <LocationHistoryInfoWindow historyClicked={historyClicked} />
            </InfoWindow>
            <Circle
              position={{
                lat: historyClicked.latitude,
                lng: historyClicked.longitude
              }}
              defaultCenter={getMarkerCenter(historyClicked)}
              radius={historyClicked.radius}
              anchor={historyClicked.id}
              options={historyClicked.options}
            />
          </Fragment>
        )}
      </GoogleMap>
    </>
  );
}

export default withScriptjs(withGoogleMap(LocationHistorySafeZone));
