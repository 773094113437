import React, { useState } from 'react';
import classNames from 'classnames';

import style from './TmoSelect.module.css';

function TmoSelect({
  className,
  onChange,
  value,
  selectSpecs: { options } = {},
  defaultValue,
  ...rest
}) {
  const [val, setVal] = useState(value);
  const handleChange = e => {
    onChange(e.target.value);
    setVal(e.target.value);
  };

  return (
    <select
      className={classNames(style.select, className)}
      onChange={handleChange}
      value={val}
      {...rest}
    >
      {options?.map(option => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.text || option.value}
        </option>
      ))}
    </select>
  );
}

export default TmoSelect;
