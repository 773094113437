import { appInsights } from '../utils/appInsight_analytics';
import config from '../configs/config';

const trackFailedRequest = (response, body, rest, url, formData) => {
  const { headers } = config.apiSettings.syncUpPlatform;
  let userId = null;
  if (rest.headers && rest.headers[headers.userImpersonation.key]) {
    userId = rest.headers[headers.userImpersonation.key];
  }
  appInsights &&
    appInsights.trackEvent({
      name: response.ok ? 'Request Tracker' : 'Failed Request Tracker',
      properties: {
        requestDetails: {
          url,
          body: formData ? body : JSON.stringify(body),
          ...rest
        },
        responseDetails: {
          ok: response.ok,
          redirected: response.redirected,
          status: response.status,
          statusText: response.statusText,
          type: response.type,
          url: response.url
        },
        userInfo: {
          userId
        },
        pageDetails: {
          domain: window.location.origin,
          pathName: window.location.pathname,
          querryParams: window.location.search,
          pageUrl: window.location.href
        }
      }
    });
};

const httpRequest = async ({
  url,
  formData = false,
  parseResponse = JSON.parse,
  body,
  ...rest
}) => {
  const response = await fetch(url, {
    body: formData ? body : JSON.stringify(body),
    ...rest
  });
  const responseText = await response.text();
  if (rest.responseStatus) {
    if (!response.ok) {
      trackFailedRequest(response, body, rest, url, formData);
    }
    let apiResponse = {
      status: response.status,
      response: responseText
    };
    return apiResponse;
  }
  if (response.ok) {
    if (responseText) {
      return parseResponse(responseText);
    }
    return;
  }
  try {
    trackFailedRequest(response, body, rest, url, formData);
    throw Error(`${response.status} : ${response.statusText}`);
  } catch (e) {
    throw Error(`${response.status} : ${response.statusText}`);
  }
};

export default httpRequest;
