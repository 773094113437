import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
  TmoButtons as TmoButton,
  TmoInput,
  LabelWithInput,
  ConfirmationModal,
  ModalContext,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';

import useManualFetcher from '../../hooks/useManualFetcher';
import { FETCH_STATE } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import StatusContext from 'context/StatusContext';
import { prettifyCamelCase, stringToBoolean } from 'utils/stringUtils';
import deviceService from 'services/deviceService';
import simulatorService from 'services/simulatorService';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { COMMANDS, getSimulatedEventParams } from '../../utils/deviceConsts';

import style from './CommandCard.module.css';
import { IoIosArrowDown } from 'react-icons/all';

function CommandCard({ label, userId, deviceId, commandData, hardwareId }) {
  const [inputFields, setInputFields] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { params: requestParameters } = commandData;
  const [selectedOption, setSelectedOption] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);
  const { closeModal } = useContext(ModalContext);
  const handleFormChange = (index, v) => {
    let data = [...inputFields];
    data[index].value = v;
    setInputFields(data);
  };
  const handleSubmit = event => {
    event.preventDefault();
  };
  const openModalPopup = () => {
    setIsModalOpen(true);
  };
  const [fetchState, actionTrigger] = useManualFetcher({
    action: () => {
      let param = {};
      inputFields &&
        inputFields.forEach(e => {
          param = { ...param, [e.name]: e.value };
        });

      const simulatedEvent = category => {
        let body = getSimulatedEventParams(category, selectedOption, param);
        return simulatorService.createSimulatorDeviceEvent({
          hardwareId: hardwareId,
          syncUpAppName: product,
          body: body
        });
      };
      switch (label) {
        case COMMANDS.TRACKING:
          return deviceService.updateDeviceTrackingRate({
            userId: userId,
            deviceId: deviceId,
            syncUpAppName: product,
            requestObj: {
              trackingMode: selectedOption,
              duration: 900
            }
          });
        case COMMANDS.LIGHT:
          return deviceService.updateDeviceLightSensor({
            userId: userId,
            deviceId: deviceId,
            syncUpAppName: product,
            requestObj: {
              onOff: selectedOption
            }
          });
        case COMMANDS.BUZZER:
          return deviceService.updateDeviceBuzzerSensor({
            userId: userId,
            deviceId: deviceId,
            syncUpAppName: product,
            requestObj: {
              duration: 10,
              onOff: selectedOption
            }
          });
        case COMMANDS.LOST_MODE:
          return deviceService.updateDeviceLostMode({
            userId: userId,
            deviceId: deviceId,
            syncUpAppName: product,
            requestObj: {
              onOff: stringToBoolean(selectedOption)
            }
          });
        case COMMANDS.UPLOAD_DEVICE_LOGS:
          return deviceService.uploadDeviceLogs({
            userId: userId,
            deviceId: deviceId,
            syncUpAppName: product
          });
        case COMMANDS.BOOT_LEVEL:
        case COMMANDS.LOW_BATTERY:
        case COMMANDS.SOS_NOTIFICATION:
        case COMMANDS.LIGHT_SENSOR:
        case COMMANDS.GEOFENCE:
        case COMMANDS.PROXIMITY_NOTIFICATION:
        case COMMANDS.LOCATION_NOTIFICATION:
        case COMMANDS.ADMIN_911_NOTIFICATION:
        case COMMANDS.USER_TASK_NOTIFICATION:
          return simulatedEvent(label);
        case COMMANDS.MOTION_LOCK:
          return null;
        default:
          return null;
      }
    },
    onLoad: data => {
      addSuccessMessage({
        message: 'Command request sent successfully'
      });
    },
    onError: error => {
      addErrorMessage({ error });
    },
    onComplete: () => {
      setIsModalOpen(false);
    }
  });
  useEffect(() => {
    let inputs = [];
    requestParameters &&
      requestParameters.forEach(e => {
        if (e.type === 'select') {
          setSelectedOption(e.selectSpecs[0].key);
        }
        inputs = [
          ...inputs,
          {
            name: e.name,
            displayName: e.displayName,
            value: e.value,
            isVisible: e.isVisible,
            type: e.type,
            selectSpecs: e.selectSpecs
          }
        ];
      });
    setInputFields(inputs);
  }, [requestParameters, label]);

  const handleOptionChange = value => {
    setSelectedOption(value);
  };

  const handleCancel = event => {
    event.stopPropagation();
    setIsModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={style.form_content}>
        {inputFields &&
          inputFields.map((input, index) => (
            <Fragment key={`inputItems-${index}`}>
              {input.isVisible === true &&
                input.type &&
                input.type === 'select' && (
                  <>
                    <label className={style.label}> Command Type </label>
                    <TmoDropDownSelect
                      className={style.select_margin}
                      optionsList={input.selectSpecs}
                      onChange={handleOptionChange}
                      renderIcon={<IoIosArrowDown />}
                      defaultSelectedValue={input.selectSpecs[0]}
                    />
                  </>
                )}
              {input.isVisible === true && !input.type && (
                <LabelWithInput
                  labelText={prettifyCamelCase(
                    input.displayName ? input.displayName : input.name
                  )}
                  name={input.name}
                  key={input.name}
                  formComponent={TmoInput}
                  inputType="primary"
                  value={input.value}
                  title={input.value}
                  required
                  onChange={v => handleFormChange(index, v)}
                />
              )}
            </Fragment>
          ))}
        <div className={style.button_wrapper}>
          <TmoButton onClick={closeModal} type="magenta_secondary">
            Cancel
          </TmoButton>
          <TmoButton
            buttonType="submit"
            type="magenta_primary"
            className={style.execute}
            onClick={openModalPopup}
          >
            Execute
          </TmoButton>
        </div>
      </form>
      <ConfirmationModal
        isOpen={isModalOpen}
        modalType="primary"
        onAcceptBtnClicked={() => {
          trackCustomEvents(`Device Command- ${label}`, {
            deviceId: deviceId,
            userId: userId
          });
          actionTrigger();
        }}
        onDenyBtnClicked={handleCancel}
        buttonsDisabled={fetchState === FETCH_STATE.LOADING}
        message={`Are you sure you want to Execute ${label} command on this device?`}
        acceptBtnTitle="Execute"
        denyBtnTitle="Cancel"
      />
    </>
  );
}

export default CommandCard;
