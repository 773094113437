import config from '../configs/config';

const {
  schema,
  hostName,
  headers
} = config.apiSettings.commercialTrackerPlatform;

const getDefaultHeaders = () => ({
  Accept: headers.accept.value,
  [headers.contentTypeJSON.key]: headers.contentTypeJSON.value
});

const commercialTrackerRequest = async ({
  urlSuffix,
  httpMethod,
  headers,
  basePath = config.apiSettings.commercialTrackerPlatform.basePath,
  body
}) => {
  let url = `${schema}://${hostName}${basePath.api}${urlSuffix}`;

  const response = await fetch(url, {
    method: httpMethod,
    headers: {
      ...getDefaultHeaders(),
      ...headers
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    const responseText = await response.text();
    if (responseText) {
      return JSON.parse(responseText);
    }
    return;
  }
  throw Error(response.status);
};

export default commercialTrackerRequest;
