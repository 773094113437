import React from 'react';

import { NavLink } from 'react-router-dom';
import TmoSvg from '../../uiHelpers/TmoSvg';

import './CommercialLeftPanelNavigation.css';

function CommercialLeftPanelNavigation() {
  const dashboardUrl = `/commercial-tracker/dashboard`;
  const trackersUrl = `/commercial-tracker/devices`;
  const alertsUrl = '/commercial-tracker/alerts';

  return (
    <div className="CommercialLeftPanelNavigation">
      <NavLink
        key={dashboardUrl}
        className="CommercialLeftPanelNavigation-link"
        activeClassName="CommercialLeftPanelNavigation-link-active"
        to={dashboardUrl}
      >
        <TmoSvg
          svgId="icon-dashboard"
          className="CommercialLeftPanelNavigation-link-icon"
        />
        Dashboard
      </NavLink>
      <NavLink
        key={trackersUrl}
        className="CommercialLeftPanelNavigation-link"
        activeClassName="CommercialLeftPanelNavigation-link-active"
        to={trackersUrl}
      >
        <TmoSvg
          svgId="icon-devices"
          className="CommercialLeftPanelNavigation-link-icon"
        />
        Devices
      </NavLink>
      <NavLink
        key={alertsUrl}
        className="CommercialLeftPanelNavigation-link"
        activeClassName="CommercialLeftPanelNavigation-link-active"
        to={alertsUrl}
      >
        <TmoSvg
          svgId="icon-user"
          className="CommercialLeftPanelNavigation-link-icon icon-user"
        />
        Account
      </NavLink>
    </div>
  );
}

export default CommercialLeftPanelNavigation;
