import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect
} from 'react';
import {
  TmoInput,
  withModal,
  FormModal,
  TmoButtons,
  TmoSvg,
  TmoSearch,
  FilterModal,
  DynamicSort,
  TmoDropDownSelect,
  TmoSelect,
  PaginationV2
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import iotFirmwareService from '../../services/iotFirmwareService';
import Fetcher from '../Fetcher';
import SingleAsset from './SingleAsset';
import useVisible from '../../hooks/useVisible';
import StatusContext from '../../context/StatusContext';
import RoleFeature from '../featureWrappers/RoleFeature';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { MEDIA_PRIORITY } from '../../utils/mediaConsts';
import {
  IOT_ASSET_TYPES,
  MEDIA_PAGE_SIZE_OPTIONS
} from '../../utils/app_constants';
import { FaPlus } from 'react-icons/all';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import { hasValue } from 'utils/helper_functions';

import style from './AssetManager.module.css';
import paginationStyle from '../../static/assets/css/pagination.module.css';
import arrowLeft from '../../static/assets/icons/left_arrow.svg';
import arrowRight from '../../static/assets/icons/right_arrow.svg';
import { RiSearchLine, BsFilterLeft, IoIosArrowDown } from 'react-icons/all';
import Error from 'components/Error/Error';

const formFieldSpecs = [
  {
    labelText: 'Target Version',
    fieldName: 'target_version',
    formComponent: TmoInput,
    required: true,
    inputType: 'primary',
    placeholder: 'Target Firmware Version'
  },
  {
    labelText: 'Applies To',
    fieldName: 'applies_to',
    formComponent: TmoInput,
    inputType: 'primary',
    required: false,
    placeholder: 'applies to v1.0'
  },
  {
    labelText: 'Priority',
    fieldName: 'priority',
    formComponent: TmoSelect,
    required: false,
    placeholder: 'priority',
    selectSpecs: {
      options: Object.keys(MEDIA_PRIORITY).map(k => ({
        value: k,
        text: k
      }))
    },
    defaultValue: MEDIA_PRIORITY.NONE
  }
  // {
  //   labelText: 'Priority',
  //   fieldName: 'priority',
  //   formComponent: TmoDropDownSelect,
  //   required: false,
  //   placeholder: 'priority',
  //   optionsList: Object.keys(MEDIA_PRIORITY).map(k => ({
  //     key: k,
  //     value: k
  //   })),
  //   defaultSelectedValue: {
  //     key: MEDIA_PRIORITY.NONE,
  //     value: MEDIA_PRIORITY.NONE
  //   },
  //   defaultValue: MEDIA_PRIORITY.NONE,
  //   renderIcon: <IoIosArrowDown />
  // }
];

function Configurations({ openModal }) {
  const [filteredListLength, setFilteredListLength] = useState(0);
  const [filteredList, setFilteredList] = useState();
  const [firmware, setFirmware] = useState();
  const { visible, setVisible } = useVisible(false);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const mediaType = IOT_ASSET_TYPES.FIRMWARE;
  const [assetPerPage, setAssetPerPage] = useState(MEDIA_PAGE_SIZE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const sortRef = useRef();
  const searchtRef = useRef();
  const filtertRef = useRef();
  const [sortOptions] = useState([
    { key: 'id', value: 'ID' },
    { key: 'isActive', value: 'Active' },
    { key: 'priority', value: 'Priority' }
  ]);
  const selectOptions = MEDIA_PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} Configurations`
  }));
  selectOptions.push({
    key: 'All',
    value: 'All Configurations'
  });
  const showPagination = filteredList && filteredList?.length > assetPerPage;

  const getCurrentPageAssetList = () => {
    if (filteredList && filteredList.length) {
      const offset = (currentPage - 1) * assetPerPage;
      return filteredList.slice(offset, offset + assetPerPage);
    }
    return [];
  };

  const [filterOptions] = useState([
    { key: 'isActive', value: 'Active' },
    { key: 'priority', value: 'Priority' }
  ]);

  const handleLoadData = useCallback(data => {
    setFirmware(data);
    setFilteredList([...data]);
    setFilteredListLength(data.length);
  }, []);

  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const handleOnFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (searchtRef?.current?.ApplyFilters) {
        searchtRef.current.ApplyFilters(data.list);
      }
    }
  };

  const handleSearchFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (filtertRef?.current?.ApplyFilters) {
        filtertRef.current.ApplyFilters(data.list);
      }
    }
  };

  const actionCallback = useCallback(() => {
    return iotFirmwareService.getAllIotFirmware({
      product: syncUpProductContext.syncUpAppName
    });
  }, [syncUpProductContext]);

  useEffect(() => {
    if (firmware) {
      setFilteredList([...firmware]);
      setFilteredListLength(firmware.length);
    }
  }, [firmware]);

  const openAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Add New ${mediaType} Config`);
      setVisible(true);
      try {
        await iotFirmwareService.createIotFirmware({
          body: {
            targetVersion: formValues.target_version,
            appliesTo: formValues.applies_to,
            isActive: false,
            priority:
              formValues.priority === 'NONE'
                ? null
                : formValues.priority.toLowerCase()
          },
          product: syncUpProductContext.syncUpAppName
        });
        setFirmware(await actionCallback());
        addSuccessMessage({
          message: `Successfully added ${mediaType} configuration: ${formValues.applies_to}`
        });
        setVisible(false);
      } catch (error) {
        setVisible(false);
        addErrorMessage({ error });
      }
    }
  };

  const handleFwEdit = async () => {
    return setFirmware(await actionCallback());
  };

  const handleFwRemove = async ({ assetId }) => {
    try {
      setVisible(true);
      await iotFirmwareService.deleteIotFirmware({
        firmwareId: assetId,
        product: syncUpProductContext.syncUpAppName
      });
      setFirmware(await actionCallback());
      addSuccessMessage({
        message: `${assetId} removed successfully`
      });
      setVisible(false);
    } catch (error) {
      setVisible(false);
      addErrorMessage({ error });
    }
  };

  return (
    <>
      <PageHeader
        title={'Configurations'}
        primary
        subtitle={
          filteredListLength > 1 &&
          `${filteredListLength} Configurations available`
        }
      >
        <TmoSearch
          placeholder="Search by Target Version"
          originalList={firmware}
          options={['targetVersion']}
          onFilter={handleSearchFilter}
          ref={searchtRef}
          renderIcon={<RiSearchLine />}
        />

        <DynamicSort
          ref={sortRef}
          listItems={filteredList}
          optionsList={sortOptions}
          onSort={e => {
            handleOnSort(e);
          }}
          renderIcon={<IoIosArrowDown />}
        />
        <FilterModal
          ref={filtertRef}
          options={filterOptions}
          originalList={firmware}
          listItems={filteredList}
          onFilter={handleOnFilter}
          renderIcon={<BsFilterLeft />}
        />
        <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
          <TmoButtons
            type="magenta_primary"
            icon={<FaPlus />}
            onClick={openAddModal}
          >
            Add New {mediaType} Config
          </TmoButtons>
        </RoleFeature>
        <FormModal
          modalType="slide"
          title={`Add new ${mediaType.toLowerCase()} configuration`}
          fieldSpecs={formFieldSpecs}
        />
      </PageHeader>

      <div className={paginationStyle.pagination_wrapper}>
        <div className={paginationStyle.page_size}>
          <TmoDropDownSelect
            optionsList={selectOptions}
            onChange={e => {
              if (e === 'All') {
                setAssetPerPage(
                  filteredList && filteredList.length
                    ? filteredList.length + 1
                    : 0
                );
              } else {
                setAssetPerPage(Number(e));
              }
              setCurrentPage(1);
            }}
            renderIcon={<IoIosArrowDown />}
            defaultSelectedValue={
              selectOptions.filter(e => e.key === assetPerPage)[0]
            }
          />
        </div>
        {showPagination && (
          <div>
            <PaginationV2
              className={paginationStyle.custom_pagination}
              totalPages={Math.ceil(filteredList.length / assetPerPage)}
              selectedPageNumber={currentPage}
              onPageChanged={currentPage => setCurrentPage(currentPage)}
              type="primary"
              icons={{
                left: <ReactSVG src={arrowLeft} />,
                right: <ReactSVG src={arrowRight} />
              }}
            />
          </div>
        )}
      </div>

      {visible && <TmoSvg className={style.spinner} svgId="loading-spinner" />}
      <Fetcher
        action={actionCallback}
        onLoad={handleLoadData}
        render={() =>
          getCurrentPageAssetList && hasValue(filteredList) ? (
            <div className={style.results}>
              {getCurrentPageAssetList().map(fw => (
                <SingleAsset
                  key={fw.id}
                  mediaType={mediaType}
                  details={fw}
                  onRemove={handleFwRemove}
                  onEdit={handleFwEdit}
                />
              ))}
            </div>
          ) : (
            <Error
              heading="There are no firmware configurations available."
              message="Add a new configuration"
            />
          )
        }
      />
    </>
  );
}

export default withModal(Configurations);
