import React from 'react';

import ContentContainer from '../uiHelpers/ContentContainer';
import TmoH1 from '../controlWrappers/TmoH1';
import config from '../../configs/config';

function AuthEnvironments({ environments = [], message = '', children }) {
  const currentEnvironment = config.apiSettings.environment;

  return environments.includes(currentEnvironment) ? (
    <>{children}</>
  ) : (
    <ContentContainer>
      {message ? (
        message
      ) : (
        <TmoH1 flush>
          This resource is not available in the <u>{currentEnvironment}</u>{' '}
          environment
        </TmoH1>
      )}
    </ContentContainer>
  );
}

export default AuthEnvironments;
