import React, { useState, useCallback, useContext, useMemo } from 'react';
import { FaPlus } from 'react-icons/all';
import {
  TmoCard,
  TmoH1,
  withModal,
  TmoButtons,
  TmoInput,
  TmoTextarea,
  FormModal,
  TmoSelect,
  PaginationV3
} from 'tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import firmwareService from '../../services/firmwareService';
import FirmwareGroupItem from './GroupItem';
import RoleFeature from '../featureWrappers/RoleFeature';
import StatusContext from '../../context/StatusContext';
import { DEVICE_STATUS } from '../../utils/deviceConsts';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './Groups.module.css';

const resultsToShow = 18;

const formFieldSpecs = [
  {
    labelText: 'Group Name',
    fieldName: 'name',
    formComponent: TmoInput,
    required: true,
    placeholder: 'TEST-GROUP-001'
  },
  {
    labelText: 'Firmware Package ID',
    fieldName: 'firmwarePackageId',
    formComponent: TmoInput,
    required: true,
    placeholder: 'Must match an existing FW package ID'
  },
  {
    labelText: 'IMEI List',
    fieldName: 'imeis',
    formComponent: TmoTextarea,
    required: true,
    placeholder: 'List of IMEIs to assign to above FW Package'
  },
  {
    labelText: 'Group Status',
    fieldName: 'status',
    formComponent: TmoSelect,
    required: true,
    selectSpecs: {
      options: Object.keys(DEVICE_STATUS).map(k => ({
        value: k,
        text: k
      }))
    },
    defaultValue: Object.keys(DEVICE_STATUS)[0]
  }
];

function Groups({ openModal }) {
  const [groups, setGroups] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const actionCallback = useCallback(() => {
    return firmwareService.getFirmwareGroups(resultsToShow);
  }, []);

  const sortedGroups = useMemo(
    () => groups?.data.sort((a, b) => (a.status > b.status ? 1 : -1)),
    [groups]
  );

  const openAddGroupModal = async () => {
    const { isConfirmed, formValues } = await openModal();

    if (isConfirmed) {
      const formatImei = formValues?.imeis?.replace(/\s/g, '').split(',');
      trackCustomEvents(`Add Firmware Group`, {
        imeis: formatImei
      });
      try {
        await firmwareService.addFirmwareGroup({
          ...formValues,
          imeis: formatImei
        });
        setGroups(await actionCallback());
        addSuccessMessage({
          message: `Successfully added Firmware: ${formValues.name}`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <>
      <TmoCard className={style.heading}>
        <TmoH1 flush>Firmware Groups</TmoH1>
        <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
          <TmoButtons
            className={style.add}
            icon={<FaPlus />}
            onClick={openAddGroupModal}
          >
            Add New Group
          </TmoButtons>
        </RoleFeature>
        <FormModal title="Add Firmware Group" fieldSpecs={formFieldSpecs} />
      </TmoCard>
      <TmoCard>
        <Fetcher
          action={actionCallback}
          onLoad={setGroups}
          render={() =>
            !!groups?.data?.length ? (
              <PaginationV3
                items={groups?.data}
                itemsPerPage={resultsToShow}
                className={style.items}
                render={() =>
                  sortedGroups.map(g => (
                    <FirmwareGroupItem
                      onRemove={async () => setGroups(await actionCallback())}
                      onEdit={async () => setGroups(await actionCallback())}
                      key={g.id}
                      group={g}
                      className={style.item}
                    />
                  ))
                }
              />
            ) : (
              'No Firmware Groups'
            )
          }
        />
      </TmoCard>
    </>
  );
}

export default withModal(Groups);
