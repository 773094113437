import React, { useContext, useState, useRef } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import {
  ModalV2,
  withModal,
  TmoButtons,
  TmoInput,
  LabelWithInput,
  TmoRadioGroup
} from 'tmo-bps-syncup-web-component-lib';

import mediaService from '../../services/mediaService';
import iotFirmwareService from '../../services/iotFirmwareService';
import { getRandomString } from '../../utils/stringUtils';
import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { IOT_ASSET_TYPES } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './UploadAsset.module.css';
import uploadIcon from '../../static/assets/icons/action/upload.svg';
import add_Icon from '../../static/assets/icons/action/add_solid.svg';

function UploadAsset({
  uploadedAsset,
  onEdit,
  assetId,
  assetName,
  mediaType,
  openModal,
  closeModal,
  setVisible,
  isAssetMedia
}) {
  const [inputFile, setInputFile] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [hashCode, setHashCode] = useState(null);
  const [useFileUpload, setUseFileUpload] = useState(true);
  const [inputFileName, setInputFileName] = useState('');
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const uploadOptions = ['File Upload', 'Use Blob Url'];
  const fileId = useRef(getRandomString());

  const handleInputChange = e => {
    setInputFile(e.target.files[0]);
    uploadedAsset(null);
    setInputFileName(e.target.files[0].name);
  };

  const handleUrlTextChange = v => {
    setBlobUrl(v);
    uploadedAsset(null);
  };

  const handleHashTextChange = v => {
    setHashCode(v);
    uploadedAsset(null);
  };

  const handleFileUpload = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', inputFile);
    trackCustomEvents(`Upload Asset for Media Type - ${mediaType}`, {
      assetId
    });
    try {
      setVisible(true);
      closeModal();
      let asset = async () => {
        if (mediaType === IOT_ASSET_TYPES.MEDIA) {
          return await mediaService.uploadMediaAsset({
            assetId,
            assetName,
            syncUpAppName: syncUpProductContext.syncUpAppName,
            body: formData
          });
        } else
          return await iotFirmwareService.uploadIotFirmwareAsset({
            firmwareId: assetId,
            body: formData,
            product: syncUpProductContext.syncUpAppName
          });
      };
      uploadedAsset(await asset());
      onEdit(await asset());
      addSuccessMessage({
        message: (
          <>
            Successfully uploaded asset <strong>{inputFile.name}</strong> to{' '}
            <strong>{assetName}</strong> config.
          </>
        )
      });
      setVisible(false);
      setInputFile(null);
    } catch (error) {
      setVisible(false);
      setInputFile(null);
      addErrorMessage({ error });
    }
  };

  const handleAddBlobUrl = async e => {
    e.preventDefault();
    try {
      setVisible(true);
      closeModal();
      if (mediaType === IOT_ASSET_TYPES.FIRMWARE) {
        trackCustomEvents(
          `Upload Asset via blob Url for Media Type - ${mediaType}`,
          { assetId }
        );
        await iotFirmwareService.uploadIotFirmwareAsset({
          firmwareId: assetId,
          body: { url: blobUrl, hashCode: hashCode },
          product: syncUpProductContext.syncUpAppName
        });
      }

      addSuccessMessage({
        message: <>Blob Url added Successfully.</>
      });
      setVisible(false);
    } catch (error) {
      setVisible(false);
      addErrorMessage({ error });
    }
  };

  const handleModeSelection = value => {
    value === uploadOptions[0]
      ? setUseFileUpload(true)
      : setUseFileUpload(false);
  };

  return (
    <>
      <TmoButtons
        type={'magenta_link'}
        icon={<ReactSVG src={uploadIcon} />}
        onClick={openModal}
      >
        Upload Asset
      </TmoButtons>
      <ModalV2 title={`Upload ${mediaType}`} portal={false} modalType={'slide'}>
        <div className={classNames(style.container, style.slide_container)}>
          <>
            {!isAssetMedia && (
              <div className={style.mode_container}>
                <TmoRadioGroup
                  name="modeSelection"
                  data={{
                    radioGrp: 'iconPosition-radio',
                    defaultValue: useFileUpload
                      ? uploadOptions[0]
                      : uploadOptions[1],
                    options: uploadOptions
                  }}
                  onChange={handleModeSelection}
                />
              </div>
            )}
            {useFileUpload && (
              <>
                <div className={style.media_asset_upload}>
                  <label htmlFor="file">Select Asset File:</label>
                  <div className={style.input_container}>
                    <label className={style.label} htmlFor={fileId.current}>
                      <div className={style.upload_placeholder}>
                        <ReactSVG src={add_Icon} />
                        <div className={style.upload_content}>
                          <div className={style.label} htmlFor="file">
                            Upload File
                          </div>
                          <div className={style.subtitle}>
                            Only PDF or Spreadsheet, max. 10 MB
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <input
                    type="file"
                    name="file"
                    id={fileId.current}
                    className={style.file_input}
                    placeholder={`${mediaType} Asset File`}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={style.filename}>{inputFileName}</div>
              </>
            )}
            {!useFileUpload && (
              <div className={style.input_container_blob}>
                <LabelWithInput
                  labelText="Add Blob Url"
                  formComponent={TmoInput}
                  name="url"
                  value={blobUrl}
                  inputType="primary"
                  id={fileId.current}
                  className={style.file_input}
                  placeholder={`${mediaType} Blob URL`}
                  onChange={handleUrlTextChange}
                />
                <LabelWithInput
                  labelText="Hash Code"
                  formComponent={TmoInput}
                  inputType="primary"
                  name="hashcode"
                  value={hashCode}
                  id={fileId.current}
                  className={style.file_input}
                  placeholder={`${mediaType} Hash Code`}
                  onChange={handleHashTextChange}
                />
              </div>
            )}
            <div className={style.button_wrapper}>
              <TmoButtons onClick={closeModal} type="magenta_secondary">
                Cancel
              </TmoButtons>
              <TmoButtons
                onClick={useFileUpload ? handleFileUpload : handleAddBlobUrl}
                className={style.action}
                disabled={!inputFile}
                type="magenta_primary"
              >
                {useFileUpload ? 'Upload Asset' : 'Add Blob URL'}
              </TmoButtons>
            </div>
          </>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(UploadAsset);
