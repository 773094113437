import React, { useCallback, useContext, useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoButtons as TmoButton,
  TmoInput,
  FormModal,
  ContentContainer,
  withModal,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';

import TmoCustomSelect from '../components/forms/TmoCustomSelect';
import TmoEditor from '../components/forms/TmoEditor';
import RoleFeature from '../components/featureWrappers/RoleFeature';
import Search from '../components/faq/Search';
import Content from '../components/faq/Content';
import DeleteBulkFAQ from '../components/faq/DeleteBulkFAQ';
import DeleteDemoFAQ from '../components/faq/DeleteDemoFAQ';
import ReOrderFAQ from '../components/faq/ReOrderFAQ';
import UploadFAQ from '../components/faq/UploadFAQ';
import faqContentService from '../services/faqContentService';
import SyncUpProductContext from '../context/SyncUpProductContext';
import StatusContext from '../context/StatusContext';
import Fetcher from '../components/Fetcher';
import config from '../configs/config';
import { SUA_ENVIRONMENTS, APP_LANGUAGE } from '../utils/app_constants';
import { capitalCase } from '../utils/helper_functions';
import { SUA_ROLES } from '../utils/app_constants';
import { trackCustomEvents } from '../utils/appInsight_analytics';
import PageHeader from '../components/uiHelpers/pageHeader/PageHeader';

import style from './FAQPage.module.css';
import deleteIcon from '../static/assets/icons/action/delete.svg';
import sortIcon from '../static/assets/icons/sort_icon.svg';
import { FaPlus, IoIosArrowDown } from 'react-icons/all';

import classNames from 'classnames';

const languageOptions = [
  { key: APP_LANGUAGE.ENGLISH, value: 'English (en)' },
  { key: APP_LANGUAGE.SPANISH, value: 'Spanish (es)' }
];

function FAQPage({ openModal }) {
  const [faqContent, setFaqContent] = useState();
  const [selectedFaqs, setSelectedFaqs] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [orderingFlag, setOrderingFlag] = useState(false);
  const [reOrderingList, setReOrderingList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[0].key
  );
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const currentEnvironment = config.apiSettings.environment;

  const formFieldSpecs = [
    {
      labelText: 'Category',
      fieldName: 'faq_category',
      formComponent: TmoCustomSelect,
      required: true,
      placeholder: 'Add new category',
      defaultText: 'Select Category',
      optionsList: [...new Set(faqContent?.map(f => f.name))]?.map(c => ({
        value: capitalCase(c),
        key: c
      })),
      addToTop: true
    },
    {
      labelText: 'Title',
      fieldName: 'faq_title',
      inputType: 'primary',
      formComponent: TmoInput,
      required: true,
      placeholder: 'Title'
    },
    {
      labelText: 'Description',
      fieldName: 'faq_description',
      formComponent: TmoEditor
    }
  ];
  const openAddModal = async () => {
    const {
      isConfirmed,
      formValues: { faq_category, faq_title, faq_description } = {}
    } = await openModal();
    if (isConfirmed) {
      if (faq_category && faq_title && faq_description) {
        trackCustomEvents(`Add Product FAQ`, {
          product: syncUpProductContext.syncUpAppName,
          title: faq_title
        });
        try {
          await faqContentService.createFAQ({
            product: syncUpProductContext.syncUpAppName,
            body: [
              {
                category: faq_category,
                title: faq_title,
                description: faq_description,
                faqOrder: 100,
                language: selectedLanguage
              }
            ]
          });
          addSuccessMessage({
            message: `Successfully added FAQ: ${faq_title}`
          });
          await handleUpdateFAQ();
        } catch (error) {
          addErrorMessage({ error });
        }
      } else {
        addErrorMessage({
          message: `Validation Error - ${
            !faq_category
              ? 'Category field is required'
              : !faq_description
              ? 'Description field is required'
              : ''
          }`
        });
      }
    }
  };
  const handleUpdateFAQ = async () => {
    setFaqContent(await actionCallback());
  };
  const handleBulkDelete = e => {
    trackCustomEvents(`Bulk Delete Initial`);
    setDeleteFlag(true);
    setOrderingFlag(false);
  };
  const handleReOrdering = () => {
    setOrderingFlag(true);
    setDeleteFlag(false);
  };
  const handleSelection = e => {
    if (e && e.target) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      let list = [...faqContent];
      faqContent.forEach((category, i) => {
        const index = category.faqList.findIndex(
          item => item.id.toString() === e.target.id
        );
        if (index >= 0) {
          let selected = !list[i].faqList[index].selected
            ? true
            : !list[i].faqList[index].selected;
          list[i].faqList[index] = {
            ...list[i].faqList[index],
            selected: selected
          };
          setFaqContent(list);
        }
      });
    }
  };
  const clearSelection = () => {
    setDeleteFlag(false);
    let list = [];
    faqContent.forEach(item => {
      list = [...list, { ...item, selected: false }];
    });
    setFaqContent(list);
  };
  const cancelReordering = () => {
    setOrderingFlag(false);
  };
  const renderFAQButtons = () => {
    return (
      <div className={style.btn_wrapper}>
        <div className={style.language_select}>
          <TmoDropDownSelect
            optionsList={languageOptions}
            onChange={e => {
              setSelectedLanguage(e);
            }}
            defaultSelectedValue={
              languageOptions.filter(e => e.key === selectedLanguage)[0]
            }
            renderIcon={<IoIosArrowDown />}
          />
        </div>
        <RoleFeature
          groups={[
            SUA_ROLES.ADMIN,
            SUA_ROLES.WATCH_ADMIN,
            SUA_ROLES.WATCH_PO,
            SUA_ROLES.MOBILE_APP_DEVS
          ]}
        >
          <DeleteDemoFAQ data={faqContent} onDataChange={handleUpdateFAQ} />
          <TmoButton
            icon={<ReactSVG src={deleteIcon} />}
            onClick={handleBulkDelete}
            type="magenta_secondary"
            className={style.action_button}
            textVisible
          >
            Bulk Delete
          </TmoButton>
          <TmoButton
            onClick={handleReOrdering}
            type="magenta_primary"
            className={classNames(style.action_button, style.btn_order)}
            icon={<ReactSVG src={sortIcon} />}
          >
            Update Order
          </TmoButton>
          <UploadFAQ
            onDataChange={handleUpdateFAQ}
            language={selectedLanguage}
          />
          <TmoButton
            icon={<FaPlus />}
            onClick={openAddModal}
            className={classNames(style.action_button, style.add_faq_btn)}
            type="magenta_primary"
          >
            Add FAQ
          </TmoButton>
        </RoleFeature>
      </div>
    );
  };
  const actionCallback = useCallback(() => {
    return (
      selectedLanguage &&
      faqContentService.getFAQbyCategories({
        product: syncUpProductContext.syncUpAppName,
        isProdEnv:
          currentEnvironment === SUA_ENVIRONMENTS.PRODUCTION ? true : false,
        language: selectedLanguage
      })
    );
  }, [syncUpProductContext, currentEnvironment, selectedLanguage]);

  useEffect(() => {
    if (deleteFlag) {
      let count = 0;
      faqContent.forEach(category => {
        let selected = category.faqList.filter(x => x.selected);
        count = count + selected.length;
      });
      setSelectedFaqs(count);
    }
  }, [deleteFlag, faqContent]);
  useEffect(() => {
    if (orderingFlag) {
      setReOrderingList([]);
    }
  }, [orderingFlag]);
  return (
    <ContentContainer className={style.container}>
      <PageHeader title={'FAQ Manager'}>
        {renderFAQButtons()}
        <FormModal
          title={`Add new FAQ`}
          fieldSpecs={formFieldSpecs}
          modalType={'slide'}
        />
      </PageHeader>
      <Fetcher
        action={actionCallback}
        onLoad={setFaqContent}
        render={() =>
          faqContent && faqContent.length > 0 ? (
            <>
              <Search
                list={faqContent}
                disableInput={orderingFlag}
                renderItem={(item, index) => (
                  <Content
                    onDataChange={handleUpdateFAQ}
                    key={`kids-faq-fragment-${index}`}
                    item={item}
                    faqContent={faqContent}
                    deleteFlag={deleteFlag}
                    handleSelection={handleSelection}
                    orderingFlag={orderingFlag}
                    setFaqContent={setFaqContent}
                    reOrderingList={reOrderingList}
                    setReOrderingList={setReOrderingList}
                    language={selectedLanguage}
                  />
                )}
                isAdmin
              />
            </>
          ) : (
            <div className={style.faq_not_found}>
              There are no FAQ's for {syncUpProductContext.syncUpAppName}. Add
              some now!
            </div>
          )
        }
      />
      {deleteFlag && (
        <DeleteBulkFAQ
          data={faqContent}
          clearSelection={clearSelection}
          selectedFaqs={selectedFaqs}
          onDataChange={handleUpdateFAQ}
        />
      )}
      {orderingFlag && (
        <ReOrderFAQ
          data={faqContent}
          reOrderingList={reOrderingList}
          cancelReordering={cancelReordering}
          onDataChange={handleUpdateFAQ}
          language={selectedLanguage}
        />
      )}
    </ContentContainer>
  );
}

export default withModal(FAQPage);
