import React, { useContext, useState, useRef, useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  TmoButtons as TmoButton,
  FormModal,
  TmoSvg,
  FilterModal,
  TmoSearch,
  DynamicSort,
  TmoDropDownSelect,
  PaginationV2
} from 'tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import mediaService from '../../services/mediaService';
import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import SingleAsset from './SingleAsset';
import { MEDIA_TYPES } from '../../utils/mediaConsts';
import { MEDIA_PAGE_SIZE_OPTIONS } from '../../utils/app_constants';
import useVisible from '../../hooks/useVisible';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import { hasValue } from '../../utils/helper_functions';
import { getRandomString } from '../../utils/stringUtils';
import Error from 'components/Error/Error';

import style from './AssetManager.module.css';
import paginationStyle from '../../static/assets/css/pagination.module.css';
import arrowLeft from '../../static/assets/icons/left_arrow.svg';
import arrowRight from '../../static/assets/icons/right_arrow.svg';
import {
  HiOutlinePlus,
  BsFilterLeft,
  RiSearchLine,
  IoIosArrowDown
} from 'react-icons/all';

function AssetManager({
  action,
  configName,
  openModal,
  assetFormFieldSpecs,
  mediaType,
  requestBody = {}
}) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { userInfo } = useContext(LoggedInUserContext);
  const [assetConfigs, setAssetConfigs] = useState();
  const { visible, setVisible } = useVisible(false);
  const [fetcherKey, setFetcherKey] = useState(getRandomString());
  const [filteredList, setFilteredList] = useState();
  const [filteredListLength, setFilteredListLength] = useState(0);
  const [assetPerPage, setAssetPerPage] = useState(MEDIA_PAGE_SIZE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOptions] = useState([
    { key: 'configType', value: 'Config Type' },
    { key: 'deviceGroup', value: 'Device Group' },
    { key: 'type', value: 'Type' },
    { key: 'fileType', value: 'File Type' }
  ]);
  const [filterOptions] = useState([
    { key: 'configType', value: 'Config Type' },
    { key: 'type', value: 'Type' },
    { key: 'fileType', value: 'File Type' }
  ]);

  const selectOptions = MEDIA_PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} Configurations`
  }));
  selectOptions.push({
    key: 'All',
    value: 'All Configurations'
  });

  const sortRef = useRef();
  const searchtRef = useRef();
  const filtertRef = useRef();

  const showPagination = filteredList && filteredList?.length > assetPerPage;

  const openAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    const mediaFileType = () => {
      switch (formValues.media_type) {
        case MEDIA_TYPES.THEME:
          return 'JPG';
        case MEDIA_TYPES.BACKGROUND:
          return 'PNG';
        case MEDIA_TYPES.RINGTONE:
          return 'MP3';
        case MEDIA_TYPES.SOUND:
          return 'MP3';
        case MEDIA_TYPES.VIDEO:
          return 'MP4';
        default:
          return 'ZIP';
      }
    };
    if (isConfirmed) {
      trackCustomEvents(`Add New ${formValues.media_type} Config`);
      setVisible(true);
      const body = {
        configType: 'MEDIA_DEFAULT',
        configName: formValues.config_name,
        productId: syncUpProductContext.syncUpAppName,
        fileType: mediaFileType(),
        createdBy: userInfo.email,
        type:
          mediaType.toUpperCase() === 'MEDIA'
            ? formValues.media_type
            : mediaType.toUpperCase(),
        action: 'UPDATE',
        ...requestBody(formValues)
      };
      try {
        await mediaService.addMediaMeta({
          assetId: configName,
          assetName: formValues.config_name,
          syncUpAppName: syncUpProductContext.syncUpAppName,
          body: body
        });
        setAssetConfigs(await action());
        addSuccessMessage({
          message: `Successfully added ${mediaType} configuration: ${formValues.config_name}`
        });
        setVisible(false);
      } catch (error) {
        setVisible(false);
        addErrorMessage({ error });
      }
    }
  };

  const handleRemoveConfig = async ({ assetId, assetName }) => {
    try {
      await mediaService.deleteMediaMeta({
        assetId,
        assetName,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      setAssetConfigs(await action());
      addSuccessMessage({
        message: `${assetName} removed successfully`
      });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const handleAssetUpdated = async () => {
    setFetcherKey(getRandomString());
  };

  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const handleOnFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (searchtRef?.current?.ApplyFilters) {
        searchtRef.current.ApplyFilters(data.list);
      }
    }
  };

  const handleSearchFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (filtertRef?.current?.ApplyFilters) {
        filtertRef.current.ApplyFilters(data.list);
      }
    }
  };

  const handleLoadData = useCallback(data => {
    setAssetConfigs(data);
    setFilteredList(data);
    setFilteredListLength(data.length);
  }, []);

  const getCurrentPageAssetList = () => {
    if (filteredList && filteredList.length) {
      const offset = (currentPage - 1) * assetPerPage;
      return filteredList.slice(offset, offset + assetPerPage);
    }
    return [];
  };

  return (
    <>
      <PageHeader
        primary
        title={`${mediaType} Manager`}
        subtitle={
          filteredListLength > 1 &&
          `Totally ${filteredListLength} media available`
        }
      >
        <TmoSearch
          placeholder="Search by Config Name, Device Group, Type & File Type"
          originalList={assetConfigs}
          options={['deviceGroup', 'configName', 'type', 'fileType']}
          onFilter={handleSearchFilter}
          ref={searchtRef}
          renderIcon={<RiSearchLine />}
        />
        <DynamicSort
          ref={sortRef}
          listItems={filteredList}
          optionsList={sortOptions}
          onSort={e => {
            handleOnSort(e);
          }}
          renderIcon={<IoIosArrowDown />}
          className={style.sort_control}
        />
        <FilterModal
          ref={filtertRef}
          options={filterOptions}
          originalList={assetConfigs}
          listItems={filteredList}
          onFilter={handleOnFilter}
          renderIcon={<BsFilterLeft />}
        />
        <TmoButton
          type="magenta_primary"
          className={style.control_btn}
          icon={<HiOutlinePlus />}
          onClick={openAddModal}
        >
          Add New {mediaType} Config
        </TmoButton>
        <FormModal
          title={`Add new ${mediaType.toLowerCase()} configuration`}
          fieldSpecs={assetFormFieldSpecs}
          modalType={'slide'}
        />
      </PageHeader>
      {visible && <TmoSvg className={style.spinner} svgId="loading-spinner" />}
      <div className={paginationStyle.pagination_wrapper}>
        <div className={paginationStyle.page_size}>
          <TmoDropDownSelect
            className={style.custom_dropdown}
            optionsList={selectOptions}
            onChange={e => {
              if (e === 'All') {
                setAssetPerPage(
                  filteredList && filteredList.length
                    ? filteredList.length + 1
                    : 0
                );
              } else {
                setAssetPerPage(Number(e));
              }
              setCurrentPage(1);
            }}
            renderIcon={<IoIosArrowDown />}
            defaultSelectedValue={
              selectOptions.filter(e => e.key === assetPerPage)[0]
            }
          />
        </div>
        <div>
          {showPagination && (
            <PaginationV2
              className={paginationStyle.custom_pagination}
              totalPages={Math.ceil(filteredList.length / assetPerPage)}
              selectedPageNumber={currentPage}
              onPageChanged={currentPage => setCurrentPage(currentPage)}
              type="primary"
              icons={{
                left: <ReactSVG src={arrowLeft} />,
                right: <ReactSVG src={arrowRight} />
              }}
            />
          )}
        </div>
      </div>
      <Fetcher
        key={fetcherKey}
        action={action}
        onLoad={handleLoadData}
        render={() => (
          <div className={style.results}>
            {getCurrentPageAssetList && hasValue(filteredList) ? (
              <>
                {getCurrentPageAssetList().map(config => (
                  <div shadow={false} key={config.id}>
                    <SingleAsset
                      mediaType={mediaType}
                      details={config}
                      onRemove={handleRemoveConfig}
                      onEdit={handleAssetUpdated}
                      isAssetMedia
                    />
                  </div>
                ))}
              </>
            ) : (
              <Error
                heading="There are no media configurations available."
                message="Add a new configuration"
              />
            )}
          </div>
        )}
      />
    </>
  );
}

export default withModal(AssetManager);
