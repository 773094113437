import React, { useState, useContext } from 'react';
import {
  TmoButtons as TmoButton,
  ModalV2,
  withModal,
  TmoRadioGroup,
  TmoDropDownSelect
} from 'tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';
import { FaPlus } from 'react-icons/all';

import SearchedUserContext from '../../context/SearchedUserContext';
import { DRIVE_SIMULATED_EVENTS } from '../../utils/deviceConsts';
import { capitalCase } from 'utils/helper_functions';
import DriveSimulatedEvents from './DriveSimulatedEvents';
import { prettifyCamelCase } from 'utils/stringUtils';
import Feature from '../featureWrappers/Feature';
import { APP_MOBILE } from '../../utils/app_constants';

import style from './CreateDeviceEvent.module.css';
import plug_off from '../../static/assets/icons/plug_off.svg';
import create_events from '../../static/assets/icons/create_events.svg';
import { IoIosArrowDown } from 'react-icons/all';

function CreateDeviceEvent({
  deviceId,
  openModal,
  isUnPlugEvent = false,
  isEventLink,
  onEventCreated
}) {
  const {
    user: { userId }
  } = useContext(SearchedUserContext);

  const eventTypes = DRIVE_SIMULATED_EVENTS.map(event => event.name);

  const [selectedTripType, setSelectedTripType] = useState(
    DRIVE_SIMULATED_EVENTS[isUnPlugEvent ? 1 : 0]
  );

  const [selectedCategory, setSelectedCategory] = useState(
    DRIVE_SIMULATED_EVENTS[isUnPlugEvent ? 1 : 0].categories[
      isUnPlugEvent ? 3 : 0
    ]
  );

  const [selectedEvent, setSelectedEvent] = useState(
    DRIVE_SIMULATED_EVENTS[isUnPlugEvent ? 1 : 0].categories[
      isUnPlugEvent ? 3 : 0
    ].events[0]
  );

  const selectSpecsEventCategoreis = selectedTripType.categories.map(k => ({
    key: k.name,
    value: k.name
  }));

  const selectSpecsEventNames = selectedCategory.events.map((k, i) => ({
    key: k.eventName + i,
    value: k.displayName
      ? k.displayName
      : capitalCase(prettifyCamelCase(k.eventName))
  }));

  const handleTripTypeChange = value => {
    const tempList = DRIVE_SIMULATED_EVENTS.filter(item => item.name === value);
    setSelectedTripType(tempList[0]);
    setSelectedCategory(tempList[0].categories[0]);
    setSelectedEvent(tempList[0].categories[0].events[0]);
  };

  const handleCategoryChange = value => {
    const tempList = selectedTripType.categories.filter(
      category => category.name === value
    );
    setSelectedCategory(tempList[0]);
    setSelectedEvent(tempList[0].events[0]);
  };

  const handleEventChange = value => {
    let tempList = [];
    selectedCategory.events.forEach((item, i) => {
      if (item.eventName + i === value) {
        tempList = [...tempList, item];
      }
    });
    setSelectedEvent(tempList[0]);
  };

  return (
    <>
      {isUnPlugEvent ? (
        <TmoButton
          onClick={openModal}
          type="magenta_link"
          icon={<ReactSVG src={plug_off} />}
        >
          {' '}
          Un-Plug{' '}
        </TmoButton>
      ) : (
        <>
          {isEventLink ? (
            <TmoButton
              onClick={openModal}
              type="magenta_primary"
              tooltip="Create Events"
              icon={<FaPlus />}
            >
              Create New Event
            </TmoButton>
          ) : (
            <TmoButton
              onClick={openModal}
              type="magenta_primary"
              tooltip="Create Events"
              icon={<ReactSVG src={create_events} />}
            />
          )}
        </>
      )}
      <ModalV2
        modalType="slide"
        title={`Create Event for device ${deviceId}`}
        portal={false}
      >
        <div className={style.container}>
          <Feature isUserDetails Products={[APP_MOBILE.DRIVE]}>
            <div className={style.eventType}>
              <TmoRadioGroup
                name="tripType"
                data={{
                  radioGrp: 'iconPosition-radio',
                  defaultValue: selectedTripType.name,
                  options: eventTypes
                }}
                onChange={handleTripTypeChange}
              />
            </div>
            <label className={style.label}> Event Category </label>
            <TmoDropDownSelect
              className={style.select_margin}
              key={selectedCategory.name}
              optionsList={selectSpecsEventCategoreis}
              onChange={handleCategoryChange}
              renderIcon={<IoIosArrowDown />}
              defaultSelectedValue={
                selectSpecsEventCategoreis.filter(
                  e => e.key === selectedCategory.name
                )[0]
              }
              formMargin
            />
            <label className={style.label}> Event Type </label>
            <TmoDropDownSelect
              className={style.select_margin}
              key={selectedEvent.eventName}
              optionsList={selectSpecsEventNames}
              onChange={handleEventChange}
              renderIcon={<IoIosArrowDown />}
              defaultSelectedValue={
                selectSpecsEventNames.filter(
                  (e, i) => e.key === selectedEvent.eventName + i
                )[0]
              }
              formMargin
            />
            {selectedEvent && (
              <DriveSimulatedEvents
                event={selectedEvent}
                label={selectedCategory.name}
                deviceId={deviceId}
                userId={userId}
                onEventCreated={onEventCreated}
              />
            )}
          </Feature>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(CreateDeviceEvent);
