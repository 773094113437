import React, { useState, useCallback, useContext } from 'react';
import { TmoCard } from 'tmo-bps-syncup-web-component-lib';

import Fetcher from '../../Fetcher';
import deviceService from '../../../services/deviceService';
import DeviceLogsTable from './DeviceLogsTable';
import FilterByDate from '../../filters/FilterByDate';
import { getTimestampRangeForLastHours } from '../../../utils/dateUtils';
import { getUserThingBackUrl } from 'utils/helper_functions';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import GoBack from 'components/uiHelpers/goBack/GoBack';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';

import style from './DeviceLogs.module.css';
const COLUMN = {
  MESSAGE: 'message',
  CUSTOM_DIMENSIONS: 'customDimensions',
  KEY_RAW_MESSAGE: 'RawMessageKey'
};

function DeviceLogs({ match, history }) {
  const [logsRequestObj, setLogsRequestObj] = useState();
  const [hardwareLogs, setHardwareLogs] = useState();
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const actionCallback = useCallback(() => {
    trackCustomEvents(`Get Device Logs`, {
      logsRequestObj,
      syncUpAppName: product
    });
    deviceService.getLogsByIMEI({
      logsRequestObj,
      syncUpAppName: product
    });
  }, [logsRequestObj, product]);

  const handleFilterChange = timeRange => {
    if (!timeRange) {
      timeRange = getTimestampRangeForLastHours(6);
    }
    setLogsRequestObj({
      hardwareId: match.params.hardwareId,
      timeRange: timeRange
    });
  };

  const getLogsTableData = () => {
    const rawMassages = [];
    let messageIndex = null;
    let customDimensionIndex = null;
    const table = hardwareLogs[0].tables[0];
    table.columns.forEach((column, index) => {
      if (column.name === COLUMN.MESSAGE) {
        messageIndex = index;
      }
      if (column.name === COLUMN.CUSTOM_DIMENSIONS) {
        customDimensionIndex = index;
      }
    });

    table.rows.forEach(row => {
      let customDimensionJson = JSON.parse(row[customDimensionIndex]);
      let rawMessageJson = customDimensionJson[COLUMN.KEY_RAW_MESSAGE]
        ? JSON.parse(customDimensionJson[COLUMN.KEY_RAW_MESSAGE])
        : '';
      rawMassages.push({
        message: messageIndex ? row[messageIndex] : '',
        timestamp: customDimensionJson['TimeStamp']
          ? customDimensionJson['TimeStamp']
          : '',
        RawMessageKey: rawMessageJson
      });
    });
    return rawMassages;
  };

  const isLogsAvailable = () => {
    return (
      hardwareLogs &&
      hardwareLogs.length === 1 &&
      hardwareLogs[0].tables.length > 0 &&
      hardwareLogs[0].tables[0].columns &&
      hardwareLogs[0].tables[0].columns.length > 0 &&
      hardwareLogs[0].tables[0].rows &&
      hardwareLogs[0].tables[0].rows.length > 0
    );
  };

  return (
    <TmoCard>
      <GoBack url={getUserThingBackUrl(userId, 'devices', history)} />
      <label className={style.heading_label}>Device hardware logs:</label>
      <label> {match.params.hardwareId}</label>
      <div className={style.container}>
        <FilterByDate
          onFilter={handleFilterChange}
          onDefaultButtonSet={handleFilterChange}
          selectedButtonName={FilterByDate.LAST_6_HOURS}
        />
        {logsRequestObj && (
          <Fetcher
            action={actionCallback}
            onLoad={setHardwareLogs}
            render={() => (
              <>
                {isLogsAvailable() ? (
                  <DeviceLogsTable table={getLogsTableData()} />
                ) : (
                  'No device logs found'
                )}
              </>
            )}
          />
        )}
      </div>
    </TmoCard>
  );
}

export default DeviceLogs;
