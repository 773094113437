import React from 'react';

import ConfirmationModalV2 from '../../modal/ConfirmationModalV2';
import TmoSvg from '../../uiHelpers/TmoSvg';

function AlertsWidgetModal({ alert }) {
  const { title, date, time, site } = alert;

  const message = (
    <div className="AlertsWidget-modal-message">
      <TmoSvg
        svgId="icon-error-triangle"
        className="AlertsWidget-modal-message-icon"
      />
      <p>{title}</p>
      <small>
        {date} - {time}
      </small>
    </div>
  );

  return (
    <div className="AlertsWidget-modal">
      <ConfirmationModalV2
        title={site}
        message={message}
        acceptButtonText="Acknowledge Alert"
      />
    </div>
  );
}

export default AlertsWidgetModal;
