export const MEDIA_TYPES = {
  THEME: 'THEME',
  BACKGROUND: 'BACKGROUND',
  RINGTONE: 'RINGTONE',
  SOUND: 'SOUND',
  VIDEO: 'VIDEO'
};

export const MEDIA_ACTIONS = {
  DELETE: 'DELETE'
};

export const MEDIA_PRIORITY = {
  NONE: 'NONE',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH'
};
