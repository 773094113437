import React, { useState } from 'react';
import _ from 'underscore';
import { ContentContainer, TmoH1 } from 'tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import TodoList from '../../components/todoList/TodoList';
import Fetcher from '../../components/Fetcher';
import ErrorMessageModal from '../../components/errorHandler/ErrorMessageModal';
import thingService from '../../services/thingService';
import { SUA_ROLES, PET_TYPES } from '../../utils/app_constants';
import { parseHTTPErrorCode } from '../../utils/helper_functions';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './BreedsPage.module.css';

function BreedsPage() {
  const [breeds, setBreeds] = useState();
  const [error, setError] = useState('');

  const filterBreedsByPetType = ({ petType }) => {
    return _.sortBy(
      breeds.filter(breed => breed.petType === petType),
      breed => breed.name
    );
  };

  const handleAddBreedByPetType = async ({ name, petType }) => {
    try {
      trackCustomEvents(`Create Breed`, {
        name,
        petType
      });
      await thingService.createBreed({
        name,
        petType
      });
      const newBreeds = await thingService.getBreeds();
      setBreeds(newBreeds);
    } catch (err) {
      setError(parseHTTPErrorCode(err.message));
    }
  };

  const handleBreedDelete = async ({ id }) => {
    try {
      await thingService.deleteBreed(id);
      const newBreeds = await thingService.getBreeds();
      setBreeds(newBreeds);
    } catch (err) {
      setError(parseHTTPErrorCode(err.message));
    }
  };

  const handleOnErrorClose = () => setError('');

  return (
    <Authorized roles={[SUA_ROLES.ADMIN, SUA_ROLES.CARE]}>
      {error && (
        <ErrorMessageModal message={error} onClose={handleOnErrorClose} />
      )}
      <ContentContainer>
        <TmoH1>Breeds</TmoH1>
        <Fetcher
          action={thingService.getBreeds}
          onLoad={setBreeds}
          render={() => (
            <div className={style.list_container}>
              {Object.values(PET_TYPES).map(petType => (
                <TodoList
                  key={petType}
                  className={style.list}
                  title={petType}
                  list={filterBreedsByPetType({ petType })}
                  filterPlaceholder={`Filter by ${petType} breed`}
                  addInputPlaceholder={`Add ${petType} breed`}
                  onRemove={handleBreedDelete}
                  onAdd={newBreedName =>
                    handleAddBreedByPetType({
                      name: newBreedName,
                      petType
                    })
                  }
                />
              ))}
            </div>
          )}
        />
      </ContentContainer>
    </Authorized>
  );
}

export default BreedsPage;
