import React from 'react';
import { getEpochDateTime } from '../../utils/dateUtils';

import style from './HintContent.module.css';

function HintContent({ value }) {
  const { index, date, source } = value;
  return (
    <div className={style.container}>
      <div className={style.row}>
        <label className={style.label}>Marker Index</label>
        <span className={style.value}>
          <strong>{index ? index : '-'}</strong>
        </span>
      </div>
      <div className={style.row}>
        <label className={style.label}>Source</label>
        <span className={style.value}>{source ? source : '-'}</span>
      </div>
      <div className={style.row}>
        <label className={style.label}>Timestamp</label>
        <span className={style.value}>
          {date ? getEpochDateTime({ unixEpochTime: date }) : '-'}
        </span>
      </div>
    </div>
  );
}

export default HintContent;
