import RoleFeature from '../../featureWrappers/RoleFeature';
import {
  TmoButtons,
  ConfirmationModalV2,
  RawData,
  StatusPill,
  TmoCard
} from 'tmo-bps-syncup-web-component-lib';
import React from 'react';
import EditAsset from '../EditAsset';
import UploadAsset from '../UploadAsset';
import SingleAssetFirmware from '../SingleAssetFirmware';
import deleteIcon from '../../../static/assets/icons/action/delete.svg';
import style from './ConfigDetails.module.css';

import { ReactSVG } from 'react-svg';
function ConfigDetails({
  details,
  mediaType,
  assetName,
  onAssetUpload,
  onEdit,
  setVisible,
  editFieldSpecs,
  onDelete,
  assetId
}) {
  const { id, isActive } = details;
  return (
    <TmoCard className={style.container}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <div className={style.status}>
            <StatusPill
              className={style.status_pill}
              inline={false}
              status={isActive ? 'active' : 'inactive'}
            >
              {isActive ? 'ACTIVE' : 'INACTIVE'}
            </StatusPill>
            {!isActive && (
              <small className={style.inactive_notice}>
                (Edit config to publish)
              </small>
            )}
          </div>
          <div className={style.config_id} title={id}>
            {id}
          </div>
        </div>
        <div className={style.action_controls}>
          <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
            <EditAsset
              mediaType={mediaType}
              asset={details}
              onEdit={onEdit}
              editFieldSpecs={editFieldSpecs}
            />

            <TmoButtons
              onClick={onDelete}
              type="magenta_secondary"
              icon={<ReactSVG src={deleteIcon} />}
              tooltip="Delete"
            />
            <ConfirmationModalV2
              message={`Are you sure you want to remove ${assetName}?`}
              title={`Confirm ${mediaType} Config Removal`}
              acceptButtonText="Yes,Delete"
              type="danger"
              confirmInput
              usePortal={false}
              modalType="delete"
              confirmText="delete"
            />
          </RoleFeature>
        </div>
      </div>

      <SingleAssetFirmware asset={details} isFirmware />

      <div className={style.footer_action}>
        <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
          <UploadAsset
            assetId={assetId}
            assetName={assetName}
            mediaType={mediaType}
            uploadedAsset={onAssetUpload}
            onEdit={onEdit}
            setVisible={setVisible}
          />
        </RoleFeature>
        <RawData
          rawObject={details}
          title="Full Configuration Details"
          type="Config"
          usePortal={false}
          modalType={'slide'}
          buttonType="magenta_link"
        />
      </div>
    </TmoCard>
  );
}

export default ConfigDetails;
