import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      const authFlag = await oktaAuth.isAuthenticated();

      if (authFlag !== isAuthenticated) {
        setIsAuthenticated(authFlag);
      }
    };

    checkAuthentication();
  }, [oktaAuth, isAuthenticated]);

  const redirectToOktaLoginPage = async () => {
    await oktaAuth.signInWithRedirect();
  };
  return (
    <>
      {isAuthenticated ? (
        <Redirect to={{ pathname: '/' }} />
      ) : (
        redirectToOktaLoginPage()
      )}
    </>
  );
};

export default Login;
