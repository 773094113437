import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  FormModal,
  TmoButtons as TmoButton,
  TmoInput,
  withModal
} from 'tmo-bps-syncup-web-component-lib';

import userService from '../../services/userService';
import useVisible from '../../hooks/useVisible';
import StatusContext from '../../context/StatusContext';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './AddSchoolMode.module.css';
import add from '../../static/assets/icons/add.svg';
import spinner from '../../static/assets/icons/spinner.png';

const schoolModeFormFieldSpecs = [
  {
    labelText: 'Display Name',
    fieldName: 'displayName',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    placeholder: 'Display Name'
  },
  {
    labelText: 'Thing Id',
    fieldName: 'thingId',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    placeholder: 'ThingId'
  },
  {
    labelText: 'Local Start Time',
    fieldName: 'localStartTime',
    inputType: 'primary',
    formComponent: TmoInput,
    required: true,
    placeholder: 'local StartTime (format : 2022-04-28T14:44:10.387) '
  },
  {
    labelText: 'Duration In Minutes',
    fieldName: 'durationInMinutes',
    inputType: 'primary',
    formComponent: TmoInput,
    required: true,
    placeholder: 'Duration In Minutes'
  }
];

const schoolModeFromThingFormFieldSpecs = [
  {
    labelText: 'Display Name',
    fieldName: 'displayName',
    inputType: 'primary',
    formComponent: TmoInput,
    required: true,
    placeholder: 'Display Name'
  },
  {
    labelText: 'Local Start Date',
    fieldName: 'localStartDate',
    inputType: 'primary',
    formComponent: TmoInput,
    required: true,
    placeholder: 'local StartTime (format : YYYY-MM-DD) '
  },
  {
    labelText: 'Local Start Time',
    fieldName: 'localStartTime',
    inputType: 'primary',
    formComponent: TmoInput,
    required: true,
    placeholder: 'local StartTime (24 hour format : HH:MM:SS) '
  },
  {
    labelText: 'Duration In Minutes',
    fieldName: 'durationInMinutes',
    inputType: 'primary',
    formComponent: TmoInput,
    required: true,
    placeholder: 'Duration In Minutes'
  }
];

function AddSchoolMode({ openModal, thingId, forceRefresh }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const { visible, setVisible } = useVisible(false);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const openAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      setVisible(true);
      const body = {
        primaryAccountHolderId: userId,
        displayName: formValues.displayName,
        enabled: true,
        id: '',
        functionToCall: 'ScheduleSchoolMode',
        attributes: {
          thingId: thingId ? thingId : formValues.thingId,
          primaryAccountHolderId: userId,
          product: 'KIDSWATCH'
        },
        scheduleType: 'OneTimeDuration',
        schedule: {
          localStartTime: formValues.localStartTime, //'2022-04-28T14:44:10.387'
          olsenTimeZone: 'America/Los_Angeles',
          durationInMinutes: parseInt(formValues.durationInMinutes)
        }
      };
      trackCustomEvents(`Add new school mode`, {
        userId: userId,
        syncUpAppName: product
      });
      try {
        await userService.postSchoolModeDetails({
          data: body,
          userId: userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Successfully added school mode configuration`
        });
        setVisible(false);
        forceRefresh();
      } catch (error) {
        setVisible(false);
        addErrorMessage({ error });
      }
    }
  };

  return (
    <div className={style.container}>
      <TmoButton
        type="magenta_primary"
        className={style.control_btn}
        onClick={openAddModal}
        disabled={visible}
        icon={
          visible ? (
            <img src={spinner} alt="spinner" className={style.spinner} />
          ) : (
            <ReactSVG src={add} />
          )
        }
      >
        {visible ? 'Adding New School Mode' : 'Add New School Mode'}
      </TmoButton>
      <FormModal
        title={`Add new school mode`}
        fieldSpecs={
          thingId ? schoolModeFromThingFormFieldSpecs : schoolModeFormFieldSpecs
        }
        modalType={'slide'}
      />
    </div>
  );
}

export default withModal(AddSchoolMode);
