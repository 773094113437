import React, { useContext } from 'react';
import { ContentContainer } from 'tmo-bps-syncup-web-component-lib';

import SyncUpProductContext from '../../context/SyncUpProductContext';
import { APP_MOBILE } from '../../utils/app_constants';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';
import WatchCoapSupport from './WatchCoapSupport';
import CoapSupport from './CoapSupport';

function CoapSupportPage() {
  const syncUpProductContext = useContext(SyncUpProductContext);

  const productTabs = () => {
    switch (syncUpProductContext.syncUpAppName) {
      case APP_MOBILE.TRACKER:
        return <CoapSupport />;
      case APP_MOBILE.WATCH:
        return <WatchCoapSupport />;
      default:
        return null;
    }
  };

  return (
    <ContentContainer>
      <PageHeader title={'COAP Support'} />
      {productTabs()}
    </ContentContainer>
  );
}

export default CoapSupportPage;
