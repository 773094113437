import React from 'react';
import {
  DynamicList,
  RawDataObject,
  TmoH3
} from 'tmo-bps-syncup-web-component-lib';

import { hasValue } from 'utils/helper_functions';
import style from './APIDetails.module.css';

const APIDetails = ({ apiDetails }) => {
  const { url, requestHeaders, requestParameters, requestBody } = apiDetails;
  const filteredParameters =
    requestParameters && requestParameters.filter(x => !x.isHidden);
  const headerNameListItemMap = [
    {
      listItemKey: 'name',
      headerDisplayName: 'Name',
      columnClass: style.name
    },
    {
      listItemKey: 'type',
      headerDisplayName: 'Type',
      columnClass: style.type
    },
    {
      listItemKey: 'required',
      headerDisplayName: 'Required',
      columnClass: style.required,
      render: ({ required }) => required?.toString()
    }
  ];

  return (
    <div>
      {url && (
        <>
          <TmoH3 className={style.title}> Request </TmoH3>
          <span className={style.request_url}> {url} </span>
        </>
      )}

      {hasValue(filteredParameters) && (
        <>
          <TmoH3 className={style.title}> Request Parameter </TmoH3>
          <DynamicList
            type="primary"
            headerNameListItemMap={headerNameListItemMap}
            listItems={filteredParameters}
          />
        </>
      )}

      {hasValue(requestHeaders) && (
        <>
          <TmoH3 className={style.title}> Request Header </TmoH3>
          <DynamicList
            type="primary"
            headerNameListItemMap={headerNameListItemMap}
            listItems={requestHeaders}
          />
        </>
      )}
      {hasValue(requestBody) && (
        <>
          <TmoH3 className={style.title}> Request Body </TmoH3>
          <RawDataObject rawObject={requestBody} />
        </>
      )}
    </div>
  );
};
export default APIDetails;
