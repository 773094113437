import React, { useRef } from 'react';
import classNames from 'classnames';
import { getRandomString } from '../../utils/stringUtils';

import style from './LabelWithInput.module.css';

function LabelWithInput({
  labelText,
  formComponent: FormComponent,
  className,
  labelClassName,
  inline,
  focus = false,
  ...rest
}) {
  const formId = useRef(getRandomString());

  return (
    <div
      className={classNames(style.container, className, inline && style.inline)}
    >
      <label
        className={classNames(
          style.label,
          labelClassName,
          inline && style.label_inline
        )}
        htmlFor={formId.current}
      >
        {labelText}
      </label>
      <FormComponent
        autoFocus
        focus="true"
        className={style.input}
        id={formId.current}
        {...rest}
      />
    </div>
  );
}

export default LabelWithInput;
