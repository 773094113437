import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import {
  TmoButtons as TmoButton,
  RawData,
  PaginationV2,
  TmoSearch,
  TmoDropDownSelect,
  TmoToggle,
  DynamicSort,
  FilterModal,
  DynamicList
} from 'tmo-bps-syncup-web-component-lib';

import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';
import FilterByDate from '../filters/FilterByDate';
import userService from '../../services/userService';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import {
  DATE_IN_TIME_ZONE,
  NOTIFICATIONS_PAGE_SIZE_OPTIONS,
  APP_MOBILE
} from '../../utils/app_constants';
import { LAST_6_HOURS } from '../../utils/stringUtils';
import {
  exportCustomData,
  generateQueryString
} from '../../utils/helper_functions';
import { getTimestampList } from '../../utils/dateUtils';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import NotificationItem from './NotificationItem';
import { hasValue } from '../../utils/helper_functions';
import Error from '../Error/Error';

import style from './Notifications.module.css';
import paginationStyle from '../../static/assets/css/pagination.module.css';
import arrowLeft from '../../static/assets/icons/left_arrow.svg';
import arrowRight from '../../static/assets/icons/right_arrow.svg';
import download from '../../static/assets/icons/download.svg';
import { IoIosArrowDown, RiSearchLine, BsFilterLeft } from 'react-icons/all';

function Notifications() {
  const [currentPage, setCurrentPage] = useState(1);
  const [panelPosition, setPanelPosition] = useState(false);
  const [timeRange, setTimeRange] = useState();
  const [notifications, setNotifications] = useState();
  const [filteredList, setFilteredList] = useState();
  const [filteredListLength, setFilteredListLength] = useState(0);
  const [notificationPerPage, setNotificationPerPage] = useState(15);
  const [listView, setlistView] = useState(true);
  const searchtRef = useRef();
  const sortRef = useRef();
  const filtertRef = useRef();
  const [sortOptions] = useState([
    { key: 'deviceId', value: 'Device ID' },
    { key: 'event', value: 'Event' },
    { key: 'title', value: 'Type' },
    { key: 'thingId', value: 'Thing ID' }
  ]);

  const [filterOptions] = useState([
    { key: 'thingId', value: 'Thing ID' },
    { key: 'deviceId', value: 'Device ID' }
  ]);
  const exportHeaders = [
    'title',
    'body',
    'event',
    'thingId',
    'deviceId',
    'timeSent'
  ];
  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const handleOnFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (searchtRef?.current?.ApplyFilters) {
        searchtRef.current.ApplyFilters(data.list);
      }
    }
  };
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const selectOptions = NOTIFICATIONS_PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} Notifications`
  }));
  selectOptions.push({
    key: 'All',
    value: 'All Notifications'
  });

  const headerNameMap = [
    {
      listItemKey: 'title',
      headerDisplayName: 'Type'
    },
    {
      listItemKey: 'body',
      headerDisplayName: 'Message',
      columnClass: style.body_column,
      render: ({ body }) => <> {body && JSON.stringify(body)} </>
    },
    {
      listItemKey: 'event',
      headerDisplayName: 'Event'
    },
    {
      listItemKey: 'thingId',
      headerDisplayName: 'Thing ID'
    },
    {
      listItemKey: 'deviceId',
      headerDisplayName: 'Device ID'
    },
    {
      listItemKey: 'timeSent',
      headerDisplayName: 'Time Sent'
    }
  ];

  const actionCallback = useCallback(() => {
    trackCustomEvents(`Get Notifications Details`, {
      userId,
      query: generateQueryString(timeRangeToQueryParams(timeRange, product)),
      syncUpAppName: product
    });
    return userService.getUserNotifications({
      userId,
      query: generateQueryString(timeRangeToQueryParams(timeRange, product)),
      syncUpAppName: product
    });
  }, [timeRange, userId, product]);

  const timeRangeToQueryParams = (timeRange, appName) => {
    const queryParams = [];
    for (let key in timeRange) {
      if (appName === 'DRIVE') {
        queryParams.push({
          key: key === 'start-date-time' ? 'startDate' : 'endDate',
          value: timeRange[key]
        });
      } else {
        queryParams.push({ key: key, value: timeRange[key] });
      }
    }

    return queryParams;
  };

  const getCurrentPageNotificationList = () => {
    if (filteredList && filteredList.length) {
      const offset = (currentPage - 1) * notificationPerPage;
      return filteredList.slice(offset, offset + notificationPerPage);
    }
    return [];
  };

  const showPagination =
    filteredList && filteredList?.length > notificationPerPage;

  const handleSearchFilter = data => {
    if (data) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      setCurrentPage(1);
    }
  };
  useEffect(() => {
    if (notifications && notifications.length) {
      setFilteredList(notifications);
      setFilteredListLength(notifications.length);
    } else {
      setFilteredList([]);
      setFilteredListLength(0);
    }
  }, [notifications]);

  return (
    <>
      <PageHeader title={'Notification'} tabHeader={true}>
        <RawData
          className={style.raw_data}
          rawObject={notifications}
          title="Full Notification Details"
          tooltip="Full Notification Details"
          type=" "
          modalType={'slide'}
          usePortal={false}
          buttonType="magenta_secondary_icon"
        />
        <FilterByDate
          onFilter={setTimeRange}
          onDefaultButtonSet={setTimeRange}
          selectedButtonName={LAST_6_HOURS}
          icon={<IoIosArrowDown />}
          type="dropdown"
          className={style.date_filter}
          onSelection={setPanelPosition}
        />
      </PageHeader>
      <div
        className={classNames(
          style.container,
          panelPosition && style.date_range_panel_position
        )}
      >
        {timeRange && (
          <Fetcher
            action={actionCallback}
            onLoad={setNotifications}
            render={() => (
              <>
                <span className={style.message}>
                  <span>{filteredListLength ? filteredListLength : 0}</span>{' '}
                  notifications from {''}
                  <span>
                    {getTimestampList(
                      timeRange['start-date-time'],
                      null,
                      DATE_IN_TIME_ZONE.SYSTEM
                    )}
                  </span>
                  {''} to {''}
                  <span>
                    {getTimestampList(
                      timeRange['end-date-time'],
                      null,
                      DATE_IN_TIME_ZONE.SYSTEM
                    )}
                  </span>
                </span>
                {notifications && notifications.length > 0 && (
                  <>
                    {hasValue(notifications) && (
                      <>
                        <div
                          className={classNames(
                            style.controls_wrapper,
                            style.notification_controls
                          )}
                        >
                          <TmoSearch
                            placeholder={`Search by ${
                              product === APP_MOBILE.DRIVE
                                ? 'Event'
                                : 'Type, Message'
                            }`}
                            originalList={notifications}
                            options={
                              product === APP_MOBILE.DRIVE
                                ? ['event']
                                : ['title', 'body']
                            }
                            onFilter={handleSearchFilter}
                            ref={searchtRef}
                            renderIcon={<RiSearchLine />}
                            className={style.custom_search}
                          />
                          <DynamicSort
                            className={style.sort_control}
                            ref={sortRef}
                            listItems={filteredList}
                            optionsList={sortOptions}
                            onSort={e => {
                              handleOnSort(e);
                            }}
                            renderIcon={<IoIosArrowDown />}
                          />
                          <FilterModal
                            ref={filtertRef}
                            options={filterOptions}
                            originalList={notifications}
                            listItems={filteredList}
                            onFilter={handleOnFilter}
                            renderIcon={<BsFilterLeft />}
                          />
                          <TmoButton
                            onClick={() => {
                              exportCustomData({
                                headers: exportHeaders,
                                fileName: 'Notifications.csv',
                                hardwareIds: notifications
                              });
                            }}
                            className={style.download_btn}
                            icon={<ReactSVG src={download} />}
                            type="magenta_primary"
                          >
                            Download All as CSV
                          </TmoButton>
                        </div>

                        <div
                          className={classNames(
                            style.controls_wrapper,
                            paginationStyle.pagination_wrapper
                          )}
                        >
                          <div className={paginationStyle.page_size}>
                            <TmoDropDownSelect
                              className={style.custom_dropdown}
                              optionsList={selectOptions}
                              onChange={e => {
                                if (e === 'All') {
                                  setNotificationPerPage(
                                    filteredList && filteredList.length
                                      ? filteredList.length + 1
                                      : 0
                                  );
                                } else {
                                  setNotificationPerPage(Number(e));
                                }
                                setCurrentPage(1);
                              }}
                              renderIcon={<IoIosArrowDown />}
                              defaultSelectedValue={
                                selectOptions.filter(
                                  e => e.key === notificationPerPage
                                )[0]
                              }
                            />
                          </div>
                          <div>
                            {showPagination && (
                              <PaginationV2
                                className={paginationStyle.custom_pagination}
                                totalPages={Math.ceil(
                                  filteredList.length / notificationPerPage
                                )}
                                selectedPageNumber={currentPage}
                                onPageChanged={currentPage =>
                                  setCurrentPage(currentPage)
                                }
                                type="primary"
                                icons={{
                                  left: <ReactSVG src={arrowLeft} />,
                                  right: <ReactSVG src={arrowRight} />
                                }}
                              />
                            )}
                          </div>
                          <TmoToggle
                            label={'List View'}
                            isChecked={listView}
                            onChange={() => setlistView(!listView)}
                          />
                        </div>
                      </>
                    )}
                    <div className={style.items}>
                      {getCurrentPageNotificationList &&
                      hasValue(filteredList) ? (
                        <>
                          {listView ? (
                            <DynamicList
                              headerNameListItemMap={headerNameMap}
                              listItems={getCurrentPageNotificationList()}
                              type="primary"
                              className={style.list_table}
                              verticalScroll={true}
                            />
                          ) : (
                            getCurrentPageNotificationList().map(t => (
                              <NotificationItem key={t.id} notifications={t} />
                            ))
                          )}
                        </>
                      ) : (
                        <Error
                          heading="No Notifications are available"
                          message="Try with a different date range or search criteria"
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          />
        )}
      </div>
    </>
  );
}

export default Notifications;
