import React, { useState, useRef, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import FileSaver from 'file-saver';
import {
  TmoButtons as TmoButton,
  ModalV2,
  withModal,
  TmoSvg
} from 'tmo-bps-syncup-web-component-lib';

import { getRandomString } from '../../utils/stringUtils';
import { DIMENSIONS, FAQ_TEMPLATE } from '../../utils/app_constants';
import StatusContext from '../../context/StatusContext';
import faqContentService from '../../services/faqContentService';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import useWindowWidth from '../../hooks/useWindowWidth';

import style from './UploadFAQ.module.css';
import uploadIcon from '../../static/assets/icons/upload.svg';
import add_Icon from '../../static/assets/icons/action/add_solid.svg';

const faqAllowedHeaders = ['category', 'title', 'description'];

function UploadFAQ({ openModal, closeModal, onDataChange, language }) {
  const [inputFileData, setInputFileData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const fileId = useRef(getRandomString());
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const width = useWindowWidth();

  const formatCsvString = text => {
    let re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    let re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    if (!re_valid.test(text)) return null;
    let a = [];
    text.replace(re_value, function(m0, m1, m2, m3) {
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      else if (m3 !== undefined) a.push(m3);
      return '';
    });
    if (/,\s*$/.test(text)) a.push('');
    return a;
  };
  const arrayCompare = (arr, target) => target.every(v => arr.includes(v));
  const csvToArray = str => {
    let errorLog = [];
    const headers = formatCsvString(str.slice(0, str.indexOf('\n'))).map(e =>
      e && typeof e === 'string' ? e.trim().toLocaleLowerCase() : ''
    );
    let validHeaders = arrayCompare(headers, faqAllowedHeaders);
    if (validHeaders) {
      const rows = str.slice(str.indexOf('\n') + 1).split('\n');
      let data = rows
        .filter(e => e !== '')
        .map(row => {
          const values = formatCsvString(row);
          let el = {};
          if (values?.length) {
            el = headers.reduce((object, header, index) => {
              object[header] = values[index];
              return object;
            }, {});
          } else {
            errorLog.push(`Bulk Upload Warning - Unable to read line : ${row}`);
          }
          return el;
        });
      errorLog?.length && alert(errorLog);
      return data;
    } else {
      setErrorMessage(
        'Invalid file Headers: Category, Title, Description headers are required'
      );
      return null;
    }
  };
  const handleInputChange = e => {
    let allowedExtensions = /(\.csv)$/i;
    if (e.target.files?.length && e.target.files[0].name) {
      if (!allowedExtensions.exec(e.target.files[0].name)) {
        setErrorMessage('Invalid file type');
      } else {
        const reader = new FileReader();
        reader.onabort = () =>
          addErrorMessage({ details: 'file reading was aborted' });
        reader.onerror = () =>
          addErrorMessage({ details: 'file reading failed' });
        reader.onload = e => {
          const text = e.target.result;
          const data = csvToArray(text);
          if (data && data.length > 0) {
            setErrorMessage(null);
            data.forEach(e => {
              if (e && e.category) e.language = language;
            });
            setInputFileData(data);
          }
        };
        reader.readAsText(e.target.files[0]);
      }
    }
  };
  const resetModalData = () => {
    setInputFileData(null);
    setErrorMessage(null);
  };
  const handleDownload = () => {
    trackCustomEvents(`FAQ temple download`);
    let csv = `${faqAllowedHeaders.join(',')}\n`;
    csv = csv + `${FAQ_TEMPLATE.values.join(',')}`;
    const csvBlob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });
    FileSaver.saveAs(csvBlob, `FAQ upload template.csv`);
  };
  const handleFileUpload = async e => {
    e.preventDefault();
    try {
      let data = inputFileData.filter(e => {
        return Object.keys(e).length !== 0;
      });
      if (data) {
        trackCustomEvents(`FAQ content upload`);
        await faqContentService.createFAQ({
          product: syncUpProductContext.syncUpAppName,
          body: data
        });
        addSuccessMessage({
          message: `Successfully uploaded bulk FAQ`
        });
      }
      closeModal();
      await onDataChange();
      resetModalData();
      setInputFileData(null);
    } catch (error) {
      setInputFileData(null);
    }
  };

  return (
    <>
      <TmoButton
        icon={<ReactSVG src={uploadIcon} />}
        onClick={openModal}
        type="magenta_primary"
        className={style.upload_faq_btn}
      >
        Upload FAQ
      </TmoButton>
      <ModalV2
        title="Upload FAQ"
        portal={true}
        resetModal={resetModalData}
        modalType="slide"
      >
        <div className={classNames(style.container, style.slide_container)}>
          <div className={style.upload_faq_container}>
            <label htmlFor="file">Select FAQ File</label>
            <div className={style.input_container}>
              <label className={style.label} htmlFor={fileId.current}>
                <div className={style.upload_placeholder}>
                  <ReactSVG src={add_Icon} />
                  <div className={style.upload_content}>
                    <div className={style.label} htmlFor="file">
                      Upload File
                    </div>
                    <div className={style.subtitle}>Only CSV Files</div>
                  </div>
                </div>
              </label>
            </div>
            <input
              type="file"
              name="file"
              id={fileId.current}
              className={style.file_input}
              placeholder="FAQ File"
              accept=".csv"
              onChange={handleInputChange}
            />
            {errorMessage && (
              <div className={style.error_message}>{errorMessage}</div>
            )}
            {!errorMessage && inputFileData && (
              <>
                <h3>Data Preview</h3>
                <table className={style.table}>
                  <thead>
                    <tr className={classNames(style.column, style.header)}>
                      <th>Category</th>
                      <th>Title</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputFileData.map((data, index) => (
                      <tr key={`tableRow-${index}`} className={style.column}>
                        <td>{data.category}</td>
                        <td>{data.title}</td>
                        <td>{data.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>

          <div className={style.button_wrapper}>
            <TmoButton
              onClick={closeModal}
              type="magenta_secondary"
              position={width <= DIMENSIONS.MOBILE_WIDTH && 'top'}
              icon={
                <TmoSvg
                  className={style.button_icon}
                  svgId="icon-close-modal"
                />
              }
            >
              Cancel
            </TmoButton>
            <div className={style.action}>
              <TmoButton
                className={style.download_btn}
                title="Download CSV template"
                onClick={handleDownload}
                type="magenta_primary"
              >
                {width <= DIMENSIONS.MOBILE_WIDTH
                  ? 'Download CSV'
                  : 'Download CSV Template'}
              </TmoButton>
              <TmoButton
                onClick={handleFileUpload}
                className={style.action}
                disabled={!inputFileData}
                type="magenta_secondary"
              >
                {width <= DIMENSIONS.MOBILE_WIDTH ? 'Upload' : 'Upload FAQ'}
              </TmoButton>
            </div>
          </div>
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(UploadFAQ);
