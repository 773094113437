import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const userRepository = {
  getUsersBySearch: async ({ q, syncUpAppName, responseStatus = false }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    const base =
      syncUpAppName === 'DRIVE' ? basePath.adminDrive : basePath.admin;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.search}q=${q}&device-status=ALL`,
      basePath: base,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getUserById: async ({ id, syncUpAppName, responseStatus = false }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    var base;
    var url;
    switch (syncUpAppName) {
      case 'DRIVE':
        base = basePath.userManagement;
        url = resources.driveUser;
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: url,
          basePath: base,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName,
            [headers.userImpersonation.key]: id
          }
        });

      default:
        base = basePath.admin;
        url = resources.user;
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: url.replace(':userId', id),
          basePath: base,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });
    }
  },
  deleteUserById: async ({ id, syncUpAppName, responseStatus = false }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deleteUser,
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: id,
        [headers.SyncUpProduct.key]: syncUpAppName,
        Accept: headers.contentTypeJSON.value
      }
    });
  },
  deleteDriveUserById: async ({
    id,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.user.replace(':userId', id),
      basePath: basePath.adminDrive,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        Accept: headers.contentTypeJSON.value
      }
    });
  },

  registerUser: async ({ body, syncUpAppName }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.registerUser,
      basePath: basePath.adminDrive,
      body: body,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getContacts: async ({ userId, syncUpAppName, responseStatus = false }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.emergencyContacts,
      basePath: basePath.platform,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getUserTasksDetails: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.userTasks,
      basePath: basePath.platform,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },

  getUserTasksDetailsByThing: async ({
    userId,
    thingId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.userTaskByThing.replace(':thingId', thingId),
      basePath: basePath.platform,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  getPhoneLocationDetails: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.phoneLocation,
      basePath: basePath.platformPhoneLocation,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  deletePhoneLocation: async ({
    userId,
    thingId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deletePhoneLocation.replace(':thingId', thingId),
      basePath: basePath.platformPhoneLocation,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  createPhoneLocationGroups: async ({
    userId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.phoneLocationGroups,
      basePath: basePath.platformCoreV1,
      body: body,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  createInviteLocationGroups: async ({
    userId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.createInvitesLocationGroup,
      basePath: basePath.platformCoreV1,
      body: body,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  redeemInvitesLocationGroups: async ({
    userId,
    syncUpAppName,
    groupId,
    inviteId,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.redeemInvitesLocationGroup
        .replace(':groupId', groupId)
        .replace(':inviteId', inviteId),
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.PUT,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      },
      body: { action: 'ACCEPT' }
    });
  },

  getPhoneLocationGroups: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.phoneLocationGroups,
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  getPhoneLocationGroupUsers: async ({
    userId,
    groupId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.phoneLocationGroupUsers.replace(':groupId', groupId),
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  getPhoneLocationGroupVB: async ({
    userId,
    groupId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.phoneLocationGroupVB.replace(':groupId', groupId),
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  deletePhoneLocationMemberInvite: async ({
    groupId,
    inviteId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deletePhoneLocationGroupMemberInvite
        .replace(':groupId', groupId)
        .replace(':inviteId', inviteId),
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  deletePhoneLocationGroup: async ({
    groupId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deletePhoneLocationGroup.replace(
        ':groupId',
        groupId
      ),
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  deletePhoneLocationGroupMember: async ({
    groupId,
    userId,
    memberId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deletePhoneLocationGroupMember
        .replace(':groupId', groupId)
        .replace(':memberId', memberId),
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  deletePhoneLocationGroupAvatar: async ({
    groupId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deletePhoneLocationGroupAvatar.replace(
        ':groupId',
        groupId
      ),
      basePath: basePath.platformCoreV1,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  swapPhoneLocationGroupMember: async ({
    groupId,
    userId,
    targetUserId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.swapPhoneLocationGroupMember
        .replace(':groupId', groupId)
        .replace(':userId', targetUserId),
      basePath: basePath.platformCoreV1,
      body: body,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },
  getSchoolModeDetails: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.schoolMode.replace(':userId', userId),
      basePath: basePath.admin,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  postSchoolModeDetails: async ({
    data,
    userId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.createSchoolMode,
      basePath: basePath.platform,
      body: body ? body : data,
      responseStatus: responseStatus,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },

  deleteSchoolModeDetails: async ({
    scheduledTaskId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.deleteSchoolMode.replace(
        ':scheduledTaskId',
        scheduledTaskId
      ),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },

  deleteUserTask: async ({
    userTaskId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deleteUserTask.replace(':userTaskId', userTaskId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },

  getUserNotificationTokens: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    let base;
    let url;
    switch (syncUpAppName) {
      case 'DRIVE':
        base = basePath.userManagement;
        url = resources.driveUserNotificationTokens;
        break;
      default:
        base = basePath.admin;
        url = resources.userNotificationTokens;
    }
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: url.replace(':userId', userId),
      basePath: base,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getUserNotificationsList: async ({
    userId,
    query,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.notification}${query}`.replace(':userId', userId),
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getDriveUserNotificationsList: async ({
    userId,
    query,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.driveNotification}${query}`,
      basePath: basePath.userManagement,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  postBulkNotification: async ({
    data,
    body,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.bulkNotification}`,
      body: body ? body : data,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getAuthUsers: async ({ syncUpAppName, responseStatus = false }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.authUsers}`,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  deleteAuthUser: async ({ syncUpAppName, userId, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.deleteAuthUser}`.replace(':userId', userId),
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createAuthUser: async ({
    syncUpAppName,
    body,
    data,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.authUsers}`,
      body: body ? body : data,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getUserSafeZones: async ({ id, syncUpAppName, responseStatus = false }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    var base;
    var url;
    switch (syncUpAppName) {
      case 'DRIVE':
        base = basePath.platformDrive;
        url = resources.driveGeofences;
        break;
      default:
        base = basePath.platform;
        url = resources.geofences;
    }
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: url,
      basePath: base,
      headers: {
        [headers.userImpersonation.key]: id,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createUserSafeZones: async ({
    userId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    var base;
    var url;
    switch (syncUpAppName) {
      case 'DRIVE':
        base = basePath.platformDrive;
        url = resources.driveGeofences;
        break;
      default:
        base = basePath.platform;
        url = resources.geofences;
    }
    return await syncUpPlatformRequest({
      urlSuffix: url,
      basePath: base,
      body: body,
      httpMethod: httpMethods.POST,
      responseStatus: responseStatus,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSafeZonesByDeviceId: async ({
    userId,
    deviceId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      urlSuffix: resources.geofencesByDeviceId.replace(':deviceId', deviceId),
      basePath: basePath.platform,
      responseStatus: responseStatus,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getNotificationSetting: async ({
    responseStatus = false,
    thingId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.notificationSettings.replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getTerms: async ({ responseStatus = false, userId, syncUpAppName }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.terms,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getCurrentUser: async ({ responseStatus = false, userId, syncUpAppName }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath:
        syncUpAppName === 'DRIVE'
          ? basePath.platformDriveV2
          : basePath.platform,
      responseStatus: responseStatus,
      urlSuffix:
        syncUpAppName === 'DRIVE'
          ? resources.driveCurrentUser
          : resources.currentUser,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getFeatureFlag: async ({
    responseStatus = false,
    platform,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.featureFlag.replace(':platform', platform),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getFeatureFlagByName: async ({
    responseStatus = false,
    featureflagName,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.featureFlagByName.replace(
        ':featureflagName',
        featureflagName
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getLocalization: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.localization,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getLocalizationByLng: async ({
    responseStatus = false,
    language,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.specificLocalization.replace(':language', language),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  postDrivePushNotificaiton: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDriveV2,
      responseStatus: responseStatus,
      httpMethod: httpMethods.POST,
      urlSuffix: resources.drivePushNotificaiton,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteSafeZone: async ({ responseStatus = false, userId, geofenceId }) => {
    const {
      resources,
      basePath,
      headers,
      syncUpAppName,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    let url =
      syncUpAppName === 'DRIVE'
        ? resources.driveGeofenceById
        : resources.geofencesByGeofenceId;
    return await syncUpPlatformRequest({
      basePath:
        syncUpAppName === 'DRIVE' ? basePath.platformDrive : basePath.platform,
      responseStatus: responseStatus,
      httpMethod: httpMethods.DELETE,
      urlSuffix: url.replace(':geofenceId', geofenceId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateSafeZone: async ({
    responseStatus = false,
    userId,
    geofenceId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.geofencesByGeofenceId.replace(
        ':geofenceId',
        geofenceId
      ),
      httpMethod: httpMethods.PUT,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  setSafeZoneThings: async ({
    responseStatus = false,
    userId,
    geofenceId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      httpMethod: httpMethods.POST,
      urlSuffix: resources.driveThingsGeofenceById.replace(
        ':geofenceId',
        geofenceId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteSafeZoneThings: async ({
    responseStatus = false,
    userId,
    thingId,
    geofenceId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      httpMethod: httpMethods.DELETE,
      urlSuffix: resources.driveGeofenceById
        .replace(':geofenceId', geofenceId)
        .replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateUserTerms: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDriveV2,
      responseStatus: responseStatus,
      urlSuffix: resources.driveCurrentUser,
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteDriveUser: async ({
    userId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.driveCurrentUser,
      basePath: basePath.platformDriveV2,
      responseStatus: responseStatus,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  postSignalRNegotiate: async ({ responseStatus = false, userId }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods,
      syncUpAppName
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      httpMethod: httpMethods.POST,
      urlSuffix: resources.driveSignalr,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createUserTasksDetails: async ({
    userId,
    syncUpAppName,
    responseStatus = false,
    body
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.userTasks,
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },

  getUserTasksDetailsByTaskId: async ({
    userId,
    userTaskId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.userTaskByTaskId.replace(':userTaskId', userTaskId),
      basePath: basePath.platform,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateUserTask: async ({
    userId,
    userTaskId,
    body,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.userTaskByTaskId.replace(':userTaskId', userTaskId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteTaskById: async ({
    userId,
    userTaskId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.userTaskByTaskId.replace(':userTaskId', userTaskId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createUserContact: async ({
    userId,
    body,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.emergencyContacts,
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  userContactDetails: async ({
    userId,
    contactId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.contactByContactId.replace(':contactId', contactId),
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateContactDetails: async ({
    userId,
    contactId,
    body,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.contactByContactId.replace(':contactId', contactId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteContactDetails: async ({
    userId,
    contactId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.contactByContactId.replace(':contactId', contactId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getUserSchoolModeDetails: async ({
    userId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.createSchoolMode,
      basePath: basePath.platform,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSchoolModeDetailsById: async ({
    userId,
    responseStatus = false,
    scheduledTaskId,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deleteSchoolMode.replace(
        ':scheduledTaskId',
        scheduledTaskId
      ),
      basePath: basePath.platform,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateSchoolModeDetails: async ({
    userId,
    responseStatus = false,
    body,
    scheduledTaskId,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deleteSchoolMode.replace(
        ':scheduledTaskId',
        scheduledTaskId
      ),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getUserNotificationList: async ({
    responseStatus = false,
    userId,
    syncUpAppName,
    pageSize
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.userNotificationList + `pageSize=${pageSize}`,
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getUserLights: async ({ responseStatus = false, userId, syncUpAppName }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: `${resources.userLights}`,
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getFirebaseAuthToken: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: `${resources.firebaseAuthToken}`,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getUserProfile: async ({ responseStatus = false, userId, syncUpAppName }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      urlSuffix: `${resources.userProfile}`,
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createUserAuthToken: async ({
    userId,
    body,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.userAuthToken,
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getCurrentUserNotification: async ({
    responseStatus = false,
    userId,
    startDateTime,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.userNotificationsList + startDateTime,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  postBulkReadNotification: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.notificationBulkRead,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createTmoIdToken: async ({
    userId,
    body,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.tmoIdToken,
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getAccessRequestList: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.accessRequestList,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getAccessManagementRedemption: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.availableAccessInvites,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getTrackerAccessManagement: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.accessRequestList,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getMaxGuestLimit: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.maxGuestLimit,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  userParentalControl: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.parentalControl,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  userAccessRedeem: async ({
    responseStatus = false,
    userId,
    action,
    inviteId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.accessInviteRedeem
        .replace(':action', action)
        .replace(':inviteId', inviteId),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  userAccessInvite: async ({
    responseStatus = false,
    userId,
    action,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.accessInvite.replace(':action', action),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  userShareAccess: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.shareAccess,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteAccess: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName,
    accessRequestId
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.accessRequestById.replace(
        ':accessRequestId',
        accessRequestId
      ),
      httpMethod: httpMethods.DELETE,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  deleteBulkNotification: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.notificationBulkDelete,
      httpMethod: httpMethods.DELETE,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  postUserDetails: async ({
    responseStatus = false,
    userId,
    syncUpAppName,
    body
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.currentUser,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateUserDetails: async ({
    responseStatus = false,
    userId,
    syncUpAppName,
    body
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.currentUser,
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  uploadContactIcon: async ({
    responseStatus = false,
    userId,
    contactId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.iconByContactId.replace(':contactId', contactId),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateNotificationDetails: async ({
    responseStatus = false,
    userId,
    notificationId,
    syncUpAppName,
    body
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.notificationByNotificationId.replace(
        ':notificationId',
        notificationId
      ),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  executeUserConsentCOAPCommand: async ({
    responseStatus = false,
    data,
    channelId,
    isTmoUser
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: isTmoUser
        ? resources.coapWatchTmoCommand
        : resources.coapWatchNonTmoCommand,
      basePath: basePath.coreAdminV1,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.channelId.key]: channelId,
        [headers.sendDownstream.key]: headers.sendDownstream.value,
        [headers.saveConsent.key]: headers.sendDownstream.value
      },
      body: data
    });
  }
};

export default userRepository;
