import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { usersReducer } from './users';
import { notificationReducer } from './notification';
import { userThingsReducer } from './user_things';
import { devicesReducer } from './devices';
import { deviceSensorHistoryReducer } from './device_sensor_history';
import { locationSensorHistoryReducer } from './location_sensor_history';
import { activitySensorHistoryReducer } from './activity_sensor_history';
import { temperatureSensorHistoryReducer } from './temperature_sensor_history';
import { healthCheckReducer } from './health_check';

// TODO: find a way to reset all the states to default once the user initiates a new search call
const reducers = combineReducers({
  users: usersReducer,
  notification: notificationReducer,
  userThings: userThingsReducer,
  devices: devicesReducer,
  healthCheck: healthCheckReducer,
  deviceSensorHistory: deviceSensorHistoryReducer,
  locationSensorHistory: locationSensorHistoryReducer,
  activitySensorHistory: activitySensorHistoryReducer,
  temperatureSensorHistory: temperatureSensorHistoryReducer
});

const persistState = store => next => action => {
  next(action);
  localStorage['reduxStore'] = JSON.stringify(store.getState());
};

const loadState = () => {
  try {
    const retrievedState = localStorage.getItem('reduxStore');
    if (retrievedState === null) {
      return {};
    }
    return JSON.parse(retrievedState);
  } catch (err) {
    return {};
  }
};

export default createStore(
  reducers,
  loadState(),
  applyMiddleware(persistState, thunk)
);
