import React, { Fragment, useRef } from 'react';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  Polyline
} from 'react-google-maps';
import { averageGeolocation } from '../../utils/mapUtils';
import { map } from './mapStyles';

import MARKER from '../../static/assets/Marker_Green.png';

function DriveMap({ places = [], zoom }) {
  const mapCenter = useRef(
    places.length
      ? averageGeolocation(
          places.map(p => ({
            lat: p.latitude,
            lng: p.longitude
          }))
        )
      : { lat: 47.577545, lng: -122.165115 }
  );

  const path = () =>
    places.map(p => ({
      lat: p.latitude,
      lng: p.longitude
    }));

  return (
    <>
      <GoogleMap
        center={mapCenter.current}
        defaultZoom={zoom}
        options={{ styles: map }}
      >
        <>
          <Polyline
            path={path()}
            options={{
              geodesic: true,
              strokeColor: '#e20074',
              strokeOpacity: 1.0,
              strokeWeight: 2
            }}
          />

          {places.map((place, index) => (
            <Fragment key={index}>
              {(index === 0 || index === places.length - 1) && (
                <Marker
                  key={index}
                  position={{
                    lat: place.latitude,
                    lng: place.longitude
                  }}
                  icon={{
                    url: MARKER,
                    labelOrigin: { x: 10, y: 12 }
                  }}
                  label={index === 0 ? 'Start' : 'End'}
                />
              )}
            </Fragment>
          ))}
        </>
      </GoogleMap>
    </>
  );
}

export default withScriptjs(withGoogleMap(DriveMap));
