import React, { useState, useContext } from 'react';
import {
  TmoButtons as TmoButton,
  ConfirmationModal,
  DynamicList
} from 'tmo-bps-syncup-web-component-lib';
import FileSaver from 'file-saver';

import useManualFetcher from '../hooks/useManualFetcher';
import deviceService from '../services/deviceService';
import FileUpload from './fileUpload/FileUpload';
import { arrayBufferToString, pluralize } from '../utils/stringUtils';
import { getCurrentDateTimeString } from '../utils/dateUtils';
import StatusContext from '../context/StatusContext';
import { FETCH_STATE } from '../utils/app_constants';
import { trackCustomEvents } from '../utils/appInsight_analytics';

import './Unclaim.module.css';

const headerNameListItemMap = [
  {
    listItemKey: 'hardwareId',
    headerDisplayName: 'IMEI'
  },
  {
    listItemKey: 'status'
  },
  {
    listItemKey: 'message'
  }
];

const resultHeaderNameListItemMap = [
  {
    listItemKey: 'hardwareId',
    headerDisplayName: 'IMEI'
  },
  {
    listItemKey: 'status'
  },
  {
    listItemKey: 'message'
  },
  {
    listItemKey: 'product'
  }
];

const Unclaim = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResultTable, setShowResultTable] = useState(false);
  const [hardwareIds, setHardwareIds] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const [fetchState, actionTrigger, error, resetFetchState] = useManualFetcher({
    action: () => {
      trackCustomEvents(`Bulk Unclaim Devices`, {
        hardwareIds: hardwareIds.map(i => i.hardwareId)
      });
      return deviceService.bulkUnregisterDevice({
        hardwareIds: hardwareIds.map(i => i.hardwareId)
      });
    },
    onLoad: data => {
      addSuccessMessage({
        message: 'Bulk unclaim completed successfully'
      });
      setHardwareIds(data);
    },
    onError: error => {
      addErrorMessage({ error });
    },
    onComplete: () => {
      closeModal();
      setShowResultTable(true);
    }
  });

  const showUnclaimModal = () => hardwareIds && setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const parseInputFile = file => {
    const reader = new FileReader();
    reader.onabort = () =>
      addErrorMessage({ details: 'file reading was aborted' });
    reader.onerror = () => addErrorMessage({ details: 'file reading failed' });
    reader.onload = () => {
      const binaryStr = reader.result;
      const csv = arrayBufferToString(binaryStr);
      const hardwareIds = csv
        .split('\n')
        .map(unparsedHardwareId => {
          const hardwareId = unparsedHardwareId.trim().replace(/\D/g, '');
          if (hardwareId) {
            return {
              hardwareId,
              status: 'Staged',
              message: 'Click unclaim to start the process'
            };
          }
          return null;
        })
        .filter(Boolean);
      setHardwareIds(hardwareIds);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleReset = () => {
    resetFetchState();
    setHardwareIds();
    setShowResultTable(false);
  };

  const handleExport = () => {
    const headers = ['hardwareId', 'status', 'message', 'product'];
    const csv = `${headers.join(',')}\n${hardwareIds
      .map(hardwareId => headers.map(header => hardwareId[header]).join(','))
      .join('\n')}`;
    const csvBlob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });
    FileSaver.saveAs(csvBlob, `BulkUnclaim-${getCurrentDateTimeString()}.csv`);
    trackCustomEvents(`Download`);
  };

  return (
    <div className="BulkUnclaim">
      <FileUpload
        title="Please load the IMEIs in csv file (separated in rows)"
        inputPlaceholderText=" Click to upload csv file"
        onReset={handleReset}
        onUpload={parseInputFile}
        actionBtnClick={showUnclaimModal}
        actionBtnName="Unclaim All"
        isUnclaim
      />
      {hardwareIds && (
        <DynamicList
          headerNameListItemMap={
            showResultTable
              ? resultHeaderNameListItemMap
              : headerNameListItemMap
          }
          listItems={hardwareIds}
        />
      )}
      <div className="Unclaim-btn-container">
        <ConfirmationModal
          isOpen={isModalOpen}
          onAcceptBtnClicked={actionTrigger}
          onDenyBtnClicked={closeModal}
          buttonsDisabled={fetchState === FETCH_STATE.LOADING}
          title={`Are you sure you want to unclaim these devices?`}
          message={`You are about to unclaim ${hardwareIds?.length} ${pluralize(
            {
              string: 'device',
              count: hardwareIds?.length
            }
          )}. This operation is not reversible.`}
          acceptBtnTitle="Unclaim"
          denyBtnTitle="Cancel"
        />
        {fetchState === FETCH_STATE.COMPLETE && (
          <TmoButton
            className="Unclaim-reset-btn Unclaim-reset-btn:hover"
            onClick={handleExport}
          >
            Download
          </TmoButton>
        )}
        {error && `There was an error ${error}}`}
      </div>
    </div>
  );
};

export default Unclaim;
