import React from 'react';
import uuidv4 from 'uuid/v4';

export const getRandomString = uuidv4;

export const arrayBufferToString = ab =>
  new Uint8Array(ab).reduce((p, c) => p + String.fromCharCode(c), '');

export const pluralize = ({ string, count }) =>
  `${string}${count <= 1 ? '' : 's'}`;

export const formatPhoneNumber = str => {
  let cleaned = ('' + str).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

export const highlightSearchQuery = ({ imei, query }) => {
  const term = imei.split(new RegExp(`(${query})`, 'gi'));
  return (
    <>
      {term.map((t, index) =>
        t.toLowerCase() === query.toLowerCase() ? (
          <mark key={imei}>{t}</mark>
        ) : (
          t
        )
      )}
    </>
  );
};

export const prettifyCamelCase = str => {
  let output = '';
  let len = str.length;
  let char;

  for (let i = 0; i < len; i++) {
    char = str.charAt(i);
    if (i === 0) {
      output += char.toUpperCase();
    } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += ' ' + char;
    } else if (char === '-' || char === '_') {
      output += ' ';
    } else {
      output += char;
    }
  }
  return output;
};

export const capitalize = ([firstLetter, ...restOfWord]) => {
  const flStr = firstLetter || '';
  return flStr.toUpperCase() + restOfWord.join('');
};

export const stringToBoolean = string => {
  switch (string.toLowerCase().trim()) {
    case 'live':
    case 'on':
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'normal':
    case 'off':
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};

export const START_DATE_TIME_KEY = 'start-date-time';
export const END_DATE_TIME_KEY = 'end-date-time';
export const LAST_1_HOURS = 'last1';
export const LAST_6_HOURS = 'last6';
export const LAST_24_HOURS = 'last24';
export const LAST_WEEK = 'lastWeek';
