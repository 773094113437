import React, { useState, useCallback, useMemo } from 'react';
import {
  TmoCard,
  DynamicList,
  TmoInputDate,
  TmoButtons as TmoButton
} from 'tmo-bps-syncup-web-component-lib';

import {
  APP_PLATFORM_APPTENTIVE,
  DATE_IN_TIME_ZONE
} from '../../../utils/app_constants';
import Fetcher from '../../../components/Fetcher';
import analyticService from '../../../services/analyticsService';
import moment from 'moment';
import {
  getTimestampList,
  momentToFormattedDate
} from '../../../utils/dateUtils';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

import style from './Reviews.module.css';

function Reviews() {
  const [appPlatform, setAppPlatform] = useState(APP_PLATFORM_APPTENTIVE.IOS);
  const [endDate, setEndDate] = useState(moment());
  const startDate = useMemo(() => endDate.clone().subtract(30, 'days'), [
    endDate
  ]);
  const [apptentiveReviews, setApptentiveReviews] = useState();

  const handleSetDate = e => {
    if (moment(e).isValid()) {
      setEndDate(moment(e));
    }
  };

  const headerNameListItemMap = [
    {
      listItemKey: 'title',
      headerDisplayName: 'Title',
      columnClass: 'title',
      columnWidth: 10
    },
    {
      listItemKey: 'content',
      headerDisplayName: 'Content',
      columnClass: 'content',
      columnWidth: 38
    },
    {
      listItemKey: 'rating',
      headerDisplayName: 'Rating',
      columnClass: 'rating',
      columnWidth: 3
    },
    {
      listItemKey: 'app_version',
      headerDisplayName: 'App Version',
      columnClass: 'app_version',
      columnWidth: 3
    },
    {
      listItemKey: 'date',
      headerDisplayName: 'Date',
      columnClass: 'date',
      columnWidth: 6
    },
    {
      listItemKey: 'region',
      headerDisplayName: 'Region',
      columnClass: 'region',
      columnWidth: 6
    }
  ];

  const actionCallback = useCallback(
    () =>
      analyticService.getApptentiveReviews({
        appPlatform,
        startDate,
        endDate
      }),

    [appPlatform, startDate, endDate]
  );

  return (
    <>
      <PageHeader title={'Reviews'} analyticsHeader />
      <div className={style.filters}>
        <div className={style.filter}>
          <label className={style.filter_label}>Platform:</label>
          <select
            className={style.select}
            onChange={e => {
              setAppPlatform(e.target.value);
            }}
            id="platformSelect"
            value={appPlatform}
          >
            <option value={APP_PLATFORM_APPTENTIVE.IOS}>iOS</option>
            <option value={APP_PLATFORM_APPTENTIVE.ANDROID}>Android</option>
          </select>
        </div>
        <div className={style.filter}>
          <label className={style.filter_label}>
            Apptentive API limits 30 days:
          </label>
          <TmoInputDate
            className={style.datepicker}
            value={momentToFormattedDate(endDate)}
            onChange={handleSetDate}
          />
        </div>
      </div>
      <TmoCard className={style.results}>
        <Fetcher
          action={actionCallback}
          onLoad={setApptentiveReviews}
          render={() =>
            !apptentiveReviews.error ? (
              <div className={style.reviews_container}>
                <span className={style.totals}>
                  Total: {apptentiveReviews.total} reviews from{' '}
                  {getTimestampList(startDate, null, DATE_IN_TIME_ZONE.SYSTEM)}{' '}
                  to {getTimestampList(endDate, null, DATE_IN_TIME_ZONE.SYSTEM)}
                </span>
                <DynamicList
                  headerNameListItemMap={headerNameListItemMap}
                  listItems={apptentiveReviews.reviews}
                  type="primary"
                />
                {appPlatform === APP_PLATFORM_APPTENTIVE.IOS ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/t-mobile-syncup-pets/id1461190332#see-all/reviews"
                    className={style.totals}
                  >
                    iOS Store Reviews
                  </a>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.tmobile.pets&hl=en_US&showAllReviews=true"
                    className={style.totals}
                  >
                    Android Store Reviews
                  </a>
                )}

                {apptentiveReviews.has_more === true && (
                  <TmoButton>Show More</TmoButton>
                )}
              </div>
            ) : (
              apptentiveReviews.error
            )
          }
        />
      </TmoCard>
    </>
  );
}

export default Reviews;
