import React, { useState, useCallback } from 'react';

import Fetcher from '../../../components/Fetcher';
import commercialTrackerService from '../../../services/commercialTrackerService';

import AccessPoints from './AccessPoints';
import DoorLocks from './DoorLocks';
import LightingControl from './LightingControl';
import Trackers from './Trackers';
import { getRandomString } from '../../../utils/stringUtils';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';

import style from './DeviceTypeGrid.module.css';

function DeviceTypeGrid({ productId, deviceId }) {
  const [commercialTagData, setCommercialTagData] = useState();

  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => {
    trackCustomEvents(`Refresh`);
    setRefreshKey(getRandomString());
  };
  const actionCallback = useCallback(
    () =>
      commercialTrackerService.getCommercialTagDataStatus({
        productId,
        deviceId
      }),
    [productId, deviceId]
  );
  return (
    <div className={style.container}>
      <AccessPoints />
      <Trackers />
      <Fetcher
        key={refreshKey}
        action={actionCallback}
        onLoad={setCommercialTagData}
        render={() =>
          commercialTagData ? (
            <>
              <DoorLocks
                forceRefresh={forceRefresh}
                tagsDoorLocks={commercialTagData}
                productId={productId}
                deviceId={deviceId}
              />
              <LightingControl
                forceRefresh={forceRefresh}
                tagsDoorLocks={commercialTagData}
                productId={productId}
                deviceId={deviceId}
              />
            </>
          ) : (
            'No Results'
          )
        }
      />
    </div>
  );
}

export default DeviceTypeGrid;
