import React, { useState } from 'react';
import classNames from 'classnames';
import {
  FilterableList,
  TmoInput,
  TmoButtons as TmoButton,
  TmoH2,
  TmoCard
} from 'tmo-bps-syncup-web-component-lib';

import TodoItem from './TodoItem';
import ErrorMessageModal from '../errorHandler/ErrorMessageModal';

import style from './TodoList.module.css';

function TodoList({
  className,
  title,
  list,
  onAdd,
  onRemove,
  addInputPlaceholder,
  filterPlaceholder
}) {
  const [newItem, setNewItem] = useState('');
  const [error, setError] = useState('');

  const handleOnAddItem = event => {
    event.preventDefault();
    onAdd(newItem);
    setNewItem('');
  };

  const handleOnErrorClose = () => setError('');

  return (
    <TmoCard className={classNames(style.container, className)}>
      {error && (
        <ErrorMessageModal message={error} onClose={handleOnErrorClose} />
      )}
      <TmoH2 flush className={style.title}>
        {title}
      </TmoH2>
      <FilterableList
        className={style.items}
        list={list}
        filterByKey="name"
        placeholder={filterPlaceholder}
        renderItem={item => (
          <TodoItem
            key={item.id}
            name={item.name}
            id={item.id}
            onRemove={onRemove}
          />
        )}
      />
      <form onSubmit={handleOnAddItem} className={style.add}>
        <TmoInput
          value={newItem}
          type="text"
          placeholder={addInputPlaceholder}
          className={style.input}
          onChange={setNewItem}
        />
        <TmoButton buttonType="submit" size="lg" disabled={!newItem}>
          +
        </TmoButton>
      </form>
    </TmoCard>
  );
}

export default TodoList;
