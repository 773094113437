import config from '../configs/config';
import { authClient, getMSALToken } from '../auth/authClient';
import httpRequest from './httpRequest';
import { IS_AZURE_AUTH } from 'utils/app_constants';

const { schema, hostName, headers } = config.apiSettings.syncUpPlatform;

const getDefaultHeaders = async () => ({
  [headers.partnerId.key]: headers.partnerId.value,
  [headers.subscriptionKey.key]: headers.subscriptionKey.value,
  [headers.authorization.key]: IS_AZURE_AUTH
    ? await getMSALToken()
    : await authClient.getIdToken()
});

const syncUpPlatformRequest = async ({
  url,
  urlSuffix,
  httpMethod,
  headers,
  basePath = config.apiSettings.syncUpPlatform.basePath.admin,
  body,
  parseResponse,
  formData = false,
  ignoreDefaultHeaders = false,
  responseStatus = false
}) => {
  const fullUrl = url || `${schema}://${hostName}${basePath}${urlSuffix}`;
  const contentType = formData ? null : { 'Content-Type': 'application/json' };
  return await httpRequest({
    url: fullUrl,
    method: httpMethod,
    headers: {
      ...(!ignoreDefaultHeaders && (await getDefaultHeaders())),
      ...headers,
      ...contentType
    },
    body,
    parseResponse,
    formData,
    responseStatus
  });
};

export default syncUpPlatformRequest;
