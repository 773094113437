import React from 'react';

import Poc from '../Poc';
import KidswatchControls from './KidswatchControls';
import tmologo from '../../../static/assets/TMO_Logo_Pennystone.png';
import Authorized from '../../../auth/Authorized';
import { SUA_PAGES } from '../../../utils/app_constants';

import './Kidswatch.module.css';

function Kidswatch({ className }) {
  return (
    <Authorized
      roles={[...SUA_PAGES.TEST_HARNESS.subPages.KIDSWATCH.authorizedRoles]}
      willShowErrorModal
    >
      <Poc productId="KIDSWATCH" logo={tmologo} controls={KidswatchControls} />
    </Authorized>
  );
}

export default Kidswatch;
