import React from 'react';
import { ReactSVG } from 'react-svg';

import { APP_MOBILE } from 'utils/app_constants';
import drive from '../../static/assets/icons/drive.svg';
import pets from '../../static/assets/icons/pets.svg';
import tracker from '../../static/assets/icons/tracker.svg';
import watch from '../../static/assets/icons/watch.svg';

function ProductIcon({ product, className }) {
  const getIcon = product => {
    switch (product) {
      case APP_MOBILE.DRIVE:
        return drive;
      case APP_MOBILE.PETS:
        return pets;
      case APP_MOBILE.WATCH:
        return watch;
      case APP_MOBILE.TRACKER:
        return tracker;
      default:
        return null;
    }
  };

  return <ReactSVG src={getIcon(product)} className={className} />;
}

export default ProductIcon;
