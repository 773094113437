import React, { useState, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  withModal,
  FormModal,
  TmoButtons as TmoButton,
  TmoInput
} from 'tmo-bps-syncup-web-component-lib';

import {
  appInsights,
  trackCustomEvents
} from '../../utils/appInsight_analytics';
import { APP_MOBILE, FAQ_VOTES } from '../../utils/app_constants';
import faqContentService from '../../services/faqContentService';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import StatusContext from '../../context/StatusContext';
import TmoEditor from '../forms/TmoEditor';
import TmoCustomSelect from '../forms/TmoCustomSelect';
import DeleteFAQ from './DeleteFAQ';
import DeleteDemoFAQ from './DeleteDemoFAQ';
import { capitalCase } from '../../utils/helper_functions';

import style from './Votes.module.css';

import editIcon from '../../static/assets/icons/action/edit.svg';
import thumbs_up from '../../static/assets/icons/thumbs_up_icon.svg';
import thumbs_down from '../../static/assets/icons/thumbs_down_icon.svg';
import selected_thumbs_up from '../../static/assets/icons/selected_thumbs_up.svg';
import selected_thumbs_down from '../../static/assets/icons/selected_thumbs_down.svg';

function ProductFAQRating({
  isAdmin,
  openModal,
  details,
  userId,
  onDataChange,
  tabIndex,
  faqContent,
  category,
  language
}) {
  const { id, title, description, upvotes, downvotes } = details;
  const [voteState, setVoteState] = useState(0);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const formFieldSpecs = [
    {
      labelText: 'Category',
      fieldName: 'faq_category',
      formComponent: TmoCustomSelect,
      required: true,
      placeholder: 'Add new category',
      defaultText: category,
      defaultValue: category,
      optionsList: [...new Set(faqContent?.map(f => f.name))]?.map((c, i) => ({
        value: capitalCase(c),
        key: c
      })),
      addToTop: true
    },
    {
      labelText: 'Title',
      fieldName: 'faq_title',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Title',
      defaultValue: title
    },
    {
      labelText: 'Description',
      fieldName: 'faq_description',
      formComponent: TmoEditor,
      required: true,
      placeholder: 'Description',
      defaultValue: /<\/?[a-z][\s\S]*>/i.test(description)
        ? description
        : `<p>${description}</p>`,
      initialValue: /<\/?[a-z][\s\S]*>/i.test(description)
        ? description
        : `<p>${description}</p>`
    }
  ];
  const openEditModal = async () => {
    const {
      isConfirmed,
      formValues: { faq_title, faq_description, faq_category } = {}
    } = await openModal();
    if (isConfirmed) {
      if (faq_title && faq_description && faq_category) {
        trackCustomEvents(`Edit Faq`, {
          faqId: id,
          title: faq_title
        });
        try {
          await faqContentService.updateFAQ({
            product: syncUpProductContext.syncUpAppName,
            body: [
              {
                id,
                title: faq_title,
                description: faq_description,
                category: faq_category,
                language: language
              }
            ]
          });
          addSuccessMessage({
            message: `Successfully updated FAQ: ${faq_title}`
          });
          onDataChange();
        } catch (error) {
          addErrorMessage({ error });
        }
      } else {
        addErrorMessage({
          message: `Validation Error - ${
            !faq_category
              ? 'Category field is required'
              : !faq_description
              ? 'Description field is required'
              : ''
          }`
        });
      }
    }
  };
  const handleVoteSelection = async vote => {
    let upvote = 0,
      downvote = 0;
    switch (voteState) {
      case 0:
        upvote = vote === FAQ_VOTES.UPVOTE ? 1 : 0;
        downvote = vote === FAQ_VOTES.UPVOTE ? 0 : 1;
        break;
      case 1:
        upvote = vote === FAQ_VOTES.UPVOTE ? 0 : -1;
        downvote = vote === FAQ_VOTES.UPVOTE ? 0 : 1;
        break;
      case -1:
        upvote = vote === FAQ_VOTES.UPVOTE ? 1 : 0;
        downvote = vote === FAQ_VOTES.UPVOTE ? -1 : 0;
        break;
      default:
        upvote = 0;
        downvote = 0;
    }
    setVoteState(vote === FAQ_VOTES.UPVOTE ? 1 : -1);
    try {
      await faqContentService.faqVote({
        product: APP_MOBILE.WATCH,
        body: [
          {
            id: id,
            upvotes: upvote,
            downvotes: downvote
          }
        ]
      });
      appInsights &&
        appInsights.trackEvent({
          name: `${APP_MOBILE.WATCH}-FAQ Question ${
            vote === FAQ_VOTES.UPVOTE ? `Up Vote` : `Down Vote`
          } Event`,
          properties: { id, title, userId }
        });
    } catch (error) {}
  };
  return (
    <div
      className={classNames(
        style.vote_section,
        isAdmin && style.faq_vote_section
      )}
    >
      {!isAdmin && (
        <p
          className={style.vote_text}
          aria-label="Was this helpful?"
          tabIndex={tabIndex && (tabIndex === -1 ? -1 : tabIndex + 1)}
        >
          Was this helpful?
        </p>
      )}
      <ul
        className={classNames(
          style.voting_wrapper,
          isAdmin && style.no_cursor && style.faq_voting_wrapper
        )}
      >
        <li>
          {isAdmin && <span className={style.number_of_votes}>{upvotes}</span>}
          {voteState === 1 ? (
            <ReactSVG
              src={selected_thumbs_up}
              alt="selected thumbs up"
              aria-label="selected thumbs up"
              role="button"
              tabIndex={tabIndex && (tabIndex === -1 ? -1 : tabIndex + 2)}
            />
          ) : (
            <ReactSVG
              className={isAdmin && style.thumbs_up_icon}
              src={thumbs_up}
              alt="thumbs up"
              aria-label="thumbs up"
              tabIndex={tabIndex && (tabIndex === -1 ? -1 : tabIndex + 2)}
              role="button"
              onClick={() => {
                !isAdmin &&
                  voteState !== 1 &&
                  handleVoteSelection(FAQ_VOTES.UPVOTE);
              }}
            />
          )}
        </li>
        <li>
          {isAdmin && (
            <span className={style.number_of_votes}>{downvotes}</span>
          )}
          {voteState === -1 ? (
            <ReactSVG
              src={selected_thumbs_down}
              alt="selected thumbs down"
              aria-label="selected thumbs down"
              role="button"
              tabIndex={tabIndex && (tabIndex === -1 ? -1 : tabIndex + 3)}
            />
          ) : (
            <ReactSVG
              className={isAdmin && style.thumbs_down_icon}
              src={thumbs_down}
              alt="thumbs down"
              aria-label="thumbs down"
              role="button"
              tabIndex={tabIndex && (tabIndex === -1 ? -1 : tabIndex + 3)}
              onClick={() => {
                !isAdmin &&
                  voteState !== -1 &&
                  handleVoteSelection(FAQ_VOTES.DOWNVOTE);
              }}
            />
          )}
        </li>
      </ul>
      {isAdmin && (
        <div className={style.modal_wrapper}>
          <TmoButton
            type="magenta_primary"
            icon={<ReactSVG src={editIcon} />}
            className={style.edit_btn}
            onClick={openEditModal}
          >
            Edit FAQ
          </TmoButton>
          <FormModal
            title={`Edit FAQ`}
            fieldSpecs={formFieldSpecs}
            modalType={'slide'}
          />
          <DeleteFAQ id={id} title={title} onDataChange={onDataChange} />
          {title && title.toLowerCase().includes('demo') && (
            <DeleteDemoFAQ
              data={faqContent}
              onDataChange={onDataChange}
              ClassName={style.demo_del_btn}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default withModal(ProductFAQRating);
