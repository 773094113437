import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import ThemeContext from '../../context/ThemeContext';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import SyncUpAppNameSelect from '../SyncUpAppNameSelect';
import { THEMES } from '../../utils/app_constants';

import style from './AsidePanel.module.css';

function AsidePanel() {
  const { theme } = useContext(ThemeContext);
  const { isAuthenticated } = useContext(LoggedInUserContext);
  const location = useLocation();

  return (
    theme === THEMES.THEME_DEFAULT &&
    isAuthenticated && (
      <div className={style.container}>
        <div className={style.product}>
          {!(
            location.pathname.includes('testharness') ||
            location.pathname.includes('unclaim') ||
            location.pathname.includes('dpi')
          ) && <SyncUpAppNameSelect className={style.product_select} />}
        </div>
      </div>
    )
  );
}

export default AsidePanel;
