import config from '../configs/config';
import appCenterRequest from '../repositories/appCenterRequest';
import syncupPlatformRequest from './syncupPlatformRequest';
import { momentToFormattedDate } from '../utils/dateUtils';

const analyticsRepository = {
  getApptentiveReviews: async (appPlatform, startDate, endDate) => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    const url = config.apiSettings.apptentivePlatform.resources.apptentiveReviews
      .replace(':StartDate', momentToFormattedDate(startDate))
      .replace(':EndDate', momentToFormattedDate(endDate));

    return await syncupPlatformRequest({
      urlSuffix: resources.apptentiveReviews,
      basePath: basePath.admin,
      appPlatform: appPlatform,
      httpMethod: httpMethods.POST,
      body: {
        apptentiveUrl: url,
        platformType: appPlatform
      }
    });
  },
  getApptentiveRatings: async ({ appPlatform, startDate }) => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    const url = config.apiSettings.apptentivePlatform.resources.apptentiveRatings.replace(
      ':StartDate',
      startDate
    );

    return await syncupPlatformRequest({
      urlSuffix: resources.apptentiveReviews,
      basePath: basePath.admin,
      appPlatform: appPlatform,
      httpMethod: httpMethods.POST,
      body: {
        apptentiveUrl: url,
        platformType: appPlatform
      }
    });
  },
  getAppCenterStatus: async () => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.appCenterPlatform;

    return await appCenterRequest({
      urlSuffix: resources.appCenterStatus,
      basePath: basePath,
      httpMethod: httpMethods.GET,
      headers: {},
      isStatusRequest: true
    });
  },
  getAppCenterIncidents: async () => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.appCenterPlatform;

    return await appCenterRequest({
      urlSuffix: resources.appCenterIncidents,
      basePath: basePath,
      httpMethod: httpMethods.GET,
      headers: {},
      isStatusRequest: true
    });
  },
  getPlatformLogSearch: async queryString => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncupPlatformRequest({
      urlSuffix: resources.logs.replace(':query', queryString),
      basePath: basePath.admin,
      httpMethod: httpMethods.GET
    });
  },
  getDeviceMap: async startTime => {
    const { basePath, resources } = config.apiSettings.syncUpPlatform;

    return await syncupPlatformRequest({
      basePath: basePath.analytics,
      urlSuffix: resources.deviceMap.replace(':date', startTime),
      parseResponse: r => r
    });
  }
};

export default analyticsRepository;
